import React from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";

const Map = ({
  center = [-34.60376783, -58.381589462346],
  zoom = 13,
  children,
}) => {
  return (
    <MapContainer
      className="map-container"
      center={center}
      zoom={zoom}
      zoomControl={false}
    >
      <ZoomControl position="bottomright" />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {children}
    </MapContainer>
  );
};

export default Map;
