import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import chevronDown from "../assets/icons/chevron-down.svg";
import chevronUp from "../assets/icons/chevron-up.svg";

const Filter = ({ categories, activeCategories, toggleCategory }) => {
  const [visible, setVisible] = useState(false);
  const maxHeight = visible ? "70dvh" : 0;
  const filterRef = useRef();

  useEffect(() => {
    if (filterRef.current) {
      L.DomEvent.disableClickPropagation(filterRef.current);
      L.DomEvent.disableScrollPropagation(filterRef.current);
    }
  }, [filterRef]);

  return (
    <>
      <div ref={filterRef} className="filters" style={{ maxHeight }}>
        <div className="list-title">Categorías</div>
        {Object.keys(categories).map((category) => (
          <div key={category}>
            <div className="category">{category}</div>
            <ul>
              {categories[category].map((type) => (
                <li key={type} className="type">
                  <label>
                    <input
                      type="checkbox"
                      checked={activeCategories.includes(type)}
                      onChange={() => toggleCategory(type)}
                    />
                    {type.replace("_", " ").toLowerCase()}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button
        className="filters-button"
        onClick={() => setVisible((prevVisible) => !prevVisible)}
      >
        <img src={visible ? chevronUp : chevronDown} alt="chevron" />
      </button>
    </>
  );
};

export default Filter;
