export const categoriesDefault = [
  "Nespresso",
  "consultorio",
  "museo_moderno",
  "santander",
  "colorshop",
];

export const categories = {
  Salud: [
    "asociaci\u00f3n",
    "consultorio",
    "hogar",
    "centro educativo",
    "fundaci\u00f3n",
    "centro de formaci\u00f3n",
    "instituto",
    "escuela",
    "centro de integraci\u00f3n",
    "miscelaneo",
    "centro terapeutico",
  ],
  Organizaciones: ["museo_moderno", "amia", "carp"],
  Comercios: ["burger", "espumeria", "Nespresso","colorshop"],
  Bancos: ["santander", "BANCCOR", "galicia"],
};
