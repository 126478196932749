import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import logo from "../assets/images/AsteroidLogo.png";

const Search = ({ onSearch }) => {
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const map = useMap();

  const searchRef = useRef(null);

  useEffect(() => {
    if (searchRef.current) {
      L.DomEvent.disableClickPropagation(searchRef.current);
      L.DomEvent.disableScrollPropagation(searchRef.current);
    }
  }, [searchRef]);

  const handleSearch = async () => {
    setError("");
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${address}`
      );
      const data = await response.json();

      if (data.length > 0) {
        const { lat, lon } = data[0];
        onSearch([lat, lon]);
        map.setView([lat, lon], 13);
      } else {
        setError("No se encontraron resultados.");
      }
    } catch (error) {
      setError("Error al buscar la dirección.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div ref={searchRef} className="searchBar">
      <div className="container">
        <img src={logo} alt="Brand logo" />
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Buscar dirección"
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSearch}>Buscar</button>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Search;
