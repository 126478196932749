import React, { useState } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import { categories, categoriesDefault } from "./data/categories";
import { markerData } from "./data/markerData";
import Map from "./components/Map";
import { Marker, SearchMarker } from "./components/Markers";
import Filter from "./components/Filter";
import Search from "./components/Search";

import "leaflet/dist/leaflet.css";
import "./App.css";

const App = () => {
  const [searchPosition, setSearchPosition] = useState(null);
  const [activeCategories, setActiveCategories] = useState(categoriesDefault); // Tipos activos por defecto

  const handleSearch = (position) => {
    setSearchPosition(position);
  };

  const toggleCategory = (type) => {
    setActiveCategories((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  return (
    <Map>
      <div className="card">
        <Search onSearch={handleSearch} />
        <Filter
          categories={categories}
          activeCategories={activeCategories}
          toggleCategory={toggleCategory}
        />
      </div>
      <MarkerClusterGroup chunkedLoading>
        {markerData
          .filter((data) => activeCategories.includes(data.type))
          .map((data, index) => (
            <Marker
              key={index}
              position={data.position}
              icon={data.type}
              title={data.name}
              subtitle={data.type.replace("_", " ").toLowerCase()}
              description={data.address}
            />
          ))}
      </MarkerClusterGroup>
      {searchPosition && <SearchMarker position={searchPosition} />}
    </Map>
  );
};

export default App;
