import React from "react";
import L from "leaflet";
import { Marker as MarkerLeaflet, Popup } from "react-leaflet";

export const Marker = ({ position, icon, title, subtitle, description }) => {
  return (
    <MarkerLeaflet position={position} icon={getIcon(icon)}>
      <Popup>
        <div className="marker-popup">
          <strong>{title}</strong>
          {subtitle && (
            <span className="marker-popup-subtitle">{subtitle}</span>
          )}
          {description && <i>{description}</i>}
        </div>
      </Popup>
    </MarkerLeaflet>
  );
};

export const SearchMarker = ({ position }) => {
  return (
    <Marker
      position={position}
      icon="search"
      title="Búsqueda encontrada"
      description={
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${position[0]},${position[1]}`}
          target="_blank"
          rel="noreferrer"
        >
          Cómo llegar
        </a>
      }
    />
  );
};

export const getIcon = (type) => {
  let iconUrl;
  try {
    iconUrl = require(`../assets/markers/${type}.png`);
  } catch (error) {
    console.error(`Error loading icon for type ${type}:`, error);
    iconUrl = require("leaflet/dist/images/marker-icon.png"); // Usa una imagen por defecto ../assets/icons/default.png
  }

  return L.icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [41, 41],
  });
};
