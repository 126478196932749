export const markerData = [
  {category:"salud",type:"hogar",address:"J. Kennedy 2041 , Victoria , Buenos Aires",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-34.459864, -58.5448543]},
  {category:"salud",type:"hogar",address:"Matheu 3330 , Mar del Plata , Buenos Aires",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-38.0125251, -57.5679945]},
  {category:"salud",type:"hogar",address:"Av. Lacaze 3963 , Claypole , Buenos Aires",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-34.8088234, -58.3422323]},
  {category:"salud",type:"hogar",address:"Pte. Illia 4250 - Ruta 8 km. 32.5 , Los Polvorines , Buenos Aires",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-34.5275425, -58.70796559999999]},
  {category:"salud",type:"hogar",address:"Manuel Estevez 620 , Avellaneda , Buenos Aires",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-34.65341, -58.3649537]},
  {category:"salud",type:"asociaci\u00f3n",address:"Billinghurst 1260 , San Isidro , Buenos Aires",name:"APADMSI",position:[-34.4735187, -58.538559]},
  {category:"salud",type:"asociaci\u00f3n",address:"Ayacucho 3939 , Olivos , Buenos Aires",name:"APIAD ",position:[-34.5064301, -58.50901649999999]},
  {category:"salud",type:"centro de formaci\u00f3n",address:"Caseros 2756 , Caseros , Buenos Aires",name:"ARCO IRIS (CTRO. DE F.L.) ",position:[-34.6072473, -58.5674797]},
  {category:"salud",type:"miscelaneo",address:"J. B. Alberdi 1641 , Olivos , Buenos Aires",name:"A.R.E.A. Aprendizaje Reeducativo para la Escolarid",position:[-34.5114024, -58.4929988]},
  {category:"salud",type:"miscelaneo",address:"Sir Alex Fleming 939, Mart\u00ednez , Buenos Aires",name:"INSTITUTO ARMONIA DE EDUCACION ESPECIAL",position:[-34.4908954, -58.5241238]},
  {category:"salud",type:"instituto",address:"Roque Saenz Pe\u00f1a 2810 , San Miguel , Buenos Aires",name:"DINAD ",position:[-34.5395417, -58.73247749999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Constancio Vigil 351 , Mu\u00f1iz , Buenos Aires",name:"FUNDACION NUEVA IMAGEN",position:[-34.5561614, -58.7471045]},
  {category:"salud",type:"escuela",address:"Reconquista 1936, Tigre , Buenos Aires",name:"ESCUELA TALLER EL SEMBRADOR S.R.L.",position:[-34.41401889999999, -58.5964423]},
  {category:"salud",type:"escuela",address:"Av. Pte. Roca 2547 , Florida , Buenos Aires",name:"ESCUELA TALLER EL SEMBRADOR S.R.L.",position:[-34.5329498, -58.4909058]},
  {category:"salud",type:"centro de d\u00eda",address:"Urquiza 1059 , San Miguel , Buenos Aires",name:"ESPACIO DE VIDA ",position:[-34.5425398, -58.7023237]},
  {category:"salud",type:"centro de d\u00eda",address:"Gral. Valentin Vergara 3280 92 , Florida , Buenos Aires",name:"ESPACIO TERAPEUTICO S.R.L ",position:[-34.5290061, -58.4916971]},
  {category:"salud",type:"hogar",address:"Per\u00fa 1051 59 , Florida , Buenos Aires",name:"HOGAR TERAPEUTICO FLORIDA II",position:[-34.5449308, -58.506638]},
  {category:"salud",type:"fundaci\u00f3n",address:"Sarmiento 1421 1417 , San Miguel , Buenos Aires",name:"FUNDACION NANO ",position:[-34.543055, -58.7118577]},
  {category:"salud",type:"fundaci\u00f3n",address:"Vicente Lopez 444, Tigre , Buenos Aires",name:"FUNDACION NOSOTROS ",position:[-34.4202622, -58.5829424]},
  {category:"salud",type:"fundaci\u00f3n",address:"Olegario Andrade 91 y Manzone 501, Acasusso , Buenos Aires",name:"FUNDACION NOSOTROS ",position:[-34.4775545, -58.5078584]},
  {category:"salud",type:"fundaci\u00f3n",address:"Manzone 431 441 , Acasusso , Buenos Aires",name:"FUNDACION NOSOTROS ",position:[-34.4769943, -58.50845809999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Monsalvo 2370, Escobar , Buenos Aires",name:"FUNDACION NOSOTROS ",position:[-34.3298806, -58.76608530000001]},
  {category:"salud",type:"hogar",address:"Churruca 8256 , Loma Hermosa , Buenos Aires",name:"HOGAR SAN FRANCISCO DE LA LOMA ",position:[-34.5763358, -58.59918129999999]},
  {category:"salud",type:"hogar",address:"Darregueyra 962 , Los Polvorines , Buenos Aires",name:"HOGAR SAN MIGUEL ",position:[-34.5182399, -58.7082844]},
  {category:"salud",type:"miscelaneo",address:"Ricardo Balbin 2459 , San Mart\u00edn , Buenos Aires",name:"CID - CRECER INSTITUTO DIFERENCIAL",position:[-34.5771242, -58.54619309999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Hait\u00ed 866 , Mart\u00ednez , Buenos Aires",name:"LAS LOMAS ORAL A.C. ",position:[-34.4982578, -58.5400051]},
  {category:"salud",type:"miscelaneo",address:"Benavidez N\u00b0 92 , Benavidez , Buenos Aires",name:"INSTITUTO DE NIVELACION MELODIA S.R.L. ",position:[-34.4214882, -58.71701289999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Dorrego 1270, Mu\u00f1iz , Buenos Aires",name:"Asociacion Civil Mi Encuentro ",position:[-34.5367077, -58.69947939999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Italia 177 , Mu\u00f1iz , Buenos Aires",name:"Asociacion Civil Mi Encuentro ",position:[-34.5363961, -58.69966530000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Saavedra 973, Mu\u00f1iz , Buenos Aires",name:"Asociacion Civil Mi Encuentro ",position:[-34.5479059, -58.6998823]},
  {category:"salud",type:"centro de d\u00eda",address:"Melo 1657 59 , Florida , Buenos Aires",name:"SEGUIR CRECIENDO ",position:[-34.5346014, -58.4910816]},
  {category:"salud",type:"centro de d\u00eda",address:"Uruguay 1022 , Beccar , Buenos Aires",name:"SOLES ",position:[-34.4557375, -58.53375089999999]},
  {category:"salud",type:"centro de d\u00eda",address:"uruguay 1028, Beccar , Buenos Aires",name:"SOLES ",position:[-34.4617272, -58.5454269]},
  {category:"salud",type:"instituto",address:"Virrey Liniers 1651 , Florida , Buenos Aires",name:"Inst. Theslenco, de THESLENCO Luisa Julieta ",position:[-34.5255938, -58.4907885]},
  {category:"salud",type:"instituto",address:"Boulogne Sur Mer 2731 , Don Torcuato , Buenos Aires",name:"INSTITUTO UN LUGAR S.R.L.",position:[-34.4853464, -58.6207187]},
  {category:"salud",type:"instituto",address:"Av. Torcuato de Alvear 2646 (R. 202) , Don Torcuato , Buenos Aires",name:"INSTITUTO UN LUGAR S.R.L.",position:[-34.6165621, -58.5393392]},
  {category:"salud",type:"miscelaneo",address:"Av. A. Illia 10961 - Ruta 8 km 39,9 (Do\u00f1, Tortuguitas , Buenos Aires",name:"POND S.R.L.",position:[-34.4574177, -58.7206215]},
  {category:"salud",type:"asociaci\u00f3n",address:"Suipacha 110 , Turdera , Buenos Aires",name:"ASOCIACION CIVIL \"BETANIA-ENCUENTRO DE NI\u00d1OS ESPECIALES CON JES\u00daS\"",position:[-34.7915284, -58.40474129999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Suipacha 240 , Turdera , Buenos Aires",name:"ASOCIACION CIVIL \"BETANIA-ENCUENTRO DE NI\u00d1OS ESPECIALES CON JES\u00daS\"",position:[-34.7912052, -58.4063868]},
  {category:"salud",type:"asociaci\u00f3n",address:"Esquiu 399 , Turdera , Buenos Aires",name:"ASOCIACION CIVIL \"BETANIA-ENCUENTRO DE NI\u00d1OS ESPECIALES CON JES\u00daS\"",position:[-34.7945396, -58.4075053]},
  {category:"salud",type:"centro educativo",address:"Arturo Illia (ex Emilio Mitre) 1144, Lan\u00fas , Buenos Aires",name:"C.E.P.P. ",position:[-34.6338476, -58.4397402]},
  {category:"salud",type:"instituto",address:"Pedernera 264 , Lomas de Zamora , Buenos Aires",name:"Instituto PUEYRREDON PEDERNERA (TSORGA S.R.L.) ",position:[-34.7578018, -58.3931958]},
  {category:"salud",type:"miscelaneo",address:"Ricardo Balb\u00edn 2919 (ex-Isletas) , Villa Diamante , Buenos Aires",name:"ELIM ",position:[-34.6813373, -58.4284622]},
  {category:"salud",type:"escuela",address:"Pedro Reta 762, Monte Grande , Buenos Aires",name:"ESCUELA PRIVADA DE ENSE\u00d1ANZA NIVELADORA - EPEN",position:[-34.8186087, -58.45742449999999]},
  {category:"salud",type:"miscelaneo",address:"Del Valle Iberlucea 4451 , Remedios de Escalada , Buenos Aires",name:"EVOLUCION S.R.L.",position:[-34.7252047, -58.3968509]},
  {category:"salud",type:"instituto",address:"Darragueira 435 , Banfield , Buenos Aires",name:"INST. DE PSICOP. DEL SUR \"HOGAR DEL SUR\" ",position:[-34.7385059, -58.3999243]},
  {category:"salud",type:"instituto",address:"Independencia 144 , Sarand\u00ed , Buenos Aires",name:"INSTITUTO DEL NI\u00d1O S.R.L. ",position:[-34.6781507, -58.34894109999999]},
  {category:"salud",type:"instituto",address:"Florentino Ameghino 537 541 , Monte Grande , Buenos Aires",name:"Instituto JHAITI S.R.L. ",position:[-34.8138277, -58.46075149999999]},
  {category:"salud",type:"instituto",address:"Florentino Ameghino 552 , Monte Grande , Buenos Aires",name:"Instituto JHAITI S.R.L. ",position:[-34.813577, -58.4606785]},
  {category:"salud",type:"instituto",address:"Florentino Ameghino 350 , Monte Grande , Buenos Aires",name:"Instituto JHAITI S.R.L. ",position:[-34.8148986, -58.46329429999999]},
  {category:"salud",type:"instituto",address:"Reta 354 360 , Monte Grande , Buenos Aires",name:"Instituto JHAITI S.R.L. ",position:[-34.8271786, -58.4620124]},
  {category:"salud",type:"hogar",address:"Virgilio 186 y Tupungato , Lomas de Zamora , Buenos Aires",name:"ANGELES CUSTODIOS -del Obispado de L. de Zamora ",position:[-34.7653887, -58.4390897]},
  {category:"miscelaneo",type:"miscelaneo",address:"Laprida 770 , Lomas de Zamora , Buenos Aires",name:"REDONDEL S.R.L ",position:[-34.7597827, -58.4078424]},
  {category:"salud",type:"miscelaneo",address:"Islas Malvinas 103, Alejandro Korn , Buenos Aires",name:"REDONDEL S.R.L ",position:[-35.0083709, -58.37979300000001]},
  {category:"salud",type:"miscelaneo",address:"Alvear N\u00ba 36 , Lomas de Zamora , Buenos Aires",name:"REDONDEL S.R.L ",position:[-34.76118230000001, -58.4302476]},
  {category:"salud",type:"miscelaneo",address:"Islas Malvinas 251 , Alejandro Korn , Buenos Aires",name:"REDONDEL S.R.L ",position:[-35.0074448, -58.3804602]},
  {category:"salud",type:"clinica",address:"Indalecio Gomez 434 , Temperley , Buenos Aires",name:"SAN CARLOS SA (CLINICA PRIVADA NEUROP.) ",position:[-34.7804027, -58.3827306]},
  {category:"salud",type:"instituto",address:"Calle 875 N\u00ba 4464 , Quilmes , Buenos Aires",name:"Instituto \"TA.P.QUI\" ",position:[-34.7687117, -58.2906601]},
  {category:"salud",type:"asociaci\u00f3n",address:"Jose Hernandez 171 , Ezpeleta , Buenos Aires",name:"UPHA Uni\u00f3n de Padres con Hijos At\u00edpicos ",position:[-34.7422025, -58.2428405]},
  {category:"salud",type:"miscelaneo",address:"Castro Barros 53 55 , Lan\u00fas Oeste , Buenos Aires",name:"WUNJO SRL ",position:[-34.7113505, -58.4025864]},
  {category:"salud",type:"miscelaneo",address:"Olazabal 569, Mar del Plata , Buenos Aires",name:"AVANCEMOS JUNTOS ",position:[-37.9824901, -57.55481649999999]},
  {category:"salud",type:"miscelaneo",address:"Guido 945 , Mar del Plata , Buenos Aires",name:"AVANCEMOS JUNTOS ",position:[-37.9865479, -57.55515219999999]},
  {category:"salud",type:"miscelaneo",address:"Salinas Grande 75, Benito Ju\u00e1rez , Buenos Aires",name:"DESPERTARES ",position:[-37.6816529, -59.79791280000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Alfonsina Storni 5540 , Mar del Plata , Buenos Aires",name:"UPPAD ",position:[-37.9556222, -57.5532628]},
  {category:"salud",type:"asociaci\u00f3n",address:"J. V. Gonz\u00e1lez 150 , San Pedro , Buenos Aires",name:"S.A.D.I.V. \"SER MAS\" (Soc. Amigos del Disminuido ",position:[-33.68005, -59.653398]},
  {category:"salud",type:"asociaci\u00f3n",address:"Lucio Mansilla Km. 5.5 , San Pedro , Buenos Aires",name:"S.A.D.I.V. \"SER MAS\" (Soc. Amigos del Disminuido ",position:[-33.6829662, -59.6875405]},
  {category:"salud",type:"asociaci\u00f3n",address:"Belgrano 675 679 , San Pedro , Buenos Aires",name:"S.A.D.I.V. \"SER MAS\" (Soc. Amigos del Disminuido ",position:[-33.6757835, -59.66286640000001]},
  {category:"salud",type:"miscelaneo",address:"Alvarez Jonte 321 , Ramos Mej\u00eda , Buenos Aires",name:"FRANCOISE DOLTO S.A. ",position:[-34.6392919, -58.5679999]},
  {category:"salud",type:"miscelaneo",address:"Alvarez Jonte 329 , Ramos Mej\u00eda , Buenos Aires",name:"FRANCOISE DOLTO S.A. ",position:[-34.6393679, -58.5681042]},
  {category:"salud",type:"escuela",address:"Esmeralda 417 425 , Haedo , Buenos Aires",name:"ESCUELA ESPECIAL DE RECUPERACI\u00d3N APRENDER A CRECER ",position:[-34.647024, -58.5950322]},
  {category:"salud",type:"instituto",address:"Juli\u00e1n P\u00e9rez 851 , Mor\u00f3n , Buenos Aires",name:"Instituto BLANQUERNA - Educaci\u00f3n Especial - ",position:[-34.656537, -58.6130077]},
  {category:"salud",type:"miscelaneo",address:"Beethoven 1702 , La Reja , Buenos Aires",name:"CREE ",position:[-34.6616835, -58.8218419]},
  {category:"salud",type:"miscelaneo",address:"Alvar Nu\u00f1ez 1477 , Moreno , Buenos Aires",name:"HOGAR Y CENTRO DE DIA DEL SOL S.R.L.",position:[-34.6863179, -58.71917620000001]},
  {category:"salud",type:"miscelaneo",address:"Sgto Gomez 164, Hurlingham , Buenos Aires",name:"BIZET S.R.L. ",position:[-34.5862962, -58.6350674]},
  {category:"salud",type:"miscelaneo",address:"Guemes 1667, Hurlingham , Buenos Aires",name:"BIZET S.R.L. ",position:[-34.5851276, -58.6362102]},
  {category:"salud",type:"instituto",address:"Obispo Miguel Raspanti 609 605 , Haedo , Buenos Aires",name:"IRIM - NUESTRA SE\u00d1ORA DE LA ESPERANZA ",position:[-34.6440676, -58.59564279999999]},
  {category:"salud",type:"escuela",address:"Paris 2726 2786 (ex Paris 2700) , Pontevedra , Buenos Aires",name:"ESCUELA GRANJA ESPECIAL PAPAI ",position:[-34.7338415, -58.7007308]},
  {category:"salud",type:"escuela",address:"Belgrano 1030 , Mor\u00f3n , Buenos Aires",name:"ESCUELA GRANJA ESPECIAL PAPAI ",position:[-34.6597626, -58.6213203]},
  {category:"salud",type:"escuela",address:"Belgrano 1041 , Mor\u00f3n , Buenos Aires",name:"ESCUELA GRANJA ESPECIAL PAPAI ",position:[-34.6598119, -58.6215974]},
  {category:"salud",type:"miscelaneo",address:"Pizzurno 525 (EX 357) , Hurlingham , Buenos Aires",name:"PARQUE QUIRNO ",position:[-34.5861005, -58.63445979999999]},
  {category:"salud",type:"hogar",address:"Sourigues 1796 98 , El Palomar , Buenos Aires",name:"HOGAR LUZ DEL ALMA DE CERRUTI Amelia ",position:[-34.80305500000001, -58.2214693]},
  {category:"salud",type:"hogar",address:"Piovano 3211 , Moreno , Buenos Aires",name:"HOGAR LUZ DEL ALMA DE CERRUTI Amelia ",position:[-34.6485216, -58.7958226]},
  {category:"salud",type:"instituto",address:"Yapeyu 2735 , Moreno , Buenos Aires",name:"Instituto Educativo SIN FRONTERAS (Formando S.A.) ",position:[-34.6318916, -58.7755325]},
  {category:"salud",type:"centro terapeutico",address:"Mariano Moreno 549, Mor\u00f3n , Buenos Aires",name:"UNUC CENTRO TERAPEUTICO S.R.L.",position:[-34.6581353, -58.617017]},
  {category:"salud",type:"miscelaneo",address:"Belgrano 248 , Ramos Mej\u00eda , Buenos Aires",name:"NUEVO DIA S.R.L. ",position:[-34.6422147, -58.56778659999999]},
  {category:"salud",type:"miscelaneo",address:"Avellaneda 135   Belgrano 314 , Ramos Mej\u00eda , Buenos Aires",name:"NUEVO DIA S.R.L. ",position:[-34.64233, -58.56878]},
  {category:"salud",type:"miscelaneo",address:"Avellaneda 151 , Ramos Mej\u00eda , Buenos Aires",name:"NUEVO DIA S.R.L. ",position:[-34.642836, -58.5687118]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ana Maria Janer 1602, Aldo Bonzi , Buenos Aires",name:"RENACER de Fundacion Nuestra Familia",position:[-34.7060664, -58.5147169]},
  {category:"salud",type:"fundaci\u00f3n",address:"Padre Mario Pantaleo 270 , Gonz\u00e1lez Cat\u00e1n , Buenos Aires",name:"67 FUNDACION PBRO. MARIO PANTALEO ",position:[-34.7784268, -58.6316075]},
  {category:"salud",type:" cooperativa",address:"Estocolmo 2446 , Isidro Casanova , Buenos Aires",name:"COOPERATIVA DE PROVISION DE SERVICIOS DE SALUD \"SA",position:[-34.6963658, -58.5884394]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia 240 , Escobar , Buenos Aires",name:"APPANE ",position:[-34.3523611, -58.7928729]},
  {category:"salud",type:"centro de d\u00eda",address:"Alem 39 , San Nicol\u00e1s , Buenos Aires",name:"CENTRO DE DIA PLAZA (ANEXO) ",position:[-34.6069979, -58.37033449999999]},
  {category:"salud",type:"escuela",address:"Francia 326 328 , San Nicol\u00e1s , Buenos Aires",name:"ESCUELA CAMINO S.R.L ",position:[-34.605571, -58.3900529]},
  {category:"salud",type:"instituto",address:"11 de Septiembre 250 , Del Viso , Buenos Aires",name:"INSTITUTO PSICOPEDAGOGICO SAN PABLO S.A.",position:[-34.4446198, -58.791823]},
  {category:"salud",type:"asociaci\u00f3n",address:"Machado 538 , Chascom\u00fas , Buenos Aires",name:"ASID ",position:[-35.5664877, -58.00908010000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Cecilio Lam\u00f3n 200 , Chivilcoy , Buenos Aires",name:"A.T.I.A.D.I.M.",position:[-34.9089158, -60.03933679999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Per\u00f3n e Arenales y Viamonte , Campana , Buenos Aires",name:"APID (ASOC. PROT. INTEGRAL) ",position:[-34.1757957, -58.95238]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rufino Inda 1369 75 , Mar del Plata , Buenos Aires",name:"ASOCIACION CIVIL CAPACITANDO ",position:[-38.0133691, -57.60007820000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia 2696 , Munro , Buenos Aires",name:"ASOCIACION JUVENIL ARAUCANA",position:[-34.5276559, -58.5223616]},
  {category:"salud",type:"asociaci\u00f3n",address:"Estrada 2451 , Sarand\u00ed , Buenos Aires",name:"A.P.A.N.A.U. Asociaci\u00f3n de Ayuda al Ni\u00f1o Autista",position:[-34.6832581, -58.3439941]},
  {category:"salud",type:" hospital",address:"Gabriel Ardoino 714 , Ramos Mej\u00eda , Buenos Aires",name:"CASA HOSPITAL SAN JUAN DE DIOS ",position:[-34.6389287, -58.5554534]},
  {category:"salud",type:" hospital",address:"Av. San Mart\u00edn 536 , Bernal , Buenos Aires",name:"VILLA DEL SOL de OMHOSPA S.A.",position:[-34.7091406, -58.2824691]},
  {category:"salud",type:"miscelaneo",address:"French 5836 , Mar del Plata , Buenos Aires",name:"NUEVOS RUMBOS Integraci\u00f3n S.A. ",position:[-38.0054771, -57.5426106]},
  {category:"salud",type:"centro de d\u00eda",address:"Saavedra 2980 - PB y Quintana 2955, Mar del Plata , Buenos Aires",name:"EL PORTAL DEL SOL CENTRO DE HABILITACI\u00d3N PARA DI ",position:[-38.0135289, -57.56316169999999]},
  {category:"salud",type:"centro de d\u00eda",address:"LAPRIDA 4850, Mar del Plata , Buenos Aires",name:"EL PORTAL DEL SOL CENTRO DE HABILITACI\u00d3N PARA DI ",position:[-38.0072328, -57.5850625]},
  {category:"salud",type:"fundaci\u00f3n",address:"Dorrego 363 , Mart\u00ednez , Buenos Aires",name:"Fundaci\u00f3n RIO PINTURAS ",position:[-34.495686, -58.5021225]},
  {category:"salud",type:"escuela",address:"Lincoln 74 , Wilde , Buenos Aires",name:"Escuela Especial MI CAMINO ",position:[-34.7058162, -58.31364559999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Alem 217, San Nicol\u00e1s , Buenos Aires",name:"ASOCIACION PADRES Y AMIGOS COOPERADORA SAN NICOLAS",position:[-34.6051252, -58.3698922]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia 118 , San Nicol\u00e1s , Buenos Aires",name:"ASOCIACION PADRES Y AMIGOS COOPERADORA SAN NICOLAS",position:[-34.6076725, -58.37035879999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Peluffo (ex-Fraga) 1349 , San Miguel , Buenos Aires",name:"FUNDACION SUZUKI",position:[-34.5403559, -58.72071829999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"San Mart\u00edn 961 , Mor\u00f3n , Buenos Aires",name:"A.M.I.R.O. Asoc. Mutual Integral de Rehabilitaci\u00f3n",position:[-34.6558611, -58.6167212]},
  {category:"salud",type:"miscelaneo",address:"Cris\u00f3logo Larralde (ex-Cnel.Rauch) 2443 , Castelar , Buenos Aires",name:"RUKALEN S.R.L. ",position:[-34.6450283, -58.638632]},
  {category:"salud",type:"miscelaneo",address:"MUNILLA 2498, Castelar , Buenos Aires",name:"RUKALEN S.R.L. ",position:[-34.640981, -58.6435828]},
  {category:"salud",type:"escuela",address:"Arozarena 340 , Tapiales , Buenos Aires",name:"PORTAL DEL SOL Esc. de Recuperaci\u00f3n y Educaci\u00f3n Es",position:[-34.705275, -58.5033721]},
  {category:"salud",type:"centro de d\u00eda",address:"C\u00f3rdoba 1006 22 , Lan\u00fas Este , Buenos Aires",name:"CONVIVIR de CENTRO TRANSDISCIPLINARIO CONVIVIR S.A.",position:[-34.7039588, -58.3764574]},
  {category:"salud",type:"instituto",address:"Ramseyer 717 , Olivos , Buenos Aires",name:"INSTITUTO DE NIVELACI\u00d3N PSICOPEDAG\u00d3GICO ",position:[-34.5061691, -58.4838251]},
  {category:"salud",type:"centro terapeutico",address:"Avda. de los Estudiantes 318 322 , San Antonio de Padua , Buenos Aires",name:"C.T.E. (Centro Terap\u00e9utico Educativo) ",position:[-34.6666718, -58.7000008]},
  {category:"salud",type:"asociaci\u00f3n",address:"Olaz\u00e1bal 4154 , Mar del Plata , Buenos Aires",name:"MARANGELES Asoc.de Padres de Hijos con Discapacida",position:[-38.01127770000001, -57.5770874]},
  {category:"salud",type:"escuela",address:"San Mart\u00edn 367 , Marcos Paz , Buenos Aires",name:"ESCUELA NIVELADORA UN LUGAR PARA VIVIR",position:[-34.7774034, -58.83831230000001]},
  {category:"salud",type:"instituto",address:"Centenario 2680 , San Isidro , Buenos Aires",name:"Instituto PINEP (PINEP S.A.) ",position:[-34.4566011, -58.5349424]},
  {category:"salud",type:"escuela",address:"Llavallol 420 , Lan\u00fas Oeste , Buenos Aires",name:"Escuela de Educ.Especial \"PELDA\u00d1OS SRL\" ",position:[-34.70384260000001, -58.3976756]},
  {category:"salud",type:"centro terapeutico",address:"Amenedo 1355 , Adrogu\u00e9 , Buenos Aires",name:"Centro Educativo Terapeuticol \"DEJALO SER\" ",position:[-34.7928205, -58.3926384]},
  {category:"salud",type:"centro educativo",address:"Martin Rodriguez 769 , Adrogu\u00e9 , Buenos Aires",name:"Centro Educativo Terapeuticol \"DEJALO SER\" ",position:[-34.804569, -58.3983144]},
  {category:"salud",type:"fundaci\u00f3n",address:"Argerich esquina Moyano - Barrio Los Cachorros , Del Viso , Buenos Aires",name:"Fundaci\u00f3n APEX ",position:[-34.4377303, -58.78151219999999]},
  {category:"salud",type:"miscelaneo",address:"RUTA NAC. 210 N\u00b021855, Glew , Buenos Aires",name:"NUESTRO LUGAR - VIDKA S.R.L. ",position:[-34.8884013, -58.3901407]},
  {category:"salud",type:"miscelaneo",address:"Policastro 183   Pilar 953 , Adrogu\u00e9 , Buenos Aires",name:"NUESTRO LUGAR - VIDKA S.R.L. ",position:[-34.81676789999999, -58.39907350000001]},
  {category:"salud",type:"miscelaneo",address:"Av. San Martin 1265 , Adrogu\u00e9 , Buenos Aires",name:"NUESTRO LUGAR - VIDKA S.R.L. ",position:[-34.805818, -58.383033]},
  {category:"salud",type:"centro terapeutico",address:"Av. Pte. Per\u00f3n (ex-Gaona) 752 , Haedo , Buenos Aires",name:"HARMOS - Ctro de Asistencial Terap\u00e9utica Interdis",position:[-34.63788110000001, -58.5812833]},
  {category:"salud",type:"miscelaneo",address:"Racedo 188 , Ezeiza , Buenos Aires",name:"DESPERTARES de Liliana Marisa GALLO",position:[-34.8502672, -58.5142835]},
  {category:"salud",type:"hogar",address:"vagues , San Antonio de Areco , Buenos Aires",name:"Hogar SAN CAMILO para Discapacitados ",position:[-34.2994067, -59.44452169999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Ehrlich 631 , Ituzaing\u00f3 , Buenos Aires",name:"AMANECER - Asoc. de Padres y Amigos del Discapacid",position:[-34.6350135, -58.66291440000001]},
  {category:"salud",type:"instituto",address:"Freud 2888 , SANTOS TESEI , Buenos Aires",name:"Instituto para la Estimulacion del Desarrollo del ",position:[-34.6207016, -58.6337104]},
  {category:"salud",type:"asociaci\u00f3n",address:"Independencia 1533 , Laprida , Buenos Aires",name:"Asoc. de Padres y Amigos del Discapacitado CAMINO ",position:[-37.5460824, -60.79547959999999]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Rivadavia 727 , Quilmes , Buenos Aires",name:" centro de integraci\u00f3n Y DESARROLLO ",position:[-34.7176363, -58.248458]},
  {category:"salud",type:"escuela",address:"Coucheiro 566, Villa Sarmiento , Buenos Aires",name:"ESCUELA ESPECIAL COLEGIO WARD",position:[-34.6348384, -58.5648563]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ruta 8 y Barrancas del Parana Km 227.6 , Pergamino , Buenos Aires",name:"Fundacion Arcas de Amor ",position:[-33.9144216, -60.5352007]},
  {category:"salud",type:"fundaci\u00f3n",address:"Monteagudo 847 , Florencio Varela , Buenos Aires",name:"Fundacion p  la Integracion del Discap. Mental FID",position:[-34.8030663, -58.2747113]},
  {category:"salud",type:"miscelaneo",address:"Hipolito Irigoyen 9173 , Lomas de Zamora , Buenos Aires",name:"SURGIR ",position:[-34.7624451, -58.4031713]},
  {category:"salud",type:"centro de formaci\u00f3n",address:"Monse\u00f1or Larumbe544 , Mart\u00ednez , Buenos Aires",name:"\"C.F.I. LUCERO\" de EL LUCERO DEL ALBA ",position:[-34.4913017, -58.5077762]},
  {category:"salud",type:"fundaci\u00f3n",address:"Garibaldi 21 , Quilmes , Buenos Aires",name:"TIEMPO FELIZ FUNDACION PARA LA RECUPERACION E INTEGRACION DEL DISCAPACITADO",position:[-34.7254405, -58.25943580000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"San Mart\u00edn 723 , Quilmes , Buenos Aires",name:"TIEMPO FELIZ FUNDACION PARA LA RECUPERACION E INTEGRACION DEL DISCAPACITADO",position:[-34.7250821, -58.25789039999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Jose Luis Torres 2957 , Olavarria , Buenos Aires",name:"ADAIHK -Asociacion de Ayuda al Instituto Hellel Ke",position:[-36.8889765, -60.3136621]},
  {category:"salud",type:"asociaci\u00f3n",address:"Los Talas 315 377, Ituzaing\u00f3 , Buenos Aires",name:"A.P.A.N.A. Asociaci\u00f3n de Padres y Amigos de Ni\u00f1o ",position:[-34.629621, -58.6615055]},
  {category:"salud",type:"fundaci\u00f3n",address:"M. de Loreto 2990 , Castelar , Buenos Aires",name:"FUNDACION VIVIR Y CRECER",position:[-34.6554584, -58.64524369999999]},
  {category:"salud",type:"escuela",address:"Sargento Cabral 1662 , Florencio Varela , Buenos Aires",name:"Escuela de Educaci\u00f3n Especial \"SER FELIZ\" ",position:[-34.7800951, -58.2704754]},
  {category:"salud",type:"asociaci\u00f3n",address:"9 de Julio 87 , Tandil , Buenos Aires",name:"Asociaci\u00f2 Civil \"MANOS ABIERTAS\" ",position:[-37.3320382, -59.1313772]},
  {category:"salud",type:"asociaci\u00f3n",address:"Catamarca 4256 , Mar del Plata , Buenos Aires",name:"Asociac\u00f2n S\u00ecndrome de Down de Mar del Plata (ASDEM",position:[-38.0174341, -57.5688243]},
  {category:"salud",type:"escuela",address:"Hip\u00f3lito Irigoyen 9847 , Lomas de Zamora , Buenos Aires",name:"Nueva Escuela Lomas \"N.E.L.\" ",position:[-34.7704674, -58.4050088]},
  {category:"salud",type:"asociaci\u00f3n",address:"Valencia 6455 , Mar del Plata , Buenos Aires",name:"A.I.Pe Asoc. Integradora de Personas con Padeci ",position:[-37.9587872, -57.5686483]},
  {category:"salud",type:"instituto",address:"Mariano Moreno 6158 , Wilde , Buenos Aires",name:"I.I.W.E.N. Inst. Integral Wilde de Ense\u00f1anza y Niv",position:[-34.7024289, -58.31675259999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Presidente Per\u00f3n 964 , Quilmes , Buenos Aires",name:"C.E.R.Y.K Centro de Rehabilitaci\u00f3n y Kinesiolog\u00eda ",position:[-34.7308673, -58.2687603]},
  {category:"salud",type:"asociaci\u00f3n",address:"Chubut 1731 , Mar del Plata , Buenos Aires",name:"A.D.E.R.M.A.D.Asoc.Deport.Educ.Reg.Marplatense.p D",position:[-37.9605871, -57.54699160000001]},
  {category:"salud",type:"miscelaneo",address:"Los Andes 1057 , Bernal , Buenos Aires",name:"Un Nuevo Horizonte ",position:[-34.7122362, -58.30782689999999]},
  {category:"salud",type:"miscelaneo",address:"Manso 160 , Merlo , Buenos Aires",name:"Los Cipreses ",position:[-34.6800558, -58.75557200000001]},
  {category:"salud",type:"miscelaneo",address:"Av. Pte. Illia Nro. 369 , Villa Sarmiento , Buenos Aires",name:"LIMICA ",position:[-34.627877, -58.5681761]},
  {category:"salud",type:"escuela",address:"Haiti 1390 , Bah\u00eda Blanca , Buenos Aires",name:"PEQUE\u00d1O COTTOLENGO MONSE\u00d1OR JOS\u00c9 NASCIMBENI ",position:[-38.7195345, -62.2345267]},
  {category:"salud",type:"miscelaneo",address:"Monteagudo 2632 (ex 766) , Florencio Varela , Buenos Aires",name:"VIVENCIAS ",position:[-34.8030663, -58.2747113]},
  {category:"salud",type:"escuela",address:"Sarmiento 5630 , Villa Ballester , Buenos Aires",name:"RUCA Centro Educativo Terap\u00e9utico GEDAM S.R.L. ",position:[-34.538719, -58.55537429999999]},
  {category:"salud",type:"centro de formaci\u00f3n",address:"Oncativo 122 , Almirante Brown , Buenos Aires",name:"Centro de Formaci\u00f2n Laboral \" LA COMUNA \" ",position:[-34.8348931, -58.3830055]},
  {category:"salud",type:"miscelaneo",address:"Alvear 1262 , Banfield , Buenos Aires",name:"ALVEAR ALEM S.A. ",position:[-34.74461120000001, -58.39173160000001]},
  {category:"salud",type:"escuela",address:"Estrada 5444 , Mar del Plata , Buenos Aires",name:"ESC. ESP. F. DOLTO DE SUSANA ELISABET JOSSERME",position:[-38.0091188, -57.5488751]},
  {category:"salud",type:"miscelaneo",address:"Ambato 1627 , Florencio Varela , Buenos Aires",name:"SER ESPECIAL S.R.L. ",position:[-34.81613189999999, -58.2023075]},
  {category:"salud",type:"escuela",address:"Av. Gaona 2772 , Ramos Mej\u00eda , Buenos Aires",name:"Colegio \"C.A.I.P.A.C.\" ",position:[-34.638363, -58.56724920000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 49 N\u00ba 316 entre 7 y 8 , Col\u00f3n , Buenos Aires",name:"A.P.I.N.E. ",position:[-33.8965305, -61.1000379]},
  {category:"salud",type:"instituto",address:"La Patria 3575-77-79 , Villa Tessei , Buenos Aires",name:"INSTITUTO CAMINO AL SOL ",position:[-34.6167905, -58.6392001]},
  {category:"salud",type:"instituto",address:"Pueyrredon 4197 , San Mart\u00edn , Buenos Aires",name:"INSTITUTO \"LA VENTANA\" Asociaci\u00f3n Civil ",position:[-34.5777812, -58.5438993]},
  {category:"salud",type:"mutual",address:"Canad\u00e1 619 , Mor\u00f3n , Buenos Aires",name:"ETRIMca Mutual Cant\u00e1brica ",position:[-34.6075904, -58.783137]},
  {category:"salud",type:"miscelaneo",address:"Calle 19 Nro. 1756 , La Plata , Buenos Aires",name:"VIDA S.R.L. ",position:[-34.92645, -57.96113]},
  {category:"salud",type:"centro de d\u00eda",address:"Tunuyan (P. 42) 5321 01 y Bianchi 1506 (, San Mart\u00edn , Buenos Aires",name:"CENTRO DE DIA NUEVOS LOGROS S.R.L. ",position:[-34.5905558, -58.54636720000001]},
  {category:"salud",type:"miscelaneo",address:"Av. Hipolito Yrigoyen 22548, Glew , Buenos Aires",name:"TU LUGAR S.R.L.",position:[-34.9066251, -58.39071879999999]},
  {category:"salud",type:"miscelaneo",address:"Angel Gallardo 646, Temperley , Buenos Aires",name:"TU LUGAR S.R.L.",position:[-34.7741168, -58.3915491]},
  {category:"salud",type:"escuela",address:"La Rioja 2691 , Olivos , Buenos Aires",name:"E.I.D.E.P. S.R.L. ",position:[-34.50759, -58.48390000000001]},
  {category:"salud",type:"miscelaneo",address:"Chanas 951 - Dinamarca 1593, Haedo , Buenos Aires",name:"PARQUE HOGAR S.A. ",position:[-34.62241789999999, -58.5979124]},
  {category:"salud",type:"escuela",address:"Av. Mitre 5157 , Caseros , Buenos Aires",name:"Escuela \"NUEVO ESPACIO\" ",position:[-34.6096266, -58.57060629999999]},
  {category:"salud",type:"miscelaneo",address:"R. Panam., R. Escobar, Km. 52,5 N\u00ba455 , Escobar , Buenos Aires",name:"FLENI Fund. P  La Lucha Contra las Enf. Neurologic",position:[-34.3507539, -58.795226]},
  {category:"salud",type:"hogar",address:"Sarmiento 1210 (ex Guillen) , San Miguel , Buenos Aires",name:"HOGAR Y CENTRO DE REHABILITACION SRL",position:[-34.543055, -58.7118577]},
  {category:"salud",type:"centro de d\u00eda",address:"Florida 257 , Florencio Varela , Buenos Aires",name:"Ctro. de Rehab. Cl\u00ednico Ambulatorio \"CERCA\" ",position:[-34.8190346, -58.2703238]},
  {category:"salud",type:"instituto",address:"Defilippi 881 83 85 , Ituzaing\u00f3 , Buenos Aires",name:"Instituto VANPAI ",position:[-34.6570243, -58.6753978]},
  {category:"salud",type:"centro de d\u00eda",address:"Buenos Aires 4231 , Ciudadela , Buenos Aires",name:"CENTRO DE DIA MI LUGAR de GUSTAVO EDUARDO SCORINI ",position:[-34.6411649, -58.54427200000001]},
  {category:"salud",type:"miscelaneo",address:"Calle 11 Nro. 1572 , La Plata , Buenos Aires",name:"CRIA S.R.L. ",position:[-34.9204948, -57.95356570000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Virgen de Lujan 3765 , Moreno , Buenos Aires",name:"FUNDALMA - LA CASA DE MARIA ",position:[-34.5859611, -58.7489657]},
  {category:"salud",type:"miscelaneo",address:"Calle 118 entre 53 y 57 , Hudson , Buenos Aires",name:"NUEVA LUZ S.R.L. ",position:[-34.8183271, -58.192698]},
  {category:"salud",type:"asociaci\u00f3n",address:"Alberti 6042 54 , Mar del Plata , Buenos Aires",name:"NUEVOS HORIZONTES Asoc.Civil p Conten.Asist.al Dis",position:[-37.9893016, -57.5876156]},
  {category:"salud",type:"asociaci\u00f3n",address:"Bahia Blanca 2529 , Mar del Plata , Buenos Aires",name:"NUEVOS HORIZONTES Asoc.Civil p Conten.Asist.al Dis",position:[-37.9867526, -57.5907237]},
  {category:"salud",type:"miscelaneo",address:"Calle 36 Nro 5505 , Platanos , Buenos Aires",name:"CRECER JUNTOS ",position:[-34.7750776, -58.1829674]},
  {category:"salud",type:"asociaci\u00f3n",address:"9 de Julio 36 38 , Avellaneda , Buenos Aires",name:"ARCO IRIS ",position:[-34.6657655, -58.3655583]},
  {category:"salud",type:"asociaci\u00f3n",address:"Schiffely 3552 , La Reja , Buenos Aires",name:"Asociacion Civil ANDAR ",position:[-34.6457299, -58.8346443]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"3 de Febrero 4138 , Mar del Plata , Buenos Aires",name:"Centro Integrador ALITO ",position:[-37.9884629, -57.55974789999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Zufriategui 670 , Ituzaing\u00f3 , Buenos Aires",name:"CENTRO TOPIA S.R.L.",position:[-34.6579734, -58.66418339999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Lebenshon 1021 , Bella Vista , Buenos Aires",name:"FUNDACION SER ",position:[-34.565203, -58.67330549999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Monteagudo 1861 , Vicente L\u00f3pez , Buenos Aires",name:"Grupo Terapeutico DEL SENDERO ",position:[-34.5236677, -58.49587949999999]},
  {category:"salud",type:"hogar",address:"Benito Perez Galdos 9349 , Pablo Podesta , Buenos Aires",name:"HOGAR ARAUCARIA SILVESTRE - PICHIMA S.A.",position:[-34.574723, -58.6156135]},
  {category:"salud",type:"hogar",address:"Av. Mitre 4728 , Caseros , Buenos Aires",name:"HOGAR ARAUCARIA SILVESTRE - PICHIMA S.A.",position:[-34.6102007, -58.56295069999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Eva Peron 1012 , Pilar , Buenos Aires",name:"Fund. Pte. Derqui Cetro Terapeutico CREAR ",position:[-34.4923267, -58.8357272]},
  {category:"salud",type:"miscelaneo",address:"Mitre 420 , Quilmes , Buenos Aires",name:"C.E.T. ALTER S.A. ",position:[-34.7516998, -58.2165293]},
  {category:"salud",type:"centro terapeutico",address:"MALVINAS ARGENTINAS 614, Quilmes , Buenos Aires",name:"C.E.T. ALTER S.A. ",position:[-34.7206336, -58.25460510000001]},
  {category:"salud",type:"escuela",address:"26 de Abril 3684 , Ituzaing\u00f3 , Buenos Aires",name:"ESCUELA DE EDUCACION ESPECIAL Y MODELO PABLO PICASSO SRL",position:[-34.6340228, -58.703256]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. 25 de Mayo 1837 53 , Merlo , Buenos Aires",name:"Asoc.Padres y Amigos del Disc.\"SAN CAYETANO\"",position:[-34.687445, -58.7294152]},
  {category:"salud",type:"miscelaneo",address:"Arengreen 5081 , Virrey del Pino, Buenos Aires",name:"VIRREY DEL PINO de NUEVO ESPACIO S.A.",position:[-34.8477933, -58.6724092]},
  {category:"salud",type:"centro terapeutico",address:"Aristobulko del Valle 1924 , Florencio Varela , Buenos Aires",name:"CENTRO TERAP\u00c9UTICO DEL SUR",position:[-34.8221275, -58.2769454]},
  {category:"salud",type:"taller",address:"Caseros 2564 , San Mart\u00edn , Buenos Aires",name:"TACYP Taller de Capac. y Prod. de Gral. San Mart",position:[-34.5744998, -58.5452161]},
  {category:"salud",type:"centro de d\u00eda",address:"Jacinto Rosso 767 , Temperley , Buenos Aires",name:"CIPAEIJ S:A \"LA CASA DE JUANITA\" Ctro. de Inv. Ps",position:[-34.7785716, -58.38563769999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Francia 2446 , Pergamino , Buenos Aires",name:"Asoc. de Familia Camiliana de Pergamino ",position:[-33.9094295, -60.5903236]},
  {category:"salud",type:"escuela",address:"Malvinas Argentinas 1154 , Temperley , Buenos Aires",name:"COLEGIO DEL ROBLE S.R.L. ",position:[-34.7731898, -58.3814284]},
  {category:"salud",type:"centro de d\u00eda",address:"Copello 1850 , Jose C. Paz , Buenos Aires",name:"Centro de dia \"QUINTA SANS FRONTIERES\" ",position:[-34.5051733, -58.767571]},
  {category:"salud",type:"centro educativo",address:"Manuel Castro 750 , Remedios de Escalada , Buenos Aires",name:"CAMINOS Centro Educativo Terapeutico",position:[-34.72782290000001, -58.40032679999999]},
  {category:"salud",type:"instituto",address:"Ruta 42 a 300 medtros de la Ruta 5 , Mercedes , Buenos Aires",name:"INSTITUTO DIFERENCIAL PRIVADO MERCEDES ",position:[-34.6780077, -59.4253591]},
  {category:"salud",type:"centro de d\u00eda",address:"Rivas 721 , Bella Vista , Buenos Aires",name:"Centro de Dia \"COLOR ESPERANZA\" S.R.L. ",position:[-34.5528292, -58.6789547]},
  {category:"salud",type:"miscelaneo",address:"G\u00fcemes 1150 , Hurlingham , Buenos Aires",name:"ABRACADABRA CET S.R.L. ",position:[-34.5907706, -58.6288217]},
  {category:"salud",type:"centro de d\u00eda",address:"Aristobulo del Valle 1567 , Florencio Varela , Buenos Aires",name:"CENTRO DE DIA CON-VIVIENDO de Ma.GONCALVES PINTO",position:[-34.8053885, -58.2626289]},
  {category:"salud",type:"miscelaneo",address:"Juan B. Justo 3244 , Lan\u00fas , Buenos Aires",name:"Proyecto Siloe ",position:[-34.7261987, -58.3890476]},
  {category:"salud",type:"escuela",address:"Olivera 736 , Ituzaing\u00f3 , Buenos Aires",name:"Escuela Recuperadora de Ituzaingo ",position:[-34.661357, -58.66393259999999]},
  {category:"salud",type:"miscelaneo",address:"9 de Julio 4033 , Lan\u00fas Este , Buenos Aires",name:"Aprender a volar - AMAD ",position:[-34.7132721, -58.3710861]},
  {category:"salud",type:"hogar",address:"Fleming 3587 , San Miguel , Buenos Aires",name:"Hogar y Centro de Dia La Magdalena S.R.L. ",position:[-34.5651822, -58.7277292]},
  {category:"salud",type:"centro de d\u00eda",address:"Coronel Flores 1259 , Rafael Calzada , Buenos Aires",name:"Centro de Dia Santa Teresa de Jesus ",position:[-34.7931215, -58.3671667]},
  {category:"salud",type:"centro de d\u00eda",address:"Eduardo Gonzalez 282 , Bah\u00eda Blanca , Buenos Aires",name:"CENTRO DE D\u00cdA INCUDI de INSTITUTO DE CUSTODIA Y ADAPTACI\u00d3N PARA DISMINUIDOS PSIC",position:[-38.6672107, -62.2525074]},
  {category:"salud",type:"centro de d\u00eda",address:"De Angelis 225 , Bah\u00eda Blanca , Buenos Aires",name:"CENTRO DE D\u00cdA INCUDI de INSTITUTO DE CUSTODIA Y ADAPTACI\u00d3N PARA DISMINUIDOS PSIC",position:[-38.6962649, -62.2893572]},
  {category:"salud",type:"centro de d\u00eda",address:"Mitre 672, Bah\u00eda Blanca , Buenos Aires",name:"CENTRO DE D\u00cdA INCUDI de INSTITUTO DE CUSTODIA Y ADAPTACI\u00d3N PARA DISMINUIDOS PSIC",position:[-38.7105268, -62.27093250000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Alvarado 2380, Bah\u00eda Blanca , Buenos Aires",name:"CENTRO DE D\u00cdA INCUDI de INSTITUTO DE CUSTODIA Y ADAPTACI\u00d3N PARA DISMINUIDOS PSIC",position:[-38.696239, -62.28882600000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Azopardo 327 , Ramos Mej\u00eda , Buenos Aires",name:"CETYPE, Asociacion Civil para el Desarrollo Comuni",position:[-34.6364865, -58.5611648]},
  {category:"salud",type:"escuela",address:"Coronel Flores 1271 , Rafael Calzada , Buenos Aires",name:"Escuela de Educacion Especial Arnoldo Janssen ",position:[-34.7933321, -58.366661]},
  {category:"salud",type:"hogar",address:"Sargento Cabral 824 , Lan\u00fas , Buenos Aires",name:"HOGAR Y CENTRO DE DIA RAMON RIAL de CALCON S.R.L. ",position:[-34.6910063, -58.37545890000001]},
  {category:"salud",type:"centro de d\u00eda",address:"O. Fresedo 9734 , Mar del Plata , Buenos Aires",name:"Centro Integral para Discapacitados Mentales Cosec",position:[-38.0132667, -57.62117389999999]},
  {category:"salud",type:"centro de d\u00eda",address:"25 de Mayo 716 , Quilmes , Buenos Aires",name:"C.E.R DE Garc\u00eda Diego Hernan y Trezza Luc\u00eda Isab",position:[-34.7208799, -58.245032]},
  {category:"salud",type:"centro de d\u00eda",address:"Amenedo 1824 , Adrogu\u00e9 , Buenos Aires",name:"CENTRO DE D\u00cdA SIN L\u00cdMITES SOBRE LAS CAPACIDADES DEL APRENDIZAJE de ASOCIACI\u00d3N CI",position:[-34.7902511, -58.386875]},
  {category:"salud",type:"miscelaneo",address:"BENITO DE MIGUEL 1524, Jun\u00edn , Buenos Aires",name:"IR S.R.L ",position:[-34.6105426, -60.972854]},
  {category:"salud",type:"miscelaneo",address:"Espa\u00f1a 224 , Jun\u00edn , Buenos Aires",name:"IR S.R.L ",position:[-34.5845876, -60.9457908]},
  {category:"salud",type:"miscelaneo",address:"Avda. Pte. peron 4224 , Jose C. Paz , Buenos Aires",name:"Juntos Haciendo Camino S.R.L ",position:[-34.5225293, -58.73838049999999]},
  {category:"salud",type:"centro de d\u00eda",address:"San Martin 1453 , Baradero , Buenos Aires",name:" C.A.Dis. S. ",position:[-33.812014, -59.50463999999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. Gral Paz 900 , Bolivar , Buenos Aires",name:"Centro de Rehabilitacion Integral de Bolivar, C.R.",position:[-36.2376451, -61.12196470000001]},
  {category:"salud",type:"centro de d\u00eda",address:"15 E 151 y 152 Nro 5125 , Berazategui , Buenos Aires",name:"CETIB S.R.L, Rehabilitacion para Discapacitados ",position:[-34.7466812, -58.21590810000001]},
  {category:"salud",type:"miscelaneo",address:"Canale 1865 , Adrogu\u00e9 , Buenos Aires",name:"PROPUESTA S.A. ",position:[-34.7918847, -58.384637]},
  {category:"miscelaneo",type:"miscelaneo",address:"French 5836 , Mar del Plata , Buenos Aires",name:"Nuevos Rumbos Integracion S.A. ",position:[-38.0054771, -57.5426106]},
  {category:"salud",type:"hogar",address:"Calle 449 Nro 1595 , City Bell, Buenos Aires",name:"HOGAR PERMANENTE CON CENTRO DE D\u00cdA LOS TILOS de LOS TILOS ASOCIACI\u00d3N CIVIL",position:[-34.8733176, -58.0789515]},
  {category:"salud",type:"centro de d\u00eda",address:"Gavilan 179 , La Tablada , Buenos Aires",name:"C.E.T.A. CENTRO DE D\u00cdA S.R.L.",position:[-34.6795477, -58.52553529999999]},
  {category:"salud",type:"miscelaneo",address:"Antonio Malaver 1883 , Olivos , Buenos Aires",name:"Nuestra Luz S.R.L ",position:[-34.5206678, -58.4916623]},
  {category:"salud",type:"centro de d\u00eda",address:"Azopardo 1949 , Castelar , Buenos Aires",name:"C.E.I.T (Ctro. de Estimulacion e Integracion Trans",position:[-34.6499702, -58.63310429999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Paseo 107 y Avenida 29 , Villa Gesell , Buenos Aires",name:"Asociacion Arco Iris ",position:[-37.2543887, -56.9926287]},
  {category:"salud",type:"centro de d\u00eda",address:"12 de Octubre 3636 , Mar del Plata , Buenos Aires",name:"K\u00dcMELEN CENTRO DE D\u00cdA de INTEGRACION Y BIENESTAR S.A. ",position:[-38.0407324, -57.5496136]},
  {category:"salud",type:"centro de d\u00eda",address:"DORREGO 4031, Mar del Plata , Buenos Aires",name:"K\u00dcMELEN CENTRO DE D\u00cdA de INTEGRACION Y BIENESTAR S.A. ",position:[-38.01191379999999, -57.5742682]},
  {category:"salud",type:"centro de d\u00eda",address:"Calle 11 Nro. 1572 , Gonnet , Buenos Aires",name:"Centro de Rehabilitacion Infantil Ambulatorio C.R.I.A SRL",position:[-34.8910067, -57.9912796]},
  {category:"salud",type:"centro de d\u00eda",address:"3 de Febrero 1227 , San Fernando , Buenos Aires",name:"Centro de Dia Senderos de San Fernando ISAFE S.R.L",position:[-34.4427054, -58.556417]},
  {category:"salud",type:"miscelaneo",address:"Tres de Febrero 337 , Pilar , Buenos Aires",name:"La Cata - AGLASC S.A. ",position:[-34.4509719, -58.90355599999999]},
  {category:"salud",type:"centro educativo",address:"Luis Braille 1348 , Florencio Varela , Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO V\u00cdNCULOS S.R.L. ",position:[-34.7831611, -58.2684092]},
  {category:"salud",type:"fundaci\u00f3n",address:"Los Tilos 560 66 - Loma Verde, Escobar , Buenos Aires",name:"FUND.PERTENECER APRENDER COMPARTIENDO LAS OPORTUNI",position:[-34.3345207, -58.8402961]},
  {category:"salud",type:"fundaci\u00f3n",address:"Tres Sargentos 1727 , Mart\u00ednez , Buenos Aires",name:"FUND.PERTENECER APRENDER COMPARTIENDO LAS OPORTUNI",position:[-34.4884703, -58.5034898]},
  {category:"salud",type:"hogar",address:"Caferatta 5710 , Caseros , Buenos Aires",name:"Hogar y Centro de Dia Entre Amigos S.A. ",position:[-34.6115273, -58.5807563]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mendelshon 2349, Hurlingham , Buenos Aires",name:"ASOCIACION PLATERITO PRO-ATENCION E INTEGRACION SOCIAL DE NI\u00d1OS EXCEPCIONALES",position:[-34.6036771, -58.6344242]},
  {category:"salud",type:"asociaci\u00f3n",address:"Marqu\u00e9s de Avil\u00e9s 2745 , Hurlingham , Buenos Aires",name:"ASOCIACION PLATERITO PRO-ATENCION E INTEGRACION SOCIAL DE NI\u00d1OS EXCEPCIONALES",position:[-34.591849, -58.6569387]},
  {category:"salud",type:"asociaci\u00f3n",address:"Marqu\u00e9s de Avil\u00e9s 2873 78, Hurlingham , Buenos Aires",name:"ASOCIACION PLATERITO PRO-ATENCION E INTEGRACION SOCIAL DE NI\u00d1OS EXCEPCIONALES",position:[-34.5868246, -58.6505614]},
  {category:"salud",type:"asociaci\u00f3n",address:"Cerrito 3259 , Olavarria , Buenos Aires",name:"Asociacion Pro Ayuda al Ni\u00f1o Sordo ",position:[-36.8866352, -60.32376019999999]},
  {category:"salud",type:"miscelaneo",address:"20 de Septiembre 3665 , Lan\u00fas Oeste , Buenos Aires",name:"Comunidad Terapeutica Surgiendo ",position:[-34.7145138, -58.401287]},
  {category:"salud",type:"instituto",address:"Raquel Espa\u00f1ol 61 , Wilde , Buenos Aires",name:"Instituto Mannoni S.R.L ",position:[-34.7053482, -58.31451419999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Lopez 787 , Monte Grande , Buenos Aires",name:"Asociaci\u00f3n Familiares y Amigos del Discapacitado d",position:[-34.8271786, -58.4620124]},
  {category:"salud",type:"miscelaneo",address:"Salvador Debenedetti 1480 , Olivos , Buenos Aires",name:"NANTUE S.R.L. ",position:[-34.5016083, -58.49659029999999]},
  {category:"salud",type:"miscelaneo",address:"Manuela Maison 925 , Luj\u00e1n , Buenos Aires",name:"AEQUITAS S.R.L. ",position:[-34.5964971, -58.9731452]},
  {category:"salud",type:"asociaci\u00f3n",address:"9 de Julio 6801 , Mar del Plata , Buenos Aires",name:"Nuevo Arcobaleno Asociacion Civil ",position:[-37.9753482, -57.5870979]},
  {category:"salud",type:"asociaci\u00f3n",address:"Comodoro Rivadavia 1173 , Boulogne , Buenos Aires",name:"Asociacion para el Desarrolo Integral de Personas ",position:[-34.487678, -58.579171]},
  {category:"salud",type:"asociaci\u00f3n",address:"Chacabuco 1550 , Florida , Buenos Aires",name:"AUPA Asoc. y Uni\u00f3n de Padres de Ni\u00f1os y Adolesc. c",position:[-34.5260299, -58.4893405]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mendoza 136, Ingeniero Maschwitz , Buenos Aires",name:"AUPA Asoc. y Uni\u00f3n de Padres de Ni\u00f1os y Adolesc. c",position:[-34.3780111, -58.7452592]},
  {category:"salud",type:"asociaci\u00f3n",address:"Olaz\u00e1bal 4154 58 , Mar del Plata , Buenos Aires",name:"Asoc. de Padres con Hijos Discapacitados Mentales ",position:[-37.9949641, -57.5646362]},
  {category:"salud",type:"instituto",address:"Calle 15 N\u00ba 1653 , Berazategui , Buenos Aires",name:"IDEAT - de Nilda Luc\u00eda Giordano ",position:[-34.7798161, -58.2403878]},
  {category:"salud",type:"centro de d\u00eda",address:"14 de Julio 270 , Temperley , Buenos Aires",name:"CENTRO DE DIA IACADI de IACADO S.A. ",position:[-34.7731461, -58.39146520000001]},
  {category:"salud",type:"centro educativo",address:"Chacabuco 157 , Haedo , Buenos Aires",name:"CETENID S.A. ",position:[-34.6456424, -58.58708629999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Farias 87 , San Miguel , Buenos Aires",name:"A.P.A.D. ASOC.DE PROTEC.Y AYUDA AL DISCAPACITADO",position:[-34.55567320000001, -58.702102]},
  {category:"salud",type:"centro de d\u00eda",address:"ventura Picado 743 , Tornquist , Buenos Aires",name:"CENTRO DE D\u00cdA TORNQUIST ",position:[-38.0930371, -62.223598]},
  {category:"salud",type:"instituto",address:"Manuel Castro 1426 , Banfield , Buenos Aires",name:"Instituto EDUCACION y FAMILIA ",position:[-34.7431825, -58.400697]},
  {category:"salud",type:"miscelaneo",address:"Darragueyra 2785 , Mar del Plata , Buenos Aires",name:"SANTA FRANCISCA",position:[-38.0728554, -57.5479958]},
  {category:"salud",type:"hogar",address:"Mercedes de San Mat\u00edn 5927 , Adolfo Sourdeaux , Buenos Aires",name:"HOGAR Y CENTRO DE DIA MAITEN ",position:[-34.5015761, -58.64715469999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Juan B. Justo 179 , Pilar , Buenos Aires",name:"CENTRO MODELO DEL PILAR S.A. ",position:[-34.4543315, -58.9055629]},
  {category:"salud",type:"centro educativo",address:"M\u00e9xico 274 , Lomas de Zamora , Buenos Aires",name:"Centr Educativo Terap\u00e9utico para el Desarrollo Inf",position:[-34.741991, -58.35003990000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Paran\u00e1 5849 , Villa Adelina , Buenos Aires",name:"FUNDACION CRECIENDO CON LOS ANGELES ",position:[-34.5199919, -58.5420352]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia 240 , Escobar , Buenos Aires",name:"ASOCIACION DE PADRES Y AMIGOS DEL NI\u00d1O NEUROLOGICO",position:[-34.3523611, -58.7928729]},
  {category:"salud",type:"miscelaneo",address:"Mansilla 4149 y Sebati\u00e1n Elcano 2419 244, Jose C. Paz , Buenos Aires",name:"Aprendiendo a Ser II ",position:[-34.4969687, -58.80848089999999]},
  {category:"salud",type:"miscelaneo",address:"Ambrosetti y Los Perales , Pilar , Buenos Aires",name:"PILARES DE ESPERANZA ",position:[-34.4462426, -58.87585000000001]},
  {category:"salud",type:"escuela",address:"Gral. Paz 250 , Adrogu\u00e9 , Buenos Aires",name:"ESTABLECIMIENTO EDUCATIVO ARGENTINO ",position:[-34.8081101, -58.39782630000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Bolivia 2729 , San Andres , Buenos Aires",name:"CENTRO INTERDISCIPLINARIO ESPACIO S.R.L. ",position:[-34.5661113, -58.53896069999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Del Valle Iberlucea 5034 , Remedios de Escalada , Buenos Aires",name:"CENTRO INTEGRAL PARA EL RESTABLECIMIENTO EDUCATIVO-SOCIAL - CIPRES",position:[-34.7320756, -58.39787479999999]},
  {category:"salud",type:"instituto",address:"La Primavera 3443 , Mar del Plata , Buenos Aires",name:"INSTITUTO AYJOMSS S.R.L. ",position:[-37.9367244, -57.5513963]},
  {category:"salud",type:"asociaci\u00f3n",address:"Moldes 486 , Llavallol , Buenos Aires",name:"ASOCIACION CIVIL CENTRO PARA EL DISCAPACITADO TALI",position:[-34.7906096, -58.43202929999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Gral. Lavalle 1150 , Monte Grande , Buenos Aires",name:"NUEVO CENTRO - CENTRO DE DIA ",position:[-34.8280047, -58.4641789]},
  {category:"salud",type:"miscelaneo",address:"Cordoba 4039 , Villa Ballester , Buenos Aires",name:"NEPEN-HUE S.R.L. ",position:[-34.5540788, -58.5481245]},
  {category:"salud",type:"asociaci\u00f3n",address:"Thames 333 , Villa Luzuriaga , Buenos Aires",name:"ASOCIACION CIVIL CALIDOSCOPIO ",position:[-34.6660305, -58.5942044]},
  {category:"salud",type:"miscelaneo",address:"Calle 1 N\u00ba 1632 , San Clemente , Buenos Aires",name:"ARCO IRIS DE LA COSTA ",position:[-36.3530157, -56.7214363]},
  {category:"salud",type:"fundaci\u00f3n",address:"Castelli 1961 , Banfield , Buenos Aires",name:"FUNDAR CAMINOS COLEGIO DIANOIA S.R.L.",position:[-34.7498981, -58.4230651]},
  {category:"salud",type:"miscelaneo",address:"Av. Constitucion 6449 , Mar del Plata , Buenos Aires",name:"LARES DE LA COSTA",position:[-37.9580615, -57.5678146]},
  {category:"salud",type:"centro de d\u00eda",address:"Calle 850 N\u00ba 482 , San Francisco Solano , Buenos Aires",name:"CENTRO DE DIA IMAGO ",position:[-34.7721379, -58.2880182]},
  {category:"salud",type:"fundaci\u00f3n",address:"Alejandro Magno 1495 , Ca\u00f1uelas , Buenos Aires",name:"FUNDACION IPNA ",position:[-34.9799631, -58.68219430000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Buen Viaje 1386 88 , Mor\u00f3n , Buenos Aires",name:"ASOCIACION IDEHARCELAS ",position:[-34.6516818, -58.6198492]},
  {category:"salud",type:"centro de d\u00eda",address:"Entre Rios s n esq. Los Laureles s n , Tortuguitas , Buenos Aires",name:"CENTRO NUEVA VIDA de BLANEF S.A. ",position:[-34.4640255, -58.7687307]},
  {category:"salud",type:"fundaci\u00f3n",address:"Unamuno 4187 , Quilmes , Buenos Aires",name:"FUNDACION AINHEP ",position:[-34.7604055, -58.2748765]},
  {category:"salud",type:"miscelaneo",address:"Avenida Miero 4056 , La Reja , Buenos Aires",name:"REVELACION de MAIO Josefina Mar\u00eda ",position:[-34.6425211, -58.8387875]},
  {category:"salud",type:"centro de d\u00eda",address:"General Pico 1548 , Lan\u00fas , Buenos Aires",name:"CE.DI.O. - CENTRO DE DIA OCUPACIONAL NUEVO ESPACIO",position:[-34.7210953, -58.3637464]},
  {category:"salud",type:" union",address:"Entre Rios 2828 , Mar del Plata , Buenos Aires",name:"UMASDECA-Uni\u00f3n Marplatense de Acci\u00f3n Social por lo",position:[-38.0105693, -57.55023860000001]},
  {category:"salud",type:"escuela",address:"Fischetti 4461 , Caseros , Buenos Aires",name:"ESCUELA DE RECUPERACION CASEROS ",position:[-34.6002957, -58.5577608]},
  {category:"salud",type:"centro de d\u00eda",address:"Indalecio Gomez 439 , Temperley , Buenos Aires",name:"CENTRO INTEGRAL CASA NUEVA S.R.L. ",position:[-34.7797953, -58.38260429999999]},
  {category:"salud",type:" union",address:"Gral. Benjam\u00edn Victorica 458, El Palomar , Buenos Aires",name:"UNION OCHO S.A. ",position:[-34.6081762, -58.5955932]},
  {category:"salud",type:"centro educativo",address:"Boulogne Sur Mer 2881 , Don Torcuato , Buenos Aires",name:"CETET S.R.L. ",position:[-34.4865738, -58.61999059999999]},
  {category:"salud",type:"instituto",address:"24 de octubre 1395 , Ituzaing\u00f3 , Buenos Aires",name:"INSTITUTO DE ESTIMULACION Y APRENDIZAJE I.D.E.A. ",position:[-34.663011, -58.6737864]},
  {category:"salud",type:"miscelaneo",address:"Jos\u00e9 Garibaldi 2348 , Llavallol , Buenos Aires",name:"EL SOL DEL SUR S.R.L. ",position:[-34.784418, -58.431746]},
  {category:"salud",type:"escuela",address:"Avda. Dardo Rocha 3445 , Ranelangh , Buenos Aires",name:"ESCUELA PARQUE DE ARBOLADA S.R.L. ",position:[-34.7960213, -58.2224232]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ruta 6 Km 181 , Los Cardales , Buenos Aires",name:"CEDEMIL DE FUNDACION CECILIA DETRY DE MILBERG",position:[-34.3291677, -58.9911723]},
  {category:"salud",type:"miscelaneo",address:"Calle 30 N\u00ba 3358, Necochea , Buenos Aires",name:"TODO PARA ELLOS ",position:[-38.5683981, -58.73022400000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Boero 690 , Ayacucho , Buenos Aires",name:"MI CASITA de A.P.I.D.D.A. ",position:[-37.1506457, -58.47867729999999]},
  {category:"salud",type:"escuela",address:"La Paz 3145 (Ex 247) , Villa Ballester , Buenos Aires",name:"Escuela Especial Maiten de BERFAL S.R.L. ",position:[-34.5492357, -58.5588195]},
  {category:"salud",type:"centro educativo",address:"Calle 11 N\u00ba 4746 56 entre calles 147 y 1, Berazategui , Buenos Aires",name:"C.E.I.R.I.N. de SANTILLAN, Fernanda Mariel ",position:[-34.762001, -58.2112961]},
  {category:"salud",type:"miscelaneo",address:"Dr. Guillermo Rawson 609 , Haedo , Buenos Aires",name:"SOLES DE ABRIL S.R.L. ",position:[-34.6409619, -58.61570870000001]},
  {category:"salud",type:"miscelaneo",address:"Moreno 2709 esq. Jujuy 2689 , Benavidez , Buenos Aires",name:"APOYOS S.R.L. ",position:[-34.413683, -58.6873233]},
  {category:"salud",type:"hogar",address:"9 de Julio 515 , Azul , Buenos Aires",name:"HOGAR VIVENCIAS ",position:[-36.7804959, -59.86410240000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Pierres 56 , Villa Dominico , Buenos Aires",name:"A.D.A. Asociacion Down de Avellaneda ",position:[-34.6936936, -58.3327472]},
  {category:"salud",type:"miscelaneo",address:"Fernando de Aragon 352 , Alejandro Korn , Buenos Aires",name:"UNA BUENA ESTRELLA S.R.L. ",position:[-34.9611903, -58.3863372]},
  {category:"salud",type:"asociaci\u00f3n",address:"Italia 345 , Arrecifes , Buenos Aires",name:"ASOCIACION CIVIL C.R.E.A.R. CENTRO DE REHABILITACI",position:[-34.0661989, -60.1000545]},
  {category:"salud",type:"miscelaneo",address:"Malabia 3480, Mar del Plata , Buenos Aires",name:"LA CASA DEL ANGEL",position:[-38.0790481, -57.55248039999999]},
  {category:"salud",type:"miscelaneo",address:"Alvear 906, Ramos Mej\u00eda , Buenos Aires",name:"APRENDER A APRENDER",position:[-34.6496503, -58.5682568]},
  {category:"salud",type:"instituto",address:"Santa Fe 129, Bragado , Buenos Aires",name:"INSTITUTO ESPERANZA de Silvina Cecilia BENGOCHEA",position:[-35.1122885, -60.50671910000001]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"La Falda 30, Bah\u00eda Blanca , Buenos Aires",name:"C.I.A.C. de DEL VALLE, Nilda Ester",position:[-38.7059387, -62.2518736]},
  {category:"salud",type:"miscelaneo",address:"Andres Chazarreta 2374 (ex 2350), Ituzaing\u00f3 , Buenos Aires",name:"DIEGO DE NAZARETH - MI MAGICO LUGAR",position:[-34.6132662, -58.7159798]},
  {category:"salud",type:"centro educativo",address:"Malabia 674, Baradero , Buenos Aires",name:"C.E.Y.T.T.E.B. S.A.",position:[-33.8088894, -59.5020263]},
  {category:"salud",type:"centro de d\u00eda",address:"Juan Manso 1760, Mar del Plata , Buenos Aires",name:"CENTRO DE DIA CEMID de BIGONI, Monica Ethel",position:[-38.0325858, -57.58342539999999]},
  {category:"salud",type:"miscelaneo",address:"Bogota 877, Pacheco, Buenos Aires",name:"CASA VERDE de KOHAN, Lilian Elizabeth",position:[-34.4594358, -58.6461719]},
  {category:"salud",type:"miscelaneo",address:"Panama 886, Bah\u00eda Blanca , Buenos Aires",name:"INBARE S.A.",position:[-38.7060555, -62.2693588]},
  {category:"salud",type:"asociaci\u00f3n",address:"Tres de Febrero 176, Escobar , Buenos Aires",name:"ASOCIACION CIVIL UN LUGAR EN EL MUNDO",position:[-34.3434358, -58.7884087]},
  {category:"salud",type:"escuela",address:"Maestro Angel D'ELIA 4989, Jose C. Paz , Buenos Aires",name:"ESCUELA ESPECIAL LA PUERTA DEL SOL",position:[-34.5159593, -58.74444980000001]},
  {category:"salud",type:" union",address:"Cisneros 855, Ramos Mej\u00eda , Buenos Aires",name:"U.NU.C. COLOR ESPERANZA S.R.L.",position:[-34.6549073, -58.5536355]},
  {category:"salud",type:"miscelaneo",address:"Anatole France 1171, Lan\u00fas , Buenos Aires",name:"ENTRE TODOS S.R.L.",position:[-34.7017747, -58.38190849999999]},
  {category:"salud",type:"escuela",address:"Uruguay 1654, Mar del Plata , Buenos Aires",name:"EMDEE",position:[-37.9853707, -57.57372669999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Sub Oficial Mayor Roberto Porta 350, Ezeiza , Buenos Aires",name:"LOS ANGELES DEL BOSQUE de FUNDACI\u00d3N CIENT\u00cdFICA Y DEPORTIVA EQUINA ",position:[-34.7902165, -58.5232636]},
  {category:"salud",type:"fundaci\u00f3n",address:"Avenida Alfonsina Storni 1933, La Reja , Buenos Aires",name:"CHECAR - ASOCIACION CIVIL",position:[-34.6331916, -58.8179661]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Carlos Croce 5146 5152, Remedios de Escalada , Buenos Aires",name:"PUENTES DE INTEGRACION",position:[-34.7364958, -58.4050638]},
  {category:"salud",type:"consultorio",address:"Bartolome Mitre 125, Ramos Mej\u00eda , Buenos Aires",name:"CONSULTARIOS PEDIATRICOS DR. MAZA",position:[-34.6415562, -58.56239489999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Republica 5672, Villa Ballester , Buenos Aires",name:"CENTRO DE D\u00cdA CUMELEN S.R.L.",position:[-34.5409086, -58.55802409999999]},
  {category:"salud",type:"instituto",address:"Lamadrid 530, Lomas de Zamora , Buenos Aires",name:"INSTITUTO CARI",position:[-34.7594022, -58.389808]},
  {category:"salud",type:"miscelaneo",address:"Avenida Gaspar Campos 1143, Bella Vista , Buenos Aires",name:"PROYECTO NUEVO S.R.L.",position:[-34.5713143, -58.6945498]},
  {category:"salud",type:"fundaci\u00f3n",address:"Chacabuco 1501   1545   1547, Ingeniero Maschwitz , Buenos Aires",name:"FUNDACI\u00d3N EDUCATIVA ARTE TERAP\u00c9UTICA ESPECIAL",position:[-34.39121540000001, -58.7384929]},
  {category:"salud",type:"centro de d\u00eda",address:"Mendoza 127, Mor\u00f3n , Buenos Aires",name:"CENTRO DE REHABILITACION INTERDISCIPLINARIA",position:[-34.647014, -58.62248149999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Arenales N\u00ba 1318, Vicente L\u00f3pez , Buenos Aires",name:"ASOCIACION CIVIL SENDEROS DEL SEMBRADOR",position:[-34.522265, -58.4825512]},
  {category:"salud",type:"asociaci\u00f3n",address:"Hipolito Yrigoyen 2659 - Ruta 188 Km 8, San Nicol\u00e1s , Buenos Aires",name:"ASOCIACION CIVIL LIHUE QUIMLU",position:[-34.603699, -58.381163]},
  {category:"salud",type:"asociaci\u00f3n",address:"OLIVERA 2249, Ituzaing\u00f3 , Buenos Aires",name:"EQUIPO BIOS ASOCIACI\u00d3N CIVIL",position:[-34.6703997, -58.681242]},
  {category:"salud",type:"miscelaneo",address:"Paran\u00e1 1963, Mart\u00ednez , Buenos Aires",name:"MI CASA HOGAR CENTRO DE D\u00cdA",position:[-34.5000234, -58.50330529999999]},
  {category:"salud",type:"miscelaneo",address:"Belgrano 664 666, Mor\u00f3n , Buenos Aires",name:"SOBRE PUENTES",position:[-34.656513, -58.6213534]},
  {category:"salud",type:"asociaci\u00f3n",address:"Tte. Gral. Ricchieri 221 esq. Corrientes 1581, Bella Vista , Buenos Aires",name:"ANDU AVY S.R.L.",position:[-34.5562379, -58.69440829999999]},
  {category:"salud",type:"miscelaneo",address:"Misiones 2010, Mar del Plata , Buenos Aires",name:"SOL CRECIENDO DE EDUARDO JOSE BAQUERO",position:[-37.9923566, -57.5679811]},
  {category:"salud",type:"miscelaneo",address:"Tte. Camilli 511, Moreno , Buenos Aires",name:"CREFIM S.R.L.",position:[-34.6430266, -58.7931947]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Manuel Ocantos 32 40, Avellaneda , Buenos Aires",name:"CIANTAL S.R.L.",position:[-34.665913, -58.3567024]},
  {category:"salud",type:"centro educativo",address:"Salta 1225, Ca\u00f1uelas , Buenos Aires",name:"CENTRO EDUCATIVO TERAPEUTICO WELCOME",position:[-35.0526796, -58.7456347]},
  {category:"salud",type:"fundaci\u00f3n",address:"Fonrouge 353 355, Lomas de Zamora , Buenos Aires",name:"FUNDACION KEIRO",position:[-34.7579044, -58.3823859]},
  {category:"salud",type:"centro de d\u00eda",address:"Lacroze 5026, Villa Ballester , Buenos Aires",name:"CTRO. DE REHABILITACION BALLESTER S.R.L.",position:[-34.54758899999999, -58.5550017]},
  {category:"salud",type:"escuela",address:"Teodoro Bronzini, Mar del Plata , Buenos Aires",name:"ESCUELA ORAL MAR DEL PLATA S.R.L.",position:[-37.9854959, -57.5810862]},
  {category:"salud",type:"centro de d\u00eda",address:"Madariaga 374, Sarand\u00ed , Buenos Aires",name:"CENTRO DE DIA ARCO IRIS S.R.L.",position:[-34.6852581, -58.3469682]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 28 N\u00ba 807, Balcarce , Buenos Aires",name:"ASOCIACION HOGAR NUESTRO SUE\u00d1O DE BALCARCE",position:[-37.8542999, -58.253583]},
  {category:"salud",type:"escuela",address:"Avellaneda 312, Z\u00e1rate , Buenos Aires",name:"ESCUELA ESPECIAL SER de Mirta Alicia RIOS",position:[-34.0938101, -59.02787420000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Juan de Garay 4763, Caseros , Buenos Aires",name:"CARRANPI S.R.L.",position:[-34.6161643, -58.5638404]},
  {category:"salud",type:"asociaci\u00f3n",address:"Lavalle 11, Bah\u00eda Blanca , Buenos Aires",name:"ASOCIACION DE PADRES DE AUTISTAS DE BAHIA BLANCA",position:[-38.7215256, -62.26158599999999]},
  {category:"salud",type:"miscelaneo",address:"Manuel Maza 3435, Moreno , Buenos Aires",name:"SOLSIRE S.A.",position:[-34.5908365, -58.7476802]},
  {category:"salud",type:"escuela",address:"Azcuenaga 761, Pergamino , Buenos Aires",name:"COLEGIO SANTA CLARA DE ASIS de HERMANOS DE ASIS S.R.L",position:[-33.8975318, -60.5683823]},
  {category:"salud",type:"miscelaneo",address:"Espora (Ex Espa\u00f1a) 789, Temperley , Buenos Aires",name:"INTEGRAR SIN BARRERAS DE ELBA MARGARITA CARDENAS",position:[-34.7695238, -58.40041970000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Hip\u00f3lito Irigoyen 10802, Temperley , Buenos Aires",name:"ASOCIACI\u00d3N CIVIL V.A.E. (VOLVER A ENSE\u00d1AR)",position:[-34.7846477, -58.4070154]},
  {category:"salud",type:"centro educativo",address:"Remigio Lopez (Ex Av. Sarmiento) 1759, San Miguel , Buenos Aires",name:"CENTRO EDUCATIVO Y DE ESTIMULACION SAN MIGUEL S.A.",position:[-34.5656834, -58.7409177]},
  {category:"salud",type:"instituto",address:"Bartolome Mitre 1043, Mor\u00f3n , Buenos Aires",name:"ITAL",position:[-34.6527533, -58.6234019]},
  {category:"salud",type:"miscelaneo",address:"Anatole France 2644 46 48, Castelar , Buenos Aires",name:"MACOGUCA - SOLPIVA S.R.L.",position:[-34.6554584, -58.64524369999999]},
  {category:"salud",type:"centro educativo",address:"Enrique Fernandez 2274, Lan\u00fas , Buenos Aires",name:"CET VIDA S.A.",position:[-34.6930232, -58.40925000000001]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Virgilio 378, Isidro Casanova , Buenos Aires",name:"C.I.R.E. SALUD MENTAL S.R.L.",position:[-34.7168121, -58.5835261]},
  {category:"salud",type:"clinica",address:"Estrada 345, Lomas de Zamora , Buenos Aires",name:"CLINICA PRIVADA UNO S.A.",position:[-34.7537983, -58.4185124]},
  {category:"salud",type:"centro de d\u00eda",address:"Armenia 438, Lan\u00fas , Buenos Aires",name:"EDUCACION Y FAMILIA",position:[-34.6679314, -58.40816909999999]},
  {category:"salud",type:"miscelaneo",address:"Ameghino 179, San Nicol\u00e1s , Buenos Aires",name:"INDIGO ESPACIO TERAPEUTICO S.A.",position:[-33.3331954, -60.2138228]},
  {category:"salud",type:"miscelaneo",address:"Julio A. Roca N\u00b0 501, Luj\u00e1n , Buenos Aires",name:"CASA NUESTRA SE\u00d1ORA DEL PILAR",position:[-34.5610911, -59.12720969999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mitre 1825, San Pedro , Buenos Aires",name:"ANDAR-ASOC.PRO-REHAB.DE LA PERSONA DISCAPACITADA",position:[-33.6848232, -59.67133080000001]},
  {category:"salud",type:"miscelaneo",address:"Barreiro Aguirre 4294, Olivos , Buenos Aires",name:"FUTURO ABIERTO S.R.L.",position:[-34.5073911, -58.5181185]},
  {category:"salud",type:"fundaci\u00f3n",address:"Barrio 20 de Octubre - Casa N\u00b0 47, Rojas, Buenos Aires",name:"FUNDAICO",position:[-34.1901144, -60.7398306]},
  {category:"salud",type:"miscelaneo",address:"Colon 1061, Remedios de Escalada , Buenos Aires",name:"EY WAIS S.R.L.",position:[-34.7289926, -58.41123109999999]},
  {category:"salud",type:"miscelaneo",address:"Brandsen 2751, Ituzaing\u00f3 , Buenos Aires",name:"RENE MAGRITTE de JAVIER ALEJANDRO Y GUSTAVO SAGUATI S.H.",position:[-34.6377235, -58.6919492]},
  {category:"salud",type:"miscelaneo",address:"Mercedes de San Martin 5957, Adolfo Sourdeaux , Buenos Aires",name:"GLAFMA S.R.L.",position:[-34.5021902, -58.6476941]},
  {category:"salud",type:"centro educativo",address:"Sarmiento 2928, Castelar , Buenos Aires",name:"EDUCACION TERAPEUTICA CASTELAR",position:[-34.6445842, -58.64826960000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Las Anemonas 1490, Escobar , Buenos Aires",name:"ASOCIACION CIVIL TRIADE",position:[-34.3383367, -58.7633738]},
  {category:"salud",type:"instituto",address:"ALVARADO 927 931, Ramos Mej\u00eda , Buenos Aires",name:"INSTITUTO SAN PEDRO S.A.",position:[-34.6539209, -58.5698215]},
  {category:"salud",type:"centro terapeutico",address:"Belgrano 2697, Victoria , Buenos Aires",name:"CENTRO TERAPEUTICO NANIHUE",position:[-34.4529657, -58.5461847]},
  {category:"salud",type:"miscelaneo",address:"Emilio Mitre 227, Villa Sarmiento , Buenos Aires",name:"CAVIAHUE de Emiliano N, VELOSO, Roque I. QUILODRAN y mar\u00eda de los Milagros BORDE",position:[-34.6371052, -58.56835390000001]},
  {category:"salud",type:"consultorio",address:"Jose Marmol 1583 Piso 5 Dpto.B, Florida , Buenos Aires",name:"ASISTENCIA EN DESARROLLO, APRENDIZAJE Y PSICOLOGIA",position:[-34.5335467, -58.4803291]},
  {category:"salud",type:"miscelaneo",address:"Las Heras 120, Monte Grande , Buenos Aires",name:"CENTRO REDES DE RUBILLO ROSANA Y GAMBARRUTTA LAURA",position:[-34.8147871, -58.4675416]},
  {category:"salud",type:"centro educativo",address:"Bustamante 170, San Nicol\u00e1s , Buenos Aires",name:"CEADIA SAN NICOLAS S.R.L.",position:[-33.3250148, -60.2251176]},
  {category:"salud",type:"miscelaneo",address:"Constanzo 174, Monte Grande , Buenos Aires",name:"DAME TU MANO",position:[-34.8146528, -58.4734594]},
  {category:"salud",type:"centro de d\u00eda",address:"Colon 858 (ex 820), Remedios de Escalada , Buenos Aires",name:"DIANA (CTRO. REGIONAL DE REHAB. DE LA SORDERA ) ",position:[-34.7212143, -58.39850999999999]},
  {category:"salud",type:"centro terapeutico",address:"Quintana 2406, Burzaco, Buenos Aires",name:"CET JUNTOS de ALMED S.A.",position:[-34.8203517, -58.3888163]},
  {category:"salud",type:"miscelaneo",address:"Dean Funes 510, Luj\u00e1n , Buenos Aires",name:"MANOS DEL SUR",position:[-34.5659483, -59.09722499999999]},
  {category:"salud",type:"miscelaneo",address:"Almafuerte 460, San Pedro , Buenos Aires",name:"CONFIAR",position:[-33.6781897, -59.66097610000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Blas Parera 3663 65 75, Olivos , Buenos Aires",name:"FUNDACION CENTRO ALUNCO",position:[-34.5189598, -58.5141844]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ruta 8 KM 77, Parada de Robles, Buenos Aires",name:"FUND.PARA EL SERV.Y EST.DE LA DISC.MENTAL-FUSEDIM",position:[-34.3733828, -59.1264622]},
  {category:"salud",type:"miscelaneo",address:"Alberdi 2460, Florencio Varela , Buenos Aires",name:"LOGROS de MIGUENS, ANSELMI Y DE LA IGLESIA",position:[-34.8010692, -58.2730411]},
  {category:"salud",type:"miscelaneo",address:"Sarmiento 1831, San Miguel , Buenos Aires",name:"REHABILITANDO S.R.L.",position:[-34.5408562, -58.7162123]},
  {category:"salud",type:"centro educativo",address:"Arana 517, Monte Grande , Buenos Aires",name:"CENTRO EDUCATIVO MILAGROS ",position:[-34.8120139, -58.4625462]},
  {category:"salud",type:"miscelaneo",address:"MANUEL DIAZ VELEZ 2041, Olivos , Buenos Aires",name:"EQUIPO EIREN SRL",position:[-34.5026693, -58.50241279999999]},
  {category:"salud",type:"centro de d\u00eda",address:"PASEO 123, Villa Gesell , Buenos Aires",name:"CENTRO DE DIA C.I.A.D",position:[-37.270851, -56.986881]},
  {category:"salud",type:"miscelaneo",address:"CASTELLI 5905 9, Carapachay , Buenos Aires",name:"CERCA INTEGRACIONES",position:[-34.5283305, -58.5380342]},
  {category:"salud",type:"escuela",address:"WARNES 3966, Lan\u00fas Oeste , Buenos Aires",name:"ESCUELA DE EDUCACION ESPECIAL SAN FRANCISCO",position:[-34.6746045, -58.43059539999999]},
  {category:"salud",type:"consultorio",address:"San Mart\u00edn 1031, Tandil , Buenos Aires",name:"GENESIS SALUD de GENESIS SALUD TANDIL S.A.",position:[-37.3241057, -59.13005399999999]},
  {category:"salud",type:"centro de d\u00eda",address:"CJAL. HORACIO JULIAN 1325, Mor\u00f3n , Buenos Aires",name:"VIVA LA VIDA CENTRO DE DIA",position:[-34.6622634, -58.6023678]},
  {category:"salud",type:"escuela",address:"CORDERO 2266 68, Sarand\u00ed , Buenos Aires",name:"ESCUELA ALAS",position:[-34.6869886, -58.3498263]},
  {category:"salud",type:"miscelaneo",address:"CERVETTI 359, Monte Grande , Buenos Aires",name:"SIN LIMITES DE SIN LIMITES SA",position:[-34.8185219, -58.4857105]},
  {category:"salud",type:"miscelaneo",address:"RUFINO VIERA 298, Bolivar , Buenos Aires",name:"CET Y HOGAR ALEGRIAS",position:[-36.2257677, -61.1014138]},
  {category:"salud",type:"miscelaneo",address:"INDEPENDENCIA 5182, Villa Ballester , Buenos Aires",name:"BELLE EPOQUE SRL",position:[-34.547505, -58.5586323]},
  {category:"salud",type:"centro educativo",address:"LAVALLE 663, Temperley , Buenos Aires",name:"C.E.N.D.A.",position:[-34.7756451, -58.4065245]},
  {category:"salud",type:"instituto",address:"RUTA 6 KM 1.5, Campana , Buenos Aires",name:"IMENIC Virgen Ni\u00f1a S.R.L. ",position:[-34.2384027, -58.9637791]},
  {category:"salud",type:" cooperativa",address:"AV. DANTE Y TORCUATO EMILIOZZI 7039, Olavarria , Buenos Aires",name:"C.O.R.P.I",position:[-36.87143289999999, -60.2840518]},
  {category:"salud",type:"centro de rehabilitacion",address:"25 DE MAYO 1169, San Mart\u00edn , Buenos Aires",name:"CENTRO ASISTENCIAL UNIVERSITARIO DE LA UNSAM",position:[-34.5757521, -58.53709649999999]},
  {category:"salud",type:"hogar",address:"BELGRANO 713 723 733, San Fernando , Buenos Aires",name:"HOGAR CON-VIVENCIAS",position:[-34.4430618, -58.55796429999999]},
  {category:"salud",type:"miscelaneo",address:"CATAMARCA 155, Quilmes , Buenos Aires",name:"FRONTERAS ABIERTAS S.A.",position:[-34.7167823, -58.268674]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"BELGRANO 606, Ramos Mej\u00eda , Buenos Aires",name:"C.I.R.I. -CENTRO DE REHABILITACION PEDIATRICA RAMOS MEJIA S.R.L.",position:[-34.6430604, -58.57310930000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"RUTA 7 KM 256.5, Jun\u00edn , Buenos Aires",name:"CLINICA DE REHABILITACION JUNIN SRL",position:[-34.6082131, -60.9612538]},
  {category:"salud",type:"miscelaneo",address:"ALSINA 1048, San Isidro , Buenos Aires",name:"HACER LAZOS",position:[-34.4787808, -58.528133]},
  {category:"salud",type:"fundaci\u00f3n",address:"MONTEAGUDO 2577 (EX 847), Florencio Varela , Buenos Aires",name:"FIDME",position:[-34.8030663, -58.2747113]},
  {category:"salud",type:"miscelaneo",address:"ALVEAR 688 PB DPTO 1, Ituzaing\u00f3 , Buenos Aires",name:"INCLUDERE",position:[-34.6570243, -58.6753978]},
  {category:"salud",type:"centro de d\u00eda",address:"CAPITAN MOYANO 1793, Adrogu\u00e9 , Buenos Aires",name:"CENTRO DE DIA AMIGOS DE JULIAN MATIAS ARTURO",position:[-34.8134649, -58.4225641]},
  {category:"salud",type:"hogar",address:"SUBTENIENTE PERDOMO 1839, Ituzaing\u00f3 , Buenos Aires",name:"HOGAR LAZOS de AFAM S.A.",position:[-34.6570243, -58.6753978]},
  {category:"salud",type:"miscelaneo",address:"RIO DE JANEIRO 3551, Castelar , Buenos Aires",name:"NUESTRA SE\u00d1ORA DE LORETO",position:[-34.6600724, -58.6560923]},
  {category:"salud",type:"miscelaneo",address:"SARMIENTO 654, Tandil , Buenos Aires",name:"EL ANDAMIO de LA NUEVA VECCHINA SRL",position:[-37.3248091, -59.1366362]},
  {category:"salud",type:"centro educativo",address:"EVITA 452, Villa Madero , Buenos Aires",name:"CEPIM",position:[-34.6942862, -58.5005618]},
  {category:"salud",type:"asociaci\u00f3n",address:"TUCUMAN 1011, Mor\u00f3n , Buenos Aires",name:"ANFI SALUD SRL",position:[-34.6434613, -58.62285379999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"ESPA\u00d1A 1549 51 53, Florida , Buenos Aires",name:"CENTRO DE MEDICINA FISICA Y REHABILITACION",position:[-34.5329498, -58.4909058]},
  {category:"salud",type:"miscelaneo",address:"TRES SARGENTOS 1347, Mart\u00ednez , Buenos Aires",name:"AIRES de MARIELA SILVINA MERY",position:[-34.4854897, -58.50562540000001]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"ROCA 237, Punta Alta , Buenos Aires",name:"CINDI",position:[-38.88395870000001, -62.07947069999999]},
  {category:"salud",type:"hogar",address:"OLAVARRIA 4759, Caseros , Buenos Aires",name:"HOGAR SAN FRANCISCIO DE LA VIRGEN DE LA MERCED S.A.",position:[-34.6274382, -58.57264050000001]},
  {category:"salud",type:"centro educativo",address:"BOLIVIA 407, Lan\u00fas , Buenos Aires",name:"CENTIR SRL",position:[-34.6856513, -58.3870533]},
  {category:"salud",type:"escuela",address:"PEDRO VIERA 50, Adrogu\u00e9 , Buenos Aires",name:"ESCUELA ESPECIAL TIEMPO DE CRECER",position:[-34.812137, -58.41879710000001]},
  {category:"salud",type:"instituto",address:"AMENEDO 1580, Adrogu\u00e9 , Buenos Aires",name:"INSTITUTO EDUCATIVO DEJALO SER",position:[-34.7919165, -58.39008579999999]},
  {category:"salud",type:"instituto",address:"LUIS MARIA GONNET 6551, Del Viso , Buenos Aires",name:"GRUIMI SRL",position:[-34.455622, -58.79238699999999]},
  {category:"salud",type:"hogar",address:"Avda. San Mart\u00edn 1835, Esteban Echeverr\u00eda , Buenos Aires",name:"HOGAR PERMANENTE CON CENTRO DE D\u00cdA LUIS GUILL\u00d3N de DE BUEN DECIR SRL",position:[-34.7998346, -58.4487779]},
  {category:"salud",type:"miscelaneo",address:"AVDA. VERGARA 5145, Ranelangh , Buenos Aires",name:"LOS JAZMINES DERANELAGH S.A.",position:[-34.8069458, -58.20633729999999]},
  {category:"salud",type:"centro de d\u00eda",address:"LA PAZ 2941 45 (EX 49), Villa Ballester , Buenos Aires",name:"CENTRO DE D\u00cdA CREI SRL",position:[-34.5492357, -58.5588195]},
  {category:"salud",type:"miscelaneo",address:"AMEGHINO 1068, Bella Vista , Buenos Aires",name:"SOLES DE BELLA VISTA SRL",position:[-34.5714936, -58.6898594]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Spiro 971 973, Adrogu\u00e9 , Buenos Aires",name:"C.I.S.A.M. CENTRO INTEGRAL PARA LA SALUD MENTAL S.R.L.",position:[-34.8022868, -58.3970094]},
  {category:"salud",type:"centro de rehabilitacion",address:"SALTA 1253, Lan\u00fas Este , Buenos Aires",name:"CENTRO DE REHABILITACI\u00d3N SAN L\u00c1ZARO S.R.L.",position:[-34.7050477, -58.3795457]},
  {category:"salud",type:"instituto",address:"Roma 2579, Lan\u00fas , Buenos Aires",name:"INDAD S.R.L.",position:[-34.7296088, -58.38757940000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Calle 34 N\u00b0 741 e 9 y10, La Plata , Buenos Aires",name:"CEMEREP de Centro M\u00e9dico de Rehabilitaci\u00f3n Pediatrica S.R.L.",position:[-34.9069356, -57.9717647]},
  {category:"salud",type:"centro educativo",address:"Gelly y Obes 1956, El Talar , Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO IDEAS S.R.L.",position:[-34.4750339, -58.6450707]},
  {category:"salud",type:"miscelaneo",address:"Viamonte 2508 22 24, Lan\u00fas Oeste , Buenos Aires",name:"VIRGEN DE FATINA S.A.",position:[-34.6912622, -58.4136932]},
  {category:"salud",type:"miscelaneo",address:"Rumania 2130, Del Viso , Buenos Aires",name:"ZERU URDINA S.R.L.",position:[-34.4196427, -58.7843386]},
  {category:"salud",type:"asociaci\u00f3n",address:"Juli\u00e1n Ag\u00fcero N\u00b0 731, Florida , Buenos Aires",name:"Asoc. Argentina para la Investigac. y Asistencia de la Persona con Autismo",position:[-34.5420897, -58.5027488]},
  {category:"salud",type:"centro educativo",address:"Florentino Ameghino N\u00b0 752 54 y Brasil N\u00b0 755 6, Villa Martelli , Buenos Aires",name:"CENTRO EDUCATIVO TERAPEUTICO NUEVAS ALAS S.R.L",position:[-34.5510812, -58.5084053]},
  {category:"salud",type:"miscelaneo",address:"Rodriguez Pe\u00f1a N\u00b0 58, Bernal , Buenos Aires",name:"LOS ANGELES DEL SUR S.A",position:[-34.7133791, -58.2755993]},
  {category:"salud",type:"centro de d\u00eda",address:"Uruguay 545, Avellaneda , Buenos Aires",name:"CENTRO DE D\u00cdA ALVIC de Omar Albino FERNANDEZ",position:[-34.6702112, -58.38711170000001]},
  {category:"salud",type:"miscelaneo",address:"Calle 95 - Alcia Moreau de Justo 1332 34 - Villa Lynch, San Mart\u00edn , Buenos Aires",name:"INCLUYENDO S.R.L.",position:[-34.5829719, -58.5345531]},
  {category:"salud",type:"miscelaneo",address:"Conesa 271, Quilmes , Buenos Aires",name:"ATA\u00d1ER de Santiago Hern\u00e1n BERNATENES",position:[-34.7175615, -58.25885390000001]},
  {category:"salud",type:"centro educativo",address:"Calle 70 N\u00b0 165, La Plata , Buenos Aires",name:"CETMAPU S.R.L.",position:[-34.9211136, -57.9213803]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Alsina 1360, Lomas de Zamora , Buenos Aires",name:"CENTRO CRECIENDO de Anal\u00eda Edith FERNANDEZ",position:[-34.7524637, -58.3947398]},
  {category:"salud",type:"miscelaneo",address:"Florencio Varela 1025, Temperley , Buenos Aires",name:"ILUSIONES COMPARTIDAS CET de ILUSIONES COMPARTIDAS S.R.L.",position:[-34.7808773, -58.3801842]},
  {category:"salud",type:"centro de d\u00eda",address:"Pergamino 2048, Castelar , Buenos Aires",name:"CENTRO DE D\u00cdA AGAPE de AGAPE: AMOR TOTAL S.R.L.",position:[-34.637548, -58.6471342]},
  {category:"salud",type:"centro de d\u00eda",address:"Irigoin 551, San Miguel , Buenos Aires",name:"CENTRO DE D\u00cdA RINC\u00d3N DE LUZ de DIEGO ARMANDO GONZALEZ y PAOLA SABRINA FERRER S.H",position:[-34.5311817, -58.71481029999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Las Heras 574, Mu\u00f1iz , Buenos Aires",name:"CENTRO DE D\u00cdA EL PORTAL de Germ\u00e1n Mat\u00edas VERGARA y Sabrina Aldana BUGALLO",position:[-34.5419517, -58.69993239999999]},
  {category:"salud",type:"consultorio",address:"Pedro Lagrave 786, Pilar , Buenos Aires",name:"C.A.P.I. - CENTRO DE ATENCI\u00d3N PSICOL\u00d3GICA INFANTIL",position:[-34.4570526, -58.9158272]},
  {category:"salud",type:"centro de d\u00eda",address:"Ram\u00f3n Castro 3167, Olivos , Buenos Aires",name:"ALESSANDRA CENTRO DE D\u00cdA de CENTRO DE D\u00cdA ALESSANDRA S.R.L.",position:[-34.5134493, -58.50915029999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Leandro N Alem 2141 43 45, Munro , Buenos Aires",name:"CENTRO INTEGRADO ARCO IRIS S.R.L.",position:[-34.5268142, -58.521302]},
  {category:"salud",type:"centro educativo",address:"Yatay 483, Mor\u00f3n , Buenos Aires",name:"CENT - EQUIPO INTERDISCIPLINARIO APOYO A LA INTEGRACI\u00d3N ESCOLAR",position:[-34.6536931, -58.615426]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Elizalde 130 , Avellaneda , Buenos Aires",name:"C.I.D.E.N.y T. - CENTRO INTEGRAL DE ESPECIALIDADES NEUROL\u00d3GICAS Y TERAP\u00c9UTICAS",position:[-34.6728442, -58.354953]},
  {category:"salud",type:"centro educativo",address:"Avda. Meeks 641, Lomas de Zamora , Buenos Aires",name:"CET BUENOS AIRES S.A.",position:[-34.7693729, -58.39826189999999]},
  {category:"salud",type:"centro educativo",address:"MAR DEL PLATA 1301, Del Viso , Buenos Aires",name:"CET BUENOS AIRES S.A.",position:[-34.4594272, -58.7925517]},
  {category:"salud",type:"hogar",address:"Zeballos 592, Pilar , Buenos Aires",name:"PILAR - ES HOGAR de Gerardo Rodolfo CEI COODIGONI",position:[-34.45910380000001, -58.9217702]},
  {category:"salud",type:"centro de d\u00eda",address:"Pueyrredon 396, La Matanza , Buenos Aires",name:"CENTRO DE D\u00cdA RAMOS MEJ\u00cdA S.R.L.",position:[-34.6430935, -58.56038319999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Dip. H\u00e9ctor Finochieto 1799, Hurlingham , Buenos Aires",name:"ACOMPSI de ALEXIA PAMELA BORELLO BENITEZ",position:[-34.5902865, -58.64077320000001]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Chile 899, El Talar , Buenos Aires",name:"CENTRO INTEGRAL DE REHABILITACI\u00d3N INTEGRA S.R.L.",position:[-34.470263, -58.6473882]},
  {category:"salud",type:"centro de rehabilitacion",address:"Sarmiento 47, General Pacheco , Buenos Aires",name:"CENTRO INTEGRAL DE REHABILITACI\u00d3N INTEGRA S.R.L.",position:[-34.461024, -58.6344497]},
  {category:"salud",type:"centro de rehabilitacion",address:"Jos\u00e9 Marmal 1583 - 5to. B, Vicente L\u00f3pez , Buenos Aires",name:"\"ADAP\" de ASISTENCIA EN DESARROLLO, APRENDIZAJE Y PSICOLOG\u00cdA ADAP S.R.L.",position:[-34.5335467, -58.4803291]},
  {category:"salud",type:"fundaci\u00f3n",address:"Beato J.M. Escriva esq. Regimiento Patricios, Moreno , Buenos Aires",name:"FUNDACI\u00d3N MEJORAR ESTUDIANDO TRABAJANDO PARA ASCENDER SOCIALMENTE M.E.T.A.S.",position:[-34.6592591, -58.8468143]},
  {category:"salud",type:"miscelaneo",address:"De las Boleadoras 2974, Ituzaing\u00f3 , Buenos Aires",name:"JUNTOS A LA PAR de FLORES DE LELOIR S.A.",position:[-34.6237554, -58.71597999999999]},
  {category:"salud",type:"centro educativo",address:"Dr. E. Eizaguirre 2431, San Justo , Buenos Aires",name:"CERMI SALUD S.A.",position:[-34.6812064, -58.55587099999999]},
  {category:"salud",type:"centro educativo",address:"PTE. PER\u00d3N 1045, San Fernando , Buenos Aires",name:"CERMI SALUD S.A.",position:[-34.4402971, -58.5563239]},
  {category:"salud",type:"centro de d\u00eda",address:"Calle 117 N\u00b0 1350, Berazategui , Buenos Aires",name:"CENTRO DE D\u00cdA \"PIEDRA LIBRE\" DE RAFAELA MAR\u00cdA BELLONI",position:[-34.7780547, -58.2411925]},
  {category:"salud",type:"miscelaneo",address:"Avda. Mitre 5485, Wilde , Buenos Aires",name:"PUERTAS ABIERTAS S.R.L.",position:[-34.6977416, -58.32582019999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Alem 2414, Munro , Buenos Aires",name:"CENTRO DE D\u00cdA \"EL ECO\" de COOPERATIVA DE TRABAJO EL ECO LTDA.",position:[-34.5294606, -58.5195091]},
  {category:"salud",type:"centro educativo",address:"Maip\u00fa 859, Bernal , Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO APYNA de APYNA S.R.L.",position:[-34.7083613, -58.2939793]},
  {category:"salud",type:"hogar",address:"Monte Carballo 2704, Mar del Plata , Buenos Aires",name:"HOGAR DE MAR\u00cdA",position:[-37.9441807, -57.55854009999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. 60 N\u00b0 558, La Plata , Buenos Aires",name:"CAREI S.A.",position:[-34.9202076, -57.9405586]},
  {category:"salud",type:"centro de rehabilitacion",address:"Calle 68 N\u00b0 2645 47, Necochea , Buenos Aires",name:"S.E.R. SERVICIOS ESPECIALES DE REHABILITACI\u00d3N Y RECREACI\u00d3N PARA DISCAPACITADOS S",position:[-38.5530766, -58.7471687]},
  {category:"salud",type:"asociaci\u00f3n",address:"Corrientes 1557, Mart\u00ednez , Buenos Aires",name:"MORADA DE JUAN BAUTISTA ASOCCIACI\u00d3N TERAP\u00c9UTICA ",position:[-34.5003903, -58.5174947]},
  {category:"salud",type:"centro educativo",address:"Rawxon 1564, Mar del Plata , Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO CUBO M\u00c1GICO de la ASOCIACI\u00d3N CIVIL CUBO M\u00c1GICO",position:[-38.0128632, -57.54312110000001]},
  {category:"salud",type:"centro educativo",address:"Yerbal 1259, Villa Adelina , Buenos Aires",name:"ESCUELA ESPECIAL NUESTRA SE\u00d1ORA DE LUJ\u00c1N del OBISPADO DE SAN ISIDRO",position:[-34.5118141, -58.5498975]},
  {category:"salud",type:"miscelaneo",address:"Guemes 2222, San Mart\u00edn , Buenos Aires",name:"QUIMEY-CO de Mat\u00edas GIAMMARINO NATALE",position:[-34.5786711, -58.54314669999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Tte. Gral. Lonardi 2089, Beccar , Buenos Aires",name:"CENTRO DE D\u00cdA SENDAS de Alicia Hayde\u00e9 GARCIA",position:[-34.4689666, -58.5510726]},
  {category:"salud",type:"centro de d\u00eda",address:"Fort\u00edn Pilcomayo 375, General Rodriguez, Buenos Aires",name:"CENTRO DE DIA PEQUEN de EDUSALPEQUEN S.R.L.",position:[-34.592649, -58.974965]},
  {category:"salud",type:"miscelaneo",address:"Reconquista 1239, Luis Guill\u00f3n , Buenos Aires",name:"NUEVOS HORIZONTES ECHEVERRIANOS de SAUCE",position:[-34.816411, -58.44636500000001]},
  {category:"salud",type:"centro educativo",address:"Charlone 3195, San Miguel , Buenos Aires",name:"CEPRADIS S.R.L.",position:[-34.5602295, -58.72705860000001]},
  {category:"salud",type:"hogar",address:"Matheu 3330, Mar del Plata , Buenos Aires",name:"HOGAR DON ORIONE de la ASOCIACI\u00d3N CIVIL PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA",position:[-38.0125251, -57.5679945]},
  {category:"salud",type:"miscelaneo",address:"Serrano 444 , San Miguel , Buenos Aires",name:"MARCHEMOS JUNTOS S.R.L.",position:[-34.543055, -58.7118577]},
  {category:"salud",type:"miscelaneo",address:"Mitre 3885 - piso 1- Dpto. 7, San Mart\u00edn , Buenos Aires",name:"KHIPU S.R.L.",position:[-34.5775564, -58.5394517]},
  {category:"salud",type:"centro de rehabilitacion",address:"Mariano Castex 1277 - of. 310 y 311, Ezeiza , Buenos Aires",name:"CERCA SALUD Y REHABILITACI\u00d3N S.A.",position:[-34.8600886, -58.5033482]},
  {category:"salud",type:"asociaci\u00f3n",address:"Belgrno 4266, San Mart\u00edn , Buenos Aires",name:"ASNECO de la ASOCIACI\u00d3N NEUROCOGNITIVA INFANTO JUVENIL",position:[-34.5810299, -58.54110310000001]},
  {category:"salud",type:"miscelaneo",address:"Uruguay 610, San Pedro , Buenos Aires",name:"SAGRADA FAMILIA de Mar\u00eda Sandra TIRAMON, Claudia Sandra VELL\u00d3N, Natalia Giselle ",position:[-33.6800171, -59.6602554]},
  {category:"salud",type:"miscelaneo",address:"Savari 1440, Pilar , Buenos Aires",name:"DI CAPACIDAD S.A.",position:[-34.4663154, -58.9153722]},
  {category:"salud",type:"miscelaneo",address:"Hipolito Yrigoyen N\u00b0 7024, Banfield , Buenos Aires",name:"ASSISTIRE de ALEVAGU S.A.",position:[-34.7368141, -58.39747499999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Ruta 5 km. 71,500 N\u00b0 2213 - Esquina 139, Luj\u00e1n , Buenos Aires",name:"CENTRO DE D\u00cdA EL SOLCITO ECOLOGICO",position:[-34.6000713, -59.1265744]},
  {category:"salud",type:"centro de d\u00eda",address:"Machado 941, Tandil , Buenos Aires",name:"CENTRO DE D\u00cdA PROCEDERE de BEATRIZ ARAEZ",position:[-37.31280810000001, -59.1387337]},
  {category:"salud",type:"miscelaneo",address:"Avda. Pte. Per\u00f3n 3265, Alejandro Korn , Buenos Aires",name:"SAN GABRIEL de FRUCHTE & ZEL S.A.",position:[-34.9975939, -58.3939312]},
  {category:"salud",type:"centro de rehabilitacion",address:"Tom\u00e1s Edison 1580, El Jag\u00fcel , Buenos Aires",name:"CENTRO INTEGRAL LA HUELLA",position:[-34.820675, -58.4918227]},
  {category:"salud",type:"centro educativo",address:"Belgrano 3881, Mar del Plata , Buenos Aires",name:"CET MI SENDA de PAPALPI S.R.L.",position:[-37.9944133, -57.5610535]},
  {category:"salud",type:"centro educativo",address:"Alvear 422, Ituzaing\u00f3 , Buenos Aires",name:"IZET CENTRO EDUCATIVO TERAP\u00c9UTICO E INTEGRACI\u00d3N ESCOLAR de IZET S.A.",position:[-34.6535524, -58.66261290000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Cuyo 963, Mart\u00ednez , Buenos Aires",name:"FUNDACI\u00d3N FIORIRE CENTRO EDUCATIVO TERAP\u00c9UTICO de FUNDACI\u00d3N FIORIRE",position:[-34.489564, -58.51523279999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Garc\u00eda del R\u00edo 812, Temperley , Buenos Aires",name:"MAR\u00cdA ORO CENTRO DE D\u00cdA de MAR\u00cdA ORO S.R.L.",position:[-34.7833328, -58.39221509999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Av. Col\u00f3n 3541, Mar del Plata , Buenos Aires",name:"CENTRO DE REHABILITAIC\u00d3N INTEGRAL RE.ME S.R.L.",position:[-37.998387, -57.5595553]},
  {category:"salud",type:"asociaci\u00f3n",address:"Consejal Tribulato 930, San Miguel , Buenos Aires",name:"APTUS de TAGOBA S.R.L.",position:[-34.5390271, -58.71147629999999]},
  {category:"salud",type:"hogar",address:"Av. Eva Per\u00f3n N\u00b0 320, Temperley , Buenos Aires",name:"HOGAR NUESTRO UMBRAL S.R.L.",position:[-34.7774653, -58.39271789999999]},
  {category:"salud",type:"consultorio",address:"Sixto Fern\u00e1ndez N\u00b0 230, Lomas de Zamora , Buenos Aires",name:"C.P.I. CENTRO PSICOTERAPE\u00daTICO INTEGRAL S.R.L.",position:[-34.7667258, -58.40063799999999]},
  {category:"salud",type:"escuela",address:"9 de Julio, Bah\u00eda Blanca , Buenos Aires",name:"ESCUELA DE ENSE\u00d1ANZA ESPECIAL ASOCIACION ALBORADA",position:[-38.7015941, -62.3065698]},
  {category:"salud",type:"centro educativo",address:"Presidente Rivadavia 204, Jose C. Paz , Buenos Aires",name:"PCP APOYOS CENTRO EDUCATIVO TERAPEUTICO",position:[-34.5167585, -58.7520956]},
  {category:"salud",type:"asociaci\u00f3n",address:"Los Tilos y Calle 9 s N\u00b0 - B\u00b0 La Trinidad, Mar del Plata , Buenos Aires",name:"DAR M\u00c1S de la ASOCIACI\u00d3N CIVIL PARA EL DISCAPACITADO",position:[-38.0728698, -57.5969822]},
  {category:"salud",type:"miscelaneo",address:"Avda. Mariano Pelliza 4423, Munro , Buenos Aires",name:"CASA DE TODOS de Lucila MOREL QUIRNO",position:[-34.5207738, -58.5201721]},
  {category:"salud",type:"centro educativo",address:"Avda. San Mart\u00edn 845, 9 de Julio , Buenos Aires",name:"PASOS CENTRO EDUCATIVO TERAP\u00c9UTICO S.R.L.",position:[-34.5961933, -58.3823287]},
  {category:"salud",type:"centro de d\u00eda",address:"Paso de la Patria 45, Ezeiza , Buenos Aires",name:"CENTRO DE DIA SAN AGUSTIN SRL",position:[-34.8525871, -58.5186549]},
  {category:"salud",type:"centro de d\u00eda",address:"ALBERTO LARROQUE 1320, Banfield , Buenos Aires",name:"CENTRO DE DIA SAN AGUSTIN SRL",position:[-34.7429434, -58.4140214]},
  {category:"salud",type:"centro de rehabilitacion",address:"Ituzaingo 695, Brandsen , Buenos Aires",name:"CENTRO DE REHABIILITACION EL TREBOL",position:[-35.1658208, -58.23332809999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Calle 45 N\u00b0 890 e 12 y 13, La Plata , Buenos Aires",name:"CEPLAR de INTERDISCIPLINA S.R.L.",position:[-34.9265668, -57.970506]},
  {category:"salud",type:"consultorio",address:"Florencio Varela 344, Ramos Mej\u00eda , Buenos Aires",name:"PSICOP de Alicia Liliana ENRIQUEZ",position:[-34.6590898, -58.54851370000001]},
  {category:"salud",type:"escuela",address:"Cordoba 4353, Villa Ballester , Buenos Aires",name:"ESCUELA ESPECIAL MARIA MONTESSORI SRL",position:[-34.5520614, -58.5508111]},
  {category:"salud",type:"miscelaneo",address:"Irigoyen 361 365, Mart\u00ednez , Buenos Aires",name:"NUEVOS CAMINOS de Fabiana Graciela INGLIERI",position:[-34.4879949, -58.49737469999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Virrey Liniers 1651, Florida , Buenos Aires",name:"FUNDACION DESPLEGAR",position:[-34.5255938, -58.4907885]},
  {category:"salud",type:"centro de d\u00eda",address:"Asborno 376, Escobar , Buenos Aires",name:"CENTRO DE APOYO A LA INTEGRACION SRL",position:[-34.3487573, -58.7916383]},
  {category:"salud",type:"centro de rehabilitacion",address:"Av.Mitre 470 , Merlo , Buenos Aires",name:"CENTRO MEDICO DE COLUMNA Y REHABILITACION SRL",position:[-34.65005, -58.78671620000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Moreno 506, Ramos Mej\u00eda , Buenos Aires",name:"INDELO de CLINICAL TRIAL SERVICE PERSONAL S.A.",position:[-34.6450204, -58.5615919]},
  {category:"salud",type:"centro de rehabilitacion",address:"Hipolito Irigoyen 779, Avellaneda , Buenos Aires",name:"REHABILITACION TRAUMATOLOGICA MOYA SRL",position:[-34.6610756, -58.3669739]},
  {category:"salud",type:"asociaci\u00f3n",address:"Ruta 3 Km 54 Colectora 81 Barrio Santa Rosa, Ca\u00f1uelas , Buenos Aires",name:"AMIGOS SIEMPRE AMIGOS ASOCIACION CIVIL",position:[-38.1051979, -60.2179471]},
  {category:"salud",type:"miscelaneo",address:"Arturo Illia N\u00b0 14241., Jose C. Paz , Buenos Aires",name:"RINC\u00d3N DE LUZ de walter Luis RAMIREZ VERA ",position:[-34.4591028, -58.8122814]},
  {category:"salud",type:"miscelaneo",address:"Atahualpa N\u00b0 1965, El Palomar , Buenos Aires",name:"INTEGRARCET SA",position:[-34.6266731, -58.5943604]},
  {category:"salud",type:"miscelaneo",address:"Moldes 553, Llavallol , Buenos Aires",name:"HERMANO SOL HERMANA LUNA de PETTERINO Marina Eugenia",position:[-34.7895521, -58.42930740000001]},
  {category:"salud",type:"escuela",address:"Berutti 905, General Pacheco , Buenos Aires",name:"ESCUELA ESPECIAL HC ANDERSEN DE WITAN SA",position:[-34.4471569, -58.6373925]},
  {category:"salud",type:"consultorio",address:"Maip\u00fa 4133, Vicente L\u00f3pez , Buenos Aires",name:"CENTRO PSICOTERAPEUTICO BUENOS AIRES S.A.",position:[-34.498455, -58.496808]},
  {category:"salud",type:"miscelaneo",address:"Pueyrredon 2625, Merlo , Buenos Aires",name:"UN LUGAR BRILLANTE de UN LUGAR BRILLANTE S.A.",position:[-34.6834126, -58.68575919999999]},
  {category:"salud",type:"escuela",address:"Rosetti 775 y San Jos\u00e9 325, Mu\u00f1iz , Buenos Aires",name:"ESCUELA DE EDUCACI\u00d3N ESPECIAL \"BUSCANDO EL SOL\"",position:[-34.5415709, -58.6960636]},
  {category:"salud",type:"miscelaneo",address:"Moreno 3908, San Mart\u00edn , Buenos Aires",name:"QUIMEY-CO",position:[-34.5795859, -58.5374352]},
  {category:"salud",type:"miscelaneo",address:"aYACUCHO 1151, Lan\u00fas Este , Buenos Aires",name:"CENTRO INTEGRAL PSICOPEDAGOGICO DERECUPERACION INFANTO JUVENIL S.A.",position:[-34.7002104, -58.38082000000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Tavi 404, Escobar , Buenos Aires",name:"MOEBIUS CENTRO DE REHABILITACION Y ATENCION INTERDISCIPLINARIA",position:[-34.345965, -58.7953449]},
  {category:"salud",type:"asociaci\u00f3n",address:"Aristobulo del vALLE 1924, Florencio Varela , Buenos Aires",name:"ASOCIACI\u00d3N CIVIL \"CENTRO TERAP\u00c9UTICO DEL SUR\"",position:[-34.80339, -58.25926]},
  {category:"salud",type:"miscelaneo",address:"Libertador 1097, San Fernando , Buenos Aires",name:"OTRAS MIRADAS de RACOSTA, Maria Cecilia y OLIVA, Carolina",position:[-34.4398104, -58.5552942]},
  {category:"salud",type:"fundaci\u00f3n",address:"Domingo French 35 y 37, Villa Martelli , Buenos Aires",name:"FUNDACI\u00d3N CISAM - CENTRO DE INVESTIGACIONES PARA LA SALUD MENTAL",position:[-34.5547645, -58.51264639999999]},
  {category:"salud",type:"instituto",address:"La Rabida 2688, Beccar , Buenos Aires",name:"INSTITUCION PRIVADA PARA MULTI IMPEDIDOS SENSORIALES",position:[-34.468293, -58.5581421]},
  {category:"salud",type:"centro de d\u00eda",address:"De la Tradici\u00f3n 2063, Ituzaing\u00f3 , Buenos Aires",name:"CENTRO DEL D\u00cdA DEL BOSQUE S.A.",position:[-34.619789, -58.701042]},
  {category:"salud",type:"miscelaneo",address:"Calle 456 s N\u00b0 e  28bis y 135, City Bell, Buenos Aires",name:"AL'ANDALUZ de AFG DIMAS 2013 S.R.L.",position:[-34.8832747, -58.0764981]},
  {category:"salud",type:"centro educativo",address:"Figueroa Alcorta 1081, Bella Vista , Buenos Aires",name:"ARCO IRIS DE BELLA VISTA de CENTRO EDUCATIVO TERAP\u00c9UTICO SOLES DE BELLA VISTA S.",position:[-34.5647797, -58.67470549999999]},
  {category:"salud",type:"miscelaneo",address:"Los Narcisos 877, Del Viso , Buenos Aires",name:"EPPIN de ROBERJOACO S.R.L.",position:[-34.435842, -58.79000780000001]},
  {category:"salud",type:"centro de d\u00eda",address:"24 de Octubre 661, Ituzaing\u00f3 , Buenos Aires",name:"CENTRO DE D\u00cdA ITUZAING\u00d3 de PROYECTO ONCE S.R.L.",position:[-34.65966, -58.66336940000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Gral. Manuel Belgrano 590 596, Mor\u00f3n , Buenos Aires",name:"FUNDACI\u00d3N ESTIMULAR PARA INCLUIR",position:[-34.656513, -58.6213534]},
  {category:"salud",type:"miscelaneo",address:"Jos\u00e9 Mar\u00eda Paz 2418, Olivos , Buenos Aires",name:"EQUIPO PIUK\u00c9 S.R.L.",position:[-34.5181198, -58.498439]},
  {category:"salud",type:"miscelaneo",address:"Santiago del Estero 690 - Local 121, Ingeniero Maschwitz , Buenos Aires",name:"LONGEVIDAD VITAL S.A.",position:[-34.3955743, -58.7387633]},
  {category:"salud",type:"miscelaneo",address:"Pirovano 22, Lincol, Buenos Aires",name:"TAXIA SALUD de Delia Mabel PRIDA",position:[-34.8635866, -61.5255792]},
  {category:"salud",type:"centro de d\u00eda",address:"Balcarce 3628, Mar del Plata , Buenos Aires",name:"CENTRO DE D\u00cdA DESPERTARES de Silvana Elina CARRASCO y Vanesa Paula WALFISCH",position:[-37.9894579, -57.55351950000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Alte. Brown 901, San Vicente , Buenos Aires",name:"CENTRO DE D\u00cdA MEDJUGORJE de CEGRAN S.A.",position:[-35.0281523, -58.41275169999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Calle 113 N\u00b0 652 e 28 y 30bis, Mercedes , Buenos Aires",name:"CENTRO DE D\u00cdA HOGAR GRANJA ARCO IRIS de ASOCIACI\u00d3N CIVIL HOGAR GRANJA AQRCO IRIS",position:[-34.6547905, -59.4094878]},
  {category:"salud",type:"centro de d\u00eda",address:"Mons. Blois 2191, San Miguel , Buenos Aires",name:"PORPADIS CON CENTRO DE D\u00cdA DALFA S.R.L.",position:[-34.5402301, -58.7226009]},
  {category:"salud",type:"instituto",address:"Ruta 88 - km. 1,5, Mar del Plata , Buenos Aires",name:"INST. NAC. DE REHABILITACION PSICOFISCO DEL SUR \"DR. JUAN OTIMIO TESONE\"",position:[-38.0121193, -57.6406768]},
  {category:"salud",type:"centro de d\u00eda",address:"Rep\u00fablica de Italia 1122, Adrogu\u00e9 , Buenos Aires",name:"CISAM CENTRO DE D\u00cdA de C.I.S.A.M. CENTRO INTEGRAL PARA LA SALUD MENTAL S.R.L.",position:[-34.80489610000001, -58.3900951]},
  {category:"salud",type:"instituto",address:"Marcos Paz 1058, Villa Madero , Buenos Aires",name:"ACCION HUMANA de INSTITUTOS ALDU S.A.",position:[-34.6262256, -58.50029259999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Sadi Carnot 979, Pig\u00fc\u00e9 , Buenos Aires",name:"ASOCIACI\u00d3N CIVIL DENTRO DE D\u00cdA AMANECER",position:[-37.60721540000001, -62.3976598]},
  {category:"salud",type:"instituto",address:" , Vicente L\u00f3pez , Buenos Aires",name:"INSTITUTO DE REHABILITACI\u00d3N MUNICIPAL \"GDOR. dR. ANSELMO MARINI\"",position:[-34.5281205, -58.473816]},
  {category:"salud",type:"miscelaneo",address:"Avellaneda 505, San Miguel , Buenos Aires",name:"GRUPO TRAMA de MILKAGROS DOLORES AGUIRRE Y LEONARDO DANIEL MORE",position:[-34.5411465, -58.70002789999999]},
  {category:"salud",type:"miscelaneo",address:"Manuel Belgrano 2125, San Fernando , Buenos Aires",name:"ACOMPA\u00d1ARTE SOCIEDAD SIMPLE",position:[-34.4495466, -58.55056560000001]},
  {category:"salud",type:"escuela",address:"Olavarria 339, Quilmes , Buenos Aires",name:"ESCUELA DE EDUCACION ESPECIAL DR OVIDIO DECROLY SRL",position:[-34.7239458, -58.252397]},
  {category:"salud",type:"centro de rehabilitacion",address:"Remedios de Escalada 214, Pacheco, Buenos Aires",name:"ANDARES de ANDARES EQUIPO S.R.L.",position:[-34.7212143, -58.39850999999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Gdor. Valent\u00edn Vergara 3181, Hurlingham , Buenos Aires",name:"CENTRO DE ESTIMULACI\u00d3N E INTERVENCI\u00d3N TEMPRANA de la MUNICIPALIDAD DE HURLINGHAM",position:[-34.6040784, -58.6350712]},
  {category:"salud",type:"centro de d\u00eda",address:"Altamira 2281, Rafael Calzada , Buenos Aires",name:"CENRO DE D\u00cdA MUNDO DE ESPERANZAS S.A.S.",position:[-34.7992941, -58.3541099]},
  {category:"salud",type:"miscelaneo",address:"Estrada N\u00ba 748, General Rodriguez, Buenos Aires",name:"PROGRAMA INTEGRAR SOCIEDAD AN\u00d3NIMA",position:[-34.6022327, -58.94901869999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Avda. Pringles 900, Bah\u00eda Blanca , Buenos Aires",name:"HOGAR PEREGRINO SAN FRANCISCO DE ASIS de la ASOCIACION CIVIL PEQUE\u00d1AS HERMANAS M",position:[-38.71625, -62.24759]},
  {category:"salud",type:"centro de d\u00eda",address:"Boulogne Sur Mer 748, General Pacheco , Buenos Aires",name:"CENTRO DE D\u00cdA LAGUNITAS de LAGUNITAS S.A.",position:[-34.4647831, -58.6260361]},
  {category:"salud",type:"miscelaneo",address:"Calle 9 (Napoles) 3747 entre 160 y 161, Berisso , Buenos Aires",name:"BERISSO SALUD S.A. - CIRE",position:[-34.8726503, -57.89264180000001]},
  {category:"salud",type:"miscelaneo",address:"Ruta 7 y El Zorzal, General Rodriguez, Buenos Aires",name:"SOMOS CASAPUEBLO S.R.L.",position:[-34.5940209, -58.9441081]},
  {category:"salud",type:"escuela",address:"Marcelno Ugarte 427 U.F. N\u00b0 6, Pergamino , Buenos Aires",name:"ESCUELA ESPECIAL LOS BUENOS HIJOS",position:[-33.8933035, -60.55983259999999]},
  {category:"salud",type:"instituto",address:"Calle 23 entre 526 y 527, La Plata , Buenos Aires",name:"I.D.A.N.I INST. APOYO INTEGRAL PERSONAS CON CAPACIDADES DIFERENTES",position:[-34.8843114, -57.9638235]},
  {category:"salud",type:"centro de d\u00eda",address:"Hip\u00f3lito Buchardo 2189, Castelar , Buenos Aires",name:"CENTRO ASIRI de Mariela Fernanda PINA",position:[-34.65043259999999, -58.63603070000001]},
  {category:"salud",type:"clinica",address:"Pbro. Jos\u00e9 Albertini 601, La Plata , Buenos Aires",name:"CL\u00cdNICA DE REHABILITACI\u00d3N LOBOS .S.R.L.",position:[-34.942737, -57.8916725]},
  {category:"salud",type:"centro educativo",address:"25 de Mayo 1073, Burzaco, Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO ALBORADA SUR de REHABILITACI\u00d3N INTEGRALDEL SUR S.A.",position:[-34.8235223, -58.3948049]},
  {category:"salud",type:"centro de rehabilitacion",address:"HIPOLIITO YRIGOYEN 4553, Vicente L\u00f3pez , Buenos Aires",name:"CENTRO DE REHABILITACION SAN MARTIN DE PORRES de PORRES S.A.",position:[-34.5388856, -58.5221287]},
  {category:"salud",type:" cooperativa",address:"Ricardo Gutierrez 1633 35 37, Olivos , Buenos Aires",name:"COENI de SANDRA ELENA EMBON",position:[-34.5161949, -58.5013526]},
  {category:"salud",type:"centro de d\u00eda",address:"Gran Canaria 555, Quilmes , Buenos Aires",name:"CENTRO DE D\u00cdA DEL SUR - CE.DI.SUR de FLORENCIA MAR\u00cdA LUZ GALLARDO y LUCIANA ANAB",position:[-34.7188623, -58.2666564]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Avellaneda 13, Trenque Lauquen, Buenos Aires",name:"CIERA S.A.",position:[-35.9696809, -62.7400096]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 137A 5260 5264 entre 52A y 52B, Hudson , Buenos Aires",name:"ASOCIACION CIVIL JUNTOS A LA PAR ESTIMLANDO CAPACIDAD EN LA DISCAPACIDAD",position:[-34.8063289, -58.1823256]},
  {category:"salud",type:"instituto",address:"Castelli 2851, Mar del Plata , Buenos Aires",name:"INSTITUTO PSICOPEDAG\u00d3GICO SOLES S.R.L.",position:[-38.00757249999999, -57.557101]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Fondo de la Legua 1281 1269, Mart\u00ednez , Buenos Aires",name:"CENTRO DE REHABILITACI\u00d3N INFANTIL CASITA AZUL de CENTRO TERAP\u00c9UTICO INFANTIL S.R",position:[-34.5031126, -58.5394508]},
  {category:"miscelaneo",type:"miscelaneo",address:"Calle 27 N\u00b0 4278 e 142A y 143, Ezpeleta , Buenos Aires",name:"MUNAY KI de ELIAS, Irma Yolanda y CORONEL, Amira Camila",position:[-34.752079, -58.23584400000001]},
  {category:"salud",type:"miscelaneo",address:"Isidro Iriarte 1390, Quilmes , Buenos Aires",name:"ABORDAJES EDUCATIVOS EMPATIA S.A.",position:[-34.7135006, -58.2291364]},
  {category:"salud",type:"fundaci\u00f3n",address:"Tiradentes 1468, Boulogne , Buenos Aires",name:"FUNDACION AMIGOS DE COMNIDADES DEL ARCA",position:[-34.4955254, -58.5771634]},
  {category:"salud",type:"miscelaneo",address:"Aguilar 1945, Olavarria , Buenos Aires",name:"MATRIZ de GISELE VANESA BURGART",position:[-36.9049026, -60.323035]},
  {category:"salud",type:"miscelaneo",address:"Pucheu 946, Merlo , Buenos Aires",name:"EQUIPO PARA LA INCLUSI\u00d3N MERLO",position:[-34.6745029, -58.7273827]},
  {category:"salud",type:"centro de d\u00eda",address:"Rivadavia 152, Monte Grande , Buenos Aires",name:"ADISE S.R.L. CENTRO DE APOYOS PARA PERSONAS CON DISCAPACIDAD",position:[-34.81301, -58.46342339999999]},
  {category:"salud",type:"escuela",address:"Leandro N. Alem 2768 y 2772, Moreno , Buenos Aires",name:" EITI MORENO S.R.L.",position:[-34.6420536, -58.6455887]},
  {category:"salud",type:"consultorio",address:"Almafuerte 530, San Pedro , Buenos Aires",name:"CONSULTORIOS E.M.I.J. - EQUIPO MULTIDISCIPLINARIO PARA LA ATENCION INFANTO-JUVEN",position:[-33.6786317, -59.6612914]},
  {category:"salud",type:"centro educativo",address:"Isabel La Catolica 863, Hurlingham , Buenos Aires",name:"CETRAN S.R.L.",position:[-34.5884554, -58.627768]},
  {category:"salud",type:"centro educativo",address:"Leandro N. Alem 2647 53, Moreno , Buenos Aires",name:"MARENAS CET S.R.L.",position:[-34.6567784, -58.780943]},
  {category:"salud",type:"fundaci\u00f3n",address:"Cris\u00f3logo Larralde 920, Ezeiza , Buenos Aires",name:"FUNDACION CAMILA DRAGONE ",position:[-34.8636179, -58.5182571]},
  {category:"salud",type:"asociaci\u00f3n",address:"Santa Fe 1940, Luj\u00e1n , Buenos Aires",name:"ASOC. CIVIL TALLER PROTEGIDO SAN JUAN XXIII",position:[-34.5681639, -59.0868893]},
  {category:"salud",type:"centro de d\u00eda",address:"Montes Carballo 1541, Mar del Plata , Buenos Aires",name:"KAIROS CENTRO DE D\u00cdA - LAQUESIS S.A.",position:[-37.9531457, -57.5661103]},
  {category:"salud",type:"miscelaneo",address:"Colect.Acc. Norte - 12 de Octubre esq. La Tijereta, Pilar , Buenos Aires",name:"CREECIMIENTOS de IVAN JACK",position:[-34.4500293, -58.9151787]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Pedro Diaz 3366, Hurlingham , Buenos Aires",name:"CENTRO DE REHABILITACI\u00d3N SAN JUAN DE DIOS ",position:[-34.5895982, -58.66725959999999]},
  {category:"salud",type:"miscelaneo",address:"Pte. Hip\u00f3lito Irigoyen 8071, Lomas de Zamora , Buenos Aires",name:"SAIE FLOR DE LIS de FLOR DE LIS S.R.L.",position:[-34.6921243, -58.5761422]},
  {category:"salud",type:"centro de d\u00eda",address:"Bah\u00eda Blanca 60 62, Wilde , Buenos Aires",name:"CENTRO DE D\u00cdA PUENTES POR LA PLENA INCLUSI\u00d3N de CENTRO DE D\u00cdA WILDE S.A.",position:[-34.7013875, -58.3192694]},
  {category:"salud",type:"instituto",address:"Montevideo 29, Bernal , Buenos Aires",name:"INSTITUTO NUEVO AMANECER S.A.",position:[-34.7052589, -58.292055]},
  {category:"salud",type:"miscelaneo",address:"Ruta 25 y San Fernando s N\u00b0, Pilar , Buenos Aires",name:"HOGAR Y CENTRO DE D\u00cdA ESTACI\u00d3N PILAR de ESTACI\u00d3N PILA S.A.",position:[-34.4229294, -58.8881853]},
  {category:"salud",type:"centro educativo",address:"BOULOGNE SUR MER 2881, Don Torcuato , Buenos Aires",name:"C.E.T.E.T. S.R.L.",position:[-34.4865738, -58.61999059999999]},
  {category:"salud",type:"miscelaneo",address:"CONDE 1140, Escobar , Buenos Aires",name:"FUNDACION PEUMAYEN ",position:[-34.345965, -58.7953449]},
  {category:"salud",type:"centro de rehabilitacion",address:"BOLIVIA 4336, Villa Ballester , Buenos Aires",name:"C.R.E.I. S.R.L. ",position:[-34.5544344, -58.5520751]},
  {category:"salud",type:"consultorio",address:"O'Higgins 465, Bah\u00eda Blanca , Buenos Aires",name:"CENTRO ORAL DEL SUR",position:[-38.7240325, -62.27133309999999]},
  {category:"salud",type:"centro de d\u00eda",address:"S\u00f3crates 1755, Del Viso , Buenos Aires",name:"S\u00d3CRATES CENTRO DE D\u00cdA de EL ENCUENRO CENTRO DE D\u00cdA S.R.L.",position:[-34.4694665, -58.7908688]},
  {category:"salud",type:"miscelaneo",address:"Juana Azurduy 55, SANTOS TESEI , Buenos Aires",name:"GRUPO CAMINO AL SOL S.R.L.",position:[-34.6173844, -58.634745]},
  {category:"salud",type:"miscelaneo",address:"Nicaragua 145, Merlo , Buenos Aires",name:"PER SE de PER SE S.A.",position:[-34.6974211, -58.693625]},
  {category:"salud",type:"centro de rehabilitacion",address:"Jos\u00e9 Hern\u00e1ndez 3078, Villa Ballester , Buenos Aires",name:"CENTRO DE ESTIMULACION TEMPRANA EN SUS PASOS",position:[-34.5538202, -58.5563093]},
  {category:"salud",type:"asociaci\u00f3n",address:"Lomas Valentinas 736, Ezeiza , Buenos Aires",name:"CEDIME de ASOC. CIVIL CENTRO DE D\u00cdA IRREGULARES MOTORES EZEIZA",position:[-34.8617303, -58.5224775]},
  {category:"salud",type:"asociaci\u00f3n",address:"Charlone 1741, San Miguel , Buenos Aires",name:"CEDIM de NATLAR S.R.L.",position:[-34.5474262, -58.71354760000001]},
  {category:"salud",type:"centro educativo",address:"Perez Taboada s N\u00b0, General Rodriguez, Buenos Aires",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO LA QUINTA de MAR\u00cdA JULIA FIORITO",position:[-34.6068251, -58.9745412]},
  {category:"salud",type:"escuela",address:"Alte. Brown 1365, Ingeniero Maschwitz , Buenos Aires",name:"COLEGIO DE EDUCACI\u00d3N ESPECIAL SAN FRANCISCO DE ASIS de COSAFRA ASIS S.R.L.",position:[-34.385818, -58.7405154]},
  {category:"salud",type:"miscelaneo",address:"San Mart\u00edn 1819, Luis Guill\u00f3n , Buenos Aires",name:"LOGRANDO S.A.",position:[-34.7998811, -58.45136369999999]},
  {category:"salud",type:"miscelaneo",address:"Camacu\u00e1 453, Ituzaing\u00f3 , Buenos Aires",name:"GRUPO ACES de SILVANA LISA MARZELLA",position:[-34.6544489, -58.66466839999999]},
  {category:"salud",type:"clinica",address:"Avda. San Mart\u00edn 354 - 1\u00b0 piso, Ramallo , Buenos Aires",name:"PANAMBI CL\u00cdNICA DE NEURODESARROLLO INFANTIL S.A.S.",position:[-33.4850695, -60.0045531]},
  {category:"salud",type:"miscelaneo",address:"Rivadavia 437 -PB- Unidad Funcional 1, Quilmes , Buenos Aires",name:"ATA\u00d1ER INTEGRACIONES S.R.L.",position:[-34.75583719999999, -58.2228081]},
  {category:"salud",type:"centro de d\u00eda",address:"C\u00e9sar D\u00edaz 669 - Dto. 2, Escobar , Buenos Aires",name:"CENTRO DE APOYO A LA INCLUSI\u00d3N LABERINTOS de LAURA ROMINA S\u00c1NCHEZ",position:[-34.34557450000001, -58.79704549999999]},
  {category:"salud",type:"miscelaneo",address:"Mu\u00f1iz 96, Ituzaing\u00f3 , Buenos Aires",name:"PSIQUE de JAVIER ALEJANDRO GOTLIB",position:[-34.6423792, -58.6580109]},
  {category:"salud",type:"centro de d\u00eda",address:"Tom\u00e1s Edison 1580 - El Jag\u00fcel, Esteban Echeverr\u00eda , Buenos Aires",name:"CENTRO DE APOYO INTEGRAL LA HUELLA S.R.L.",position:[-34.820675, -58.4918227]},
  {category:"salud",type:"consultorio",address:"Avda. Vergara 3161, Hurlingham , Buenos Aires",name:"FISIKALGROUP S.A.S.",position:[-34.604349, -58.63505439999999]},
  {category:"salud",type:"hogar",address:"Rosario esquina Cuyo sin n\u00famero, Bah\u00eda Blanca , Buenos Aires",name:"HOGAR SOLAR DEL ROSARIO S.A.",position:[-38.7183177, -62.2663478]},
  {category:"salud",type:"miscelaneo",address:"Calle 489 N\u00b0 2130 entre 16 y 16 bis, Gonnet , Buenos Aires",name:"INTRES de HELPING HAN S.A.",position:[-34.8793027, -58.03035569999999]},
  {category:"salud",type:"miscelaneo",address:"Jos\u00e9 Manuel Estrada 871 - PB -F, Escobar , Buenos Aires",name:"DEVENERIS de FRANCISCO JESUS GONZALEZ",position:[-34.345965, -58.7953449]},
  {category:"salud",type:"hogar",address:"Darregueira 1159, Bah\u00eda Blanca , Buenos Aires",name:"HOGAR ACOMPA\u00d1A Y ASISTE S.A.",position:[-38.7328789, -62.2584919]},
  {category:"salud",type:"miscelaneo",address:"Gral. Jos\u00e9 de San Mart\u00edn 1024, Mor\u00f3n , Buenos Aires",name:"ABRIENDO MANOS de MAR\u00cdA CAROLINA BARENGHI ROLDAN",position:[-34.6597117, -58.6227506]},
  {category:"salud",type:"miscelaneo",address:"Gral. Juan Mart\u00edn de Pueyrred\u00f3n 54, Merlo , Buenos Aires",name:"PEQUE\u00d1OS SUE\u00d1OS de GUSTAVO ADRIAN SAGUATTI",position:[-34.6407588, -58.56136249999999]},
  {category:"salud",type:"centro de d\u00eda",address:"C\u00f3rdoba 2464, Mart\u00ednez , Buenos Aires",name:"CENTRO DEL PARQUE de MERMO S.A.",position:[-34.4996738, -58.5286787]},
  {category:"salud",type:"centro de rehabilitacion",address:"9 de Julio 110, Quilmes , Buenos Aires",name:"CENTRO DE REHABILITACION 9 DE JULIO de SANATORIO MODELO QUILMES S.A.",position:[-34.7204896, -58.2608751]},
  {category:"salud",type:"miscelaneo",address:"Fernando Fader 1354 - Bosques, Florencio Varela , Buenos Aires",name:"CREANDO LAZOS de VER\u00d3NICA ELIZABETH GHIBAUDI",position:[-34.8274881, -58.2285766]},
  {category:"salud",type:"consultorio",address:"Rodriguez Pe\u00f1a 288 , Banfield , Buenos Aires",name:"DIAGN\u00d3STICO Y TRATAMIENTO DE LA COMUNICACI\u00d3N S.A.",position:[-34.7415097, -58.3983059]},
  {category:"salud",type:"instituto",address:"Avda. Meeks 1393, Temperley , Buenos Aires",name:"INSTITUTO DE NEUROCIENCIAS TEMPERLEY S.A.",position:[-34.7779071, -58.3973162]},
  {category:"salud",type:"asociaci\u00f3n",address:"Avda. Sarmiento 291 - 2do. piso - Dpto. B, Chivilcoy , Buenos Aires",name:"ESPEJOS SERVICIO DE APOYO A LA INTEGRACI\u00d3N ESCOLAR CHIVILCOY ASOCIACI\u00d3N CIVIL",position:[-34.9013595, -60.01351469999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Ecuador 480, Tigre , Buenos Aires",name:"DEL JUNCAL CENTRO DE REHABILITACION DE ALTA COMPLEJIDAD LJREHABILITACI\u00d3N S.A.S.",position:[-34.6003469, -58.4065441]},
  {category:"salud",type:"centro de d\u00eda",address:"Infanta Isabel 3935, Moreno , Buenos Aires",name:"CENTRO DE DIA NAHUEZE de NAHUEZE S.R.L.",position:[-34.6028934, -58.86509640000001]},
  {category:"salud",type:"miscelaneo",address:"Santa Fe 1749, Luj\u00e1n , Buenos Aires",name:"HOGAR Y CENTRO DE DIA ESPACIO ENCUENTRO de ESPACIO ENCUENTRO S.A.",position:[-34.5690577, -59.0879458]},
  {category:"salud",type:"consultorio",address:"Rodolfo Lopez 627, Quilmes , Buenos Aires",name:"CONSULTORIOS INTEGRALES DEL SUR de NADIA SUSANA BOSCATO y GISELE ROMINA BRAVO",position:[-34.7231406, -58.2698692]},
  {category:"salud",type:"centro de d\u00eda",address:"Diego Laure 995, Ezeiza , Buenos Aires",name:"CENTRO DE DIA EZEIZA de ARQUIMEDES DE SIRACUSA S.R.L.",position:[-34.8625941, -58.51642569999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Oncativo 1098, Lan\u00fas Este , Buenos Aires",name:"CENTRO CRECIENDO de EQUIPO DEVENIR .S.R.L.",position:[-34.7033707, -58.3787797]},
  {category:"salud",type:"centro de d\u00eda",address:"De los Espa\u00f1oles 3632 , Pontevedra , Buenos Aires",name:"CENTRO DE DIA PONTEVEDRA DE NUEVA INCLUSION S.R.L.",position:[-34.7486245, -58.7052875]},
  {category:"salud",type:"centro educativo",address:"Camino Gral. Belgrano 3250 e 443 y 444, City Bell, Buenos Aires",name:"CENTRO CEDICBELL S.R.L.",position:[-34.8782608, -58.068083]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. de las Industrias 3486 96, Rojas, Buenos Aires",name:"CENTRO DE DIA NORTE de CENTRO DE DIA TIGRE S.R.L.",position:[-34.197698, -60.7338035]},
  {category:"salud",type:"miscelaneo",address:"Avda. Libertador 6502 08, Moreno , Buenos Aires",name:"RED ATI S.R.L.",position:[-34.6228938, -58.7756469]},
  {category:"salud",type:"centro de rehabilitacion",address:"Gral. Bernardo OHiggins 3230, Olivos , Buenos Aires",name:"TCC.TEA de LAURA MARIELA GARC\u00cdA",position:[-34.5143328, -58.50923960000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Juan Manuel Estrada 1559, Burzaco, Buenos Aires",name:"CENTRO DE DIA AMIGOS S.R.L.",position:[-34.8121422, -58.3925503]},
  {category:"salud",type:"centro de rehabilitacion",address:"French 654, Banfield , Buenos Aires",name:"MATERNAR CENTRO INTEGRAL DE SALUD MENTAL BC S.R.L.",position:[-34.74461120000001, -58.39173160000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Mons. Larumbe 2435 39, Mart\u00ednez , Buenos Aires",name:"CENTRO DE DIA SENTIRES de FUNDACION JUNTOS PARA HACER",position:[-34.4963305, -58.5191609]},
  {category:"salud",type:"centro de d\u00eda",address:"Padre Perna 1067 - Local 5, Gar\u00edn , Buenos Aires",name:"C.A.N.E.E. de MARGARITA M\u00d3NICA AGUIRREZ",position:[-34.4191436, -58.72958000000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Lavalle 1680, Monte Grande , Buenos Aires",name:"CENTRO DE DIA MONTE GRANDE S.R.L.",position:[-34.833319, -58.45969139999999]},
  {category:"salud",type:"miscelaneo",address:"25 de Mayo 197, Quilmes , Buenos Aires",name:"FEEDBACK de CABARCOS ARRIGO S.R.L.",position:[-34.7261303, -58.2544887]},
  {category:"salud",type:"centro de rehabilitacion",address:"Salta 3275 79, Mar del Plata , Buenos Aires",name:"APNEA de PILETA LIBRE S.A.",position:[-38.008208, -57.56437909999999]},
  {category:"salud",type:"centro terapeutico",address:"Fragata Sarmiento 285, Suipacha , Buenos Aires",name:"CENTRO TERAP\u00c9UTICO Y DE INTEGRACI\u00d3N ESCOLAR INCLUIR S.R.L.",position:[-34.7720644, -59.6852961]},
  {category:"salud",type:"miscelaneo",address:"Bernardo de Moteagudo 2645, Florencio Varela , Buenos Aires",name:"ESPACIO ABRA de ESPACIOS EDUCATIVOS Y TERAPEUTICOS ABRA S.R.L.",position:[-34.8031351, -58.2751254]},
  {category:"salud",type:"centro de rehabilitacion",address:"Zuvir\u00eda 850, PEHUAJ\u00d3, Buenos Aires",name:"LEKU CENTRO DE REHABILITACION S.R.L.",position:[-35.8051494, -61.8975531]},
  {category:"salud",type:"asociaci\u00f3n",address:"Perito Moreno 805, Ca\u00f1uelas , Buenos Aires",name:"HOGAR SAN ONOFRIO de ASOCIACION CIVIL POR UN NUEVO MUNDO",position:[-35.0545181, -58.7651366]},
  {category:"salud",type:"instituto",address:"Felipe Amodeo 2430, Quilmes Oeste , Buenos Aires",name:"ITENQUI S.R.L.",position:[-34.7427913, -58.28058770000001]},
  {category:"salud",type:"centro educativo",address:"San Mart\u00edn 335, Mor\u00f3n , Buenos Aires",name:"SANTA CLARA CENTRO EDUCATIVO TERAPEUTICO S.R.L.",position:[-34.6821142, -58.5249969]},
  {category:"salud",type:"centro educativo",address:"Mariano Alegre 254, Monte Grande , Buenos Aires",name:"CENTRO EDUCATIVO TERAPEUTICO BRIO S.A.",position:[-34.811064, -58.4691216]},
  {category:"salud",type:"centro de d\u00eda",address:"Moreno 772, Pergamino , Buenos Aires",name:"PAGORI de CENTROS DEL SOL S.A.S.",position:[-33.8973147, -60.5695509]},
  {category:"salud",type:"miscelaneo",address:"Gral. Paz 1936 - UF 1, Beccar , Buenos Aires",name:"ENTRE LAZOS de MARINA ALICIA IBARRA",position:[-34.4615819, -58.52931219999999]},
  {category:"salud",type:"miscelaneo",address:"V\u00e9lez Sarsfield 282 84, Avellaneda , Buenos Aires",name:"RENACER AVELLANEDA S.R.L",position:[-34.6696526, -58.3619118]},
  {category:"salud",type:"centro de d\u00eda",address:"9 de Julio 5426, Villa Ballester , Buenos Aires",name:"CALDEN CENTRO DE DIA DE COBATOS S.R.L.",position:[-34.5561976, -58.5727579]},
  {category:"salud",type:"centro de rehabilitacion",address:"CALLE 144 N\u00b01763, Berazategui , Buenos Aires",name:"CENTRO DE REHABILITACION INTEGRAL TE INVITO A JUGAR S.R.L.",position:[-34.7675898, -58.21068849999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Lavalle 1203, Quilmes , Buenos Aires",name:"C.R.I.L. DE CENTRO PEDIATRICO NEUROLOGICO S.A",position:[-34.7320006, -58.25024860000001]},
  {category:"salud",type:"miscelaneo",address:"Ehrlich 544 546, Ituzaing\u00f3 , Buenos Aires",name:"WAYRA de MARIA SOLEDAD FERNANDEZ",position:[-34.6362545, -58.66170469999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Entre Rios 1046 esq. Senador Moron, Bella Vista , Buenos Aires",name:"CENTRO CLAUDINA THAVENET de FUNDACION CLAUDINA THEVENET ",position:[-32.5175643, -59.1041758]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Gral. Manuel Belgrano 420, Gar\u00edn , Buenos Aires",name:"CENTRO ENLACES S.R.L.",position:[-34.4207886, -58.7400175]},
  {category:"salud",type:"miscelaneo",address:"calle 11 4630, Berazategui , Buenos Aires",name:"HOGAR Y CENTRO DE DIA SAN MARTIN de CLINICA PRIVADA DE PSIQUIATRIA Y PSICOLOGIA ",position:[-34.7597764, -58.21372649999999]},
  {category:"salud",type:"centro educativo",address:"NOLTING 3632, Ciudadela , Buenos Aires",name:"RAYUELA CET de VITIUM S.R.L.",position:[-34.6442951, -58.53531940000001]},
  {category:"salud",type:"miscelaneo",address:"Beruti 177, Quilmes Oeste , Buenos Aires",name:"IDENTIDAD PLURAL S.R.L.",position:[-34.7362682, -58.25311]},
  {category:"salud",type:"asociaci\u00f3n",address:"COTAGAITA 2014, Ramos Mej\u00eda , Buenos Aires",name:"APACID",position:[-34.6618107, -58.55874559999999]},
  {category:"salud",type:"asociaci\u00f3n",address:" , Lincol, Buenos Aires",name:"ASOCIACION CIVIL CE.PA.DIS",position:[-34.8684492, -61.52909059999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Uruguay 610, San Pedro , Buenos Aires",name:"CENTRO SAGRADA FAMILIA S.A.",position:[-33.6800171, -59.6602554]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Gaspar Campos 3646, San Miguel , Buenos Aires",name:"CENTRO DE ESTIMULACION TEMPRANA MAMA PIUQUEN S.R.L.",position:[-34.5470193, -58.7272821]},
  {category:"salud",type:"asociaci\u00f3n",address:"Hughes 225, Escobar , Buenos Aires",name:"ASOCIACION CIVIL ASANA - ASOCIACION DE AYUDA AL NI\u00d1O AISLADO",position:[-34.3391505, -58.82190319999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Dr. Luis Murature 2648, Remedios de Escalada , Buenos Aires",name:"CENTRO DE DIA REVELARTE DE VITSAM S.R.L.",position:[-34.731965, -58.3954165]},
  {category:"salud",type:"miscelaneo",address:"Rep\u00fablica Bolivariana de Venezuela 1146 - 3er. pis, Esteban Echeverr\u00eda , Buenos Aires",name:"CERCA OSPA\u00d1A de APRENDIENDO CERCA CANNING S.R.L.",position:[-34.614482, -58.38235090000001]},
  {category:"salud",type:"miscelaneo",address:"Calle 56 N\u00b01469, La Plata , Buenos Aires",name:"CORPUS de CORSYS SALUD S.R.L.",position:[-34.9340963, -57.9635857]},
  {category:"salud",type:"miscelaneo",address:"Senillosa 540, Temperley , Buenos Aires",name:"MERAKI VAYU de MARTIN JORGE PASCUAL",position:[-34.7715442, -58.36776949999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"TRES ARROYOS 55, Mar del Plata , Buenos Aires",name:"ASOCIACION CIVIL NUESTROS VINCULOS PARA LA INCLUSION DE LAS PERSONAS MAS VULNERA",position:[-37.9658076, -57.57649490000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"GRAL. BERNARDO O'HIGGINS 3230, Olivos , Buenos Aires",name:"TCC.TEA de LAURA MARIELA GARCIA",position:[-34.5143328, -58.50923960000001]},
  {category:"salud",type:"miscelaneo",address:"LUIS PEREYRA 370, Tigre , Buenos Aires",name:"NEURODELTA de LUIS ERNESTO ANDR\u00c9S",position:[-34.4275472, -58.5723272]},
  {category:"salud",type:"miscelaneo",address:"INTENDENTE PAGANO 2832 - UF 2, Moreno , Buenos Aires",name:"TIEMPOS de M.L. CENTURION, V.E. SPAGNA y A.M. GALINEUSKY",position:[-34.6447102, -58.7885163]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. rivadavia 17750 52, Mor\u00f3n , Buenos Aires",name:"CENTRO DE REHABILITACION JUAN XXIII S.A.",position:[-34.6492806, -58.6161519]},
  {category:"salud",type:"escuela",address:"COLON 1214 1220, Saenz Pe\u00f1a , Buenos Aires",name:"ESCUELA DE COMUNICACION Y LENGUAJE SIEMBRA S.A",position:[-34.599205, -58.5316813]},
  {category:"salud",type:"centro educativo",address:"LACROZE 7322, Gral. San Mart\u00edn , Buenos Aires",name:"JACARANDA EDUCACI\u00d3N ESPECIAL INTEGRAL S.R.L.",position:[-34.5316516, -58.5782373]},
  {category:"salud",type:"centro de d\u00eda",address:"VIAMONTE 479, La Matanza , Buenos Aires",name:"CAVIDI de ANALISAN S.R.L.",position:[-34.6416855, -58.5546723]},
  {category:"salud",type:"miscelaneo",address:"MENDOZA 2133, Lan\u00fas , Buenos Aires",name:"ESTUDIO AVI LANUS S.R.L",position:[-34.6899698, -58.41045459999999]},
  {category:"salud",type:"miscelaneo",address:"FRENCH 654, Lomas de Zamora , Buenos Aires",name:"SALUD MENTAL BC S.R.L.",position:[-34.7419583, -58.40397170000001]},
  {category:"salud",type:"centro educativo",address:"BAHIA THETIS 1219, Tortuguitas , Buenos Aires",name:"CET LEO KANNER S.A",position:[-34.4798652, -58.77450100000001]},
  {category:"salud",type:"centro educativo",address:"ENRE RIOS 3558 60, San Justo , Buenos Aires",name:"SAN JUSTO CET S.R.L.",position:[-34.6769886, -58.5593087]},
  {category:"salud",type:"fundaci\u00f3n",address:"RUTA 30 KM. 118 - HARAS GRAL. LAVALLE, Tandil , Buenos Aires",name:"FUNDACION TODOS SOMOS CIANE TANDIL",position:[-37.3287999, -59.1367167]},
  {category:"salud",type:"miscelaneo",address:"BARTOLOME MITRE 1379, Luj\u00e1n , Buenos Aires",name:"SINERGIA SAIE LUJAN S.R.L.",position:[-34.5706693, -59.1035099]},
  {category:"salud",type:"centro de d\u00eda",address:"GENEAL PICO 1548 Y 1554, Lan\u00fas Este , Buenos Aires",name:"CEDIO CENTRO DE DIA S.R.L.",position:[-34.7210953, -58.3637464]},
  {category:"salud",type:"centro de d\u00eda",address:"LACROZE 7259, Jos\u00e9 Le\u00f3n Su\u00e1rez , Buenos Aires",name:"CENTRO AGALMA de VERONICA ANDREA RUHKIECK",position:[-34.5315619, -58.57429390000001]},
  {category:"salud",type:"centro de d\u00eda",address:"LAS PIEDRAS 2737, Lan\u00fas Este , Buenos Aires",name:"UTOPIA EN EL SUR S.R.L.",position:[-34.7212736, -58.37560329999999]},
  {category:"salud",type:"centro de d\u00eda",address:"GELLY Y OBES 1425, Mor\u00f3n , Buenos Aires",name:"LA CASITA KIA ORA S.R.L.",position:[-34.6356341, -58.58503019999999]},
  {category:"salud",type:"centro de d\u00eda",address:"SUCRE 2126, Don Torcuato , Buenos Aires",name:"CENTRO DE DIA CASA DE TODOS S.R.L.",position:[-34.4952685, -58.6173387]},
  {category:"salud",type:"fundaci\u00f3n",address:"SAAVEDRA 4848, Mar del Plata , Buenos Aires",name:"FUNDACION CRECIENDO CON LECAR ",position:[-38.0033428, -57.5818154]},
  {category:"salud",type:"centro educativo",address:"ESTADOS UNIDOS 175, Villa Adelina , Buenos Aires",name:"INTEGRACI\u00d3N ESCOLAR CENTRO KUM\u00cbN S.R.L",position:[-34.52025690000001, -58.54453960000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"JUAN MANUEL DE ROSAS 855, Merlo , Buenos Aires",name:"CENTRO DE REHABILITACION ITUZAINGO S.R.L",position:[-34.6664383, -58.7300433]},
  {category:"salud",type:"asociaci\u00f3n",address:"ALMIRANTE GUILLERMO BROWN 140, Lober\u00eda, Buenos Aires",name:"ASOCIACION DE FAMILIARES DE PERSONAS CON CAPACIDADES DIFERENTES DE LOBERIA ",position:[-38.1634422, -58.7816955]},
  {category:"salud",type:"miscelaneo",address:"O'HIGGINS 56, General Viamonte, Buenos Aires",name:"MUNICIPALIDAD DE GENERAL VIAMONTE ",position:[-34.9955354, -61.04610770000001]},
  {category:"salud",type:"miscelaneo",address:"Pringles 738 , CABA, CABA",name:"ACUARELA ",position:[-34.6036325, -58.42761299999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Teodoro Garc\u00eda 2948 , CABA, CABA",name:"Asociacion en Defensa del Infante Neurologico - A.",position:[-34.5749576, -58.44901720000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Francisco Fernandez de la Cruz 2875, CABA, CABA",name:"ASOCIACION JUVENIL ARAUCANA",position:[-34.6573246, -58.44055470000001]},
  {category:"salud",type:"miscelaneo",address:"Melincu\u00e9 2756 , CABA, CABA",name:"ALBANTA ",position:[-34.5998895, -58.4887416]},
  {category:"salud",type:"fundaci\u00f3n",address:"Av. Belgrano 4014 , CABA, CABA",name:"FUNDACI\u00d3N CISAM - CENTRO DE INVESTIGACIONES PARA LA SALUD MENTAL",position:[-34.6159156, -58.4231399]},
  {category:"salud",type:"escuela",address:"Ayacucho 665, CABA, CABA",name:"COLEGIO DE LA SALLE de ASOCIACION EDUCACIONISTA ARGENTINA",position:[-34.6016485, -58.39531299999999]},
  {category:"salud",type:"miscelaneo",address:"Estomba 765 , CABA, CABA",name:"CREANDO DE DARSHAN S.R.L.",position:[-34.5841491, -58.4647615]},
  {category:"salud",type:"instituto",address:"MAGARI\u00d1OS CERVANTES 3235 3237, CABA, CABA",name:"INSTITUTO DE LA FLOR DE EDUCACION ESPECIAL S.R.L. ",position:[-34.6171946, -58.4869394]},
  {category:"salud",type:"miscelaneo",address:"Caldas 1355 57 , CABA, CABA",name:"DESPERTAR ",position:[-34.587767, -58.4640281]},
  {category:"salud",type:"miscelaneo",address:"Iber\u00e1 5042 , CABA, CABA",name:"DINAD ",position:[-34.566737, -58.4900488]},
  {category:"salud",type:"centro de d\u00eda",address:"La Pampa 5980 82 , CABA, CABA",name:"Centro Psicoasistencial ESPACIOS S.R.L. ",position:[-34.5677725, -58.4592876]},
  {category:"salud",type:"fundaci\u00f3n",address:"Costa Rica 4436 38 , CABA, CABA",name:"FUNDACION TOBIAS (SEMIEM) ",position:[-34.5860453, -58.4301638]},
  {category:"salud",type:"fundaci\u00f3n",address:"Gurruchaga 1160 64 , CABA, CABA",name:"INSTITUTO GENESIS SRL ",position:[-34.5920267, -58.4334159]},
  {category:"salud",type:"miscelaneo",address:"Gascon 1269 , CABA, CABA",name:"GRUPO CAMINANTES ",position:[-34.5950089, -58.42212869999999]},
  {category:"salud",type:"hogar",address:"Caracas 3818 20, CABA, CABA",name:"HOGAR HSER de Miriam NOVARO",position:[-34.6007, -58.4793624]},
  {category:"salud",type:"instituto",address:"Conesa 2051 , CABA, CABA",name:"ILE S.R.L.",position:[-34.5658055, -58.46179540000001]},
  {category:"salud",type:"instituto",address:"Guardia Vieja 4541 , CABA, CABA",name:"Instituto INFANCIAS S.R.L. ",position:[-34.5996824, -58.4287055]},
  {category:"salud",type:"instituto",address:"H\u00edp\u00f3lito Yrigoyen 3700   Colombres 216, CABA, CABA",name:"INSTITUTO INTEGRAL S.A.",position:[-34.6143941, -58.41847309999999]},
  {category:"salud",type:"instituto",address:"Pte. Juan D. Peron 2239 , CABA, CABA",name:"INSTITUTO ORAL MODELO S.A. ",position:[-34.6071832, -58.39848039999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Aranguren 1551 , CABA, CABA",name:"FUNDACION IPNA ",position:[-34.6189628, -58.4539476]},
  {category:"salud",type:"instituto",address:"Bogota 2847 , CABA, CABA",name:"I.R.T.E. Instituto Recreativo Terap\u00e9utico Especial",position:[-34.627103, -58.4703986]},
  {category:"salud",type:"miscelaneo",address:"Valdenegro 3470 , CABA, CABA",name:"MI MA\u00d1ANA de NORMA EDITH GIGENA",position:[-34.5626421, -58.4899874]},
  {category:"salud",type:"escuela",address:"Av. Directorio 368 70 72, CABA, CABA",name:"NUESTRA ESCUELA S.R.L. ",position:[-34.6389171, -58.476089]},
  {category:"salud",type:"miscelaneo",address:"Galicia 1982 , CABA, CABA",name:"OSUAL S.R.L.",position:[-34.6125084, -58.46444169999999]},
  {category:"salud",type:"miscelaneo",address:"J. B. Justo 8355 , CABA, CABA",name:"PROHODIS ",position:[-34.6340193, -58.50608020000001]},
  {category:"salud",type:"instituto",address:"Aguilar 2845 , CABA, CABA",name:"INSTITUTO PSICOPEDAGOGICO SENDEROS S.R.L. ",position:[-34.5726386, -58.45067770000001]},
  {category:"salud",type:"instituto",address:"J. F. Aranguren 1352 , CABA, CABA",name:"ISAL S.R.L.",position:[-34.6182714, -58.45133670000001]},
  {category:"salud",type:" union",address:"Tamborini 6065 , CABA, CABA",name:"UN.HI.D. S.R.L. ",position:[-34.5718322, -58.5028179]},
  {category:"salud",type:"instituto",address:"Yerbal 1680 , CABA, CABA",name:"INSTITUTO VENID ",position:[-34.6244519, -58.4540126]},
  {category:"salud",type:"miscelaneo",address:"Montes de Oca 110  250 , CABA, CABA",name:"VITRA ",position:[-34.6413271, -58.3741756]},
  {category:"salud",type:"escuela",address:"Laprida 1138 , CABA, CABA",name:"ESCUELA TERAP\u00c9UTICA ZOE S.A. ",position:[-34.5955403, -58.40853059999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Mariano Acosta 1259 , CABA, CABA",name:"67 FUNDACION PBRO. MARIO PANTALEO ",position:[-34.6730636, -58.3808726]},
  {category:"salud",type:"centro educativo",address:"Rinc\u00f3n 983 , CABA, CABA",name:"CRECIENDO JUNTOS S.A. Centro Educativo Terap\u00e9utico",position:[-34.6203162, -58.39614019999999]},
  {category:"salud",type:"escuela",address:"Thames 1078 , CABA, CABA",name:"W. PRINGLE MORGAN Escuela de Recuperaci\u00f3n ",position:[-34.5914994, -58.43689010000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Nu\u00f1ez 4147 , CABA, CABA",name:"CENTRO DE RECUPERACI\u00d3N INTEGRAL ",position:[-34.558659, -58.48298750000001]},
  {category:"salud",type:"miscelaneo",address:"Av. Boyac\u00e1 483 , CABA, CABA",name:"VENID ",position:[-34.6214567, -58.4599902]},
  {category:"salud",type:"instituto",address:"Av. Boyac\u00e1 1440 , CABA, CABA",name:"INSTITUTO DE EDUCACION ESPECIAL AYELEN S.R.L.",position:[-34.6132104, -58.4668803]},
  {category:"salud",type:"consultorio",address:"Amen\u00e1bar 3554 , CABA, CABA",name:"Centro M\u00e9dico AMEN\u00c1BAR S.R.L. ",position:[-34.5506588, -58.4698017]},
  {category:"salud",type:"escuela",address:"Teodoro Garc\u00eda 3110 3120 , CABA, CABA",name:"MANANTIAL Jard\u00edn Terap\u00e9utico S.R.L. ",position:[-34.574257, -58.4489179]},
  {category:"salud",type:"centro educativo",address:"Rawson 88 , CABA, CABA",name:"CETEI S.A.",position:[-34.6119192, -58.4259633]},
  {category:"salud",type:"instituto",address:"Teodoro Garc\u00eda 2449 , CABA, CABA",name:"Instituto JORGE NEWBERY ",position:[-34.5701657, -58.44636209999999]},
  {category:"salud",type:"instituto",address:"Achega 3515 , CABA, CABA",name:"Instituto PIEDRA LIBRE (ACUARELA A.G.A.S. S.R.L.) ",position:[-34.5686744, -58.50425980000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Combatientes de Malvinas 3223 , CABA, CABA",name:"C.A.I.T.I. Ctro. Arg. Integral de Trat. Individual",position:[-34.58263, -58.47320130000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. D\u00edaz Velez 3449 , CABA, CABA",name:"C.P.C. Centro de Par\u00e1lisis Cerebral ",position:[-34.608208, -58.4153518]},
  {category:"salud",type:"centro educativo",address:"Gallardo 566 , CABA, CABA",name:"MI CASA BLANCA - Centro Educativo Terap\u00e9utico ",position:[-34.6321439, -58.52500369999999]},
  {category:"salud",type:"centro educativo",address:"Aranguren 1841 1843 , CABA, CABA",name:"CEREP SRL ",position:[-34.6225704, -58.463789]},
  {category:"salud",type:"fundaci\u00f3n",address:"Bruno Mariano de Zabala 3444 , CABA, CABA",name:"CPI de FUNDACI\u00d3N CENTRO DE PSICOTERAPIAS INTEGRADAS",position:[-34.5778359, -58.4566739]},
  {category:"salud",type:"asociaci\u00f3n",address:"Boyaca 48 , CABA, CABA",name:"PROMOVER ASOCIACION MUTUAL",position:[-34.6261936, -58.45720989999999]},
  {category:"salud",type:"centro educativo",address:"Alte. F.J. Segui 2272 , CABA, CABA",name:"LAZOS (Fupa) C.E.T. ",position:[-34.6000017, -58.4620407]},
  {category:"salud",type:"escuela",address:"Pedro Ignacio Rivero 5445 , CABA, CABA",name:"ASOCIACI\u00d3N COLEGIO \"SAINT- JEAN\"",position:[-34.5740742, -58.4925527]},
  {category:"salud",type:"centro educativo",address:"Jaramillo 2839 , CABA, CABA",name:"C.E.T. PLIEGUES ",position:[-34.5501638, -58.47293149999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Mexico 2962 , CABA, CABA",name:"EL HILO de ARIADNA Ctro. Asistencial ",position:[23.634501, -102.552784]},
  {category:"salud",type:"miscelaneo",address:"Juan Domingo Per\u00f3n 4043-45, CABA, CABA",name:"KAUSAS S.R.L.",position:[-34.6058915, -58.4235706]},
  {category:"salud",type:"asociaci\u00f3n",address:"Fragata Presidente Sarmiento 829 31 , CABA, CABA",name:"APEBI Asoc. para Espina B\u00edfida e Hidrocefalia ",position:[-34.6144006, -58.4527939]},
  {category:"salud",type:"centro de d\u00eda",address:"Uruguay 1237 , CABA, CABA",name:"CENTRO CLAUDINA THAVENET de FUNDACION CLAUDINA THEVENET ",position:[-34.5940041, -58.3873049]},
  {category:"salud",type:"instituto",address:"Conesa 940   942 , CABA, CABA",name:"INSTITUTO SANTA CATALINA S.R.L. ",position:[-34.5634914, -58.4639676]},
  {category:"salud",type:"miscelaneo",address:"Gorostiaga 1746 , CABA, CABA",name:"CAMPO ALTO de SERVIAM S.R.L. ",position:[-34.5664965, -58.4372379]},
  {category:"salud",type:"asociaci\u00f3n",address:"Tte. Gral. Juan D. Peron 3988 , CABA, CABA",name:"ASOCIACI\u00d3N DE AYUDA AL CIEGO \"A.S.A.C.\" ",position:[-34.6060697, -58.4225742]},
  {category:"salud",type:"instituto",address:"Planes 1150 , CABA, CABA",name:"Instituto Modelo \"PROYECTO 2000\" ",position:[-34.6124249, -58.449811]},
  {category:"salud",type:"centro de rehabilitacion",address:"Juramento 4751, CABA, CABA",name:"CTRO.DE RECUP.ADAPTACION Y RECREACION \"CREAR\" SRL",position:[-34.574874, -58.47910159999999]},
  {category:"salud",type:"miscelaneo",address:"Aranguren 2857, CABA, CABA",name:"CONVIVIR S.H.",position:[-34.6249087, -58.4715344]},
  {category:"salud",type:"centro de d\u00eda",address:"Iber\u00e1 2786 - P.B. , CABA, CABA",name:"Centro de d\u00eda \"LOGROS\" MARAVILLAS DE JES\u00daS. ",position:[-34.5554073, -58.4676608]},
  {category:"salud",type:"centro de rehabilitacion",address:"Caracas 2619 , CABA, CABA",name:"SER (Servicios Especializados en Rehabilitaci\u00f3n) ",position:[-34.6025042, -58.4775615]},
  {category:"salud",type:"centro de d\u00eda",address:"Maure 2108 , CABA, CABA",name:"C.C.R.A.I.(Ctro. de Cap. y Rec. de Ap. para la Inc",position:[-34.5697412, -58.4391175]},
  {category:"salud",type:"miscelaneo",address:"Bacacay 3122 , CABA, CABA",name:"ABRIENDO CAMINOS S.R.L. ",position:[-34.629395, -58.4736825]},
  {category:"salud",type:"miscelaneo",address:"Pasaje Mas\u00f3n 4425 , CABA, CABA",name:"SEMBRAR S.R.L. ",position:[-34.5946895, -58.4275238]},
  {category:"salud",type:"miscelaneo",address:"Av. La Plata 1833 , CABA, CABA",name:"CAPACIDADES DIFERENTES S.R.L. ",position:[-34.6361366, -58.4250269]},
  {category:"salud",type:"miscelaneo",address:"Mendoza 2695, CABA, CABA",name:"NEXO DE MARIA ELENA BERTOLINI",position:[-34.5625744, -58.46020189999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ramon Freire 881 , CABA, CABA",name:"FUNDACION PASO A PASO ",position:[-34.5759772, -58.45066769999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"2 de Abril 3165 , CABA, CABA",name:"WEMTEMIL RAYO LUCIENTE -ASOCIACION CIVIL PARA PER ",position:[-34.5970621, -58.4869761]},
  {category:"salud",type:"escuela",address:"Mendoza 4485 , CABA, CABA",name:"COLEGIO MAYOR ",position:[-34.5728563, -58.47749839999999]},
  {category:"salud",type:"miscelaneo",address:"Uriarte 2256 , CABA, CABA",name:"PROYECTO LAYLA S.R.L ",position:[-34.5827153, -58.4255283]},
  {category:"salud",type:"miscelaneo",address:"Tucuman 3074 86 , CABA, CABA",name:"Hamakon Sheli - Mi Lugar A.C. ",position:[-34.5896167, -58.4974129]},
  {category:"salud",type:"instituto",address:"Gorriti 4065 , CABA, CABA",name:"Inst. Atenea S.H. de Bernardi Daniel, Carrino Beat",position:[-34.5949567, -58.4222179]},
  {category:"salud",type:"fundaci\u00f3n",address:"14 de Julio 137 41 , CABA, CABA",name:"FUNDAIPA ",position:[-34.5828967, -58.4619668]},
  {category:"salud",type:"instituto",address:"Terrada 5547 , CABA, CABA",name:"Instituto Pedag\u00f3gico mi Amanecer S.R.L. ",position:[-34.578761, -58.5070083]},
  {category:"salud",type:"centro educativo",address:"Sanchez de Bustamante 1969 PB B , CABA, CABA",name:"Centro de Educacion Especial ni uno ni menos asoci",position:[-34.5891164, -58.4076703]},
  {category:"salud",type:"escuela",address:"Republica Bolivariana de Venezuela 3648 , CABA, CABA",name:"ESSERE ",position:[-34.6175694, -58.41758099999999]},
  {category:"salud",type:"instituto",address:"Entre Rios 1954 6 , CABA, CABA",name:"Instituto LOS ANGELES (Angeducar S.A.) ",position:[-34.6216854, -58.3915687]},
  {category:"salud",type:"centro educativo",address:"Terrada 5441 43 , CABA, CABA",name:"Centro Educativo Terap\u00e9utico y de Investigaci\u00f3n Ps",position:[-34.6018098, -58.4878777]},
  {category:"salud",type:"instituto",address:"Carlos Calvo 3893 95 , CABA, CABA",name:"INSTITUTO DE PSICOPATOLOGIA NUESTRA SE\u00d1ORA DE LUJA",position:[-34.6207216, -58.3971122]},
  {category:"salud",type:"miscelaneo",address:"Mendoza 5663 , CABA, CABA",name:"PEQUE\u00d1O MUNDO ",position:[-34.5801297, -58.4892067]},
  {category:"salud",type:"miscelaneo",address:"Gavilan 1263 , CABA, CABA",name:"ACRECENCIA SRL ",position:[-34.6153044, -58.4659624]},
  {category:"salud",type:"asociaci\u00f3n",address:"Manuela Pedrdaza 3195, CABA, CABA",name:"ADEEI - ASOCIACI\u00d3N CIVIL PARA EL DESARROLLO DE LA EDUCACI\u00d3NM ESPECIAL Y LA INTEG",position:[-34.5549974, -58.47311550000001]},
  {category:"salud",type:"centro educativo",address:"Somellera 5922 , CABA, CABA",name:"S.E.A. SERVICIOS EDUCATIVOS ASISTENCIALES S.R.L. ",position:[-34.6778839, -58.47809239999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Franklin Roosevelt 2455 4\u00ba \"B\" , CABA, CABA",name:"AIDIS SOCIEDAD ANONIMA ",position:[-34.55784149999999, -58.4613261]},
  {category:"salud",type:"miscelaneo",address:"Paysandu 564 , CABA, CABA",name:"TRA.SO. propiedad de Fundacion por la igualdad de ",position:[-34.616654, -58.4498737]},
  {category:"salud",type:"miscelaneo",address:"Roseti 297 PB , CABA, CABA",name:"ANDALICAN ",position:[-34.5852423, -58.44827360000001]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Delgado 1553 1569 PB Dto.4 Unidad Func. , CABA, CABA",name:"INTEGRACION ZENIT de LIPARINI, Ana Maria ",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"fundaci\u00f3n",address:"Arribe\u00f1os 1276, CABA, CABA",name:"FUNDACION JUDAICA",position:[-34.5659217, -58.44393609999999]},
  {category:"salud",type:"instituto",address:"Gorriti 4079, CABA, CABA",name:"INSTITUTO FEDERICO DOMINICK",position:[-34.5950855, -58.42246309999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Cosquin 4660, CABA, CABA",name:"CUMEDI",position:[-34.684627, -58.4772846]},
  {category:"salud",type:"miscelaneo",address:"General Juan Gregorio Lemos, CABA, CABA",name:"COMPRENDER",position:[-34.5888442, -58.4484545]},
  {category:"salud",type:"instituto",address:"J. F. Aranguren 4512 4518, CABA, CABA",name:"INSTITUTO RA\u00cdCES S.R.L.",position:[-34.6225704, -58.463789]},
  {category:"salud",type:"fundaci\u00f3n",address:"Av.Diaz Velez 4720, CABA, CABA",name:"FEPI FUND.PARA EL EST. DE LOS PROB.DE LA INFANCIA",position:[-34.6088936, -58.43331319999999]},
  {category:"salud",type:"miscelaneo",address:"Av. Belgrano 2738 PB, CABA, CABA",name:"NOESIS de Maria Alejandra JUNQUEIRA",position:[-34.6147219, -58.404341]},
  {category:"salud",type:"miscelaneo",address:"Avda. Congreso 2595 - 4to. piso, CABA, CABA",name:"DEVELAR S.R.L.",position:[-34.5561881, -58.464296]},
  {category:"salud",type:"instituto",address:"Anibal Pedro Arbeletche 855 57, CABA, CABA",name:"ITAQUI de Natalia FUEGO",position:[-34.6466726, -58.4248107]},
  {category:"salud",type:"centro educativo",address:"Belgrano 5156, CABA, CABA",name:"MATICES CENTRO EDUCATIVO TERAPEUTICO S.A.",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"centro de d\u00eda",address:"Larrazabal 943, CABA, CABA",name:"TALLERES ESPECIALES OCUPACIONALES",position:[-34.6483051, -58.5061291]},
  {category:"salud",type:"centro educativo",address:"Pte. Fragata Sarmiento 1857, CABA, CABA",name:"CEDTI",position:[-34.6045558, -58.4598059]},
  {category:"salud",type:"centro educativo",address:"Palpa 3771 73 75, CABA, CABA",name:"CEPPLA DE AYUDAR S.R.L.",position:[-34.5769671, -58.4515094]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Pueyrredon 1441 3 Piso 12 \"B\", CABA, CABA",name:"A.P.A.D.E.A.",position:[-34.60984699999999, -58.3820355]},
  {category:"salud",type:"escuela",address:"Guemes 4070 PB \"D\", CABA, CABA",name:"EPPLAI",position:[-34.5855978, -58.41834709999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Rivadavia 4684, CABA, CABA",name:"CERMI SALUD S.A.",position:[-34.6163263, -58.4316891]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Rivadavia 4232 40 Piso 1\u00b0 Dpto. \"A\", CABA, CABA",name:"ASOCIACION CIVIL CONSTRUYENDO POR LA INTEGRACION",position:[-34.5688589, -58.45342969999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Niceto Vega 5140, CABA, CABA",name:"EQUIPO ADIP",position:[-34.5900082, -58.4348113]},
  {category:"salud",type:"escuela",address:"Rodo 5557 5559, CABA, CABA",name:"ES.CA.LA.",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"instituto",address:"Av. Nazca 3356, CABA, CABA",name:"INSTITUTO NUEVO HORIZONTE S.A.",position:[-34.5979843, -58.49264189999999]},
  {category:"salud",type:"miscelaneo",address:"Gascon 1269, CABA, CABA",name:"GRUPO CAMINANTES de PAZOOS S.R.L.",position:[-34.5950089, -58.42212869999999]},
  {category:"salud",type:"instituto",address:"Avda. Avellaneda 2279 2281, CABA, CABA",name:"INSTITUTO LUZ DE LUNA S.R.L.",position:[-34.6226455, -58.4601887]},
  {category:"salud",type:"hogar",address:"Juan F. Aranguren 167 69, CABA, CABA",name:"HOGAR LAZOS de AFAM S.A.",position:[-34.6225704, -58.463789]},
  {category:"salud",type:"miscelaneo",address:"Cuenca 407 5\u00b0 \"C\", CABA, CABA",name:"UN SOLO CORAZON de HYUN - CHAEK LEE",position:[-2.9001285, -79.00589649999999]},
  {category:"salud",type:"miscelaneo",address:"MARIO BRAVO 971 3, CABA, CABA",name:"PROYECTO PUENTE - Symbolon S.R.L. ",position:[-34.6267677, -58.4039441]},
  {category:"salud",type:"miscelaneo",address:"GUEVARA 447, CABA, CABA",name:"LEDOR VADOR",position:[-34.5863248, -58.4509576]},
  {category:"salud",type:"asociaci\u00f3n",address:"TRES ARROYOS 1548, CABA, CABA",name:"ADID",position:[-34.6090428, -58.46030579999999]},
  {category:"salud",type:"hogar",address:"VARELA 648 654, CABA, CABA",name:"EL HOGAR DE LOS JAZMINES",position:[-34.6477581, -58.4485805]},
  {category:"salud",type:"asociaci\u00f3n",address:"COCHABAMBA 3243, CABA, CABA",name:"ASOCIACION AMAR",position:[-34.6263078, -58.41112599999999]},
  {category:"salud",type:"centro de d\u00eda",address:"VENEZUELA 3981, CABA, CABA",name:"EQUIPO INTERDISCIPLINARIO ESPACIO",position:[-34.6173874, -58.42121470000001]},
  {category:"salud",type:"centro de d\u00eda",address:"MORETO 732, CABA, CABA",name:"CENTRO DE DIA LA CASABELLA SRL",position:[-34.6443067, -58.4865746]},
  {category:"salud",type:"hogar",address:"TERRADA 3845 7, CABA, CABA",name:"RESIDENCIA MEDITERRANEO",position:[-34.6018098, -58.4878777]},
  {category:"salud",type:"miscelaneo",address:"PLAZA 960 38 54 56, CABA, CABA",name:"CONGREGACION SIERVAS DE LA DIVINA PROVIDENCIA",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"miscelaneo",address:"JOS\u00c9 ENRIQUE ROD\u00d3 5661, CABA, CABA",name:"PRESTACIONES SANTA MARTA S.A.",position:[-34.6534984, -58.49826340000001]},
  {category:"salud",type:"miscelaneo",address:"Correa 2250, CABA, CABA",name:"PROGRAMA DE RENSERCION SOCIAL",position:[-34.5430942, -58.4704102]},
  {category:"salud",type:" cooperativa",address:"Avda. Luis. M. Campos 1582, CABA, CABA",name:"COOP. DE PADRES PARA LA EDUCACI\u00d3N DE NI\u00d1OS SORDOS (COESO) LTDA.",position:[-34.562442, -58.44465699999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Hip\u00f3lito irigoyen 4081, CABA, CABA",name:"CENTRO C.A.R.I. S.R.L",position:[-34.5362543, -58.5170085]},
  {category:"salud",type:"centro de d\u00eda",address:"Yerbal 1330 -PB - U.F N\u00b0 1, CABA, CABA",name:"\"CIMA\" de Agustina Beatriz BOGGIO, Stella Maris GARC\u00cdA y mar\u00eda de los Angeles SA",position:[-34.62252460000001, -58.44906220000001]},
  {category:"salud",type:"miscelaneo",address:"Franklin D. Roosevelt 5742, CABA, CABA",name:"ESPACIO FEL\u00cdZ S.A.",position:[-34.5778409, -58.4942824]},
  {category:"salud",type:"fundaci\u00f3n",address:"Teodoro Garc\u00eda 3545, CABA, CABA",name:"FUNDACI\u00d3N CAMINOS ",position:[-34.5806172, -58.4527193]},
  {category:"salud",type:"miscelaneo",address:"Yapeyu 473, CABA, CABA",name:"TCC.TEA de LAURA MARIELA GARC\u00cdA",position:[-34.6174267, -58.421672]},
  {category:"salud",type:"miscelaneo",address:"Arribe\u00f1os 3728 - 2do. B, CABA, CABA",name:"SANT\u00c9 de CECILIA BIBIANA D'ABBRACCIO",position:[-34.5439763, -58.4614169]},
  {category:"salud",type:"fundaci\u00f3n",address:"Gurruchaga 1178, CABA, CABA",name:"FUNDACI\u00d3N JUANCHO REALE",position:[-34.5922791, -58.4340229]},
  {category:"salud",type:"instituto",address:"Republica Bolivariana de Venezuela 3451 PB, CABA, CABA",name:"INSTITUTO SANTA BARBARA de SIGUARAYA S.R.L.",position:[-34.617138, -58.41486140000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Pringles 722 PB - unidad funcional 1, CABA, CABA",name:"REDAT S.R.L.",position:[-34.6038258, -58.42737379999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Adolfo Alsina 1662 1666 1668, CABA, CABA",name:"CL\u00cdNICA DE REHABILITACI\u00d3N INTEGRAL ALSINA de GRUPO SALUD S.A.",position:[-34.6114752, -58.3917815]},
  {category:"salud",type:"miscelaneo",address:"Tejedor 470, CABA, CABA",name:"LUMOS S.R.L.",position:[-34.6304823, -58.43269879999999]},
  {category:"salud",type:"miscelaneo",address:"Sarandi 1365, CABA, CABA",name:"CONFLUIR S.A.",position:[-34.6248794, -58.3937306]},
  {category:"salud",type:"miscelaneo",address:"Lavalle 3119 21 23, CABA, CABA",name:"CLUB DE LOS SUE\u00d1OS de GAMBARU S.A.",position:[-34.6032527, -58.3954209]},
  {category:"salud",type:"centro educativo",address:"Curapaligue 726, CABA, CABA",name:"CET CURAPALIGUE S.A.",position:[-34.6327516, -58.4497881]},
  {category:"salud",type:"centro de d\u00eda",address:"Pacheco de Melo 2734 Piso 3 Depto 303, CABA, CABA",name:"CENTRO PELDA\u00d1OS de Belen MEJIA",position:[-34.5863095, -58.4023279]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. D\u00edaz V\u00e9lez 3873 - 9\u00b0 piso, CABA, CABA",name:"CENTRO LAZOS S.R.L.",position:[-34.6081475, -58.42076789999999]},
  {category:"salud",type:"miscelaneo",address:"Bolivia 4746 , CABA, CABA",name:"Grupo Vagues SRL",position:[-34.5838746, -58.49942319999999]},
  {category:"salud",type:"instituto",address:"Balbastro 2253 55, CABA, CABA",name:"ITEDIS INSTITUTO TERAP\u00c9UTICO EDUCATIVO DE INTEGRACION SOCIAL .S.R.L.",position:[-34.6709301, -58.4816421]},
  {category:"salud",type:"centro educativo",address:"Caracas 852, CABA, CABA",name:"C.E.T.Re. S.R.L.",position:[-34.6200212, -58.46513359999999]},
  {category:"salud",type:"centro educativo",address:"Riglos 119, CABA, CABA",name:"C.E.T.Re. S.R.L.",position:[-34.6200907, -58.4374676]},
  {category:"salud",type:"centro de d\u00eda",address:"Moreno 3430, CABA, CABA",name:"CENTRO SHORN S.R.L.",position:[-34.6144096, -58.4149855]},
  {category:"salud",type:"centro de d\u00eda",address:"Gregoria Perez 3559, CABA, CABA",name:"YO QUIERO S.R.L.",position:[-34.5795038, -58.4564767]},
  {category:"salud",type:"instituto",address:"Valdenegro 3470, CABA, CABA",name:"INSTITUTO MI MA\u00d1ANA S.R.L.",position:[-34.5626421, -58.4899874]},
  {category:"salud",type:"miscelaneo",address:"Biarritz 1926, CABA, CABA",name:"UNIRSALUD S.R.L.",position:[-34.6012805, -58.4740044]},
  {category:"salud",type:"miscelaneo",address:"Gregorio9 de Laferrere 3050, CABA, CABA",name:"RENACER DEL \u00c1RBOL DEL A VIDA",position:[-34.6397801, -58.4636018]},
  {category:"salud",type:"escuela",address:"Nogoy\u00e1 N\u00ba 5.448 5.450 y Pasaje Leonardo Da Vinci N\u00ba 2.091, CABA, CABA",name:"\u201cETI SALUD\u201d de Andrea Fabiana DOYHENART, Sandra Beatriz RENDO, Juan Jos\u00e9 CHIEFFO",position:[-32.3901919, -59.7931152]},
  {category:"salud",type:"centro terapeutico",address:"Avda. Corrientes 3547 - 5to. A, CABA, CABA",name:"CENTRO TERAP\u00c9UTICO IVAR S.R.L.",position:[-34.6037974, -58.41501539999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Jos\u00e9 Marmol 1372, CABA, CABA",name:"FUNDACI\u00d3N CENTRO DE APOYO AL DISCAPACITADO (C.A.D.) CON AMOR Y POR AMOR",position:[-34.6305384, -58.42308719999999]},
  {category:"salud",type:"escuela",address:"Campana 2819 - 6to. piso - Dptos. B y C, CABA, CABA",name:"ETEI de PANIZZI M. Alejandra y MATHIEU, M\u00f3nica E. S.H.",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"asociaci\u00f3n",address:"Manuela Pedrdaza 2618 - 2do. piso - Dpto. 2, CABA, CABA",name:"AILES de MAR\u00cdA SIMOND",position:[-34.552927, -58.46836219999999]},
  {category:"salud",type:"centro educativo",address:"Avda. Corrientes 980 - 9\u00b0 A, CABA, CABA",name:"CIE -  centro de integraci\u00f3n ESCOLAR S.R.L.",position:[-34.6037436, -58.3803491]},
  {category:"salud",type:"miscelaneo",address:"C\u00f3rdoba 1324 - 10\u00b0 piso - Dpto. A, CABA, CABA",name:"EN-CAUSAR PSI S.R.L.",position:[-34.5993767, -58.3857865]},
  {category:"salud",type:"fundaci\u00f3n",address:"Beauchef 477 479 PB - UF 1, CABA, CABA",name:"FUNDACION CALEIDOSCOPIO",position:[-34.6296289, -58.4319132]},
  {category:"salud",type:"escuela",address:"Mansilla 3074, CABA, CABA",name:"EIPAN S.R.L.",position:[-34.5941542, -58.4100505]},
  {category:"salud",type:"centro educativo",address:"Rawson 88, CABA, CABA",name:"CETEI S.A.",position:[-34.6119192, -58.4259633]},
  {category:"salud",type:"centro de d\u00eda",address:"Galicia 701, CABA, CABA",name:"CENTRO ALKI de ENRIQUE LOCOCO",position:[-34.6052153, -58.4507904]},
  {category:"salud",type:"centro de d\u00eda",address:"Tte. Gral. Juan Domingo per\u00f3n 1615 - 4\u00b0 41. , CABA, CABA",name:"CENTRO REGARDS de CENTRO REGARDS S.A.S.",position:[-34.6065395, -58.3895474]},
  {category:"salud",type:"miscelaneo",address:"Dr. Florentino Ameghino 1681 83 85, CABA, CABA",name:"NOHI de ROMINA VANINA PASQUERO",position:[-34.6496375, -58.4790521]},
  {category:"salud",type:"miscelaneo",address:"Directorio 1846 - 8\u00b0 piso- Dpto. C, CABA, CABA",name:"CRIANZA POSITIVA S.A.S.",position:[-34.6315394, -58.4536781]},
  {category:"salud",type:"fundaci\u00f3n",address:"Homero 147, CABA, CABA",name:"FUNDACION SOLAR DE INTEGRACION POR LA CALIDAD DE VIDA DE LAS PERSONAS CON DISCAN",position:[-34.6386273, -58.4948176]},
  {category:"salud",type:"miscelaneo",address:"CONDOR 2346 PB 2, CABA, CABA",name:"GSA INTERVENCI\u00d3N PSICOEDUCATIVA S.A.",position:[0.0, 0.0]},
  {category:"salud",type:"miscelaneo",address:"MELINCUE 2756, CABA, CABA",name:"ALBANTA S.R.L.",position:[-34.5998895, -58.4887416]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Can\u00f3nigo Miguel del Corro 500, CABA, CABA",name:"CENTRO INTEGRAL CADAQUES S.A.S.",position:[-34.6332877, -58.4983973]},
  {category:"salud",type:"instituto",address:"Avda. Juan B. Alberdi 1679, CABA, CABA",name:"INSTITUTO ANTONIO PROVOLO",position:[-34.6274688, -58.4525186]},
  {category:"salud",type:"centro de d\u00eda",address:"Jos\u00e9 Pablo Torcuato Batlle y ordo\u00f1ez 6112, CABA, CABA",name:"CENTRO INTEGRAL DE ASISTENCIA PSICOEDUCATIVA Y SOCIAL - CIAPS",position:[-34.6814858, -58.4770222]},
  {category:"salud",type:"centro de d\u00eda",address:"Ram\u00f3n Falc\u00f3n 2387 - 1er. piso - UF 21, CABA, CABA",name:"CIPA de Cynthia CONVERSO LLAMAL",position:[-34.6298712, -58.4619738]},
  {category:"salud",type:"miscelaneo",address:"Gallo 367 - PB - Dpto. A, CABA, CABA",name:"MIRADA INCLUSIVA de Flor Estrella ULLOA OJEDA",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Olazabal 5124 - 1er. piso - Dpto. 6 , CABA, CABA",name:"EQUIPO TERAP\u00c9UTICO VILLA URQUIZA de M\u00d3NICA BEATRIZ MARZETTI",position:[-34.5767874, -58.486074]},
  {category:"salud",type:"miscelaneo",address:"Asunci\u00f3n 3245, CABA, CABA",name:"L'ETOURDIT SALUD MENTAL",position:[-34.59472179999999, -58.50165429999999]},
  {category:"salud",type:"miscelaneo",address:"Biarritz 1962, CABA, CABA",name:"EN-TRAMA de EN-TRAMA S.A.S.",position:[-34.6015813, -58.4740954]},
  {category:"salud",type:"asociaci\u00f3n",address:"Avda. de los Constituyentes 5387, CABA, CABA",name:"ASOCIACI\u00d3N CIVIL CRECIENDO JUNTOS PARA LA ATENCI\u00d3N DE LA PERSONA CON DISCAPACIDA",position:[-34.5761856, -58.49924499999999]},
  {category:"salud",type:"centro educativo",address:"Juan Bautista Alberdi 2228 - 11\u00b0 A, CABA, CABA",name:"CETEIN de ASOCIACI\u00d3N CIVIL CETEIN",position:[-34.6305405, -58.45907279999999]},
  {category:"salud",type:"miscelaneo",address:"G\u00fcemes 4573 y 4575 - PB of. B, CABA, CABA",name:"FELUMA SALUD S.R.L.",position:[-34.5815687, -58.4233326]},
  {category:"salud",type:"centro de d\u00eda",address:"Alvarez Jonte 1762, CABA, CABA",name:"CAEDIS S.R.L.",position:[-34.6013791, -58.4686639]},
  {category:"salud",type:"asociaci\u00f3n",address:"Avda. Belgrano 1876 - 2do. piso - oficina A, CABA, CABA",name:"DESIR SALUD ASOCIACI\u00d3N CIVIL",position:[-34.6141139, -58.3928391]},
  {category:"salud",type:"centro de rehabilitacion",address:"Mendez de Andes 725, CABA, CABA",name:"CENTRO INTEGRAL DE ABORDAJE NEUMOTOR DE BUENOS AIRES S.R.L.",position:[-34.6139363, -58.444781]},
  {category:"salud",type:"miscelaneo",address:"Av. Rivadavia 4240, CABA, CABA",name:"EQUIPO CONSTRUYENDO S.R.L.",position:[-34.6136606, -58.4253838]},
  {category:"salud",type:"centro educativo",address:"Virrey del Pino 2328 30 32 - 2do. piso, CABA, CABA",name:"LUDERE CENTRO DE INTEGRACI\u00d3N ESCOLAR Y APRENDIZAJE S.R.L.",position:[-34.5691102, -58.4574832]},
  {category:"salud",type:"centro de rehabilitacion",address:"Rep\u00fablica Bolivariana de Venezuela 2961, CABA, CABA",name:"CENTRO INTERDISCIPLINARIO DANDO PASITOS de SANDRA MARISA BOERR",position:[-34.6163659, -58.4076454]},
  {category:"salud",type:"miscelaneo",address:"Juan Bautista Alberdi 1593 - 6to. piso - Oficina A, CABA, CABA",name:"CONSULTORA ENCLAVE S.R.L.",position:[-34.6266953, -58.4509337]},
  {category:"salud",type:"instituto",address:"Carlos Calvo 1186, CABA, CABA",name:"INSTITUTO PARROQUIAL INMACULADA CONCEPCION del ARZOBISPADO DE BUENOS AIRES",position:[-34.6198276, -58.3827368]},
  {category:"salud",type:"miscelaneo",address:"Juan B. Justo 2469 - 7mo. piso - Dpto. A, CABA, CABA",name:"CREER EQUIPO INTERDISCIPLINARIO S.A.S.",position:[-34.59386, -58.44342269999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Andalgal\u00e1 2269, CABA, CABA",name:"ASOCIACI\u00d3N CIVIL FRANCO UZ",position:[-34.6624165, -58.5069639]},
  {category:"salud",type:"centro de d\u00eda",address:"Gervasio Espinosa 2536, CABA, CABA",name:"CENTRO DE D\u00cdA MANO CON MANO de DIEGO MART\u00cdN GLASBAUER",position:[-34.6010474, -58.46769]},
  {category:"salud",type:"miscelaneo",address:"Avda. Larraz\u00e1bal 503, CABA, CABA",name:"ESPACIO CRESERES S.A.S.",position:[-34.6443823, -58.51115830000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Coronel Manuel Roseti 300 - PB - UF 3, CABA, CABA",name:"ANDALICAN S.R.L.",position:[-34.5433871, -58.49455169999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Avda. Sn Mart\u00edn 3175, CABA, CABA",name:"APREDIS S.R.L.",position:[-34.6018093, -58.4685137]},
  {category:"salud",type:"centro de d\u00eda",address:"Blanco Escalada 1640, CABA, CABA",name:"CENTRO INTEGRAL DE MOTRICIDAD, AUTOVALIMIENTO Y REHABILITACION S.R.L.",position:[-34.6521342, -58.4858699]},
  {category:"salud",type:"asociaci\u00f3n",address:"Pinto 4120 PB - Dpto. 1 - UF 1, CABA, CABA",name:"ASOCIACION CIVIL GRUPO CRE ",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"centro de d\u00eda",address:"CALDAS 1355 1357, CABA, CABA",name:"Centro de Dia \"MAMUSHKA\" de Palanoheri S.A. ",position:[-34.587767, -58.4640281]},
  {category:"salud",type:"centro educativo",address:"Avda. Avellaneda 1235, CABA, CABA",name:"CETREBA de CETRED S.A.",position:[-34.6179835, -58.4488332]},
  {category:"salud",type:"asociaci\u00f3n",address:"MEDINA 1749 1751, CABA, CABA",name:"KARITES - PROFESIONALES DE LA SALUD - ATENCION CON FORMACION - ASOCIACION CIVIL",position:[-34.6036844, -58.3815591]},
  {category:"salud",type:"miscelaneo",address:"Riobamba 358 4A, Balvanera, CABA, CABA",name:"PROGRAMAS RECOBA SOCIEDAD ANONIMA",position:[-34.6050938, -58.3938851]},
  {category:"salud",type:"miscelaneo",address:"AV. REGIMIENTO DE PATRICIOS 1052, CABA, CABA",name:"DIVERPSI de ANA JULIA PAREDES",position:[-34.6397213, -58.36803569999999]},
  {category:"salud",type:"centro de d\u00eda",address:"AV. Cordoba 645, CABA, CABA",name:"NEBI NUEVO ENFOQUE DE BIENESAR INCLUSIVO - CENTRO INTERDISCIPLINARIO",position:[-34.598433, -58.3760335]},
  {category:"salud",type:"miscelaneo",address:"JURAMENTO 1445 - 3RO. D, CABA, CABA",name:"PONTIS INCLUSION S.R.L.",position:[-34.5561282, -58.44714399999999]},
  {category:"salud",type:"centro de d\u00eda",address:"HUMBOLDT 337 - PB A, CABA, CABA",name:"CENTRO MEDRAR S.A.S.",position:[0.0, 0.0]},
  {category:"salud",type:"centro de d\u00eda",address:"NU\u00d1EZ 2751, CABA, CABA",name:"CIREM S.R.L.",position:[-34.5515867, -58.47078310000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"BONIFACINI 3837 , CABA, CABA",name:"ASOCIACION CIVIL PUENTES",position:[-34.59919, -58.5616312]},
  {category:"salud",type:"centro de d\u00eda",address:"MARCELO T. DE ALVEAR 1632, CABA, CABA",name:"CENTRO CITES INECO DE NEUROCIENCIAS MARCELO T DE ALVEAR S.A",position:[-34.5973686, -58.39025099999999]},
  {category:"salud",type:"miscelaneo",address:"AVDA. NAZCA 3177 - 3\u00b0 41, CABA, CABA",name:"DEDICARNOS A INTEGRAR S.R.L.",position:[-34.5993411, -58.4909924]},
  {category:"salud",type:"centro educativo",address:"TERRERO 1350 , CABA, CABA",name:"CETET S.R.L. ",position:[-34.612778, -58.4636]},
  {category:"salud",type:"instituto",address:"GUEVARA 1594 98 ESQUINA TRONADOR S N ESQUINA TRIUN, CABA, CABA",name:"INSTITUCION ARABELA S.R.L",position:[-34.5772485, -58.4816785]},
  {category:"salud",type:"centro de d\u00eda",address:"NICETO VEGA 4965 4967, CABA, CABA",name:"ALEGRA CENTRO DE D\u00cdA S.A.S",position:[-34.5883109, -58.4370185]},
  {category:"salud",type:"instituto",address:"Jun\u00edn 346 , San Fernando Del Valle De Catamarca, Catamarca",name:"Instituto de Rehabilitaci\u00f3n Integral Catamarca SRL",position:[-28.4652536, -65.7826884]},
  {category:"salud",type:"miscelaneo",address:"JUNIN 1152, Catamarca , Catamarca",name:"EDUCARTE S.R.L.",position:[-28.4757387, -65.78235339999999]},
  {category:"salud",type:"miscelaneo",address:"VISITACION VEGA 465, Fray Mamerto Esqui\u00fa , Catamarca",name:"ADAPTA SIN BARRERAS S.R.L",position:[-28.3288956, -65.7371749]},
  {category:"salud",type:"fundaci\u00f3n",address:"JUNIN 346, Catamarca , Catamarca",name:"FUNDACION HUAINO",position:[-28.4652536, -65.7826884]},
  {category:"salud",type:"instituto",address:"Camino de Acceso a Puerto Tirol , Puerto Tirol , chaco",name:"Instituto RAICES - EL CASTILLO ",position:[-27.3825467, -59.088123]},
  {category:"salud",type:"hogar",address:"Avda. Malvinas y Nicol\u00e1s Avellaneda, Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-26.7834095, -60.4412469]},
  {category:"salud",type:"centro educativo",address:"Corrientes 1655 , Resistencia , chaco",name:"Centro Educativo Terapeutico con Integracion Depen",position:[-27.4355438, -58.9987724]},
  {category:"salud",type:"centro de rehabilitacion",address:"Saavedra N\u00ba 167 , Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"CENTRO DE ESTIMULACION TERAPEUTICA TEMPRANA ",position:[-26.793533, -60.438585]},
  {category:"salud",type:"miscelaneo",address:"A.Frondizi n\u00ba 971 , Resistencia , chaco",name:"CRECER CON TODOS ",position:[-27.4597109, -58.99403029999999]},
  {category:"salud",type:"miscelaneo",address:"Molina N\u00ba 142-44 , Resistencia , chaco",name:"CRECER CON TODOS ",position:[-27.4518622, -58.98555469999999]},
  {category:"salud",type:"miscelaneo",address:"Entre Rios N\u00ba 142 , Resistencia , chaco",name:"CRECER CON TODOS ",position:[-27.4467525, -58.98419920000001]},
  {category:"salud",type:"miscelaneo",address:"Coronel Falcon N\u00ba 67 , Resistencia , chaco",name:"CRECER CON TODOS ",position:[-27.4377137, -59.0004353]},
  {category:"salud",type:"miscelaneo",address:"Brown N\u00ba 742 , Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"CRECER CON TODOS ",position:[-26.7813424, -60.44110229999999]},
  {category:"salud",type:"instituto",address:"Montevideo 2314 , Resistencia , chaco",name:"INSTITUTO BARRILETE de A.PA.DE.A.",position:[-27.4565585, -59.02894509999999]},
  {category:"salud",type:"instituto",address:"Av. San Mart\u00edn N\u00ba 423 , Resistencia , chaco",name:"INSTITUTO DE REHABILITACION \"PLAZA ESPA\u00d1A\" ",position:[-27.4584964, -58.9856522]},
  {category:"salud",type:"clinica",address:"Sarmiento N\u00ba 160 , General San Mart\u00edn , chaco",name:"Cl\u00ednica \"Central S.R.L.\" ",position:[-26.5379812, -59.3420281]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Wilde 646, Resistencia , chaco",name:"ADEEI - ASOCIACI\u00d3N CIVIL PARA EL DESARROLLO DE LA EDUCACI\u00d3NM ESPECIAL Y LA INTEG",position:[-27.4424107, -58.9850548]},
  {category:"salud",type:"centro educativo",address:"Nicol\u00e1s Matienzo N\u00ba 272 , Villa Angela , chaco",name:"CENTRO DE EDUCACI\u00d3N TERAP\u00c9UTICA VILLA ANGELA (LA ROSA AZUL) ",position:[-27.5764706, -60.71924620000001]},
  {category:"salud",type:"centro educativo",address:"RIVADAVIA 1700, Villa Angela , chaco",name:"CENTRO DE EDUCACI\u00d3N TERAP\u00c9UTICA VILLA ANGELA (LA ROSA AZUL) ",position:[-27.5633028, -60.7139371]},
  {category:"salud",type:"centro educativo",address:"25 de Mayo 278, Charata , chaco",name:"CENTRO DE EDUCACI\u00d3N TERAP\u00c9UTICA VILLA ANGELA (LA ROSA AZUL) ",position:[-27.2184279, -61.19197919999999]},
  {category:"salud",type:"instituto",address:"Av. 9 de Julio N\u00ba 5180, Barranqueras , chaco",name:"INSTITUTO INTEGRAL BARRANQUERAS S.R.L.",position:[-27.4919597, -58.9401651]},
  {category:"salud",type:"escuela",address:"Av.Malvinas Arg. y Nicolas Avellaneda, Roque Saenz Pe\u00f1a , chaco",name:"PEQUE\u00d1O COTTOLENGO DON ORIONE",position:[-31.3830115, -64.1086373]},
  {category:"salud",type:"centro educativo",address:"Av. Wilde 798, Resistencia , chaco",name:"CETAI CHACO",position:[-27.441251, -58.98388370000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Juan Jose Paso 450, Juan Jos\u00e9 Castelli , chaco",name:"CAIDIN - ASOCIACI\u00d3N CIVIL CENRO DE AYUDA INTEGRAL AL DISCAPACITADO DEL NORTE",position:[-25.943923, -60.61124100000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Mariano Moreno 551 557, Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"A.I.L.A.A.C.C. Abordaje Interdisciplinario: Lenguaje, Audici\u00f3n, Aprendizaje, Con",position:[-26.780781, -60.4387965]},
  {category:"salud",type:"miscelaneo",address:"AVENIDA RISSIONE N\u00b0 155, Resistencia , chaco",name:"CASAGRANDE FONTANA",position:[-27.4393811, -58.97077909999999]},
  {category:"salud",type:"miscelaneo",address:"Sarmiento esq. Dig. Chubut, Fontana , chaco",name:"CASAGRANDE FONTANA",position:[-27.4266007, -59.0201642]},
  {category:"salud",type:"centro de d\u00eda",address:"Rivadavia 56, Corzuela , chaco",name:"CENTRO SEBASTIAN S.R.L.",position:[-26.9593825, -60.9749935]},
  {category:"salud",type:"centro de d\u00eda",address:"Jos\u00e9 Hernandez 343, Resistencia , chaco",name:"CENTRO INTEGRAL DE REHABILITACION CHACO C.I.RE.Ch.",position:[-27.453789, -58.97751510000001]},
  {category:"salud",type:"miscelaneo",address:"Los alisos 4720, Resistencia , chaco",name:"LOS GIRASOLES S.C.",position:[-27.4466937, -59.00543349999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"MARIANO MORENO N\u00b0 1796, Roque Saenz Pe\u00f1a , chaco",name:"DESPERTARES HIJOS DEL ALMA DE FUNDACION VISIONARIOS",position:[-26.7745528, -60.4360807]},
  {category:"salud",type:"centro de rehabilitacion",address:"Calle 9 de Julio 562, Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"CENTRO DE REHABILITACION INTEGRAL \"LOS NOGALES\"",position:[-26.7891556, -60.44194890000001]},
  {category:"salud",type:"miscelaneo",address:"OBLIGADO 4765, Resistencia , chaco",name:"HACIENDO CAMINO ",position:[-27.4914941, -58.94644639999999]},
  {category:"salud",type:"miscelaneo",address:"Belgrano 775, Barranqueras , chaco",name:"HACIENDO CAMINO ",position:[-27.4885598, -58.9301864]},
  {category:"salud",type:"instituto",address:"Concepci\u00f3n del Bermejo 4845, Resistencia , chaco",name:"INSTITUTO SOL de SOL DEL NORTE SRL ",position:[-27.4369126, -58.9882842]},
  {category:"salud",type:"miscelaneo",address:"Chile 631, General San Mart\u00edn , chaco",name:"DESPERTARES S.R.L.",position:[-26.5379812, -59.3420281]},
  {category:"salud",type:"miscelaneo",address:"Uruguay 1127, General San Mart\u00edn , chaco",name:"DESPERTARES S.R.L.",position:[-32.522779, -55.765835]},
  {category:"salud",type:"miscelaneo",address:"CARLOS VECIETTI Y URUGUAY, Machagai , chaco",name:"DESPERTARES S.R.L.",position:[-26.9337813, -60.0504515]},
  {category:"salud",type:"centro de d\u00eda",address:"Santiago del Estero 338, Resistencia , chaco",name:"CENTRO DE DIA PARA ADULTOS MAYORES \"EL PUENTE\"",position:[-27.4515498, -58.99288070000001]},
  {category:"salud",type:"instituto",address:"9 de Julio 263, General San Mart\u00edn , chaco",name:"INSTITUTO DE APOYO INTEGRAL A LA INCLUSI\u00d3N ESCOLAR \"ENSE\u00d1ARTE\"",position:[-26.5379812, -59.3420281]},
  {category:"salud",type:"asociaci\u00f3n",address:"Dorrego 490, Presidencia Roque S\u00e1enz Pe\u00f1a , chaco",name:"ASOCIACI\u00d3N CIVIL \"JUNTOS EN ACCI\u00d3N\"",position:[-26.7862876, -60.4466235]},
  {category:"salud",type:"miscelaneo",address:"Mariano Moreno e  Oscar R. Sein y Martin Farias, Presidencia de la Plaza , chaco",name:"LUZ DE \u00c1NGELES S.R.L.",position:[-26.9970888, -59.8491096]},
  {category:"salud",type:"escuela",address:"Juan Domingo Per\u00f3n 754, Villa Angela , chaco",name:"EDUCACI\u00d3N PARA TODOS",position:[-27.5705766, -60.7207816]},
  {category:"salud",type:"centro de d\u00eda",address:"FRAY GROTTI 141, Resistencia , chaco",name:"CENTRO DE NEUROREHABILITACION \"!MOVEMENT\"",position:[-27.4617861, -58.97197079999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Jos\u00e9 Hernandez 547, Resistencia , chaco",name:"CENTRO DE NEUROREHABILITACION \"!MOVEMENT\"",position:[-27.4521268, -58.9757403]},
  {category:"salud",type:"instituto",address:"Avda. SABIN N\u00b0 3050, Resistencia , chaco",name:"INSTITUTO DE ATENCI\u00d3N A LA DIVERSIDAD - IAD S.R.L.",position:[-27.4175585, -58.97083419999999]},
  {category:"salud",type:"miscelaneo",address:"CERVANTES 330, Resistencia , chaco",name:"INCLUSI\u00d3N DEL NORTE S.R.L.",position:[-27.4593652, -58.98989719999999]},
  {category:"salud",type:"miscelaneo",address:"MAIPU 439, San Bernardo , chaco",name:"INCLUSI\u00d3N DEL NORTE S.R.L.",position:[-27.2893308, -60.71763780000001]},
  {category:"salud",type:"miscelaneo",address:"Pedro A. Padrier 658, Tres Isletas , chaco",name:"INCLUSI\u00d3N DEL NORTE S.R.L.",position:[-26.3447177, -60.431289]},
  {category:"salud",type:"miscelaneo",address:"AV. SAN MARTIN 740, Las Bre\u00f1as , chaco",name:"CREDES LAS BRE\u00d1AS S.R.L.",position:[-27.0886153, -61.0839098]},
  {category:"salud",type:"miscelaneo",address:"AV. San Mart\u00edn 1360, Resistencia , chaco",name:"CREDES LAS BRE\u00d1AS S.R.L.",position:[-27.4658245, -58.994512]},
  {category:"salud",type:"centro educativo",address:"URUNDAY Y ZORZAL, Colonia Ben\u00edtez , chaco",name:"CET COLONIA BENITEZ",position:[-27.3316059, -58.955724]},
  {category:"salud",type:"miscelaneo",address:"Echevarria 76, Resistencia , chaco",name:"EL PUENTE de LETAPEL S.A.S.",position:[-27.4469141, -58.99257410000001]},
  {category:"salud",type:"miscelaneo",address:"Avda. San Mart\u00edn 423, Resistencia , chaco",name:"PLAZA ESPA\u00d1A de GOMEZ CARLOS RUBEN",position:[-27.4584964, -58.9856522]},
  {category:"salud",type:"miscelaneo",address:"CALLE MAIP\u00da N\u00b0 348, San Bernardo , chaco",name:"INCLUSI\u00d3N DEL NORTE",position:[-27.2872872, -60.7110753]},
  {category:"salud",type:"miscelaneo",address:" , Resistencia , chaco",name:"COMUNIDAD DE APRENDIZAJE",position:[-27.4518622, -58.98555469999999]},
  {category:"salud",type:"miscelaneo",address:"PADRE SENA N\u00b0 124 PLANTA BAJA, Resistencia , chaco",name:"CASA FRANCISCA",position:[-27.4424867, -58.99860039999999]},
  {category:"salud",type:"miscelaneo",address:"donovan 609, Resistencia , chaco",name:"INCLUIRTE",position:[-27.4529578, -58.9952288]},
  {category:"salud",type:"centro educativo",address:"ALGARROBAN Y YAPEYU MZ 27 PO.20, Miraflores , chaco",name:"CENTRO EDUCATIVO MIRAFLORES",position:[-25.6493, -60.9294925]},
  {category:"salud",type:"centro educativo",address:"SARGENTO CABRAL 1251, Miraflores , chaco",name:"CENTRO EDUCATIVO MIRAFLORES",position:[-26.8120441, -59.5181554]},
  {category:"salud",type:"miscelaneo",address:"JOSE HERNANDEZ 343, Resistencia , chaco",name:"CIRECH",position:[-27.453789, -58.97751510000001]},
  {category:"salud",type:"centro de d\u00eda",address:"SAN FERNANDO N\u00b0 113, Resistencia , chaco",name:"C.I.T.T.I.",position:[-27.4412811, -58.9953744]},
  {category:"salud",type:"centro de d\u00eda",address:"RUTA NICOLAS AVELLANADE Km12.1, Resistencia , chaco",name:"Centro de Dia y Hogar Crecer",position:[-27.4523665, -58.9933539]},
  {category:"salud",type:"miscelaneo",address:"SULIGOY N\u00b0 154 MACHAGAI, Machagai , chaco",name:"SAN ANTONIO DE PADUA",position:[-26.9265873, -60.04655229999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Arturo lllia N\u00b0 1138, Resistencia , chaco",name:"FUNDACION PERFECCIONANDO EL NORDESTE ARGENTINO",position:[-27.4618346, -58.9782938]},
  {category:"salud",type:"instituto",address:"AMEGHINO 2251, Las Bre\u00f1as , chaco",name:"IMAP",position:[-27.0900534, -61.0927855]},
  {category:"salud",type:"centro de d\u00eda",address:"9 DE JULIO 381, Fontana , chaco",name:"CENTRO INTEGRAL DE REHABILITACION - UNIPERSONAL",position:[-27.4185047, -59.0378258]},
  {category:"salud",type:"centro de d\u00eda",address:"9 DE JULIO 651, Prov. de la Plaza , chaco",name:"CENTRO DE D\u00cdA MERAKI",position:[-26.5857656, -60.9540073]},
  {category:"salud",type:"escuela",address:"MITRE 198, Resistencia , chaco",name:"UNIVERSIDAD POPULAR DE RESISTENCIA ",position:[-27.448807, -58.9854036]},
  {category:"salud",type:"fundaci\u00f3n",address:"AV. ALBERDI 571, Resistencia , chaco",name:"FUNDACION SOMOS SOCIEDAD ORGANIZADA PARA UN MUNDO MAS OPTIMISTA Y SOLIDARIO",position:[-27.4557462, -58.9912839]},
  {category:"salud",type:"instituto",address:"SAN MART\u00cdN 1643, Quitilipi , chaco",name:"INSTITUTO SAN ANTONIO DE PADUA S.A.",position:[-26.859584, -60.212073]},
  {category:"salud",type:"centro de d\u00eda",address:"AV. HERNANDARIAS 1755, Resistencia , chaco",name:"CENTRO DE D\u00cdA V\u00cdNCULOS ",position:[-27.4586132, -59.0093752]},
  {category:"salud",type:"miscelaneo",address:"LOS HACHEROS 420, Resistencia , chaco",name:"UNIPERSONAL SER ",position:[-27.4623736, -58.98126749999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"AMEGHINO 2251, Las Bre\u00f1as , chaco",name:"FUNDACION LAS MARIAS ",position:[-27.0900534, -61.0927855]},
  {category:"salud",type:"miscelaneo",address:"MANUEL ESTRADA 559, Las Bre\u00f1as , chaco",name:"AMANECER S.R.L",position:[-27.0932538, -61.08495550000001]},
  {category:"salud",type:"miscelaneo",address:"Chacra 40 B\u00ba Los Pinos , Rawson , chubut",name:"COMIENZOS S.R.L ",position:[-43.2998581, -65.09948729999999]},
  {category:"salud",type:"miscelaneo",address:"Magallanes 2090, Esquel , chubut",name:"MUNICIPALIDAD DE ESQUEL",position:[-42.9167742, -71.2948055]},
  {category:"salud",type:"miscelaneo",address:"Villegas 507, Puerto Madryn , chubut",name:"MATICES",position:[-42.7729528, -65.0439464]},
  {category:"salud",type:"miscelaneo",address:"Avenida Polonia 936, Comodoro Rivadavia , chubut",name:"PILARES PATAGONIA S.R.L.",position:[-45.8734809, -67.5272219]},
  {category:"salud",type:"centro educativo",address:"Necochea N\u00ba 1544, Comodoro Rivadavia , chubut",name:"Voces Centro Privado de Educacion Especial",position:[-45.8711481, -67.4981358]},
  {category:"salud",type:"miscelaneo",address:"Ing. Strasser - Casa 10 Comipa - Standar Sur Km. 8, Comodoro Rivadavia , chubut",name:"Sol Emprendimiento S.R.L",position:[-45.7956207, -67.4440729]},
  {category:"salud",type:"instituto",address:"VELERO MIMOSA N\u00b0286, Trelew , chubut",name:"NICADPI - NUEVO INSTITUTO DE CUSTODIA Y ADAPTACI\u00d3N PARA DISMINUIDOS PSICOF\u00cdSICOS",position:[-43.2623973, -65.28682239999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Hipolito Yrigoyen N\u00b0846, Comodoro Rivadavia , chubut",name:"Fundacion Crecer",position:[-45.8671056, -67.486329]},
  {category:"salud",type:"centro de d\u00eda",address:"Pasaje Guatemala 690, Trelew , chubut",name:"CENTRO DE D\u00cdA LAZOS ADULTOS MAYORES de MAR\u00cdA LUC\u00cdA PES",position:[-43.2563202, -65.3027614]},
  {category:"salud",type:"centro educativo",address:"Francisco de Viedma 274, Comodoro Rivadavia , chubut",name:"CENTRO TERAP\u00c9UTICO NANDHY de CENTRO EDUCATIVO TERAP\u00c9UTICO PRADHANA S.R.L.",position:[-45.8331609, -67.47883209999999]},
  {category:"salud",type:"miscelaneo",address:"Fragueiro 1761 63 Barrio Alta Cordoba , C\u00f3rdoba , Cordoba",name:"ALEGRIA ",position:[-31.3913328, -64.1839953]},
  {category:"salud",type:"asociaci\u00f3n",address:"Velez Sarfield 5000 , C\u00f3rdoba , Cordoba",name:"ASOCIACI\u00d2N DE PADRES Y AMIGOS DE DISCAPACITADO MENTAL APADIM CORDOBA",position:[-31.4196066, -64.1884721]},
  {category:"salud",type:"centro de rehabilitacion",address:"Alte. Brown s n , S. M. de Punilla , Cordoba",name:"Estab.Privado de Asist. y Rehabilitaci\u00f3n VILLA BUS",position:[-31.2640514, -64.4632691]},
  {category:"salud",type:"instituto",address:"Bajada de Pucara 1000 , C\u00f3rdoba , Cordoba",name:"INST. DE REHAB, EDUC. Y FORMACI\u00d3N PARA EL DISCAPACITADO MENTAL (EX-IRAM) ASOC. C",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"hogar",address:"Urquiza 951 , San Francisco , Cordoba",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-31.4188137, -62.09537109999999]},
  {category:"salud",type:"hogar",address:"Jose Roque Funes N\u00ba1066, C\u00f3rdoba , Cordoba",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-31.3730106, -64.2384924]},
  {category:"salud",type:"hogar",address:"Camino Alta Gracia km 7.5 , Santa Isabel , Cordoba",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-31.3744493, -64.1853978]},
  {category:"salud",type:"hogar",address:"Juan de Garay N\u00b0 2900, San Francisco , Cordoba",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-31.419126, -62.09617729999999]},
  {category:"salud",type:"hogar",address:"Av. Armada Argentina 2440. B\u00ba Santa Isab, C\u00f3rdoba , Cordoba",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-31.4778151, -64.23780719999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Pan de Azucar N\u00b0 612, Cosqu\u00edn , Cordoba",name:"CENTRO PRIVADO DE DIA MARIA CAUSA DE NUESTRA ALEGR\u00cdA ",position:[-31.2370071, -64.4630021]},
  {category:"salud",type:"centro educativo",address:"Nataniel Morcillo 1907 B\u00ba Maip\u00fa segunda , C\u00f3rdoba , Cordoba",name:"EL FARO Centro Educativo Terap\u00e9utico ",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"fundaci\u00f3n",address:"COQUENA 8414 VILLA 9 DE JULIO, C\u00f3rdoba , Cordoba",name:"FUNDACI\u00d3N AVENIR ",position:[-31.328309, -64.28309399999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"R\u00edo Negro N\u00ba372 B\u00ba Alberdi , C\u00f3rdoba , Cordoba",name:"FUNDACI\u00d3N AVENIR ",position:[-31.410813, -64.2060416]},
  {category:"salud",type:"instituto",address:"Colombres 938, C\u00f3rdoba , Cordoba",name:"INSTITUTO PRIVADO DE ENSE\u00d1ANZA ESPECIAL EL SOL",position:[-31.3945994, -64.1951247]},
  {category:"salud",type:"fundaci\u00f3n",address:"GREGORIO DE LAFERRERE 2234 , C\u00f3rdoba , Cordoba",name:"FUNDACION OTIUM ",position:[-31.3699203, -64.2290028]},
  {category:"salud",type:"miscelaneo",address:"COMECHINGONES N\u00aa1359 - B\u00aa LOS PL\u00c1TANOS , C\u00f3rdoba , Cordoba",name:"S.R.L. EL RINCON DE LOS PEQUE\u00d1OS ",position:[-31.4212357, -64.2286862]},
  {category:"salud",type:"fundaci\u00f3n",address:"DEAN FUNES 2337 B\u00ba ALTO ALBERDI , C\u00f3rdoba , Cordoba",name:"FUNDACION JORGE BONINO ",position:[-31.4068979, -64.214133]},
  {category:"salud",type:"centro de d\u00eda",address:"AV. PTE. ARTUTO ILLIA 541 B\u00ba PELLEGRINI , Alta Gracia , Cordoba",name:"CRECER (Centro de Integraci\u00f3n del Discapacitado)",position:[-31.6510111, -64.4352107]},
  {category:"salud",type:"instituto",address:"MONTECATINI 450. BARRIO COLINAS, Villa Carlos Paz , Cordoba",name:"Instituto Especial MARIETTE LYDIS - Centro de Dia Vida Feliz ",position:[-31.4117392, -64.5302655]},
  {category:"salud",type:"instituto",address:"Instituto Especial MARIETTE LYDIS , Villa Carlos Paz , Cordoba",name:"Instituto Especial MARIETTE LYDIS - Centro de Dia Vida Feliz ",position:[-31.4123018, -64.5304531]},
  {category:"salud",type:"instituto",address:"Jacinto R\u00edos N\u00ba 380 - B\u00ba General Paz , C\u00f3rdoba , Cordoba",name:"Instituto de Educaci\u00f3n Especial \"EL SOL\" ",position:[-31.4118579, -64.1663549]},
  {category:"salud",type:"asociaci\u00f3n",address:"JOS\u00c9 ESTEBAN BUSTOS 873 , C\u00f3rdoba , Cordoba",name:"DERECHO A LA EDUCACI\u00d3N DEL NI\u00d1O FRONTERIZO ",position:[-31.3842255, -64.2438682]},
  {category:"salud",type:"fundaci\u00f3n",address:"MATEO BERES 465, Alta Gracia , Cordoba",name:"FUNDACI\u00d3N ESPACIOS ",position:[-31.6594382, -64.4277978]},
  {category:"salud",type:"centro de d\u00eda",address:"BUENOS AIRES 198, Villa Carlos Paz , Cordoba",name:"CENTRO PRIVADO EDUCATIVO SAN FRANCISCO DE ASIS DE ",position:[-31.4144163, -64.4853483]},
  {category:"salud",type:"centro de rehabilitacion",address:"Pellegrini N\u00b0 362, De\u00e1n Funes , Cordoba",name:"Centro de Rehabilitaci\u00f3n del Norte - CE.R.NO.",position:[-30.4209111, -64.34606029999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"TREJO Y SANABRIA 174. B\u00ba CENTRO , R\u00edo Tercero , Cordoba",name:"CENTRO DE ATENCI\u00d3N INTERDISCIPLINARIA ",position:[-32.1692251, -64.1178738]},
  {category:"salud",type:"hogar",address:"RUTA E-53 ESQUINA ARRECIFE , C\u00f3rdoba , Cordoba",name:"HOGAR DE INTEGRACI\u00d2N SOCIAL AL INCAPACITADO MENTAL",position:[-31.3348062, -64.2086534]},
  {category:"salud",type:"asociaci\u00f3n",address:"San Luis 76 , De\u00e1n Funes , Cordoba",name:"Asociacion Para la Lucha Contra la Paralisis Infan",position:[-30.4244679, -64.3488119]},
  {category:"salud",type:"fundaci\u00f3n",address:"Madre Rubatto 2910 Barrio Alto de San Ma, C\u00f3rdoba , Cordoba",name:"FUNDACI\u00d2N CAUSANA ",position:[-31.3811791, -64.2159403]},
  {category:"salud",type:"centro de d\u00eda",address:"REP\u00d9BLICA DEL L\u00ccBANO 43 B\u00ba BANDA NORTE , R\u00edo Cuarto , Cordoba",name:"PROYECTAR CENTRO DE D\u00ccA ",position:[-33.1054459, -64.3353]},
  {category:"salud",type:"asociaci\u00f3n",address:"felix aguilar 1231 , C\u00f3rdoba , Cordoba",name:"A.R.E.N.A ",position:[-31.4188394, -64.2035368]},
  {category:"salud",type:"instituto",address:"GASPAR DE MEDINA 333 B\u00ba MARQUES DE SOBRE, C\u00f3rdoba , Cordoba",name:"INSTITUCI\u00d2N SULLAI ",position:[-31.3656361, -64.2034905]},
  {category:"salud",type:"escuela",address:"Ruta Provinvial N\u00ba 10 , Hernando , Cordoba",name:"Escuela Mar\u00eca Montessori- Modalidad Especial ",position:[-32.4255251, -63.7319581]},
  {category:"salud",type:"centro educativo",address:"MOGROVEJO 7760. VILLA 9 DE JULIO , C\u00f3rdoba , Cordoba",name:"CUMELCAN, CENTRO EDUCATIVO TERAP\u00c8UTICO ",position:[-31.3267793, -64.2683555]},
  {category:"salud",type:"centro educativo",address:"ANDALUCIA 2843 2853. B\u00b0 COLON , C\u00f3rdoba , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO \"MI LUGAR I\"- \"CENTRO DE D\u00cdA MI LUGAR",position:[-31.43313145302473, -64.1486895362796]},
  {category:"salud",type:"centro educativo",address:"LA RAMADA 2944 50 58. BARRIO ALTO VERDE , C\u00f3rdoba , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO \"MI LUGAR I\"- \"CENTRO DE D\u00cdA MI LUGAR",position:[-31.3743129, -64.2165788]},
  {category:"salud",type:"centro de rehabilitacion",address:"Salta N\u00b0 272 B\u00b0 Centro , C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE TRATAMIENTO DE NI\u00d1OS Y ADOLESCE",position:[-31.4148491, -64.1792179]},
  {category:"salud",type:"centro de d\u00eda",address:"ADOLFO ORMA 1446. B\u00ba CERRO DE LAS ROSAS , C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA OCUPACIONAL ",position:[-31.3829655, -64.2299404]},
  {category:"salud",type:"instituto",address:"BELGRANO 238 , Justiniano Posse , Cordoba",name:"INSTITUTO DALMACIO V\u00c8LEZ S\u00c0RSFIELD- MODALIDAD ESPE",position:[-32.8822634, -62.67983599999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"SALTA 1761 E HIPOLITO IRIGOYEN 1575 , San Francisco , Cordoba",name:"ASOCIACI\u00d2N DE PADRES Y AMIGOS DEL INSUFICIENTE MEN",position:[-31.42499919999999, -62.0841599]},
  {category:"salud",type:"centro de rehabilitacion",address:"ALBERDI 1032 , C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACI\u00d2N M\u00c8DICO PRIVADO ",position:[-31.4298153, -64.1854098]},
  {category:"salud",type:"clinica",address:"AV. RICARDO ROJAS 10500 , Sald\u00e1n , Cordoba",name:"CLINICA CASTILLO MORALES ",position:[-31.3222102, -64.30572649999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"JOS\u00c8 INGENIEROS 559 , Villa Mar\u00eda , Cordoba",name:"FUNDACI\u00d2N EL PRINCIPITO ",position:[-32.405354, -63.2472626]},
  {category:"salud",type:"fundaci\u00f3n",address:"Entre R\u00ecos 2867 , C\u00f3rdoba , Cordoba",name:"Fundaci\u00f2n Haru\u00e8n ",position:[-31.4244866, -64.1472461]},
  {category:"salud",type:"asociaci\u00f3n",address:"DEAN FUNES 459 , Arroyito , Cordoba",name:"Asociaci\u00f2n Civil sin Fines de lucro CUMELEN ",position:[-31.4180691, -63.04785010000001]},
  {category:"salud",type:"instituto",address:"GENERAL GUIDO 1069. BARRIO SAN MARTIN , C\u00f3rdoba , Cordoba",name:"INSTITUTO DE REHABILITACI\u00d2N DEL LISIADO C\u00d2RDOBA ",position:[-31.3945682, -64.1981595]},
  {category:"salud",type:"miscelaneo",address:"BUNGE DE GALVEZ 232 , Alta Gracia , Cordoba",name:"SI.SA.ME S.R.L. ",position:[-31.6485382, -64.4437068]},
  {category:"salud",type:"fundaci\u00f3n",address:"HAEDO 473. BARRIO OBRERO , C\u00f3rdoba , Cordoba",name:"FUNDACI\u00d2N F.A.R.O.S. ",position:[-31.4135824, -64.2128283]},
  {category:"salud",type:"asociaci\u00f3n",address:"ZONA RURAL , R\u00edo Tercero , Cordoba",name:"ASOCIACI\u00d3N PADRES AYUDA H\u00daERFANOS DISCAPACITADOS ",position:[-32.1766541, -64.1130995]},
  {category:"salud",type:"fundaci\u00f3n",address:"AV. SANTA FE 882 , C\u00f3rdoba , Cordoba",name:"FUNDACION GAUDE ",position:[-31.4019219, -64.1976784]},
  {category:"salud",type:"centro de d\u00eda",address:"ZONA RURAL CAMINO A SANTA FLORA , R\u00edo Cuarto , Cordoba",name:"CENTRO DE DIA COOPERATIVA TODOS JUNTOS",position:[-33.1039295, -64.3371417]},
  {category:"salud",type:"centro de d\u00eda",address:"MOGROVEJO 7760. VILLA 9 DE JULIO , C\u00f3rdoba , Cordoba",name:"CUMELCAN ",position:[-31.3267793, -64.2683555]},
  {category:"salud",type:"centro de rehabilitacion",address:"GABRIEL IGNACIO GIL 95, Villa Del Totoral , Cordoba",name:"CENTRO PRIVADO DE REHABILITACI\u00d3N DESPERTAR",position:[-30.7060475, -64.0675866]},
  {category:"salud",type:"asociaci\u00f3n",address:"ELPIDIO GONZALEZ 302. B\u00b0 INDUSTRIAL. RIV, C\u00f3rdoba , Cordoba",name:"ASOCIACI\u00d3N DE PADRES Y AMIGOS DEL DIAGN\u00d3STICO, REH",position:[-31.29327069999999, -64.3015973]},
  {category:"salud",type:"centro educativo",address:"JUAN ARGA\u00d1ARAZ 1756. B\u00b0 VILLA CABRERA , C\u00f3rdoba , Cordoba",name:"CENTRO EDUCATIVO MATER ASOCIACI\u00d3N CIVIL ",position:[-31.3851503, -64.217285]},
  {category:"salud",type:"centro de rehabilitacion",address:"Las Quintas N\u00ba 2430, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACI\u00d3N KIRON S.A. ",position:[-31.3814971, -64.1100435]},
  {category:"salud",type:"centro de rehabilitacion",address:"BV. GUZMAN 625 7 , C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACI\u00d3N KIRON S.A. ",position:[-31.4124636, -64.1753527]},
  {category:"salud",type:"centro educativo",address:"GUADALUPE 2899. BARRIO SANTA ISABEL. III, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO EDUCATIVO TERAPEUTICO ANGEL ",position:[-31.4833298, -64.23332979999999]},
  {category:"salud",type:"centro educativo",address:"Sarmiento N\u00b0 1283, C\u00f3rdoba , Cordoba",name:"CE.IN.S.R.L.",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"centro educativo",address:"SOLES. CET , C\u00f3rdoba , Cordoba",name:"SOLES. CET ",position:[-31.4291685, -64.23005069999999]},
  {category:"salud",type:"centro de d\u00eda",address:"GENARAL PAZ 370 , R\u00edo Tercero , Cordoba",name:"C.A.I. ",position:[-32.1779743, -64.1111115]},
  {category:"salud",type:"centro de d\u00eda",address:"MARIANO MORENO 1560 , Arroyito , Cordoba",name:"CENTRO DE ESTIMULACION RENACER ",position:[-31.4192561, -63.0573947]},
  {category:"salud",type:"fundaci\u00f3n",address:"REAL: LOCALIDAD POZO DEL TIGRE. LEGAL: J, C\u00f3rdoba , Cordoba",name:"FUNDACION MI CASA ",position:[-24.8971267, -60.31805079999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"MADRID 2262 , C\u00f3rdoba , Cordoba",name:"CTRO INTEGRAL DE NEUROREHABILITACION CIN ",position:[-31.434886541846687, -64.1689443699728]},
  {category:"salud",type:"asociaci\u00f3n",address:"Juan Castagnino N\u00b0 2155 B\u00b0 Tablada Park , C\u00f3rdoba , Cordoba",name:"Asociacion Civil sin Fines de Lucro ANTARES ",position:[-31.3722582, -64.229098]},
  {category:"salud",type:"asociaci\u00f3n",address:"San Lorenzo N\u00b0 283 B\u00b0 Nueva Cordoba , C\u00f3rdoba , Cordoba",name:"Asociaciacion Para La Lucha Contra La Paralisis In",position:[-31.4285855, -64.1848524]},
  {category:"salud",type:"centro de d\u00eda",address:"Misiones N\u00b0 389 B\u00b0 Paso de los Andes , C\u00f3rdoba , Cordoba",name:"C.I.R. ",position:[-31.4168228, -64.20183899999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Santa Ana N\u00b02265 B\u00b0Alto Alberdi , C\u00f3rdoba , Cordoba",name:"Centro de Rehabilitacion Neurologica LOGROS ",position:[-31.4157953, -64.215182]},
  {category:"salud",type:"asociaci\u00f3n",address:"Independencia N\u00b0 1457 , San Francisco , Cordoba",name:"APRID (Asociacion de Padres y Responsables Integra",position:[-31.422302, -62.1042071]},
  {category:"salud",type:"centro de d\u00eda",address:"Tucuman N\u00b0 556 , Marcos Ju\u00e1rez , Cordoba",name:"Centro Interdisciplinario de Rehabilitacion Ra\u00edces",position:[-32.6915326, -62.1069344]},
  {category:"salud",type:"centro educativo",address:"Bialet Masse N\u00b0 1735 B\u00b0 Providencia , C\u00f3rdoba , Cordoba",name:"Centro Educativo Terapeutico Talita Kum I.D.E.A.C.",position:[-31.3981228, -64.200278]},
  {category:"salud",type:"centro educativo",address:"Gral. Paz 370, R\u00edo Tercero , Cordoba",name:"CAI CENTRO EDUCATIVO TERAPEUTICO CON INTEGRACION E",position:[-32.1779743, -64.1111115]},
  {category:"salud",type:"miscelaneo",address:"Tristan Malbran N\u00b0 3733 B\u00b0 Cerro de las , C\u00f3rdoba , Cordoba",name:"5 SENTIDOS ",position:[-31.3787686, -64.22961900000001]},
  {category:"salud",type:"centro educativo",address:"RODRIGUEZ PE\u00d1A 2523, C\u00f3rdoba , Cordoba",name:"CENTRO EDUCATIVO TERAPEUTICO CON INTEGRACI\u00d3N ESCOLAR FARFALINA ",position:[-31.3861699, -64.18805329999999]},
  {category:"salud",type:"miscelaneo",address:"Cerrito N\u00c2\u00b0 82 , Villa Carlos Paz , Cordoba",name:"LAT AY S.R.L. ",position:[-31.4272507, -64.4943575]},
  {category:"salud",type:"asociaci\u00f3n",address:"MADRE SACRAMENTO N\u00b0 1850 B\u00b0 VILLA EUCARI, C\u00f3rdoba , Cordoba",name:"ESPACIOS PARA CRECER ASOCIACION CIVIL ",position:[-31.4807429, -64.1698318]},
  {category:"salud",type:"centro de rehabilitacion",address:"MITRE 1764, R\u00edo Cuarto , Cordoba",name:"CENTRO DE REHABILITACION MEDICO PRIVADO S.R.L. ",position:[-33.1324655, -64.35561659999999]},
  {category:"salud",type:"instituto",address:"Parana 350 , R\u00edo Cuarto , Cordoba",name:"INSTITUCION EDUCATIVA SANTO TOMAS ",position:[-33.1199335, -64.3434233]},
  {category:"salud",type:"centro de d\u00eda",address:"Diego Cala N\u00ba 195 B\u00ba San Salvador, C\u00f3rdoba , Cordoba",name:"CENTRO INTERDISCIPLINARIO KARUNA CREC.Y SALUD",position:[-31.4035011, -64.231636]},
  {category:"salud",type:"centro de d\u00eda",address:"25 de Mayo 541 B\u00ba Centro, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA FUNDACION APADIM",position:[-31.4167357, -64.1765453]},
  {category:"salud",type:"centro educativo",address:"Fernandez Moreno 461, C\u00f3rdoba , Cordoba",name:"CD AMIGOS ESP.y CTRO.DE APOYO A LA INTEGR. ESCOLAR",position:[-31.3648571, -64.1696592]},
  {category:"salud",type:"centro de rehabilitacion",address:"Rivadavia 691, Oliva , Cordoba",name:"INISED - CTRO. DE REHABILITACI\u00d3N E INTEGR. ESC.",position:[-32.0349746, -63.56680249999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Castro Barros 22, C\u00f3rdoba , Cordoba",name:"CENTRO ISE CON INTEGRACI\u00d3N ESCOLAR",position:[-31.405013, -64.1938787]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. Belgrano 123, Monte Cristo , Cordoba",name:"CENTR. TERAP. INTEG. PRIV. HUEPIL CON INTEGR. ESC.",position:[-31.3461873, -63.9497763]},
  {category:"salud",type:"instituto",address:"Sarmiento 1855 75, R\u00edo Cuarto , Cordoba",name:"INSTITUTO M\u00c9DICO DE SALUD INTEGRAL",position:[-33.1189119, -64.341481]},
  {category:"salud",type:"consultorio",address:"Mayor Arruabarrena 1947, C\u00f3rdoba , Cordoba",name:"CTRO. PRIVADO DE SALUD \"UN CAMINO\"",position:[-31.3763036, -64.2289674]},
  {category:"salud",type:"centro de rehabilitacion",address:"Prudencio Bustos 302, Alta Gracia , Cordoba",name:"KALEN - CTRO. DE REHAB. E INTEGRACI\u00d3N ESCOLAR",position:[-31.6550658, -64.4283801]},
  {category:"salud",type:"centro educativo",address:"Agust\u00edn Garz\u00f3n 2129, C\u00f3rdoba , Cordoba",name:"DELMAR - CET Y CTRO DE D\u00cdA Y CTRO DE INTEGRACIONES",position:[-31.4245724, -64.1567194]},
  {category:"salud",type:"centro de d\u00eda",address:"Octavio Pinto 2514, C\u00f3rdoba , Cordoba",name:"CTRO. DE INTEGR. ESC. INTERDISCIPLINARIO SEMILLAS",position:[-31.3907149, -64.2133877]},
  {category:"salud",type:"fundaci\u00f3n",address:"NICANOR CARRANZA 3992 B\u00b0 CERRO DE LAS ROSAS, C\u00f3rdoba , Cordoba",name:"FUNDACION SAN BENITO",position:[-31.3771676, -64.23590240000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Goyechea 3368, C\u00f3rdoba , Cordoba",name:"FUNDACION SAN BENITO",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"centro de d\u00eda",address:"Esperanza 1270, R\u00edo Tercero , Cordoba",name:"CTRO. PRIV. DE APOYO A LA INT. ESC. \u00c1BACO INTERDIS",position:[-32.1758923, -64.1185967]},
  {category:"salud",type:"miscelaneo",address:"Alberdi N\u00ba 354, R\u00edo Tercero , Cordoba",name:"SOLES RIO TERCERO PRIVADO",position:[-32.1751122, -64.1133293]},
  {category:"salud",type:"centro de d\u00eda",address:"Jose Roque Funes N\u00ba1066, C\u00f3rdoba , Cordoba",name:"CENTRO EFAPP-Equipo de Form.y Asis.Psic.y Psicoped",position:[-31.3730106, -64.2384924]},
  {category:"salud",type:"centro de d\u00eda",address:"Oncativo N\u00ba 1332, C\u00f3rdoba , Cordoba",name:"CILHIAR-CENTRO INTEG.PARA HIPOACUSICOS ARG.PRIVADO",position:[-31.4095737, -64.1670561]},
  {category:"salud",type:"centro de rehabilitacion",address:"Viamonte N\u00b0 131, C\u00f3rdoba , Cordoba",name:"CTRO. PRIV. DE NEURO. Y NEUROPSIC. INF. WERNICKE",position:[-31.414114, -64.16464239999999]},
  {category:"salud",type:"centro educativo",address:"RUTA NACIONAL N\u00b0 9 KM 565 ZONA RURAL, T\u00edo Pujio , Cordoba",name:"CENTRO PRIVADO EDUCATIVO TERAPEUTICO DR, LEO KANNER",position:[-32.2858968, -63.353412]},
  {category:"salud",type:"fundaci\u00f3n",address:"Emilio Casas Ocampo N\u00b0 2910, C\u00f3rdoba , Cordoba",name:"FUND. PASOS CTRO. DE SALUD Y EDUC. INTERDISCIP.",position:[-31.3760554, -64.2143988]},
  {category:"salud",type:"centro de d\u00eda",address:"Juan XXIII - N\u00b0 102, R\u00edo Primero , Cordoba",name:"CTRO. TERAP. PRIV. CON INTEGRACION ESCOLAR",position:[-31.3317205, -63.6220702]},
  {category:"salud",type:"centro educativo",address:"Col\u00f3n N\u00b0 1642 B| ALBERDI, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE INTEGRACION A LA ESCUELA COMUN \"ARK-HO\"",position:[-31.4070315, -64.205123]},
  {category:"salud",type:"centro de rehabilitacion",address:"Rotary 2685, C\u00f3rdoba , Cordoba",name:"CTRO.DE REHAB.Y APOYO A LA INT.ESC.DESCUBRIR SRL",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"centro de rehabilitacion",address:"BUONAROTTI N\u00b0 644, Villa Carlos Paz , Cordoba",name:"CTRO PRIVADO INTERDISCIPLINARIO DE REHAB. NEUROLOGICA CARDEA",position:[-31.4061587, -64.50605350000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Paso de los Andes N\u00b0 225, C\u00f3rdoba , Cordoba",name:"AMUN CTRO. DE REHAB. CON INTEGRACION ESCOLAR",position:[-31.4141733, -64.1997248]},
  {category:"salud",type:"centro educativo",address:"Agust\u00edn Garz\u00f3n Agulla 440, C\u00f3rdoba , Cordoba",name:"CTRO. EDUCATIVO TERAPEUTICO Y DE INT. ESC. WI\u00d1AY",position:[-31.41915119999999, -64.1651377]},
  {category:"salud",type:"centro educativo",address:"URUGUAY 531, Cosqu\u00edn , Cordoba",name:"CALLPA CTRO. EDUC. TERAP. ",position:[-31.231878, -64.46420499999999]},
  {category:"salud",type:"centro educativo",address:"NU\u00d1EZ DEL PRADO 2607, C\u00f3rdoba , Cordoba",name:"CARRILET C.E.T. PRIVADO CON APOYO A LA INTEG. ESC.",position:[-32.2968402, -63.580611]},
  {category:"salud",type:"centro educativo",address:"Coronel Olmedo N\u00b0 734, C\u00f3rdoba , Cordoba",name:"PROVIDUS II - CTRO. DE APOYO A LA INTEGRACION ESC.",position:[-31.4033162, -64.19684]},
  {category:"salud",type:"fundaci\u00f3n",address:"Miguel Arrambide 6127, C\u00f3rdoba , Cordoba",name:"CENTRO FUNDACION MULTIPLES EXPRESIONES",position:[-31.3517562, -64.2461208]},
  {category:"salud",type:"centro de d\u00eda",address:"General Deheza 1542 - B\u00b0 Pueyrred\u00f3n, C\u00f3rdoba , Cordoba",name:"APUKAY CENTRO DE D\u00cdA S.R.L.",position:[-31.3991484, -64.1675753]},
  {category:"salud",type:"centro de rehabilitacion",address:"Nicaragua N\u00b0 58, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACION INTEGRAL ELPHIS",position:[-31.4794916, -64.2449477]},
  {category:"salud",type:"centro de rehabilitacion",address:"Felix Olmedo N\u00b0 2153, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACION NEUROLOGICA INFANTIL",position:[-31.4363575, -64.1780811]},
  {category:"salud",type:"centro de d\u00eda",address:"Santa Fe N\u00b0 475, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE ATENCION INTEGRAL",position:[-31.4054328, -64.1997277]},
  {category:"salud",type:"asociaci\u00f3n",address:"ANTONIO DEL VISO N\u00b0 844 B\u00b0 ALTA CORDOBA, C\u00f3rdoba , Cordoba",name:"ASOCIACI\u00d3N CIVIL CASANDRA ",position:[-31.3928582, -64.1865455]},
  {category:"salud",type:"miscelaneo",address:"Carcano 501, Villa Dolores , Cordoba",name:"MANO A MANO SRL",position:[-31.9481959, -65.18427969999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"ARMENGOL TECERA 261, C\u00f3rdoba , Cordoba",name:"FUNDACION AZUD",position:[-31.4035907, -64.2147808]},
  {category:"salud",type:"centro de rehabilitacion",address:"JACHAL 4304, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACION INSERIR",position:[-31.4342809, -64.1308031]},
  {category:"salud",type:"centro de rehabilitacion",address:"CARNERILLO 2173 B\u00b0 eMPALME, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACION INSERIR",position:[-31.4376103, -64.1279428]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ameghino 377 , San Francisco , Cordoba",name:"Fundaci\u00f3n Akhenaton",position:[-31.4319565, -62.0955361]},
  {category:"salud",type:"centro de rehabilitacion",address:"24 DE SEPTIEMBRE 1536, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACION MEDITERRANEO SRL",position:[-31.4131077, -64.1630826]},
  {category:"salud",type:"centro de d\u00eda",address:"OBISPO ECHENIQUE ALTAMIRA 3066, C\u00f3rdoba , Cordoba",name:"CENTRO IESE",position:[-31.4474948, -64.1896845]},
  {category:"salud",type:"centro de d\u00eda",address:"LAGUNA BLANCA 1335 45, R\u00edo Cuarto , Cordoba",name:"CENTRO DE DIA PROYECTAR",position:[-33.1067992, -64.3133447]},
  {category:"salud",type:"asociaci\u00f3n",address:"LA RIOJA 179, R\u00edo Cuarto , Cordoba",name:"ASOCIACION PRO-AYUDADEL INSUFICIENTE MENTAL APADIM",position:[-33.1275959, -64.3515288]},
  {category:"salud",type:"centro educativo",address:"ROQUE FUNES N\u00b0 1194 B\u00b0 COLINAS DEL CERRO, C\u00f3rdoba , Cordoba",name:" LOGROS -  centro de integraci\u00f3n ESCOLAR",position:[-31.37983119999999, -64.24421389999999]},
  {category:"salud",type:"centro educativo",address:"RAFAEL NU\u00d1EZ 5471, C\u00f3rdoba , Cordoba",name:"CET MI LUGAR III Y II",position:[-31.35315, -64.24571]},
  {category:"salud",type:"centro educativo",address:"Colectora Agustin Tosco N\u00b0 1180, C\u00f3rdoba , Cordoba",name:"CET MI LUGAR III Y II",position:[-31.4666553, -64.1947215]},
  {category:"salud",type:"fundaci\u00f3n",address:"SAN MARTIN 67, Alta Gracia , Cordoba",name:"FUNDACION KAMAY",position:[-31.65895009999999, -64.4315447]},
  {category:"salud",type:"centro de d\u00eda",address:"SANTA FE 1278, Villa Mar\u00eda , Cordoba",name:" CENTRO INEDI - INSTITUTO DE NEUROCIENCIAS DEL DESARROLLO INTEGRAL",position:[-32.4091817, -63.2424492]},
  {category:"salud",type:"centro de d\u00eda",address:"PAGUAGUA 1144, Villa General Belgrano , Cordoba",name:"CENTRO DE ATENCION ESPECIAL RENACER Y CENTRO DE DIA",position:[-31.97766519999999, -64.5594102]},
  {category:"salud",type:"centro educativo",address:"RAFAEL OBLIGADO 52, Villa Allende , Cordoba",name:"CETIF",position:[-31.296044, -64.2986291]},
  {category:"salud",type:"fundaci\u00f3n",address:"Justiniano Posse 932 - B\u00b0 Jard\u00edn, C\u00f3rdoba , Cordoba",name:"FUNDACION CEATI",position:[-31.4450688, -64.1823612]},
  {category:"salud",type:"centro de d\u00eda",address:"BAJADA PUCARA 1000 BARRIO CRISOL, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA FUND. DE INV., REH. Y PROTECCION AL DISC. MENTAL IRAM ",position:[-31.429238, -64.170102]},
  {category:"salud",type:"miscelaneo",address:"Camino Boca del Rio S N, Villa San Isidro , Cordoba",name:"YI\u00d1 PEUMAYEN S.R.L. ",position:[19.106273, -96.10632040000002]},
  {category:"salud",type:"centro de d\u00eda",address:"Intendente Vila 1626, Mina Clavero , Cordoba",name:"Centro de D\u00eda Encuentro Recreativo Terapeutico",position:[-31.7221856, -65.0005987]},
  {category:"salud",type:"consultorio",address:"AV. COLON 4405, C\u00f3rdoba , Cordoba",name:"CENTRO MEDICO NEUROS PROPIEDAD DE GEA SALUD SRL",position:[-31.3973684, -64.2376306]},
  {category:"salud",type:"centro educativo",address:"9 DE JULIO 1215 B\u00b0 CATEDRAL, San Francisco , Cordoba",name:"CENTRO EDUCATIVO TERAPEUTICO SENTIDOS",position:[-31.4318933, -62.0802488]},
  {category:"salud",type:"centro de rehabilitacion",address:"AV. CHACAL 9124 VILLA CORNU, C\u00f3rdoba , Cordoba",name:"CTRO PRIV. DE ASISTENCIA Y REHAB. PARA NI\u00d1OS CON NEC. ESPECIALESCEPRINES S.R.L.",position:[-31.3183951, -64.270299]},
  {category:"salud",type:"centro de d\u00eda",address:"ZONA RURAL, El Manzano , Cordoba",name:"CENTRO DE DIA CANDONGA. ESPACIO ABIERTO",position:[-31.0740621, -64.2982387]},
  {category:"salud",type:"centro de d\u00eda",address:"PRESIDENTE ILLIA N \u00b0250, La Falda , Cordoba",name:"CENTRO DE DIA MARIA MONTESSORI",position:[-31.0950601, -64.47799189999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"JOSE P OTEROS 1435, C\u00f3rdoba , Cordoba",name:"CENTRO FUNDACI\u00d3N MAUD MANNONI CON INTEGRACI\u00d3N ESCOLAR",position:[-31.382417, -64.2311149]},
  {category:"salud",type:"fundaci\u00f3n",address:"DUSMENIL N\u00b0 1378, C\u00f3rdoba , Cordoba",name:"CENTRO FUNDACI\u00d3N MAUD MANNONI CON INTEGRACI\u00d3N ESCOLAR",position:[-31.4016672, -64.19722469999999]},
  {category:"salud",type:"centro de d\u00eda",address:"RIO DE LA PLATA 940, La Calera , Cordoba",name:"CENTRO DE DIA,F. L.,C. E. T., INTEGRACI\u00d3N, REHABILITACI\u00d3N AYSAND",position:[-31.3395038, -64.3380686]},
  {category:"salud",type:"centro de rehabilitacion",address:"DUARTE QUIROS N\u00b0 5307 B\u00b0 TEODORO FELS, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACI\u00d3N INTEGRAL NEUROFUNCIONAL COPRIN",position:[-31.3967347, -64.2522886]},
  {category:"salud",type:"centro de rehabilitacion",address:"LUIS DE TEJEDA N\u00b0 4485 B\u00b0 URCA, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE ESTUDIO E INVESTIGACI\u00d3N PARA EL DESARROLLO INFANTO JUVENIL",position:[-31.367086, -64.2368176]},
  {category:"salud",type:"centro terapeutico",address:"HIPOLITO IRIGOYEN 146 VILLA DOLORES , Villa Dolores , Cordoba",name:"CENTRO TERAP\u00c9UTICO CON APOYO A LA INTEGRACI\u00d3N ESCOLAR INTEGRARTE",position:[-31.9450289, -65.1902739]},
  {category:"salud",type:"centro de rehabilitacion",address:"SAN MARTIN 763, Oncativo , Cordoba",name:"CENTRO DE NEUROCIENCIAS DE INCLUSI\u00d3N Y REHABILITACI\u00d3N -CENIR",position:[-31.9142205, -63.6820037]},
  {category:"salud",type:"centro de rehabilitacion",address:"AV. PABLO RICHIERI 3182. B JARDIN, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE SALUD Y REHABILITACION INTEGRAL SAN CAMILO DE LELLIS SRL",position:[-31.4490414, -64.17685209999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"BARTOLOME DE LAS CASAS N\u00b03685 JARDIN ESPINOSA, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE SALUD Y REHABILITACION INTEGRAL SAN CAMILO DE LELLIS SRL",position:[-31.4548591, -64.1720855]},
  {category:"salud",type:"centro de d\u00eda",address:"ROTARY INTERNACIONAL N\u00b0 2685. B\u00b0 VILLA EUCARISTICA, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA DESCUBRIR S.R.L",position:[-31.48021749999999, -64.1775861]},
  {category:"salud",type:"centro educativo",address:"AMADEO AVOGADRO 5938. B\u00b0 VILLA BELGRANO, C\u00f3rdoba , Cordoba",name:"C.E.T.CON APOYOA LA INTEGRACION ESCOLAR .FUNDACION RUCAHUE",position:[-31.3529083, -64.2520521]},
  {category:"salud",type:"centro educativo",address:"AMELIA EARHART N\u00b0 4547 B\u00b0 VILLA DEL CERRO, C\u00f3rdoba , Cordoba",name:"C.EDUCATIVO TERAPEUTICO ESC.TERAPEUTICA PEDAGOGICA ROBERT Y ROSINE LEFORT",position:[-31.3739651, -64.2161981]},
  {category:"salud",type:"centro de rehabilitacion",address:"SUECIA 2430 B\u00b0 PARQUE VELEZ SARSFIELD, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACI\u00d3N CRESCERE",position:[-31.4394893, -64.2055243]},
  {category:"salud",type:"centro de rehabilitacion",address:"CLEVELAND 5372 B\u00b0SANTA ISABEL PRIMERA SECCION, C\u00f3rdoba , Cordoba",name:"CENTRO NEUROPSICOLOGICO INTERDISCIPLINARIO CENEIN",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"centro educativo",address:"PAUL HARRIS 829, R\u00edo Cuarto , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO CON INTEGRACI\u00d3N ESCOLAR Y CENTRO DE D\u00cdA KALEN",position:[-33.1012407, -64.34116999999999]},
  {category:"salud",type:"instituto",address:"AQUILINO ARGUELLO ARDILES N\u00b0 531, Berrotar\u00e1n , Cordoba",name:"INSTITUTO SAN JOSE MODALIDAD ESPECIAL",position:[-32.450644, -64.4014164]},
  {category:"salud",type:"centro de d\u00eda",address:"HAMBURGO N\u00b02153 B\u00b0 SAN NICOLAS, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO AMBULATORIO DE APOYO A LA INTEGRACION ESCOLAR PIKATSU MOROTI",position:[-31.42008329999999, -64.1887761]},
  {category:"salud",type:"centro de d\u00eda",address:"RIO NEGRO 467, Unquillo , Cordoba",name:"CENTRO DE DIA \"RED SIERRAS CHICAS\"",position:[-31.2410811, -64.3093793]},
  {category:"salud",type:"centro educativo",address:"CALLE PUBLICA S N , La Paz , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO UN NUEVO SOL",position:[-32.2968402, -63.580611]},
  {category:"salud",type:"centro de rehabilitacion",address:"RIVADAVIA 579 B\u00b0 MIGUEL MU\u00d1OZ, Villa Carlos Paz , Cordoba",name:"CENTRO MODELO DE REHABILITACION GIRALUNA",position:[-31.4373829, -64.5109148]},
  {category:"salud",type:"centro de d\u00eda",address:"SAN LUIS 1270 B\u00b0 OBSERVATORIO, C\u00f3rdoba , Cordoba",name:"CENTRO DE INTEGRACI\u00d3N Y ASISTENCIA JOVANA SRL",position:[-31.4197557, -64.201856]},
  {category:"salud",type:"centro de d\u00eda",address:"NEUQUEN 1070, C\u00f3rdoba , Cordoba",name:"PILARES CENTRO PRIVADO INTERDISCIPLINARIO DE APOYO A LA INTEGRACION ESCOLAR",position:[-31.3986878, -64.19987139999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"BV. SAN JUAN 1132 PLANTA BAJA, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACI\u00d3N REHABILITANDO",position:[-31.4166809, -64.2011911]},
  {category:"salud",type:"centro de d\u00eda",address:"GENOVA 4145 B\u00b0 LAS MAGNOLIAS, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA \"OCCUPARE\"",position:[-31.3689362, -64.20674500000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"IRIGOYEN 70, La Calera , Cordoba",name:"CENTRO PRIV. DE REHABILITACI\u00d3N Y EDUCACI\u00d3N MANOS UNIDAS CON INTEGRACION ESCOLAR",position:[-31.3464019, -64.33617579999999]},
  {category:"salud",type:"centro de d\u00eda",address:"CORRIENTES 511, Freyre , Cordoba",name:"CENTRO DE ATENCION A LA DIVERSIDAD AMANECER",position:[-31.1606394, -62.0916655]},
  {category:"salud",type:"centro de rehabilitacion",address:"PRIMERO DE MAYO N\u00b0 210, Hernando , Cordoba",name:"CENTRO PRIVADO DE APOYO A LA INTEGRACION ESCOLAR CONVERGER EN LA DIVERSIDAD",position:[-32.4255251, -63.7319581]},
  {category:"salud",type:"centro de d\u00eda",address:"OBISPO MERCADILLO 2651 VILLA CENTENARIO, C\u00f3rdoba , Cordoba",name:"CENTRO DESPERTARES",position:[-31.3621703, -64.23093539999999]},
  {category:"salud",type:"miscelaneo",address:"JUAN JOSE PASO 2068, San Francisco , Cordoba",name:"INTEGRAR MAS",position:[-31.4231857, -62.0877625]},
  {category:"salud",type:"centro de d\u00eda",address:"LINCOLN 168 B\u00b0 VILLA DOMINGUEZ, Villa Carlos Paz , Cordoba",name:"CENTRO DE DIA ENLACES S.R.L",position:[-31.410758, -64.494171]},
  {category:"salud",type:"instituto",address:"FIGUEREDO DE PIETRA N\u00b0 1615 B\u00b0 LICEO GRAL.PAZ, C\u00f3rdoba , Cordoba",name:"NUESTRA SE\u00d1ORA DE ITATI- MODALIDAD ESPECIAL",position:[-31.3407446, -64.1573733]},
  {category:"salud",type:"fundaci\u00f3n",address:"TRISTAN MALBRAN N\u00b03872 CERRO DE LAS ROSAS, C\u00f3rdoba , Cordoba",name:"FUNDACI\u00d3N APRENDER A SER-HACER",position:[-31.3766083, -64.2311319]},
  {category:"salud",type:"centro educativo",address:"VELEZ SARSFIELD N\u00b0 1810, Arroyito , Cordoba",name:"CENTRO INTEGRAR CON INTEGRACION ESCOLAR",position:[-31.4209671, -63.060481]},
  {category:"salud",type:"centro de d\u00eda",address:"JULIO ROCA N\u00b0 1262, R\u00edo Segundo , Cordoba",name:"TRISQUEL- CENTRO DE ATENCI\u00d3N INTEGRAL",position:[-31.6509879, -63.91399730000001]},
  {category:"salud",type:"centro educativo",address:"AV. SAN MARTIN 1245, Hernando , Cordoba",name:"CINED- CENTRO PRIVADO DE APOYO A LA INTEGRACI\u00d3N ESCOLAR",position:[-32.4187505, -63.7250756]},
  {category:"salud",type:"centro educativo",address:"LA PAMPA 55 B\u00b0 QUINTAS DE SANTA ANA, C\u00f3rdoba , Cordoba",name:"IDENTIDAD CENTRO DE INTEGRACI\u00d3N ESCOLAR ",position:[-31.4111117, -64.203063]},
  {category:"salud",type:"centro de d\u00eda",address:"ACONCAGUA N\u00b02164 B\u00b0 PARQUE CAPITAL, C\u00f3rdoba , Cordoba",name:"CENTRO DE D\u00cdA FUNDACI\u00d3N PADRE JOSE GABRIEL DEL ROSARIO BROCHERO",position:[-31.4354661, -64.21512]},
  {category:"salud",type:"centro de d\u00eda",address:"EDMUNDO MARIOTTE N\u00b0 5889 B\u00b0 VILLA BELGRANO, C\u00f3rdoba , Cordoba",name:"IGUALMENTE- CENTRO PRIVADO DE EST. TEMPRANA E INTEGRACI\u00d3N ESCOLAR",position:[-31.3561444, -64.25273059999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Manuel Pizarro 2225 - B\u00b0 Cerro de las Rosas, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA KRUPPAL",position:[-31.3641828, -64.2345244]},
  {category:"salud",type:"centro de d\u00eda",address:"DEL CARMEN 879, Villa Allende , Cordoba",name:"CENTRO DE ATENCION INTEGRAL PRIVADO-CENTRO DE APOYO A LA INTEGRACION ESCOLAR",position:[-31.2874558, -64.295752]},
  {category:"salud",type:"centro educativo",address:"25 de MAYO N\u00b0 376.PLANTA BAJA- A, C\u00f3rdoba , Cordoba",name:" centro de integraci\u00f3nES EDUCATIVAS PRIVADO",position:[0.0, 0.0]},
  {category:"salud",type:"fundaci\u00f3n",address:"BAIGORRI 986, C\u00f3rdoba , Cordoba",name:"FUNDADI CENTRO DE REHABILITACION CON INTEGRACION ESCOLAR",position:[-31.3911544, -64.1880071]},
  {category:"salud",type:"centro de rehabilitacion",address:"9 DE JULIO 758 ALBERDI, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACION E INTEGRACION ESCOLAR- FEMAR",position:[-31.4116137, -64.1937382]},
  {category:"salud",type:"centro educativo",address:"ITALIA N\u00b0 2176 B\u00b0 VILLA CABRERA, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO EDUCATIVO TERAPEUTICO NUESTROS PILARES",position:[-31.3851503, -64.217285]},
  {category:"salud",type:"centro de d\u00eda",address:"MATHEU N\u00b0 603 , Hernando , Cordoba",name:"CENTRO DE DIA MANOS A LA OBRA",position:[-32.4203977, -63.7330373]},
  {category:"salud",type:"centro de d\u00eda",address:"JIMENEZ DE LORCA N\u00b0 4316 B\u00b0 VILLA CENTENARIO, C\u00f3rdoba , Cordoba",name:" CATEPSI S.R.L",position:[-31.367304, -64.2326187]},
  {category:"salud",type:"centro de d\u00eda",address:"JUAN A MOLINA N\u00b0 4437 B\u00b0 POETA LUGONES , C\u00f3rdoba , Cordoba",name:" CATEPSI S.R.L",position:[-31.3610792, -64.2109904]},
  {category:"salud",type:"centro de rehabilitacion",address:"BENITO PEREZ GALDOS N\u00b0 2931, C\u00f3rdoba , Cordoba",name:"CENTRO PRIVADO DE REHABILITACION PSICOFISICA DRA. RAQUEL HEREDIA",position:[-31.3684754, -64.21236069999999]},
  {category:"salud",type:"centro de d\u00eda",address:"BAHIA BLANCA N\u00b0473 BARRIO JUNIORS, C\u00f3rdoba , Cordoba",name:"CENTRO DE DIA PSICOANALITICO N\u00b0 4",position:[-31.4184315, -64.17012129999999]},
  {category:"salud",type:"centro de d\u00eda",address:"JAIME DAVALOS S N VILLA CENTENARIO, Santa Rosa De Calamuchita , Cordoba",name:"C.A.S.E- CENTRO ASISTENCIAL PARA LA SALUD Y LA EDUCACION",position:[-32.071006, -64.5358835]},
  {category:"salud",type:"instituto",address:"GENERAL PAZ N\u00b0 192, San Francisco , Cordoba",name:"INS.DE REHABILITACI\u00d3N PRIVADO ALPI SAN FRANCISCO",position:[-31.4311793, -62.0888994]},
  {category:"salud",type:"centro de rehabilitacion",address:"ROSARIO DE SANTA FE N\u00b0773, C\u00f3rdoba , Cordoba",name:"INCLUIR CENTRO DE REHABILITACION INCLUSIVO de SAMEGER S.A.",position:[-31.4174267, -64.1718313]},
  {category:"salud",type:"centro educativo",address:"MAIPU 51 VILLA ZOILA, R\u00edo Tercero , Cordoba",name:"CENTRO DE APOYO A LA INTEGRACI\u00d3N ESCOLAR LOGRARES INTERDISCIPLINA",position:[-32.1820479, -64.1117273]},
  {category:"salud",type:"consultorio",address:"FLORENTINO AMEGHINO 311 B\u00b0 CENTRO, Monte Cristo , Cordoba",name:"HUEPIL PRESTACIONES MEDICO EDUCATIVAS",position:[-31.343339, -63.94603720000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"AV. JUAN B JUSTO N\u00b0 3627 ALTA CORDOBA, C\u00f3rdoba , Cordoba",name:"CENTRO INTEGRAL DE RECUPERACI\u00d3N DE LOA SALUD CIRS",position:[-31.3825554, -64.1762461]},
  {category:"salud",type:"centro de rehabilitacion",address:"AV. LAPLACE N\u00b0 5400 LOC. 1 DEP. 1 P.B, C\u00f3rdoba , Cordoba",name:"CIREHA- CENTRO INTERDISCIPLINARIO DE REHABILITACION",position:[-31.3604433, -64.2474177]},
  {category:"salud",type:"centro de rehabilitacion",address:"FERNANDO FADER N\u00b04071 B\u00b0 CERRO DE LAS ROSAS, C\u00f3rdoba , Cordoba",name:"CENTRO EDUCATIVO TERAPEUTICO Y APOYO A LA INTEGRACION ESCOLAR DE LA MANO",position:[-31.3731073, -64.23417119999999]},
  {category:"salud",type:"centro educativo",address:"CABRERA N\u00b0 946, R\u00edo Cuarto , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO FUNDACI\u00d3N DESAF\u00cdO",position:[-33.1252012, -64.35222929999999]},
  {category:"salud",type:"centro de d\u00eda",address:"SAN JUAN N\u00b01541 VILLA ALLENDE, Villa Allende , Cordoba",name:"CENTRO DE DIA PRIVADO IBOPE",position:[-31.2947833, -64.298733]},
  {category:"salud",type:"fundaci\u00f3n",address:"EL BALCON S N VERTIENTES DE LA GRANJA, La Granja , Cordoba",name:"FUNDACION DE DESARROLLO EDUC. Y ACCI\u00d3N SOCIAL cASA DE LUZ",position:[-31.0347544, -64.2743158]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. del Libertador 1794 , Alta Gracia , Cordoba",name:"ARBOL CENTRO DE REHABILITACI\u00d3N E INTEGRACI\u00d3N ESCOLAR de EN LA NUBE S.R.L.",position:[-31.6586818, -64.4125305]},
  {category:"salud",type:"centro de rehabilitacion",address:"Raymond Poincare 7175 - B\u00b0 Arguello, C\u00f3rdoba , Cordoba",name:"COMPLEJO PRIVADO DE REHABILITACI\u00d3N VIDA PLENA ARGUELLO de VANIN S.A.",position:[-31.3422737, -64.2614473]},
  {category:"salud",type:"centro de rehabilitacion",address:"Tucum\u00e1n 1195 - Barrio Cofico, C\u00f3rdoba , Cordoba",name:"CENTRO DE REHABILITACI\u00d3N FARFALINA de DRAGUA S.A.S.",position:[-31.402889, -64.1834547]},
  {category:"salud",type:"centro de d\u00eda",address:"Cavalango 4339 - B\u00b0 Empalme, C\u00f3rdoba , Cordoba",name:"CENTRO DE D\u00cdA SANTO TOM\u00c1S de ST SALUD S.A.S.",position:[-31.4340277, -64.1293774]},
  {category:"salud",type:"centro de rehabilitacion",address:"Andr\u00e9s M. Ampere 6368 - B\u00b0 Villa Belgrano, C\u00f3rdoba , Cordoba",name:"ORIGO CENTRO DE NEUROREHABILITACI\u00d3N CON INTEGRACION ESCOLAR",position:[-31.3501867, -64.2548233]},
  {category:"salud",type:"centro de rehabilitacion",address:"Hernando de Lerna 3383 - B\u00b0 Jard\u00edn Espinosa, C\u00f3rdoba , Cordoba",name:"NEUROESPACIO S.A.S.",position:[-31.45214519999999, -64.1734373]},
  {category:"salud",type:"centro educativo",address:"Sarmiento 546, Villa Dolores , Cordoba",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO CON SAIE, CENTRO DE DIA DE SALUD EDUCALIA",position:[-31.9514034, -65.19158879999999]},
  {category:"salud",type:"miscelaneo",address:"Paul Harris 829, R\u00edo Cuarto , Cordoba",name:"KAYNES S.A.",position:[-33.1012407, -64.34116999999999]},
  {category:"salud",type:"centro de d\u00eda",address:"RIVERA INDARTE N\u00b0 576, Villa Allende , Cordoba",name:"CENTRO INTEGRARTE S.A.S",position:[-31.2910142, -64.2935802]},
  {category:"salud",type:"miscelaneo",address:"NEMESIO GONZALES N \u00b0 82, Jes\u00fas Mar\u00eda , Cordoba",name:"GUANUSACATE SALUD S.A.",position:[-30.9781429, -64.0925378]},
  {category:"salud",type:"consultorio",address:"SARMIENTO N\u00b0 73, Almafuerte , Cordoba",name:"APRENDERES CONSULTORIOS INTERDISCIPLINARIOS Y APOYO A LA INT. ESCOLAR",position:[-32.1953807, -64.2558404]},
  {category:"salud",type:"asociaci\u00f3n",address:"DEAN FUNES 350- B\u00b0 SAN JOSE, Unquillo , Cordoba",name:"ASOCIACION CASA DEL NI\u00d1O",position:[-31.2320979, -64.3026478]},
  {category:"salud",type:"miscelaneo",address:"AV. BELGRANO 1071, Villa Dolores , Cordoba",name:"IOCARI RENACER S.R.L.",position:[-31.94716559999999, -65.1754409]},
  {category:"salud",type:"instituto",address:"AV. PABLO RICCHIERI 2378, B\u00b0 ROGELIO MARTINEZ , C\u00f3rdoba , Cordoba",name:"INENCIBA S.A.S",position:[-31.4390454, -64.1765915]},
  {category:"salud",type:"fundaci\u00f3n",address:"TIERRA DEL FUEGO 121, Tanti , Cordoba",name:"FUNDACION RITA BIANCHI",position:[-31.3550198, -64.5946862]},
  {category:"salud",type:"miscelaneo",address:"ADORATRICES N\u00b0898 B\u00b0 SAN ANTONIO, C\u00f3rdoba , Cordoba",name:"CASTELLO ROJO E HIJOS S.R.L",position:[-31.4454999, -64.2088437]},
  {category:"salud",type:"hogar",address:"Obispo Luis Niella S N\u00b0 (y Av. 25 de may, Itat\u00ed , Corrientes",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-27.2685668, -58.2435853]},
  {category:"salud",type:"instituto",address:"Chaco 1420 , Corrientes , Corrientes",name:"PADRE LEOPOLDO MANDIC - Inst. de Rehab. para Ni\u00f1os",position:[-27.4726806, -58.84755320000001]},
  {category:"salud",type:"instituto",address:"Estados Unidos 956 , Corrientes , Corrientes",name:"Instituto de Edeucacion Especial INTEGRAR ",position:[-27.4703943, -58.81371600000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Alberdi 1821 , Corrientes , Corrientes",name:"CENTRO DE NI\u00d1OS ESPECIALES ",position:[-27.4776842, -58.8445652]},
  {category:"salud",type:"miscelaneo",address:"Chacabuco 960, Corrientes , Corrientes",name:"INTEGRAR S.R.L.",position:[-27.4705274, -58.8126311]},
  {category:"salud",type:"consultorio",address:"Mariano I. Loza 815, Goya , Corrientes",name:"FONOAUDIOLOG\u00cdA INTEGRAL DE VILMA ALICIA HERRERA",position:[-29.1428287, -59.2687096]},
  {category:"salud",type:"instituto",address:"AV. ALBERDI 2059, Corrientes , Corrientes",name:"I.P.E.E.C S.R.L",position:[-27.4803438, -58.8449269]},
  {category:"salud",type:"miscelaneo",address:"SANTA FE 1560, Corrientes , Corrientes",name:"SEADI",position:[-27.4754108, -58.8314114]},
  {category:"salud",type:"centro de d\u00eda",address:"colon 1176, Goya , Corrientes",name:"Centro de Apoyo para la Integraci\u00f3n Escolar \"MANOS\" ",position:[-29.1467806, -59.2652071]},
  {category:"salud",type:"fundaci\u00f3n",address:"Calle Alejo Rueda 247- Ca\u00f1ada Quiroz, Corrientes , Corrientes",name:"Fundaci\u00f3n San Pio de Pietrelcina-Instituto de Atenci\u00f3n a la Diversidad San Diego",position:[-27.4868959, -58.8227199]},
  {category:"salud",type:"miscelaneo",address:"Sarmiento 274, Santa Lucia , Corrientes",name:"Peque\u00f1os Pasos",position:[-28.9906579, -59.1035282]},
  {category:"salud",type:"instituto",address:"Alejo Rueda 247, Corrientes , Corrientes",name:"\"San Diego\" Instituto Terap\u00e9utico de la Diversidad",position:[-27.4985777, -58.7176385]},
  {category:"salud",type:"centro educativo",address:"Jose Ramon Vidal 2159, Corrientes , Corrientes",name:"Centro Educativo Terap\u00e9utico \"ALAS\"",position:[-27.4822265, -58.8386483]},
  {category:"salud",type:"asociaci\u00f3n",address:"Angel Soto 977, Goya , Corrientes",name:"Asociaci\u00f3n para la Integraci\u00f3n de Personas Especiales A.P.I.P.E",position:[-29.1458128, -59.271667]},
  {category:"salud",type:"centro educativo",address:"Ferre N\u00ba 555, Bella Vista , Corrientes",name:"Centro Educativo Terap\u00e9utico \"COLORES\"",position:[-28.5051974, -59.0435829]},
  {category:"salud",type:"instituto",address:"Hipolito Yrigoyen 928, Corrientes , Corrientes",name:"Instituto Creando Lazos",position:[-27.4685736, -58.8389951]},
  {category:"salud",type:"instituto",address:"Avda. Alberdi 2099, Corrientes , Corrientes",name:"INSTITUTO DE ATENCI\u00d3N A LA DIVERSIDAD - IAD S.R.L.",position:[-27.4801829, -58.8447882]},
  {category:"salud",type:"instituto",address:"AVDA. ALBERDI 2093, Corrientes , Corrientes",name:"INSTITUTO DE ATENCI\u00d3N A LA DIVERSIDAD - IAD S.R.L.",position:[-27.4801618, -58.844786]},
  {category:"salud",type:"centro de rehabilitacion",address:"San Lorenzo N\u00ba 1.239, Corrientes , Corrientes",name:"CENTRO DE REHABILTACI\u00d3N PARA DISCAPACITADOS VISUALES \"VALENTIN HAUY\"",position:[-27.4713575, -58.8321602]},
  {category:"salud",type:"centro educativo",address:"Catamarca 1051 1055, Corrientes , Corrientes",name:"CET RINCON DE SUE\u00d1OS de NOELIA SOLEDAD KOLLY",position:[-27.468977, -58.8332065]},
  {category:"salud",type:"centro de d\u00eda",address:"Entre Rios 1.112, Corrientes , Corrientes",name:"C.L.A.M.P. CENTRO INTERDISCIPLINARIO S.R.L. ",position:[-27.4725755, -58.8446316]},
  {category:"salud",type:"fundaci\u00f3n",address:"Guayaquil 4031, Corrientes , Corrientes",name:"Fundaci\u00f3n Carita Feliz ",position:[-27.5123553, -58.8098439]},
  {category:"salud",type:"miscelaneo",address:"Catamarca 770, Corrientes , Corrientes",name:"ANALIA DEL CARMEN ARCE",position:[-27.466424, -58.8330999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Entre Rios 166, Goya , Corrientes",name:"Asociaci\u00f3n de ayuda para el discapacitado ADAPED ",position:[-29.1502286, -59.2611321]},
  {category:"salud",type:"centro educativo",address:"Av 3 de Abril 1633, Corrientes , Corrientes",name:"Centro Educativo Terap\u00e9utico Yo Puedo ",position:[-27.4763067, -58.8308331]},
  {category:"salud",type:"centro de d\u00eda",address:"Lamadrid 1431, Corrientes , Corrientes",name:"Centro Superar SAS",position:[-27.4820252, -58.8342968]},
  {category:"salud",type:"fundaci\u00f3n",address:"Quintana1339, Corrientes , Corrientes",name:"Fundaci\u00f3n Progresando Juntos ",position:[-27.4692131, -58.8306349]},
  {category:"salud",type:"escuela",address:"25 DE MAYO 627, Corrientes , Corrientes",name:"ESCUELA PARA NI\u00d1OS SORDOS E HIPOACUSICOSCORRIENTES ORAL",position:[-27.463529, -58.84235109999999]},
  {category:"salud",type:"miscelaneo",address:"Belgrano 1260, Corrientes , Corrientes",name:"CENTRO ESENCIA \"LOS ABUELOS SRL\"",position:[-27.4722776, -58.8353909]},
  {category:"salud",type:"instituto",address:"Abigail Pintos, Curuzu Cuatia , Corrientes",name:"Instituto de Apoyo Integral para Personas Especiales ",position:[-29.7854147, -58.0563211]},
  {category:"salud",type:"miscelaneo",address:"BOLIVAR 2486, Corrientes , Corrientes",name:"CURIOSAMENTE S.R.L.",position:[-27.4727881, -58.81967739999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"25 de mayo 740, Saladas , Corrientes",name:"FUNDACION SENDAS",position:[-28.254175, -58.6236587]},
  {category:"salud",type:"miscelaneo",address:"AV.EL MAESTRO N\u00b02375, Corrientes , Corrientes",name:"CRECER CON TODOS S.R.L",position:[-27.4922582, -58.82631180000001]},
  {category:"salud",type:"centro de d\u00eda",address:"9 DE JULIO 236, Corrientes , Corrientes",name:"EL PUENTE de LETAPEL S.A.S.",position:[-27.4657252, -58.8475232]},
  {category:"salud",type:"centro de d\u00eda",address:"CORDOBA 2342, Corrientes , Corrientes",name:"CENTRO ACTIVAMENTE S.R.L",position:[-27.4755532, -58.83564519999999]},
  {category:"salud",type:"miscelaneo",address:"SARGENTO CABRAL 1236, Saladas , Corrientes",name:"BORGET MARIA INES ",position:[-28.2538893, -58.6225756]},
  {category:"salud",type:"hogar",address:"Ovidio Lagos N\u00ba 1068 , Paran\u00e1 , Entre Rios",name:"HOGAR PARA IMPEDIDOS SAN CAMILO DE LELLIS",position:[-31.7598064, -60.4891413]},
  {category:"salud",type:"asociaci\u00f3n",address:"Lebensohn N\u00ba 5630, Paran\u00e1 , Entre Rios",name:"APANA- Asociaci\u00f3n de Padres y Amigos de Ni\u00f1os y Adolescentes Aminorados",position:[-31.7892355, -60.5339399]},
  {category:"salud",type:"asociaci\u00f3n",address:"25 de Junio N\u00ba 527 , Paran\u00e1 , Entre Rios",name:"APANA- Asociaci\u00f3n de Padres y Amigos de Ni\u00f1os y Adolescentes Aminorados",position:[-31.7288284, -60.5372389]},
  {category:"salud",type:"centro terapeutico",address:"Av. Roberto Uncal 782 , Concepci\u00f3n del Uruguay , Entre Rios",name:"La Casita - Centro Terapeutico ",position:[-32.4865287, -58.2341607]},
  {category:"salud",type:"instituto",address:"Bolivar 635  Pellegrini 163 , Gualeguaych\u00fa , Entre Rios",name:"I.D.E.E.A ",position:[-33.0111039, -58.51236309999999]},
  {category:"salud",type:"hogar",address:"Barrio San Bernardo , San Jos\u00e9 , Entre Rios",name:"HO.DI.M.A. Hogar para Disc. Mentales Adultos ",position:[-32.1808462, -58.1873115]},
  {category:"salud",type:"asociaci\u00f3n",address:"25 de Mayo N\u00ba 228, Gualeguaych\u00fa , Entre Rios",name:"Asociaci\u00f3n S\u00edndrome de Down Gualeguaych\u00fa",position:[-33.0090997, -58.5052884]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia N\u00ba 546 , Gualeguaych\u00fa , Entre Rios",name:"Asociaci\u00f3n Santa Rita ",position:[-33.0071018, -58.5108396]},
  {category:"salud",type:"centro de d\u00eda",address:"Paysandu N\u00ba 267 , Col\u00f3n , Entre Rios",name:"EL SOLAR COLON S.R.L. ",position:[-32.2145599, -58.14500649999999]},
  {category:"salud",type:"miscelaneo",address:"3 de Febrero 974 esq. Chacabuco , Diamante , Entre Rios",name:"SI.SA.ME S.R.L. ",position:[-32.0725873, -60.63127710000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Hernandarias N\u00ba 398, General Galarza, Entre Rios",name:"RECUP S.A.",position:[-32.7155857, -59.3924495]},
  {category:"salud",type:"asociaci\u00f3n",address:"Maip\u00fa N\u00ba 522, Paran\u00e1 , Entre Rios",name:"ASOCIACI\u00d3N OL\u00cdMPICA ESPECIAL",position:[-31.7453376, -60.5274362]},
  {category:"salud",type:"asociaci\u00f3n",address:"La Rioja N\u00ba 134, Paran\u00e1 , Entre Rios",name:"As.Pa.SiD - Asociaci\u00f3n Paranaense de S\u00edndrome de Down y otras Discapacidades ",position:[-31.7331491, -60.52353799999999]},
  {category:"salud",type:"escuela",address:"Bernardo de Irigoyen N\u00ba 476, Concordia , Entre Rios",name:"ESCALA S.H.",position:[-31.3961917, -58.0285498]},
  {category:"salud",type:"miscelaneo",address:"JOSE MARIA PAZ N\u00b0 4480, Paran\u00e1 , Entre Rios",name:"CONGREGACION SIERVAS DE LA DIVINA PROVIDENCIA",position:[-31.7410688, -60.5412196]},
  {category:"salud",type:"centro de d\u00eda",address:"Roque Saenz Pe\u00f1a N\u00b0 880, Urdinarrain, Entre Rios",name:"CENTRO URDINARRAIN DE AYUDA AL DISCAPACITADO",position:[-32.6878198, -58.8820656]},
  {category:"salud",type:"asociaci\u00f3n",address:"Alf\u00e9rez Sobral y P\u00fablica, Gualeguaych\u00fa , Entre Rios",name:"Asociaci\u00f3n Emanuel ",position:[-33.0077778, -58.5111667]},
  {category:"salud",type:"centro de d\u00eda",address:"San Mart\u00edn N\u00ba 91, Col\u00f3n , Entre Rios",name:"Centro Santa Ana S.R.L.",position:[-32.2182662, -58.1360292]},
  {category:"salud",type:"instituto",address:"G\u00fcemes N\u00ba 111, Concordia , Entre Rios",name:"SER Instituto Interdisciplinario de Rehabilitaci\u00f3n",position:[-31.3926535, -58.0202087]},
  {category:"salud",type:"asociaci\u00f3n",address:"Concordia N\u00ba 225, Villaguay , Entre Rios",name:"AS.ED.I.P.P.D.",position:[-31.8719853, -59.0308539]},
  {category:"salud",type:"asociaci\u00f3n",address:"San Mart\u00edn N\u00ba 440, Paran\u00e1 , Entre Rios",name:"ASOCIACION CIVIL MUNDO JOVEN",position:[-31.7234865, -60.535515]},
  {category:"salud",type:"instituto",address:"Espino 58, Concordia , Entre Rios",name:"ITER Concordia  Instituto Terapeutico de Estimulacion y Rehabilitci\u00f3n de Concor",position:[-31.4013539, -58.0184753]},
  {category:"salud",type:"asociaci\u00f3n",address:"Dr. Lacava N\u00ba 140, Concepci\u00f3n del Uruguay , Entre Rios",name:"ASDCU - Asociaci\u00f3n S\u00edndrome de Down Concepci\u00f3n del Uruguay",position:[-32.48611, -58.2529809]},
  {category:"salud",type:" cooperativa",address:"Urquiza N\u00ba 475, Urdinarrain, Entre Rios",name:"COOPERADORA DE LA ESCUELA PRIVADA DE EDUCACI\u00d3N INTEGRAL N\u00ba 13 \"ARCO IRIS\"",position:[-32.690373, -58.89421129999999]},
  {category:"salud",type:" cooperativa",address:"Pbro. Kaul y L.N. Alem, Urdinarrain, Entre Rios",name:"COOPERADORA DE LA ESCUELA PRIVADA DE EDUCACI\u00d3N INTEGRAL N\u00ba 13 \"ARCO IRIS\"",position:[-32.6919519, -58.886491]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. de las Am\u00e9ricas N\u00ba 4203, Paran\u00e1 , Entre Rios",name:"Centro Integral de Rehabilitaci\u00f3n Neurol\u00f3gica de Entre R\u00edos",position:[-31.77817869999999, -60.51929759999999]},
  {category:"salud",type:"miscelaneo",address:"Av. Zanni N\u00ba 1330, Paran\u00e1 , Entre Rios",name:"Raffo Norma Susana y Basso Gabriela Teresita ",position:[-31.7619603, -60.49858740000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Urquiza N\u00ba 510, Concordia , Entre Rios",name:"CENTRO DE DIA Y ESTIMULACION TEMPRANA CONCORDIA",position:[-31.3993879, -58.01833929999999]},
  {category:"salud",type:"escuela",address:"Secci\u00f3n Quintas, Calle 117 S N, Gualeguay , Entre Rios",name:"Lucecitas Escuela de Educaci\u00f3n Especial",position:[-33.1147332, -59.3012322]},
  {category:"salud",type:"centro educativo",address:"Salta N\u00ba 82, Gualeguay , Entre Rios",name:"Lucecitas Escuela de Educaci\u00f3n Especial",position:[-33.1485395, -59.31651979999999]},
  {category:"salud",type:"miscelaneo",address:"Monza y Gorgonzola (Colonia Belgrano), CHAJARI, Entre Rios",name:"PIZZIO, Andrea Isabel",position:[-31.9096388, -61.4020679]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia N\u00ba 2485, Gualeguaych\u00fa , Entre Rios",name:"ALIUM SRL",position:[-33.0084182, -58.54626940000001]},
  {category:"salud",type:"miscelaneo",address:"Alem N\u00ba 120, Concepci\u00f3n del Uruguay , Entre Rios",name:"El Solar Uruguay ",position:[-32.4867589, -58.2341738]},
  {category:"salud",type:"centro de rehabilitacion",address:"Catamarca N\u00ba 217, Concordia , Entre Rios",name:"RENI S.R.L.",position:[-31.3958046, -58.013789]},
  {category:"salud",type:"miscelaneo",address:"Rivadavia N\u00ba 2760, CHAJARI, Entre Rios",name:"PERCARA, Rosana Lorena",position:[-30.7565111, -57.9834183]},
  {category:"salud",type:"asociaci\u00f3n",address:"Espa\u00f1a N\u00ba 293, Paran\u00e1 , Entre Rios",name:"Juntos Andar Asociaci\u00f3n Civil",position:[-31.7319118, -60.53462099999999]},
  {category:"salud",type:"instituto",address:"Luis Rodriguez N\u00ba 2340, Concepci\u00f3n del Uruguay , Entre Rios",name:"Instituto de Rehabilitaci\u00f3n Neurol\u00f3gica Alfredo Thompson (AReNe)",position:[-32.4786423, -58.26493660000001]},
  {category:"salud",type:"hogar",address:"Catamarca N\u00ba 645, CRESPO, Entre Rios",name:"Hogar Nuevo Amanecer",position:[-32.0239145, -60.3138477]},
  {category:"salud",type:"miscelaneo",address:"Bolivia N\u00ba 917, Concordia , Entre Rios",name:"NAVARRO JAURENA, Alejandro Maximiliano",position:[-31.393638, -58.00729519999999]},
  {category:"salud",type:"miscelaneo",address:"Padre Londero 2947, Paran\u00e1 , Entre Rios",name:"Aprendiendo a Ser II ",position:[-31.7283942, -60.4560861]},
  {category:"salud",type:"miscelaneo",address:"Posadas 2000, Concepci\u00f3n del Uruguay , Entre Rios",name:"Blanco Liliana Corazzini Sergio Corazzini Lucas",position:[-32.486099, -58.256736]},
  {category:"salud",type:" union",address:"9 de Julio N\u00ba 39, Victoria , Entre Rios",name:"\u201cUPALA\u201d Servicio de Apoyo a la Integraci\u00f3n Escolar ",position:[-32.621011, -60.15529799999999]},
  {category:"salud",type:"miscelaneo",address:"1ro de Mayo N\u00ba 645, FEDERAL, Entre Rios",name:"Los Cardales SRL",position:[-30.9512012, -58.7849549]},
  {category:"salud",type:"instituto",address:"Bulevard Concordia N\u00ba 50, SAN SALVADOR, Entre Rios",name:"Instituto de abordaje para la diversidad funcional - IADIF",position:[-31.6236544, -58.5045222]},
  {category:"salud",type:"asociaci\u00f3n",address:"Ruperto P\u00e9rez N\u00ba 524 526, Paran\u00e1 , Entre Rios",name:"Asociaci\u00f3n Cooperadora San Francisco de As\u00eds",position:[-31.7505227, -60.5165471]},
  {category:"salud",type:"asociaci\u00f3n",address:"Elena Raffo N\u00ba 140, Paran\u00e1 , Entre Rios",name:"Asociaci\u00f3n Cooperadora San Francisco de As\u00eds",position:[-31.7413197, -60.51154709999999]},
  {category:"salud",type:"centro de d\u00eda",address:"MEXICO 392, Paran\u00e1 , Entre Rios",name:"CENTRO INTEGRAL DE NEUROREHABILITACION LOS ALAMOS S.R.L",position:[-31.7258453, -60.53333680000001]},
  {category:"salud",type:"escuela",address:"JOSE MARIA PAZ 4480, Paran\u00e1 , Entre Rios",name:"ESCUELA PRIVADA DE EDUCACION ESPECIAL N19 NUESTRA SE\u00d1ORA DE LA DIVINA PROVIDENCI",position:[-31.7410688, -60.5412196]},
  {category:"salud",type:"centro de d\u00eda",address:"Fontana 1179 , Formosa, Formosa",name:"C.R.I. FORMOSA ",position:[-26.188933, -58.17158299999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"BELGRANO 55, Formosa, Formosa",name:"CENTRO DE REHAB. INTEGRAL FORMOSA - CRIF S.R.L.",position:[-26.174638, -58.16844829999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Belgrano 55, Formosa, Formosa",name:"CENTRO DE REHAB. INTEGRAL FORMOSA - CRIF S.R.L.",position:[-26.174638, -58.16844829999999]},
  {category:"salud",type:"centro de d\u00eda",address:"HIPOLITO IRIGOYEN 860, El Colorado , Formosa",name:"INCLUIR NEA S.R.L.",position:[-26.3098321, -59.3750782]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ruggero 417, Formosa, Formosa",name:"FUNDACI\u00d3N UNIENDO HUELLAS",position:[-26.1857768, -58.1755669]},
  {category:"salud",type:"instituto",address:"BELGRANO 535, Formosa, Formosa",name:"INSTITUTO TERAPEUTICO EDUCATIVO",position:[-26.1795055, -58.16644640000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"AVENIDA SANTIBA\u00d1EZ 1230, San Salvador de Jujuy , Jujuy",name:"APPACE ",position:[-24.1806357, -65.31049999999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mej\u00edas 615 B\u00ba Malvinas Argentinas , San Salvador de Jujuy , Jujuy",name:"APPACE ",position:[-24.2096706, -65.2656025]},
  {category:"salud",type:"asociaci\u00f3n",address:"Selin Issa 378 B\u00b0 Bajo La Vi\u00f1a , San Salvador de Jujuy , Jujuy",name:"APPACE ",position:[-24.1744659, -65.289293]},
  {category:"salud",type:"fundaci\u00f3n",address:"Carrizo 680, Manuel Belgrano , Jujuy",name:"FUNDACION SENTIR",position:[-24.1801497, -65.3172723]},
  {category:"salud",type:"fundaci\u00f3n",address:"CLUB DE CAMPO ATALAYA, San Antonio , Jujuy",name:"FUNDACION SENTIR",position:[-24.2796394, -65.2758307]},
  {category:"salud",type:"fundaci\u00f3n",address:"Las heras 855, Manuel Belgrano , Jujuy",name:"FUNDACION SENTIR",position:[-24.1991398, -65.287633]},
  {category:"salud",type:"fundaci\u00f3n",address:"Independencia N\u00b0 1191, San Salvador de Jujuy , Jujuy",name:"FUNDACION SENTIR",position:[-24.1857864, -65.2994767]},
  {category:"salud",type:"fundaci\u00f3n",address:"Independencia N\u00ba 1122, San Salvador de Jujuy , Jujuy",name:"FUNDACION SENTIR",position:[-24.1857864, -65.2994767]},
  {category:"salud",type:" cooperativa",address:"Av. Crucero General Belgrano esq. 25 de , Monterrico , Jujuy",name:"Coooperativa de Trabajo Divino Ni\u00f1o ",position:[-24.4429822, -65.1663478]},
  {category:"salud",type:"asociaci\u00f3n",address:"Arist\u00f3bulo del Valle N\u00ba 460 , San Pedro De Jujuy , Jujuy",name:"ASOCIACION CAMINEMOS JUNTOS ",position:[-24.2340778, -64.8658734]},
  {category:"salud",type:"fundaci\u00f3n",address:"Hip\u00f3lito Irigoyen 1540, Manuel Belgrano , Jujuy",name:"FUNDACION IDEAS ",position:[-24.1904397, -65.3098187]},
  {category:"salud",type:"fundaci\u00f3n",address:"Jose de la Iglesia N\u00ba 1282 - B\u00ba Cuyaya , San Salvador de Jujuy , Jujuy",name:"FUNDACION IDEAS ",position:[-24.1909805, -65.3060471]},
  {category:"salud",type:"asociaci\u00f3n",address:"Av. Corrientes 3070, San Salvador de Jujuy , Jujuy",name:"AMR S.R.L.",position:[-24.2154228, -65.27243299999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"BELGRANO 1351, San Salvador de Jujuy , Jujuy",name:"ASOCIACION TODOS JUNTOS",position:[-24.1855814, -65.3117524]},
  {category:"salud",type:"centro de rehabilitacion",address:"San Martin 210, San Salvador de Jujuy , Jujuy",name:"FIDES NEUROCARDIOVASCULAR SRL",position:[-24.1863654, -65.2962311]},
  {category:"salud",type:"asociaci\u00f3n",address:"AV. 25 DE MAYO N\u00b0 464, San Pedro De Jujuy , Jujuy",name:"AS.P.E.R. (ASOCIACION PARA LA ESTIMULACION Y REHABILITACION)",position:[-24.2401983, -64.8678943]},
  {category:"salud",type:"centro de d\u00eda",address:"ANTONIO PALEARI 274, San Salvador de Jujuy , Jujuy",name:"CENTRO SAN NICOLAS",position:[-24.1770685, -65.28286729999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Jos\u00e9 Luro 169 , Santa Rosa , La Pampa",name:"C.E.T. de Mirtha Noemi Portalez ",position:[-36.6277735, -64.3027101]},
  {category:"salud",type:"miscelaneo",address:"Santiago Alvarez 1503 , Santa Rosa , La Pampa",name:"CRIANZA ",position:[-36.6402666, -64.2926415]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 7 Nro.956 , General Pico , La Pampa",name:"APRODIS Asoc. Pro-Ayuda al Discapacitado ",position:[-35.6593239, -63.75778869999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 112 N\u00ba 606 , General Pico , La Pampa",name:"A.P.A.P. ",position:[-35.6709612, -63.7691416]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 311 N\u00ba13 , General Pico , La Pampa",name:"A.P.A.P. ",position:[-35.6679105, -63.781187]},
  {category:"salud",type:"hogar",address:"Roque Saenz Pe\u00f1a 258 , Toay , La Pampa",name:"Hogar A.D.I.S. (Asociaci\u00f3n de Integraci\u00f3n Social) ",position:[-36.6666323, -64.3694206]},
  {category:"salud",type:"centro de rehabilitacion",address:"Roque Pe\u00f1a 1314 , Santa Rosa , La Pampa",name:"Centro de Rehabilitacion KINE ",position:[-36.6303229, -64.28393179999999]},
  {category:"salud",type:"miscelaneo",address:"Pasteur 1248, Santa Rosa , La Pampa",name:"SOLAR S.R.L. \"Un lugar para crecer\"",position:[-36.6356305, -64.293127]},
  {category:"salud",type:"centro de d\u00eda",address:"Olascoaga N\u00b0 726, Santa Rosa , La Pampa",name:"CIAPI",position:[-36.620922, -64.2912369]},
  {category:"salud",type:"centro de d\u00eda",address:"Belgrano N\u00b0 555, Intendente Alvear , La Pampa",name:"Juntos y Unidos Podemos- Centro de D\u00eda",position:[-35.2398593, -63.5904579]},
  {category:"salud",type:"centro educativo",address:"Leandro N. Alem 603, Santa Rosa , La Pampa",name:"APREHENDER CENTRO DE FORTALECIMIENTO EDUCATIVO",position:[-36.6230972, -64.2834305]},
  {category:"salud",type:"centro educativo",address:"Calle 21 N\u00b0 2599, General Pico , La Pampa",name:"Centro Educativo Terap\u00e9utico con integraci\u00f3n escolar \"De Tu Mano\" ",position:[-35.6593239, -63.75778869999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Ra\u00fal B. Diaz y Pilcomayo.-, Santa Rosa , La Pampa",name:"Servicio de Rehabilitaci\u00f3n - Hospital \"Dr. Lucio MOLAS\"",position:[-36.5995953, -64.2897812]},
  {category:"salud",type:"centro de d\u00eda",address:"Quintana 444, Santa Rosa , La Pampa",name:"PUENTES - CENTRO DE APOYO A LA INCLUSI\u00d3N EDUCATIVA",position:[-36.620922, -64.2912369]},
  {category:"salud",type:"centro de d\u00eda",address:"Wilde1735, Santa Rosa , La Pampa",name:"CENTRO DE ESTIMULACION TEMPRANA OIRES de la ASOCIACION CIVIL OIRES",position:[-36.620922, -64.2912369]},
  {category:"salud",type:"miscelaneo",address:"Lamadrid 178 , La Rioja , La Rioja",name:"NUESTRA CASA ",position:[-29.4110241, -66.85419759999999]},
  {category:"salud",type:"centro educativo",address:"San Nicolas de Bari (O) 1316, La Rioja , La Rioja",name:"CET \"SANTA ANA\"",position:[-29.40946959999999, -66.82500999999999]},
  {category:"salud",type:"centro educativo",address:"Azteca 938 B. Juan F. Quiroga , La Rioja (Capital) , La Rioja",name:"CET \"SANTA ANA\"",position:[-29.4120913, -66.8499495]},
  {category:"salud",type:"miscelaneo",address:"Dalmacio Velez Sarfield 271, La Rioja , La Rioja",name:"TODO PARA ELLOS",position:[-29.4169423, -66.8671739]},
  {category:"salud",type:"centro de d\u00eda",address:"Viamonte 1967, La Rioja , La Rioja",name:"CENTRO DE DIA AMADO FELICIANO ROMERO",position:[-29.4345417, -66.851433]},
  {category:"salud",type:"centro de d\u00eda",address:"viamonte 1967 barrio federacion , La Rioja (Capital) , La Rioja",name:"CENTRO DE DIA AMADO FELICIANO ROMERO",position:[-29.431941, -66.851707]},
  {category:"salud",type:"fundaci\u00f3n",address:"Cleofe arias n\u00b0 460 B\u00b0 Federacion , La Rioja (Capital) , La Rioja",name:"CENTRO UNKAY de FUNDACION INTEGRAR",position:[-29.4360514, -66.8532792]},
  {category:"salud",type:"fundaci\u00f3n",address:"Juan Bautista Alberdi 159, La Rioja , La Rioja",name:"CENTRO UNKAY de FUNDACION INTEGRAR",position:[-29.4089168, -66.85051640000002]},
  {category:"salud",type:"miscelaneo",address:"MATE DE LUNA S N B\u00b0 SOLAR DEL NORTE , La Rioja (Capital) , La Rioja",name:"CRISOL de HUAINO S.R.L.",position:[-29.6192563, -66.4271499]},
  {category:"salud",type:"fundaci\u00f3n",address:"Santa Fe 761, La Rioja (Capital) , La Rioja",name:"FUNDACION EN CAMINO",position:[-29.4172191, -66.8583447]},
  {category:"salud",type:"instituto",address:"colon 380, La Rioja (Capital) , La Rioja",name:"INSTITUTO \"MARIA DE LA PAZ\"",position:[-29.4026704, -66.8425496]},
  {category:"salud",type:"centro de rehabilitacion",address:"Av. Angel V. Pe\u00f1alos N\u00b0 468, La Rioja (Capital) , La Rioja",name:"NEUROLAR",position:[-29.6192563, -66.4271499]},
  {category:"salud",type:"fundaci\u00f3n",address:"JUJUY 162, La Rioja (Capital) , La Rioja",name:"Fundacion un Mundo para los Ni\u00f1os",position:[-29.4119882, -66.8610377]},
  {category:"salud",type:"centro de rehabilitacion",address:"ADOLFO E. DAVILA 224, La Rioja (Capital) , La Rioja",name:"CENTRO DE REHABLILTACION Y SERVICO DE ESTIMULACION TEMPRANA \" REHABILITARTE \"",position:[-29.4160078, -66.8532349]},
  {category:"salud",type:"centro de rehabilitacion",address:"ADOLFO E. DAVILA N\u00b0 224 BARRIO CENTRO, La Rioja (Capital) , La Rioja",name:"CENTRO DE REHABLILTACION Y SERVICO DE ESTIMULACION TEMPRANA \" REHABILITARTE \"",position:[-29.4160078, -66.8532349]},
  {category:"salud",type:"centro de rehabilitacion",address:"adolfo e. davila 224, La Rioja (Capital) , La Rioja",name:"CENTRO DE REHABLILTACION Y SERVICO DE ESTIMULACION TEMPRANA \" REHABILITARTE \"",position:[-29.4160078, -66.8532349]},
  {category:"salud",type:"centro de rehabilitacion",address:"CALLE ARTIGAS 511 B\u00b0 EVITA, La Rioja (Capital) , La Rioja",name:"CENTRO INTEGRAL \" SENTIDOS \"",position:[-29.4134538, -66.8564579]},
  {category:"salud",type:"centro de rehabilitacion",address:"CALLE ARTIGAS 511 B\u00b0 EVITA , La Rioja (Capital) , La Rioja",name:"CENTRO INTEGRAL \" SENTIDOS \"",position:[-29.4134538, -66.8564579]},
  {category:"salud",type:"miscelaneo",address:"AVENIDAS LAS PAMERAS 2785 BARRIO SOLARES DEL GOL, La Rioja , La Rioja",name:"CUATRO ESQUINITAS",position:[-29.43636, -66.8951225]},
  {category:"salud",type:"centro educativo",address:"Julian Amate 65 , Chilecito , La Rioja",name:"Centro Educativo Terapeutico \" KAIROS\"",position:[-29.1630426, -67.4923508]},
  {category:"salud",type:"miscelaneo",address:"8 DE DICIEMBRE N\u00b070, La Rioja (Capital) , La Rioja",name:"OFEMA S.RL ",position:[-29.4719324, -66.88716819999999]},
  {category:"salud",type:"miscelaneo",address:"santa fe 1394, La Rioja (Capital) , La Rioja",name:"SANTA ANGELA DE MERICI",position:[-29.4185574, -66.8678726]},
  {category:"salud",type:"hogar",address:"San Mart\u00edn 8317, Luj\u00e1n de Cuyo , Mendoza",name:"THADI - TALLER HOGAR ACTIVIDADES DIFERENCIALES",position:[-32.9603744, -68.8526289]},
  {category:"salud",type:"miscelaneo",address:"San Ram\u00f3n S N - El Borboll\u00f3n, Las Heras , Mendoza",name:"GRUPO VIDA NATURAL S.R.L. ",position:[-32.8121647, -68.7657248]},
  {category:"salud",type:"hogar",address:"Pedro Vargas 631, San Mart\u00edn , Mendoza",name:"HISDIM S.R.L.",position:[-33.0709303, -68.4591191]},
  {category:"salud",type:"asociaci\u00f3n",address:"Montecaseros 2670 , Mendoza , Mendoza",name:"AMAD - ASOCIACION MENDOCINA DE ACTIVIDADES PARA DISCAPACITADOS",position:[-32.8722471, -68.8307957]},
  {category:"salud",type:"asociaci\u00f3n",address:"Perito Moreno 845 , Godoy Cruz , Mendoza",name:"APNA - ASOCIACION PRO AYUDA AL NI\u00d1O ATIPICO ",position:[-32.9339861, -68.8464448]},
  {category:"salud",type:"instituto",address:"Presidente Derqui 193 , Godoy Cruz , Mendoza",name:"INSTITUTO EINNO ",position:[-32.9174497, -68.8491927]},
  {category:"salud",type:"instituto",address:"Alsina 1250 , Guaymallen , Mendoza",name:"INSTITUTO TERAPEUTICO NARANJITO S.R.L. ",position:[-32.9181389, -68.8259345]},
  {category:"salud",type:"instituto",address:"Alfonsina Storni 309, Guaymallen , Mendoza",name:"IRID - INSTITUTO DE REHABILITACION INTEGRAL DEL DISCAPACITADO",position:[-32.9199665, -68.8583474]},
  {category:"salud",type:"instituto",address:"Sarmiento 1200 , Rivadavia , Mendoza",name:"IRID - INSTITUTO DE REHABILITACION INTEGRAL DEL DISCAPACITADO",position:[-33.187794, -68.4643721]},
  {category:"salud",type:"miscelaneo",address:"Velez Sarsfield 2478, Maip\u00fa , Mendoza",name:"SIN FRONTERAS S.R.L.",position:[-32.9565635, -68.831647]},
  {category:"salud",type:"miscelaneo",address:"Pablo Pescara 482 , Maip\u00fa , Mendoza",name:"SIN FRONTERAS S.R.L.",position:[-32.9805667, -68.786385]},
  {category:"salud",type:"miscelaneo",address:"El Toledano Norte 3595 , San Rafael , Mendoza",name:"SOL DEL ESTE ",position:[-34.5721694, -68.3669778]},
  {category:"salud",type:"asociaci\u00f3n",address:"Armani 1120 , Godoy Cruz , Mendoza",name:"ASOCIACION ESPERANZA ",position:[-32.9148788, -68.8571336]},
  {category:"salud",type:"centro de d\u00eda",address:"9 de Julio 2160 , Mendoza , Mendoza",name:"CPEI de INES BEATRIZ MATAR",position:[-32.8777676, -68.8375928]},
  {category:"salud",type:"centro de d\u00eda",address:"Castelli 125 , San Rafael , Mendoza",name:"CITEA",position:[-34.6117874, -68.3380032]},
  {category:"salud",type:"miscelaneo",address:"Republica Siria 3454 , Guaymallen , Mendoza",name:"KHUSKA",position:[-32.8994345, -68.7960042]},
  {category:"salud",type:"instituto",address:"Juan de Dios Videla 249 , Mendoza , Mendoza",name:"INSTITUTO NEWEN S.A. ",position:[-32.8801566, -68.8511353]},
  {category:"salud",type:"centro de d\u00eda",address:"Ruta 60 y Calle Lencinas s n. Junin , Rivadavia , Mendoza",name:"CENTRO DE DIA CRECIENDO S.A.",position:[-33.1909479, -68.4621744]},
  {category:"salud",type:"asociaci\u00f3n",address:"Sargento Cabral 151 , Guaymallen , Mendoza",name:"AMEM - ASOCIACION MENDOCINA DE ESCLEROSIS MULTIPLE ",position:[-32.9089936, -68.83640299999999]},
  {category:"salud",type:"miscelaneo",address:"Joaqu\u00edn V. Gonz\u00e1lez 1547 , Godoy Cruz , Mendoza",name:"RUCA CAHUIN de SANTILLI ELISA",position:[-32.940143, -68.8582182]},
  {category:"salud",type:"centro de d\u00eda",address:"Francisco Amigorena 755 , Malargue , Mendoza",name:"RE-CREAR de MARGIOTTA REBECA ELISA ",position:[-35.4738534, -69.5794638]},
  {category:"salud",type:"instituto",address:"Alvarez Condarco 1237, Godoy Cruz , Mendoza",name:"IPSI 44 PE ",position:[-32.9131868, -68.8576692]},
  {category:"salud",type:"centro de rehabilitacion",address:"Primitivo de la Reta 555 , Mendoza , Mendoza",name:"CERIN - CENTRO DE REHABILITACION INTERDISCIPLINARIO",position:[-32.8968752, -68.8393189]},
  {category:"salud",type:"centro de d\u00eda",address:"Guardia Vieja 2711 , Luj\u00e1n de Cuyo , Mendoza",name:"CENTRO DE DIA PROGRESAR de ALEBRIEL S.R.L. ",position:[-33.0074513, -68.91000629999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Jose Federico Moreno 3087, Mendoza , Mendoza",name:"APANDO - ASOCIACION DE PADRES DE NI\u00d1OS DOWN ",position:[-32.86771960000001, -68.8315875]},
  {category:"salud",type:"centro de rehabilitacion",address:"Dr. Pablo Erlich 168, Godoy Cruz , Mendoza",name:"CENID - CENTRO INTEGRAL DE ATENCION A LA PERSONA CON DISCAPACIDAD S.A.",position:[-32.9146156, -68.843138]},
  {category:"salud",type:"miscelaneo",address:"Sarmiento 3252, San Rafael , Mendoza",name:"TU LUGAR S.R.L.",position:[-34.6144146, -68.3687628]},
  {category:"salud",type:"consultorio",address:"Jean Jaures 233, Godoy Cruz , Mendoza",name:"FRAK KINESIO S.A. \"CER\"",position:[-32.91771500000001, -68.84379009999999]},
  {category:"salud",type:"miscelaneo",address:"Colon 148, San Rafael , Mendoza",name:"REFUGIO DEL SUR S.R.L.",position:[-34.6203962, -68.3229329]},
  {category:"salud",type:"fundaci\u00f3n",address:"Ituzaingo 1200, Godoy Cruz , Mendoza",name:"FUNDACION CONSENTIDOS",position:[-32.9401047, -68.8613486]},
  {category:"salud",type:"miscelaneo",address:"Lavalle 537, Mendoza , Mendoza",name:"PIVA S.A. ",position:[-32.8890016, -68.8308964]},
  {category:"salud",type:"centro de d\u00eda",address:"Jose Nestor Lencinas 855, San Rafael , Mendoza",name:"CAI ASISTENCIA S.A.",position:[-34.6020313, -68.3415787]},
  {category:"salud",type:"centro de d\u00eda",address:"GRAL. MANUEL DORREGO 506, San Rafael , Mendoza",name:"CAI ASISTENCIA S.A.",position:[-34.6060613, -68.34193789999999]},
  {category:"salud",type:"miscelaneo",address:"Coronel Plaza 745, Mendoza , Mendoza",name:"ANDAR de SARMIENTO SALUD S.A.",position:[-34.5869034, -68.14314139999999]},
  {category:"salud",type:"instituto",address:"Lateral Sur Acceso Este 1553, Guaymallen , Mendoza",name:"IPRO S.R.L.",position:[-32.8984396, -68.8174753]},
  {category:"salud",type:"miscelaneo",address:"Almirante Brown 1700. Chacras de Coria, Luj\u00e1n de Cuyo , Mendoza",name:"PROPERFOR S.A. ",position:[-33.0057107, -68.8851711]},
  {category:"salud",type:"centro de d\u00eda",address:"Montecaseros 2442, Mendoza , Mendoza",name:"CENTRO DE D\u00cdA COMPARTIR de COMPARTIR DE NAVA S.R.L.",position:[-32.8747948, -68.8308772]},
  {category:"salud",type:"centro de d\u00eda",address:"Aguirre 382, Luj\u00e1n de Cuyo , Mendoza",name:"CENTRO DE D\u00cdA COMPARTIR de COMPARTIR DE NAVA S.R.L.",position:[-33.0343944, -68.89385709999999]},
  {category:"salud",type:"consultorio",address:"Alfonsina Storni 234, Guaymallen , Mendoza",name:"ACCION MEDICA S.A.",position:[-32.8951552, -68.8233903]},
  {category:"salud",type:"instituto",address:"Godoy Cruz 3678, Guaymallen , Mendoza",name:"INSTITUTO MARIA MONTESSORI",position:[-32.8934946, -68.79034519999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Jorge A. Calle 336, Mendoza , Mendoza",name:"ASOCIACION CIPRES",position:[-32.8688706, -68.84930779999999]},
  {category:"salud",type:"miscelaneo",address:"16 de Septiembre 741, San Mart\u00edn , Mendoza",name:"APRENDAMOS A VIVIR S.A.",position:[-33.0670335, -68.452541]},
  {category:"salud",type:"centro educativo",address:"Darragueira 8145, Luj\u00e1n de Cuyo , Mendoza",name:"CENTRO EDUCATIVO TERAPEUTICO INTEGRAL ALAS",position:[-32.96462, -68.8715239]},
  {category:"salud",type:"centro de rehabilitacion",address:"San Luis 151, Mendoza , Mendoza",name:"CENTRO DE PSICOMOTRICIDAD DEL OESTE S.A.",position:[-32.88489850000001, -68.83601209999999]},
  {category:"salud",type:"miscelaneo",address:"Juan de Dios Videla 324, Mendoza , Mendoza",name:"PIUQUEN S.R.L.",position:[-32.8802565, -68.852418]},
  {category:"salud",type:"miscelaneo",address:"Rep\u00fablica del Per\u00fa 1110, Las Heras , Mendoza",name:"SOL NACIENTE de LOADI S.A. ",position:[-32.8547616, -68.8462337]},
  {category:"salud",type:"miscelaneo",address:"Ruta Provincial N\u00b0 52 s n. Lote 8. Las Bovedas. Uspallata, Las Heras , Mendoza",name:"MUNDO ALAS S.A.",position:[-32.836685, -68.8687993]},
  {category:"salud",type:"miscelaneo",address:"Juan Agustin Maza 669, Mendoza , Mendoza",name:"MUNDO ALAS S.A.",position:[-32.8778491, -68.8449078]},
  {category:"salud",type:"miscelaneo",address:"3 de Febrero 1476, San Rafael , Mendoza",name:"DESPERTAR S.A.",position:[-34.62816919999999, -68.3447729]},
  {category:"salud",type:"consultorio",address:"Pellegrini 123. 2\u00b0 Piso \"C\", San Rafael , Mendoza",name:"KINESIOLOGIA INFANTIL de GARCIA YANINA NOELIA",position:[-34.6128922, -68.3390622]},
  {category:"salud",type:"miscelaneo",address:"Nicol\u00e1s Serpa 5235. Rodel del Medio, Maip\u00fa , Mendoza",name:"MONTANDO ESPERANZAS de MELOVI S.R.L.",position:[-32.9365217, -68.6582251]},
  {category:"salud",type:"miscelaneo",address:"Jos\u00e9 Federico Moreno 1295, Mendoza , Mendoza",name:"LA ROCA",position:[-32.888895, -68.8328369]},
  {category:"salud",type:"miscelaneo",address:"San Mart\u00edn 3164, Luj\u00e1n de Cuyo , Mendoza",name:"GRINBY de ROALE S.R.L.",position:[-33.0093526, -68.8688004]},
  {category:"salud",type:"miscelaneo",address:"Jos\u00e9 Federico Moreno 2270, Mendoza , Mendoza",name:"NUESTRO LUGAR de ALEXIS ARAYA",position:[-32.8768599, -68.8323648]},
  {category:"salud",type:"instituto",address:"Diagonal Carlos Pellegrini 2395, General Alvear , Mendoza",name:"INSTITUCIONES NUEVO SOL HOGAR Y CENTRO DE DIA S.A.",position:[-34.9865121, -67.6741559]},
  {category:"salud",type:"instituto",address:"Cayetano Silva 2084, San Rafael , Mendoza",name:"INSTITUCIONES NUEVO SOL HOGAR Y CENTRO DE DIA S.A.",position:[-34.62397139999999, -68.3650318]},
  {category:"salud",type:"instituto",address:"Adolfo Calle 3355, San Rafael , Mendoza",name:"INSTITUCIONES NUEVO SOL HOGAR Y CENTRO DE DIA S.A.",position:[-34.5835175, -68.3159252]},
  {category:"salud",type:"centro de d\u00eda",address:"Alvarez Condarco 2061, Las Heras , Mendoza",name:"CENTRO DE DIA JUNTOS A LA PAR",position:[-32.8244802, -68.8060644]},
  {category:"salud",type:"centro de d\u00eda",address:"Cacheuta 497, Las Heras , Mendoza",name:"SONSOLES CENTRO DE DIA de RED SALUD S.A.",position:[-32.8439537, -68.8389463]},
  {category:"salud",type:"centro de d\u00eda",address:"Joaqu\u00edn V. Gonz\u00e1lez 374, Godoy Cruz , Mendoza",name:"AEQUALITAS de CENTRO INTEGRAL DE DESARROLLO S.R.L.",position:[-32.9281932, -68.8583511]},
  {category:"salud",type:"fundaci\u00f3n",address:"Lavalle 1250, San Mart\u00edn , Mendoza",name:"FUNDACI\u00d3N RECREARTE",position:[-33.0682506, -68.47095139999999]},
  {category:"salud",type:"miscelaneo",address:"Almirante Brown N\u00ba 643, San Mart\u00edn , Mendoza",name:"HABILITAR de FANNY NILDA FABRONI",position:[-33.0755688, -68.4697994]},
  {category:"salud",type:"miscelaneo",address:"Terrada 1668, Luj\u00e1n de Cuyo , Mendoza",name:"SALUDARTE S.R.L.",position:[-32.9485176, -68.82373799999999]},
  {category:"salud",type:"miscelaneo",address:"La Gloria 1145. Tupungato, Mendoza , Mendoza",name:"SIEMPRE A TU LADO S.A.",position:[-33.3494329, -69.1512165]},
  {category:"salud",type:"miscelaneo",address:"Hip\u00f3lito Irigoyen 1444, San Rafael , Mendoza",name:"ANTES NUNCA ESTUVE",position:[-34.6098782, -68.3477498]},
  {category:"salud",type:"miscelaneo",address:"Laprida 379, Guaymallen , Mendoza",name:"REFLEJO DORADO S.A.",position:[-32.89904480000001, -68.83065599999999]},
  {category:"salud",type:"centro de d\u00eda",address:"JUAN JOSE PASO 75, Luj\u00e1n de Cuyo , Mendoza",name:"TREBOL CENTRO DE DIA S.A.",position:[-32.9650551, -68.8551076]},
  {category:"salud",type:"centro de d\u00eda",address:"Seru 152, Mendoza , Mendoza",name:"CERAL S.A.",position:[-32.9029345, -68.8446894]},
  {category:"salud",type:"centro de d\u00eda",address:"Teniente 1\u00ba Iba\u00f1ez 43, Godoy Cruz , Mendoza",name:"CEDDEC - CENTRO DE DIAGNOSTICO Y DESARROLLO DE CAPACIDADES S.R.L.",position:[-32.8707661, -68.8082185]},
  {category:"salud",type:"consultorio",address:"Saenz Pe\u00f1a 1555, Godoy Cruz , Mendoza",name:"SERVICIOS MEDICOS AMBULATORIOS S.A.",position:[-32.908496, -68.84514070000002]},
  {category:"salud",type:"centro de d\u00eda",address:"Colon 164, San Rafael , Mendoza",name:"CAI ABORDAJE INTEGRAL de DESARROLLO Y VIDA S.A.S.",position:[-34.6205623, -68.3231216]},
  {category:"salud",type:"asociaci\u00f3n",address:"Volc\u00e1n Santa Mar\u00eda 1440, Godoy Cruz , Mendoza",name:"ASOCIACI\u00d3N KUMELEN UNA ESCUELA PARA TODOS ",position:[-32.945046, -68.8286533]},
  {category:"salud",type:"centro de d\u00eda",address:"San Martin 8255   8291, Luj\u00e1n de Cuyo , Mendoza",name:"CINATT S.A.S.",position:[-33.0009908, -68.8671125]},
  {category:"salud",type:"miscelaneo",address:"Bombal 455, San Rafael , Mendoza",name:"NAIF de GALLARDO MARINA",position:[-34.6102566, -68.33140569999999]},
  {category:"salud",type:"hogar",address:"San Martin 1420, Luj\u00e1n de Cuyo , Mendoza",name:"HOGAR VIRGEN DE FATIMA S.A.S.",position:[-33.027049, -68.8755536]},
  {category:"salud",type:"centro de d\u00eda",address:"Ruta 50 s n, San Mart\u00edn , Mendoza",name:"CENTRO DE DIA NUEVA VIDA",position:[-33.0693105, -68.5124519]},
  {category:"salud",type:"asociaci\u00f3n",address:"Carril Costa Canal Montecaseros Km3, San Mart\u00edn , Mendoza",name:"ASOCIACION DE EQUINOTERAPIA LA EQUITANA",position:[-33.0688401, -68.4805101]},
  {category:"salud",type:"hogar",address:"Fray Inalican 1348, San Rafael , Mendoza",name:"MI CASA PEQUE\u00d1O HOGAR de SAINT ART S.A.S.",position:[-34.618267, -68.3625421]},
  {category:"salud",type:"centro de d\u00eda",address:"Olavarria 4940, Luj\u00e1n de Cuyo , Mendoza",name:"CENTRO DE DIA CHAS de FUNDACION CACHYPUM",position:[-33.0660925, -68.8399051]},
  {category:"salud",type:"miscelaneo",address:"Vicente Morales 57. LAVALLE, Mendoza , Mendoza",name:"VITIUM S.A.",position:[-32.8886311, -68.8376948]},
  {category:"salud",type:"centro de rehabilitacion",address:"Primitivo de la Reta 555, Mendoza , Mendoza",name:"CERIN - CENTRO DE REHABILITACION INTERDISCIPLINARIO",position:[-32.8968752, -68.8393189]},
  {category:"salud",type:"miscelaneo",address:"Avenida El Libertador 1649, Palmira , Mendoza",name:"CORAZONES FELICES S.A.",position:[-33.0517968, -68.5614274]},
  {category:"salud",type:"miscelaneo",address:"Jose Hernandez 2271, San Rafael , Mendoza",name:"AVANZAR de ALUMINE SOCIEDAD SIMPLE",position:[-34.6090123, -68.3543524]},
  {category:"salud",type:"asociaci\u00f3n",address:"Bartolome Mitre 30, General Alvear , Mendoza",name:"A.P.I.D. - ASOCIACION PROTECCION INTEGRAL DEL DISCAPACITADO",position:[-34.9779882, -67.6893858]},
  {category:"salud",type:"consultorio",address:"Videla Castillo 2331, Mendoza , Mendoza",name:"KINESIA de MIRASER S.A.",position:[-32.8762454, -68.82772179999999]},
  {category:"salud",type:"miscelaneo",address:"Francisco Moyano 1470, Mendoza , Mendoza",name:"ORIZA S.R.L.",position:[-32.8845113, -68.8504968]},
  {category:"salud",type:"hogar",address:"Carril Barriales Km 2,5 Junin, Mendoza , Mendoza",name:"HOGAR RENACER de INSTITUTO FICUS S.A.",position:[-32.9022122, -68.8424841]},
  {category:"salud",type:"hogar",address:"Juan B. Justo 1565, Godoy Cruz , Mendoza",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-32.9064227, -68.8607897]},
  {category:"salud",type:"centro de d\u00eda",address:"Chacabuco 1150, San Mart\u00edn , Mendoza",name:"CENTRO ARJE S.R.L",position:[-33.0744374, -68.4841596]},
  {category:"salud",type:"miscelaneo",address:"Arizu 351, Godoy Cruz , Mendoza",name:"TRES LAZOS S.A.S.",position:[-32.908322, -68.84868039999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Montevideo 225, Mendoza , Mendoza",name:"AIDAM",position:[-32.8922621, -68.8428454]},
  {category:"salud",type:"asociaci\u00f3n",address:"Paso de los Andes 2853, Mendoza , Mendoza",name:"ASOCIACION CIVIL CONVIVIR",position:[-32.8694406, -68.8543639]},
  {category:"salud",type:"centro de d\u00eda",address:"Belgrano N\u00b0846, Mendoza , Mendoza",name:"CRISE S.A.S",position:[-32.8912118, -68.8508228]},
  {category:"salud",type:"centro de rehabilitacion",address:"JUAN JOSE PASO N\u00b0272, Luj\u00e1n de Cuyo , Mendoza",name:"RHB MENDOZA SAS",position:[-32.9683395, -68.8363729]},
  {category:"salud",type:"centro de d\u00eda",address:"Acceso Las Catitas 201 Lateral Oeste Santa Rosa, Mendoza , Mendoza",name:"CIREDIS S.A.S.",position:[-33.298202, -68.0474632]},
  {category:"salud",type:"fundaci\u00f3n",address:"Acceso Sur Lateral Oeste N\u00ba7582, Luj\u00e1n de Cuyo , Mendoza",name:"FUNDACION EVOLUTIVA",position:[-33.0148928, -68.8583574]},
  {category:"salud",type:"asociaci\u00f3n",address:"Sargento Cabral 480, San Mart\u00edn , Mendoza",name:"MUSONYES de ASOCIACION MUTUAL SONRISAS Y ESPERANZAS DEL NI\u00d1O ESPECIAL",position:[-33.090529, -68.469391]},
  {category:"salud",type:"instituto",address:"J. B. Azopardo 115, Godoy Cruz , Mendoza",name:"ITEN de INSTITUTO TERAPEUTICO ESPECIALIZADO EN NI\u00d1OS S.A.S.",position:[-32.9263795, -68.8421017]},
  {category:"salud",type:"centro terapeutico",address:"Coronel Suarez N\u00ba514 3\u00ba Piso, San Rafael , Mendoza",name:"CETIN - CENTRO TERAPEUTICO DE TRATAMIENTOS INTEGRALES",position:[-34.6113307, -68.3474384]},
  {category:"salud",type:"miscelaneo",address:"Rivadavia 1048, Godoy Cruz , Mendoza",name:"OGUES de PANEMI S.A.",position:[-32.9239086, -68.8518813]},
  {category:"salud",type:"hogar",address:"Ruta 7 Km1002 Lateral Sur Acceso Este, San Mart\u00edn , Mendoza",name:"HOGAR HUANOMI de PER.SE.GAR S.A.S.",position:[-33.074601, -68.446968]},
  {category:"salud",type:"miscelaneo",address:"FORMOSA 175, Mendoza , Mendoza",name:"VIVIANA ANDREA PORCEL",position:[-32.9027564, -68.84095769999999]},
  {category:"salud",type:"miscelaneo",address:"PERITO MORENO 2202, Godoy Cruz , Mendoza",name:"EQUITUM S.A.S",position:[-32.9487975, -68.84941289999999]},
  {category:"salud",type:"centro de d\u00eda",address:"JUAN JOSE PASO 75, Luj\u00e1n de Cuyo , Mendoza",name:"TREBOL CENTRO DE D\u00cdA S.A",position:[-32.9650551, -68.8551076]},
  {category:"salud",type:"centro de rehabilitacion",address:"9 de Julio y Ceretti, Mendoza , Mendoza",name:"NEUROUCO CENTRO DE REHABILITACION INFANTO JUVENIL",position:[-32.8838938, -68.8390232]},
  {category:"salud",type:"miscelaneo",address:"LENCINAS 458, Mendoza , Mendoza",name:"GERMAN ALEJANDRO CA\u00d1IZARE",position:[-32.86228, -68.8204154]},
  {category:"salud",type:"miscelaneo",address:"GIANZA ESQ. ORFILIA MZA 4 LOTE 7\u00b0B ALTA MENDOZA , Las Heras , Mendoza",name:"FEDERICO EMILIO SANCHEZ",position:[-32.8894587, -68.8458386]},
  {category:"salud",type:"fundaci\u00f3n",address:"FELICIANO GAMARTE 395, Godoy Cruz , Mendoza",name:"FUNDACION AREA ",position:[-32.9286268, -68.8583963]},
  {category:"salud",type:"instituto",address:"AV. SANTA CRUZ N\u00b0 3106, Posadas , Misiones",name:"IAIM INSTITUTO ATENCION INTEGRAL MISIONES",position:[-27.3998382, -55.9116947]},
  {category:"salud",type:"centro de d\u00eda",address:"Ricardo Guiraldes N\u00ba 361 Km 3, Eldorado , Misiones",name:"CENEMI CENTRO DE DIA",position:[-26.4068823, -54.6369172]},
  {category:"salud",type:"centro de d\u00eda",address:"French 750, Ober\u00e1 , Misiones",name:"CENEMI CENTRO DE DIA",position:[-27.4805858, -55.1216673]},
  {category:"salud",type:"centro educativo",address:"REBOLLO 2105, Posadas , Misiones",name:"C.E.F.A.P. S.R.L.",position:[-27.37722, -55.8982343]},
  {category:"salud",type:"centro educativo",address:"Avda.B.Mitre N\u00b0 1619, Posadas , Misiones",name:"C.E.F.A.P. S.R.L.",position:[-27.3752671, -55.8929296]},
  {category:"salud",type:"fundaci\u00f3n",address:"RADEMACHER 3536, Posadas , Misiones",name:"FUNDACION SER",position:[-27.3823787, -55.89602379999999]},
  {category:"salud",type:"miscelaneo",address:"calle 63 N\u00ba 8205, Posadas , Misiones",name:"ESKINAZI, Florencia Natalia",position:[-27.4367005, -55.9162694]},
  {category:"salud",type:"miscelaneo",address:"Mitre 1651, Posadas , Misiones",name:"Delpiano Carlos Alberto",position:[-27.3751779, -55.8931905]},
  {category:"salud",type:"centro educativo",address:"Av. Mitre 1619, Posadas , Misiones",name:"CE.F.A.P.S.R.L.",position:[-27.3752671, -55.8929296]},
  {category:"salud",type:"centro de d\u00eda",address:"Junin N\u00aa 2467, Posadas , Misiones",name:"CENEMI Centro de Dia",position:[-27.3674725, -55.8979312]},
  {category:"salud",type:"centro de d\u00eda",address:"calle 131 N\u00b0 7490, Posadas , Misiones",name:"CENEMI Centro de Dia",position:[-27.4085352, -55.9458084]},
  {category:"salud",type:"instituto",address:"AV. RADEMACHER N\u00aa 3536, Posadas , Misiones",name:"INSTITUTO PRIVADO PARA LA ATENCION DE LAS NECESIDADES EDUCATIVAS ESPECIALES ",position:[-27.3898011, -55.8968665]},
  {category:"salud",type:"centro de d\u00eda",address:"REBOLLO N\u00b0 2117, Posadas , Misiones",name:"CENTRO DE DIA DESPERTARES",position:[-27.3774199, -55.8983463]},
  {category:"salud",type:"miscelaneo",address:"tucuman 2256, Posadas , Misiones",name:"Nona Morocha",position:[-27.3719892, -55.8993323]},
  {category:"salud",type:"fundaci\u00f3n",address:"COLOMBRES 3811, Posadas , Misiones",name:"FUNDACION CAMINO DE LAS MISIONES",position:[-27.410641, -55.91998299999999]},
  {category:"salud",type:"centro educativo",address:"AV. SAN MARTIN N\u00b0 960, Puerto Rico , Misiones",name:"CE.M.A.D MISIONES S.R.L",position:[-26.4063955, -54.648202]},
  {category:"salud",type:"centro de d\u00eda",address:"PEDERNERA N\u00b0 1747, Posadas , Misiones",name:"CENTRO TAITY ",position:[-27.3582707, -55.8918022]},
  {category:"salud",type:"fundaci\u00f3n",address:"Pasaje Brasil n\u00b0 2524, Posadas , Misiones",name:"FUNDACION \u00d1ANDE GURISES",position:[-27.3723677, -55.9087808]},
  {category:"salud",type:"centro de d\u00eda",address:"MZ 150 CASA N\u00b0 06, Bernardo De Irigoyen , Misiones",name:"CENSARI",position:[-26.2630263, -53.6474817]},
  {category:"salud",type:"fundaci\u00f3n",address:"AV. MITRE N\u00b0 1615, Posadas , Misiones",name:"FUNDACION APRENDER Y CRECER de CEFAP S.R.L.",position:[-27.3750322, -55.8927942]},
  {category:"salud",type:"miscelaneo",address:"Rebollo 2117, Posadas , Misiones",name:"DESPERTARES S.A.S",position:[-27.3774199, -55.8983463]},
  {category:"salud",type:"instituto",address:"Avda. Mitre 1651, Posadas , Misiones",name:"INSTITUTO DE REHABILITACION DELPIANO S.A.",position:[-27.3751779, -55.8931905]},
  {category:"salud",type:"fundaci\u00f3n",address:"Conrado Villegas 164 , Neuqu\u00e9n , Neuquen",name:"FUNDACI\u00d3N NACERES SISTEMA TERAP\u00c9UTICO INTEGRAL ",position:[-38.9533644, -68.0482674]},
  {category:"salud",type:"fundaci\u00f3n",address:"Belen 4754, Neuqu\u00e9n , Neuquen",name:"FUNDACION CRECER EN COMUNIDAD",position:[-38.9564682, -68.1252504]},
  {category:"salud",type:"asociaci\u00f3n",address:"CALLE S N 95, San Martin de los Andes, Neuquen",name:"ASOCIACION CIVIL PUENTES DE LUZ",position:[-40.1572103, -71.3524436]},
  {category:"salud",type:"asociaci\u00f3n",address:"Cacique Sayhueque 515 Y 535, Neuqu\u00e9n , Neuquen",name:"ASOCIACI\u00d3N CIVIL DE AYUDA AL DISCAPACITADO DE ALUMIN\u00c9",position:[-40.7592876, -71.64141169999999]},
  {category:"salud",type:"miscelaneo",address:"Sarmiento 752, Chos Malal, Neuquen",name:"PUENTES de AGUIRRE, Viviana M\u00f3nica",position:[-37.3800484, -70.2692283]},
  {category:"salud",type:"miscelaneo",address:"25 de Mayo 367, Chos Malal, Neuquen",name:"PUENTES de AGUIRRE, Viviana M\u00f3nica",position:[-37.3773627, -70.2741066]},
  {category:"salud",type:"miscelaneo",address:"Figueroa 2286, Neuqu\u00e9n , Neuquen",name:"COLORES de LILIANA MARGOT VERA",position:[-38.9516784, -68.0591888]},
  {category:"salud",type:"miscelaneo",address:"Covunco 937, Neuqu\u00e9n , Neuquen",name:"MUTISIAS S.R.L.",position:[-38.9457979, -68.0723233]},
  {category:"salud",type:"centro de d\u00eda",address:"Mariano Moreno 1158, San Martin de los Andes, Neuquen",name:"CEPRIN S.R.L.",position:[-40.1618179, -71.35141399999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Ruta 237 km 1638 Bo. Las Mar\u00edas , San Carlos De Bariloche , Rio Negro",name:"Centro de Dia ALUMINE S.A. ",position:[-39.9465266, -69.9787296]},
  {category:"salud",type:"centro educativo",address:"Hube 933, El Bols\u00f3n , Rio Negro",name:"CENTRO EDUCATIVO TERAPEUTICO AZUL S.A.",position:[-41.9678766, -71.5291939]},
  {category:"salud",type:" cooperativa",address:"BRASIL 280, General Roca , Rio Negro",name:"COOP. DE TRABAJO OLIVERIO GIRONDO LIMITADA",position:[-39.040736, -67.57068]},
  {category:"salud",type:"fundaci\u00f3n",address:"BOLIVIA 1890, Cipolletti , Rio Negro",name:"FUNDACION ALAS DEL ALMA",position:[-38.9311083, -67.97318220000001]},
  {category:"salud",type:"consultorio",address:"Lan\u00edn 104, San Carlos De Bariloche , Rio Negro",name:"Centro M\u00e9dico Painamal S.R.L.",position:[-41.1503086, -71.3157566]},
  {category:"salud",type:"asociaci\u00f3n",address:"ALSINA 1711, General Roca , Rio Negro",name:"A.PA.SI.DO ASOCIACION CIVIL PATAGONICA DE SINDROME DE DOWN",position:[-39.03240940000001, -67.59064409999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Garrone 181, Viedma , Rio Negro",name:"Fundaci\u00f3n Bien-Estar",position:[-40.8082927, -62.992293]},
  {category:"salud",type:"fundaci\u00f3n",address:"Jos\u00e9 Mar\u00eda Guido 426, Viedma , Rio Negro",name:"Fundaci\u00f3n Facilitar",position:[-40.8119087, -62.9962044]},
  {category:"salud",type:"fundaci\u00f3n",address:"Km 983,camino 16, Parcela A 150 de Idevi, Viedma , Rio Negro",name:"Fundaci\u00f3n Facilitar",position:[-40.8090083, -62.99409439999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Jos\u00e9 Mar\u00eda Guido 426, Viedma , Rio Negro",name:"FUNDACION FACILITAR",position:[-40.8119087, -62.9962044]},
  {category:"salud",type:"asociaci\u00f3n",address:"SANTOS VEGA Y CERRUTTI 1553, Viedma , Rio Negro",name:"Asociaci\u00f3n Civil Centro Amuch\u00e9n",position:[-40.8119087, -62.9962044]},
  {category:"salud",type:"centro educativo",address:"Gallardo 855 . 2\u00b0 Piso. Oficina 1, San Carlos De Bariloche , Rio Negro",name:"Lazos Inclusi\u00f3n Educativa S.R.L. ",position:[-41.1380846, -71.2982222]},
  {category:"salud",type:"asociaci\u00f3n",address:"San Mart\u00edn 750, Allen , Rio Negro",name:"Asocoaci\u00f3n Civil de padres y amigos de personas especiales de Allen Amanecer",position:[-38.9796831, -67.82020240000001]},
  {category:"salud",type:"hogar",address:"Santiago del Estero 1951 , Salta , Salta",name:"H.I.R.P.A.C.E. ",position:[-24.7841529, -65.4291947]},
  {category:"salud",type:"instituto",address:"Las Blancas S N\u00b0 , Cerrillo , Salta",name:"INSTITUTO GRANJA TALLER y HOGAR INTI PUNKU S.R.L. ",position:[-24.912459, -65.514102]},
  {category:"salud",type:"instituto",address:"Litoral de Corrientes 2909 , Barrio Intersindical , Salta",name:"INSTITUTO GRANJA TALLER y HOGAR INTI PUNKU S.R.L. ",position:[-24.7821269, -65.4231976]},
  {category:"salud",type:"fundaci\u00f3n",address:"M. Silva de Gurruchaga 225 , Salta , Salta",name:"PIADI FUNDACION (A. BINET) ",position:[-24.7705286, -65.4040528]},
  {category:"salud",type:"asociaci\u00f3n",address:"Martin Cornejo N\u00b0 98, Salta , Salta",name:"A.D.A.N.A. ",position:[-24.7879004, -65.4231856]},
  {category:"salud",type:"miscelaneo",address:"JUJUY 835 , Salta , Salta",name:"SOCIEDAD DE LA ESTRELLA ",position:[-24.7998226, -65.4174324]},
  {category:"salud",type:"centro de d\u00eda",address:"Tucum\u00e1n 667 , Salta , Salta",name:"C.R.I.O.S ",position:[-24.7999894, -65.4128506]},
  {category:"salud",type:"miscelaneo",address:"Zuvir\u00eda 752 , Salta , Salta",name:"AYUDAME A CRECER ",position:[-24.7804972, -65.40862229999999]},
  {category:"salud",type:"miscelaneo",address:"FLORIDA 476 , Salta , Salta",name:"S.A.C.R.A. ",position:[-24.7954358, -65.41316379999999]},
  {category:"salud",type:"miscelaneo",address:"Nevado de Cachi n\u00b01005, Salta , Salta",name:"S.A.C.R.A. ",position:[-24.8435799, -65.51288509999999]},
  {category:"salud",type:"miscelaneo",address:"Ruta Nacional N\u00b051 km 20,5, Campo Quijano , Salta",name:"S.A.C.R.A. ",position:[-24.907204, -65.6441178]},
  {category:"salud",type:"miscelaneo",address:"Nevado de Cachi y Cerro Minero km 81 2 S, Salta , Salta",name:"SACRA - FILIAL SALTA",position:[-24.8437156, -65.5126959]},
  {category:"salud",type:"miscelaneo",address:"Ruta 51 - Km 20,5, Campo Quijano, Salta",name:"SACRA - FILIAL SALTA",position:[-24.907349, -65.6393627]},
  {category:"salud",type:"miscelaneo",address:"Ruta 51 - Km 15, Salta , Salta",name:"SACRA - FILIAL SALTA",position:[-24.8385037, -65.4683809]},
  {category:"salud",type:"miscelaneo",address:"PARAJES LAS BLANCAS , Cerrillo , Salta",name:"INTI PUNKU ",position:[-24.9139837, -65.5214457]},
  {category:"salud",type:"escuela",address:"General Guemes N\u00b0 1780, Salta , Salta",name:"AYUDAME A CRECER ESCUELA ESPECIAL",position:[-24.7847456, -65.4268775]},
  {category:"salud",type:"escuela",address:"General Guemes 1060, Salta , Salta",name:"AYUDAME A CRECER ESCUELA ESPECIAL",position:[-24.7855029, -65.4169568]},
  {category:"salud",type:"miscelaneo",address:"TUCUMAN 667 , Salta , Salta",name:"CRIOS ",position:[-24.7999894, -65.4128506]},
  {category:"salud",type:"centro educativo",address:"Balcarce 380, Salta , Salta",name:"CENTRO EDUCATIVO TERAPEUTICO ANIDAR",position:[-24.7852776, -65.4118647]},
  {category:"salud",type:"centro terapeutico",address:"Mendoza N\u00b0 1060, Salta , Salta",name:"C.I.B.A. CENTRO TERAPEUTICO",position:[-24.7941059, -65.417792]},
  {category:"salud",type:"miscelaneo",address:"12 de Octubre 832, Salta , Salta",name:"RAYCES S.H",position:[-24.7745237, -65.41268819999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"SARMIENTO 344, Cerrillo , Salta",name:"FUNDACION LEVEN",position:[-24.9051805, -65.48866009999999]},
  {category:"salud",type:"miscelaneo",address:"MONTE AGUDO N\u00b0441, Profesor salvador Mazza , Salta",name:"O.N.G.",position:[-22.0480962, -63.68929489999999]},
  {category:"salud",type:"centro educativo",address:"Martina Silva de Gurruchaga N\u00b0447, Salta , Salta",name:"CENTRO EDUCATIVO TERAPEUTICO \"PUENTES DE LUZ\"",position:[-24.7702096, -65.4068422]},
  {category:"salud",type:"fundaci\u00f3n",address:"manzana 7, lote18 atocha san lorenzo, Salta , Salta",name:"Fundaci\u00f3n Anidar",position:[-24.73129, -65.48761]},
  {category:"salud",type:"miscelaneo",address:"Juan Mart\u00edn Leguizamon1286, Salta , Salta",name:"\u00c1RBOL DE LA VIDA",position:[-24.7828087, -65.41984470000001]},
  {category:"salud",type:"miscelaneo",address:"Los Parrales 110, Salta , Salta",name:"\u00c1RBOL DE LA VIDA",position:[-24.7690345, -65.3913118]},
  {category:"salud",type:"fundaci\u00f3n",address:"SANTIAGO DEL ESTERO 2302, Salta , Salta",name:"FUNDACI\u00d3N QUIROPR\u00c1CTICA SALTA",position:[-24.7837567, -65.43427530000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"AV. SARMIENTO N\u00aa774, Salta , Salta",name:"FUNDACION NEUROCIENCIAS",position:[-24.7807979, -65.4156229]},
  {category:"salud",type:"miscelaneo",address:"Calle Gorriti N\u00b0 301, Tartagal , Salta",name:"Munay Huasi",position:[-22.5179278, -63.7995058]},
  {category:"salud",type:"miscelaneo",address:"AV. CONSTITUCION NACIONAL 3265, Salta , Salta",name:"Encuentros",position:[-24.7475245, -65.4032548]},
  {category:"salud",type:"miscelaneo",address:"Vicente Lopez 168, Salta , Salta",name:"Encuentros",position:[-24.7882501, -65.4052763]},
  {category:"salud",type:"miscelaneo",address:"R\u00edo de la Plata S N, San Lorenzo , Salta",name:"S.R.L. Ay\u00fadame a Crecer",position:[-24.73129, -65.48761]},
  {category:"salud",type:"miscelaneo",address:"Mendoza 173, Salta , Salta",name:"Recrear Salud",position:[-24.7953521, -65.4054757]},
  {category:"salud",type:"miscelaneo",address:"LERMA 576, Salta , Salta",name:"Recrear Salud",position:[-24.8037156, -65.4203851]},
  {category:"salud",type:"fundaci\u00f3n",address:"Adolfo Guemes 81, Salta , Salta",name:"Fuapne",position:[-24.788402, -65.4179425]},
  {category:"salud",type:"fundaci\u00f3n",address:"FACUNDO DE ZUVIRIA 552, Salta , Salta",name:"FUNDACION ESPECIOS",position:[-24.7831728, -65.408896]},
  {category:"salud",type:"miscelaneo",address:"Av. Reyes Cat\u00f3licos 1505, Salta , Salta",name:"TERRA",position:[-24.770493, -65.3982169]},
  {category:"salud",type:"miscelaneo",address:"ZUVIRIA 820, Salta , Salta",name:"AMUYEN",position:[-24.7797353, -65.4084313]},
  {category:"salud",type:"miscelaneo",address:"Ruta Provincial 86 km. 20600, Cerrillo , Salta",name:"Aprendiendo a Ser II ",position:[-24.7901219, -65.4634694]},
  {category:"salud",type:"fundaci\u00f3n",address:"Santiago del Estero 2302, Salta , Salta",name:"FUNDACI\u00d3N QUIROPRAXIA SALTA",position:[-24.7837567, -65.43427530000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Av. Bolivia 2800, Salta , Salta",name:"FUNDACI\u00d3N EQUINOTERAPIA DEL AZUL",position:[-24.7567315, -65.41318199999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"ADOLFO G\u00dcEMES 640, Salta , Salta",name:"CENTRO PROVINCIAL DE REHABILITACION FISICA - CE.PRE.FI-S",position:[-24.7816686, -65.4170122]},
  {category:"salud",type:"fundaci\u00f3n",address:"Rivadavia N\u00ba 160, Joaqu\u00edn V. Gonz\u00e1les , Salta",name:"Fundaci\u00f3n Santa Catalina",position:[-24.8276749, -65.40321759999999]},
  {category:"salud",type:"miscelaneo",address:"9 de Julio 471, General Guemes , Salta",name:"SINAPSIS S.R.L.",position:[-24.6735151, -65.0487534]},
  {category:"salud",type:"miscelaneo",address:"Manzana 449 A-Tercera Etapa, lote 11 barrio solida, Salta , Salta",name:"SEMBRANDO ESPERANZA",position:[-24.7821269, -65.4231976]},
  {category:"salud",type:"miscelaneo",address:"RUTA NACIONAL N\u00ba 34 KM 1429, General Mosconi , Salta",name:"PASE SRL",position:[-22.5960958, -63.81158569999999]},
  {category:"salud",type:"miscelaneo",address:"Avda. Belgica 1532, Salta , Salta",name:"CD SAGRADA FAMILIA de NBR INVERSIONES SAS",position:[-24.8046719, -65.4236079]},
  {category:"salud",type:"fundaci\u00f3n",address:"JOAQUIN CASTELLENOS 1229 , San Lorenzo , Salta",name:"FUNDACI\u00d3N FU.RE.NE",position:[-24.7429141, -65.4945305]},
  {category:"salud",type:"miscelaneo",address:"B\u00ba Los Robles M53 Casa 1 la Silleta , Campo Quijano , Salta",name:"MENTE SANA",position:[-24.8796523, -65.5905136]},
  {category:"salud",type:"miscelaneo",address:"Santa Fe Este 241, Metan , Salta",name:"CONECTAR SIMPLE ASOCIACION",position:[-25.4938225, -64.9687482]},
  {category:"salud",type:"escuela",address:"Pje CALCHAQUI 149, Salta , Salta",name:"EDUCARE",position:[-24.7909006, -65.4226852]},
  {category:"salud",type:"miscelaneo",address:"Pje Baldomero Castro 1858, Salta , Salta",name:"PASOS",position:[-24.7953718, -65.42898570000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"SARMIENTO 468, Cerrillo , Salta",name:"REHABILITACION LEVEN",position:[-24.9063404, -65.4891086]},
  {category:"salud",type:"miscelaneo",address:"9 DE JULIO 471, General Guemes , Salta",name:"SINAPSIS S.R.L.",position:[-24.6735151, -65.0487534]},
  {category:"salud",type:"miscelaneo",address:"MZA A LOTE 2 B\u00b0 RIBERA DE CAFAYATE, Cafayate , Salta",name:"LOS MEDANOS SALUD CAFAYATE - SALTA S.R.L.",position:[-26.0819956, -65.9713873]},
  {category:"salud",type:"miscelaneo",address:"LOS EUCALIPTOS 667, Rosario de la Frontera , Salta",name:"EL SOLAR DE LA COLONIA de La Cecilia S.R.L.",position:[-25.8068803, -64.9825216]},
  {category:"salud",type:"hogar",address:"Callejon de los Rios 1951 1987 (N), Rivadavia , San Juan",name:"HUARPES S.R.L. ",position:[-31.5294819, -68.59405579999999]},
  {category:"salud",type:"hogar",address:"Casa 17 y 18 manzana 1 (Bo. Natania XV), Rivadavia , San Juan",name:"HUARPES S.R.L. ",position:[-31.5339014, -68.5940285]},
  {category:"salud",type:"instituto",address:"Genova 1237 (oeste) - B\u00b0 Marco , Rawson , San Juan",name:"IN - MANUS ",position:[-31.5351074, -68.5385941]},
  {category:"salud",type:"asociaci\u00f3n",address:"Justo Jos\u00e9 de Urquiza 374 (s) , San Juan , San Juan",name:"A.S.A.L.",position:[-31.5296157, -68.5446291]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mat\u00edas Zaballa 57 (Norte) Dto. Desampara, San Juan , San Juan",name:"A.P.A.D.I.M. Asoc.Padres y Amigos del Insuficiente",position:[-31.5318539, -68.5496745]},
  {category:"salud",type:"asociaci\u00f3n",address:"Estados Unidos 645 (sur) , San Juan , San Juan",name:"A.R.I.D. ASOCIACION CIVIL PARA LA REALIZACI\u00d3N E INCLUSI\u00d3N DE LAS PERSONAS CON DI",position:[37.09024, -95.712891]},
  {category:"salud",type:"fundaci\u00f3n",address:"Santa Fe 565 ( E ), San Juan , San Juan",name:"FUNDACION ABRIL",position:[-31.5394512, -68.5341005]},
  {category:"salud",type:"fundaci\u00f3n",address:"Entre Rios 129 (N) , San Juan , San Juan",name:"FUNDACION ABRIL",position:[-32.5175643, -59.1041758]},
  {category:"salud",type:"miscelaneo",address:"AGUILERA S N ENTRE CALLES ZAPATA Y ESPA\u00d1A, Angaco , San Juan",name:"Bel\u00e9n de la Frontera S.R.L. ",position:[-31.2249626, -68.0477509]},
  {category:"salud",type:"miscelaneo",address:"Aguilera S N La Ca\u00f1ada , Angaco , San Juan",name:"Bel\u00e9n de la Frontera S.R.L. ",position:[-31.2249626, -68.0477509]},
  {category:"salud",type:"miscelaneo",address:"Misiones 998 (Norte), Rivadavia , San Juan",name:"LAZOS S.R.L.",position:[-31.5181204, -68.569307]},
  {category:"salud",type:"instituto",address:"Av. Rioja 1425 (N), San Juan , San Juan",name:"I.R.I.N.A. Inst. de Recuperacion Integral del Ni\u00f1o",position:[-31.5180462, -68.523017]},
  {category:"salud",type:"miscelaneo",address:"Catamarca 507 (S), San Juan , San Juan",name:"ANDARES S.R.L.",position:[-31.5409095, -68.5299788]},
  {category:"salud",type:"miscelaneo",address:"TORANZO 273 (N), San Juan , San Juan",name:"NUESTRA SE\u00d1ORA DE LA PAZ S.R.L.",position:[-31.5351074, -68.5385941]},
  {category:"salud",type:"miscelaneo",address:"AV. CORDOBA 451 (E), San Juan , San Juan",name:"ANDALUE S.R.L.",position:[-31.5403364, -68.5190497]},
  {category:"salud",type:"miscelaneo",address:"25 DE MAYO 30(E), San Juan , San Juan",name:"ESENCIAL S.R.L",position:[-31.5317359, -68.5257893]},
  {category:"salud",type:"miscelaneo",address:"AV. C\u00d3RDOBA 585. OESTE, San Juan , San Juan",name:"ESENCIAL S.R.L",position:[-31.5407225, -68.5342622]},
  {category:"salud",type:"miscelaneo",address:"SAN LUIS 229 (OESTE), San Juan , San Juan",name:" \"ABRIENDO CAMINOS\"",position:[-31.5351074, -68.5385941]},
  {category:"salud",type:"miscelaneo",address:"MANUEL BELGRANO 46 (ESTE), San Juan , San Juan",name:"M.A.R S.R.L",position:[-31.5453533, -68.52569559999999]},
  {category:"salud",type:"miscelaneo",address:"TUCUMAN 278. NORTE, San Juan , San Juan",name:"SENDEROS DE SOL - S.A.S",position:[-26.8263978, -65.1999554]},
  {category:"salud",type:"miscelaneo",address:"TUCUMAN 822. SUR, San Juan , San Juan",name:"QUINCE - S.R.L",position:[-26.8082848, -65.2175903]},
  {category:"salud",type:"miscelaneo",address:"Mitre 670 este, San Juan , San Juan",name:"\"IRIS\" NESE S.A.S.",position:[-31.5351074, -68.5385941]},
  {category:"salud",type:" cooperativa",address:"MENDOZA 361 (5), Rawson , San Juan",name:"COOPERATIVA DE SERVICIOS Y CONSUMOS MEDISUR LTDA",position:[-32.8894587, -68.8458386]},
  {category:"salud",type:"miscelaneo",address:"Av. Pringles 1245 , Villa Mercedes , San Luis",name:"NEWEN S.A. Centro de D\u00eda ",position:[-33.6949535, -65.4409802]},
  {category:"salud",type:"centro de d\u00eda",address:"Sarmiento 396 , San Luis , San Luis",name:"Centro de Dia \"LA ESPERANZA\" S.A. ",position:[-33.3037761, -66.32105460000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"SANTA FE N\u00b0 177, Villa Mercedes , San Luis",name:"REHABILITAR TERAPIA NEUROLOGICA DE ARIDO S.A.",position:[-33.666833, -65.4566292]},
  {category:"salud",type:"centro de rehabilitacion",address:"Belgrano N\u00b0 823, Villa Mercedes , San Luis",name:"REHABILITAR TERAPIA NEUROLOGICA DE ARIDO S.A.",position:[-33.6754417, -65.45806879999999]},
  {category:"salud",type:"centro educativo",address:"BUENOS AIRES N\u00b0 223, Villa Mercedes , San Luis",name:"CENTRO EDUCATIVO TERAPP\u00c9UTICO INTEGRACI\u00d3N ESCOLAR TRIADA",position:[-33.6875089, -65.4617892]},
  {category:"salud",type:"centro educativo",address:"B\u00b0 Solares del Norte Lote 1101, Ruta 146 km 117, San Luis , San Luis",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO AILEN ",position:[-33.31881080000001, -66.3408037]},
  {category:"salud",type:"miscelaneo",address:"Av. Fuerza \u00c1rea Barrio Amep S N Manzana L Casa 314, San Luis , San Luis",name:"INCIDE S.R.L.",position:[-33.2729582, -66.3438855]},
  {category:"salud",type:"miscelaneo",address:"Gobernador Alric 336, Villa Mercedes , San Luis",name:"SE.I.ES",position:[-33.6769518, -65.4713044]},
  {category:"salud",type:"miscelaneo",address:"Nicaragua 2237, San Luis , San Luis",name:"San Benito",position:[-2.5640337, -44.3326318]},
  {category:"salud",type:"fundaci\u00f3n",address:"Nicaragua 2237, San Luis , San Luis",name:"Fundacion San Benito",position:[-2.5640337, -44.3326318]},
  {category:"salud",type:"miscelaneo",address:"Heroes de Malvinas 804, San Luis , San Luis",name:"VITA QATRO S.R.L.",position:[-33.2947972, -66.31213149999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Paunero 1940, Villa Mercedes , San Luis",name:"CENTRO DE D\u00cdA Y SERVICIO DE APOYO A LA INTEGRACI\u00d3N ESCOLAR \"VALDUVIECO\"",position:[-33.6555202, -65.47009779999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Parcela 16 Manzana 124 Ciudad de la Punta, San Luis , San Luis",name:"CENTRO DE D\u00cdA COLIBRIES",position:[-33.1823375, -66.3118745]},
  {category:"salud",type:"centro educativo",address:"Ca\u00eddos en Malvinas 804, San Luis , San Luis",name:"CENTRO EDUCATIVO VITA de QATRO S.R.L.",position:[-33.3064189, -66.3530578]},
  {category:"salud",type:"centro educativo",address:"Ca\u00eddos en Malvinas 804, San Luis , San Luis",name:"CENTRO EDUCATIVO VITA de QATRO S.R.L. ",position:[-33.3064189, -66.3530578]},
  {category:"salud",type:"fundaci\u00f3n",address:"Nicaragua 2237, San Luis , San Luis",name:"FUNDACION SAN BENITO",position:[-2.5640337, -44.3326318]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mariano Moreno 317 , R\u00edo Gallegos , Santa Cruz",name:"APPADI ",position:[-51.6229977, -69.2238381]},
  {category:"salud",type:"asociaci\u00f3n",address:"Magallanes 371 , R\u00edo Gallegos , Santa Cruz",name:"A.P.P.A.D.I Asoc. de Padres Pro-Ayuda al Discapaci",position:[-51.61506869999999, -69.2181126]},
  {category:"salud",type:"miscelaneo",address:"SANTA FE 102, R\u00edo Gallegos , Santa Cruz",name:"ANGELES ESPECIALES",position:[-51.629837, -69.22694539999999]},
  {category:"salud",type:"miscelaneo",address:"PINEDA S N CHACRA N\u00ba21, R\u00edo Gallegos , Santa Cruz",name:"ANGELES ESPECIALES",position:[-51.6230485, -69.2168291]},
  {category:"salud",type:"fundaci\u00f3n",address:"BAHIA BUEN SUCESO 1641 CASA 2025, R\u00edo Gallegos , Santa Cruz",name:"FUNDACI\u00d3N PARA LA INCLUSI\u00d3N DE PERSONAS CON DISCAPACIDAD",position:[-51.6233224, -69.25442939999999]},
  {category:"salud",type:"hogar",address:"HOGAR PADRE TIBURCIO-Gorriti 461 , Rosario , Santa Fe",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-32.923401, -60.670189]},
  {category:"salud",type:"hogar",address:"Pasaje Corvalan 327, Rosario , Santa Fe",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-32.92408400000001, -60.668896]},
  {category:"salud",type:"hogar",address:"Ruta 21 km 282 , General Lagos , Santa Fe",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-33.110462, -60.5647017]},
  {category:"salud",type:"hogar",address:"Gorriti 419, Rosario , Santa Fe",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-32.9233338, -60.66971059999999]},
  {category:"salud",type:"centro educativo",address:"Sarmiento 1228 30 , Rosario , Santa Fe",name:"CENTRO INTEGRAL DE REEDUCACION ESPECIALIZADA CIRE",position:[-32.9687344, -60.6438107]},
  {category:"salud",type:"instituto",address:"Presidente Roca 1179 , Rosario , Santa Fe",name:"INSTITUTO REGIONAL de FORM. INTEGRAL CRECER SC.",position:[-32.949934, -60.64615749999999]},
  {category:"salud",type:"instituto",address:"MARTIN FIERRO 622, Rosario , Santa Fe",name:"INSTITUTO REGIONAL de FORM. INTEGRAL CRECER SC.",position:[-32.8774317, -60.695086]},
  {category:"salud",type:"instituto",address:"Av. Rondeau 2260 , Rosario , Santa Fe",name:"INSTITUTO REGIONAL de FORM. INTEGRAL CRECER SC.",position:[-32.9587022, -60.69304159999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rivadavia 10 , Colonia Esperanza , Santa Fe",name:"ASOCIACI\u00d3N AYUDA A NI\u00d1OS ESPECIALES (AANE) UBAJAY",position:[-31.4670487, -60.93404140000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"25 de Mayo 2088, Esperanza , Santa Fe",name:"ASOCIACI\u00d3N AYUDA A NI\u00d1OS ESPECIALES (AANE) UBAJAY",position:[-31.4469796, -60.9355293]},
  {category:"salud",type:"centro de d\u00eda",address:"Javier de la Rosa 25 , Santa Fe , Santa Fe",name:"CENTRO DE DIA CONVIVIR ",position:[0.0, 0.0]},
  {category:"salud",type:"asociaci\u00f3n",address:"SAN FRANCISCO DE ASIS Y CHANAES, Santa Fe , Santa Fe",name:"ASOCIACI\u00d3N CIVIL UN MUNDO ESPECIAL ",position:[-31.6106578, -60.697294]},
  {category:"salud",type:"miscelaneo",address:"Avda de los Trabajadores Ferroviarios 355 , San Cristobal , Santa Fe",name:"CLUB DE LEONES DE SAN CRISTOBAL",position:[-30.2998235, -61.5136037]},
  {category:"salud",type:" hospital",address:"Mendoza y Avda. Mosconi , Santa Fe , Santa Fe",name:"Hospital de Ni\u00f1os Orlando Alassia ",position:[-31.6480494, -60.7300059]},
  {category:"salud",type:" hospital",address:"Lisandro de la Torre 737 , Rafaela , Santa Fe",name:"HOSPITAL SAMCO JAIME FERRE (RAFAELA) ",position:[-31.2442106, -61.5009676]},
  {category:"salud",type:" hospital",address:"Teofilo Madrejon 1320 , San Javier , Santa Fe",name:"HOSPITAL SAMCO \"Dr. RAWSON\" (SAN JAVIER) ",position:[-30.5835305, -59.9340813]},
  {category:"salud",type:" hospital",address:"Almirante Brown 7398, Santa Fe , Santa Fe",name:"HOSPITAL de REHABILITACION \"CARLOS VERA CANDIOTI\" ",position:[-31.6106578, -60.697294]},
  {category:"salud",type:" hospital",address:"Monse\u00f1or Zaspe 3738 , Santa Fe , Santa Fe",name:"HOSPITAL de REHABILITACION \"CARLOS VERA CANDIOTI\" ",position:[-31.6532104, -60.72428060000001]},
  {category:"salud",type:"miscelaneo",address:"San Lorenzo 156 , Rafaela , Santa Fe",name:"FUSION para el TRABAJO y APRENDIZAJE del DISCAPACITADO (RAFAELA)",position:[-31.2546459, -61.4880703]},
  {category:"salud",type:"asociaci\u00f3n",address:"Bvard. Avellaneda 972, Carlos Pellegrini , Santa Fe",name:"ASOCIACION ITALIANA de SOCORROS MUTUOS JOSE MAZZINI (CARLOS PELLEGRINI)",position:[-32.050592, -61.7899787]},
  {category:"salud",type:" hospital",address:"Av. Freyre 2150 , Santa Fe , Santa Fe",name:"Hospital Provincial JOSE MARIA CULLEN ",position:[-31.648704, -60.71887299999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Primera Junta 3033 , Santa Fe , Santa Fe",name:"A.L.P.I. DE SANTA FE",position:[-31.6467215, -60.7124722]},
  {category:"salud",type:" hospital",address:"Alem 1450 , Rosario , Santa Fe",name:"HOSPITAL PROVINCIAL de ROSARIO ",position:[-32.9561688, -60.6306783]},
  {category:"salud",type:"asociaci\u00f3n",address:"Belgrano 1166, Tostado , Santa Fe",name:"ASOCIACION CIVIL\" EL ANGEL DE LA GUARDA\"",position:[-29.2373222, -61.7718283]},
  {category:"salud",type:"asociaci\u00f3n",address:"Alvear 1359 , Tostado , Santa Fe",name:"ASOCIACION CIVIL\" EL ANGEL DE LA GUARDA\"",position:[-29.2367069, -61.7705517]},
  {category:"salud",type:"hogar",address:"Los Inmigrantes 375 , Capit\u00e1n Berm\u00fadez , Santa Fe",name:"HOGAR SAN ROQUE DE FUNDACION PROVIDENCIA DIVINA",position:[-32.8154054, -60.7079481]},
  {category:"salud",type:" hospital",address:"9 de julio 479 , Santo Domingo , Santa Fe",name:"HOSPITAL SAMCO \u00a8SANTO DOMINGO\u00a8 ",position:[-31.1232847, -60.887322]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda Los Quinteros sin nro , Angel Gallardo , Santa Fe",name:"CENTRO DE DIA \"SAN JOSE\" perteneciente a la asoc. discap. San Jose",position:[-31.5551039, -60.6798695]},
  {category:"salud",type:"instituto",address:"Paraguay y Ocampo , Rosario , Santa Fe",name:"ILAR -ROSARIO ",position:[-32.9654576, -60.64877499999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Almirante Brown 2462 , Santo Tome , Santa Fe",name:"A.D.A.L.P.E.-ASOCIACION DE APOYO LABORAL PARA PERSONAS ESPECIALES",position:[-31.6645968, -60.76655779999999]},
  {category:"salud",type:" hospital",address:"Avellaneda 6687, Santa Fe , Santa Fe",name:"HOSPITAL PROVINCIAL SAYAGO",position:[-31.6097816, -60.6795651]},
  {category:"salud",type:" hospital",address:"Avda. Blas Parera 8200 , Santa Fe , Santa Fe",name:"HOSPITAL PROVINCIAL SAYAGO",position:[-31.585479, -60.7257322]},
  {category:"salud",type:"asociaci\u00f3n",address:"Viamonte 744 , Rosario , Santa Fe",name:"C.O.N.N.A.R. A.P.N.A.R. Asociacion de Padres Ni\u00f1os Atipicos de Rosario ",position:[-32.9661001, -60.6380396]},
  {category:"salud",type:"centro de d\u00eda",address:"25 de mayo 3400 , Reconquista , Santa Fe",name:"CENTRO DE DIA MUNICIPAL DE RECONQUISTA \"\u00a8RAICES\"",position:[-29.1830889, -59.6539836]},
  {category:"salud",type:"centro de d\u00eda",address:"3 de febrero 1973 , Rosario , Santa Fe",name:"CENTRO DE DIA \u00a8E.T.N.A.D.E.\u00a8 ",position:[-32.9507024, -60.6518361]},
  {category:"salud",type:"fundaci\u00f3n",address:"ITALIA 466 EL TRAMPOLIN, Rosario , Santa Fe",name:"FUNDACION DEL CENTRO DE DESARROLLO INFANTIL",position:[-32.9408408, -60.647074]},
  {category:"salud",type:"fundaci\u00f3n",address:"ITALIA 466 UN LUGAR PARA APRENDER , Rosario , Santa Fe",name:"FUNDACION DEL CENTRO DE DESARROLLO INFANTIL",position:[-32.9408408, -60.647074]},
  {category:"salud",type:"centro educativo",address:"Tucuman 2050 , Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO INSTITUTO CZERNY",position:[-32.939416, -60.6499087]},
  {category:"salud",type:"centro de d\u00eda",address:"Alvear 359 , Rosario , Santa Fe",name:"ALAS CENTRO DE ATENCI\u00d3N AL DISCAPACITADO ",position:[-32.9384144, -60.65326349999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Rodriguez 120 bis , Rosario , Santa Fe",name:"ASOCIACION CIVIL LA VENTANA",position:[-32.9319664, -60.65615]},
  {category:"salud",type:"centro de rehabilitacion",address:"Alvear 863 , Rosario , Santa Fe",name:"Servicio de Rehabilitacion del SANATORIO DE NI\u00d1OS SA.",position:[-32.9443308, -60.6545462]},
  {category:"salud",type:"centro de d\u00eda",address:"Ovidio Lagos 1084 , Rosario , Santa Fe",name:"C.I.A.D.I.R Centro Integral de Asistencia para el desarrollo Integral Rosar",position:[-32.945664, -60.6624652]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. Pellegrini 156 , Rosario , Santa Fe",name:"CENTROS\" CERIN\" de CERIN-CET S.R.L.",position:[-32.959473, -60.62754330000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Viamonte 3054 , Rosario , Santa Fe",name:"CENTRO DE DIA \"LA CASA DEL PASAJE\" ",position:[-32.9604328, -60.6695886]},
  {category:"salud",type:"centro terapeutico",address:"4 de Enero 2880 , Santa Fe , Santa Fe",name:"COMUNIDAD TERAP\u00c9UTICA DE AYUDA A NI\u00d1OS ESPECIALES ",position:[-31.6415639, -60.7104659]},
  {category:"salud",type:"centro terapeutico",address:"Angel Gallardo S N , Angel Gallardo , Santa Fe",name:"COMUNIDAD TERAP\u00c9UTICA DE AYUDA A NI\u00d1OS ESPECIALES ",position:[-31.555217059243468, -60.6813917818698]},
  {category:"salud",type:"fundaci\u00f3n",address:"Regimiento 11 N\u00b0 434 , Rosario , Santa Fe",name:"FUNDACION EVEREST ",position:[-32.9984102, -60.6387062]},
  {category:"salud",type:"centro de d\u00eda",address:"Lisandro de la Torre 2564 , Casilda , Santa Fe",name:"CENTRO DE DIA TRES MUNDOS de la A.S. \"EL PUENTE\" ",position:[-33.0475223, -61.17029309999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Bek y Herzok 670 , San Carlos Centro , Santa Fe",name:"CENTRO DE ENCUENTRO Y TERAPIA PARA PERSONAS CON CAP. DIF. ",position:[-31.7297413, -61.085417]},
  {category:"salud",type:"centro de d\u00eda",address:"Alem 750 , Ca\u00f1ada de G\u00f3mez , Santa Fe",name:"CENTRO DE D\u00cdA \u201cINTEGRAR\u201dS.R.L.",position:[-32.8098597, -61.4108951]},
  {category:"salud",type:"asociaci\u00f3n",address:"Jorge Newbery 782 , Galvez , Santa Fe",name:"PINQUEN de G ASOCIACION AMIGOS del TALLER INTEGRA p  DISCAP ",position:[-32.03578590000001, -61.2201481]},
  {category:"salud",type:"centro de d\u00eda",address:"Edison 17 , Venado Tuerto , Santa Fe",name:"C.E.N.A.P. Centro de Atencion Psicoterapeutica (VENADO TUERTO) ",position:[-33.7570557, -61.9707904]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda 1\u00aa de junio 464 , San Jer\u00f3nimo Sud , Santa Fe",name:"Centro de Rehabilitacion Mutual APREPA ",position:[-32.8790394, -61.02709279999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Laprida 2164 , Rosario , Santa Fe",name:"CENTRO DE DIA TRAYECTORIA S.S.",position:[-32.9633066, -60.6385114]},
  {category:"salud",type:"hogar",address:"Av. Arij\u00f3n 124 bis , Rosario , Santa Fe",name:"HOGAR Y CD SANTA CECILIA de M&A Servicios Asistenciales S.R.L. ",position:[-33.0023344, -60.63227939999999]},
  {category:"salud",type:"hogar",address:"SAN LUIS Y SUIPACHA, Diaz , Santa Fe",name:"HOGAR Y CD SANTA CECILIA de M&A Servicios Asistenciales S.R.L. ",position:[-32.3712995, -61.0870401]},
  {category:"salud",type:"hogar",address:"San Martin 1077 , Rold\u00e1n , Santa Fe",name:"HOGAR Y CD SANTA CECILIA de M&A Servicios Asistenciales S.R.L. ",position:[-32.897695, -60.9067969]},
  {category:"salud",type:"hogar",address:"Centenario 491 , Carrizales , Santa Fe",name:"HOGAR Y CD SANTA CECILIA de M&A Servicios Asistenciales S.R.L. ",position:[-32.5080848, -61.02814600000001]},
  {category:"salud",type:"centro de d\u00eda",address:"CORRIENTES 2123, Rosario , Santa Fe",name:"CENTROS RAYUELA Asociacion Simple ",position:[-32.9615195, -60.646144]},
  {category:"salud",type:"centro de d\u00eda",address:"9 de Julio 1976 , Rosario , Santa Fe",name:"CENTROS RAYUELA Asociacion Simple ",position:[-32.9514527, -60.652053]},
  {category:"salud",type:"centro educativo",address:"Bv.Rondeau 147 (jovenes) , Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPE\u00daTICO TRAZOS, S.R.L. ",position:[-32.9135599, -60.6869064]},
  {category:"salud",type:"centro educativo",address:"Bv.Rondeau 147 (ni\u00f1os). , Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPE\u00daTICO TRAZOS, S.R.L. ",position:[-32.9135599, -60.6869064]},
  {category:"salud",type:"centro educativo",address:"GABOTO 77, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPE\u00daTICO TRAZOS, S.R.L. ",position:[-32.9760923, -60.6306006]},
  {category:"salud",type:"hogar",address:"Bvar Sarmiento N\u00ba 1882, Villa Ocampo , Santa Fe",name:"HOGAR Y CD \"SAN FRANCISCO propiedad de RED DE ATEN INT DE C DIFERENTES",position:[-28.4836193, -59.3576076]},
  {category:"salud",type:"hogar",address:"Ituzaingo1458 , Reconquista , Santa Fe",name:"HOGAR Y CD \"SAN FRANCISCO propiedad de RED DE ATEN INT DE C DIFERENTES",position:[-29.1529567, -59.6401248]},
  {category:"salud",type:"hogar",address:"ROCA S N, La Gallareta , Santa Fe",name:"HOGAR Y CD \"SAN FRANCISCO propiedad de RED DE ATEN INT DE C DIFERENTES",position:[-29.5838171, -60.3760434]},
  {category:"salud",type:"hogar",address:"COLON 143, Calchaqu\u00ed , Santa Fe",name:"HOGAR Y CD \"SAN FRANCISCO propiedad de RED DE ATEN INT DE C DIFERENTES",position:[-29.8951912, -60.2846093]},
  {category:"salud",type:"centro de d\u00eda",address:"C\u00f3rdoba 561 , Villa Constituci\u00f3n , Santa Fe",name:"CENTRO de DIA\" CASA VERDE\" ",position:[-33.230902, -60.3303536]},
  {category:"salud",type:"fundaci\u00f3n",address:"Rioja 2463 , Rosario , Santa Fe",name:"FUNDACION ROBLES Y ROBLES",position:[-32.9443101, -60.6571652]},
  {category:"salud",type:"fundaci\u00f3n",address:"Espa\u00f1a 244, Rosario , Santa Fe",name:"FUNDACION ROBLES Y ROBLES",position:[-32.9383403, -60.6450439]},
  {category:"salud",type:"miscelaneo",address:"San Lorenzo 3640 , Rosario , Santa Fe",name:"CENTRO DE DIA de la ASOCIACION CIVIL INTEGRARTE ",position:[-32.9378574, -60.6723081]},
  {category:"salud",type:"centro de d\u00eda",address:"Rioja 2463 , Rosario , Santa Fe",name:"CENTRO DE PEDAGOGIA Y TERAPEUTICA DE LA DIVERSIDAD S.R.L.",position:[-32.9443101, -60.6571652]},
  {category:"salud",type:"centro de d\u00eda",address:"RIVADAVIA 2225 , Laguna Paiva , Santa Fe",name:"CENTRO DE DIA INTEGRAR S.R.L.(LAGUNA PAIVA) ",position:[-31.3074988, -60.6621257]},
  {category:"salud",type:"instituto",address:" VIAMONTE 1367 , Rosario , Santa Fe",name:"INSTITUTO REHAB DE ENF NEUROLOGICAS ROSARIO IREN ",position:[-32.9647369, -60.64667840000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Gral. Guemes 894 , Malabrigo , Santa Fe",name:"CENTRO DE DIA NUEVO CAMINO ",position:[-29.3539557, -59.97275699999999]},
  {category:"salud",type:"centro terapeutico",address:"1\u00b0 de Mayo 1380 , Pilar , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO de la ASOCIACION CIVIL CONOCIENDONOS ",position:[-31.4401513, -61.2541107]},
  {category:"salud",type:"centro de d\u00eda",address:"Moreno 1052 , Villa Constituci\u00f3n , Santa Fe",name:"CENTROS LOS GIRASOLES SRL ",position:[-33.2281646, -60.3327728]},
  {category:"salud",type:"centro de d\u00eda",address:"RECONQUISTA 752, Alcorta , Santa Fe",name:"CENTROS LOS GIRASOLES SRL ",position:[-33.5371808, -61.1263549]},
  {category:"salud",type:"centro de d\u00eda",address:"San Martin 938 , Arroyo Seco , Santa Fe",name:"CENTROS LOS GIRASOLES SRL ",position:[-33.1533429, -60.5142444]},
  {category:"salud",type:"hogar",address:"Centenario 1275, Rosario , Santa Fe",name:"HOGAR \"LOS AMORINIS\" de SANATORIO NEUROPATICO S.R.L. ",position:[-33.0045407, -60.6512135]},
  {category:"salud",type:"hogar",address:"Entre Rios 5849 , Rosario , Santa Fe",name:"HOGAR \"LOS AMORINIS\" de SANATORIO NEUROPATICO S.R.L. ",position:[-33.0048441, -60.65148660000001]},
  {category:"salud",type:"clinica",address:"Paraguay 2041 , Rosario , Santa Fe",name:"CLINICA DE REHABILITACION PILARES DE ROSARIO",position:[-32.9602105, -60.64726679999999]},
  {category:"salud",type:"clinica",address:"CORRIENTES 2017, Rosario , Santa Fe",name:"CLINICA DE REHABILITACION PILARES DE ROSARIO",position:[-32.9602967, -60.6457243]},
  {category:"salud",type:"centro de d\u00eda",address:"General Lopez 1564 , San Lorenzo , Santa Fe",name:"CENTRO DE DIA \"C.R.E.E.\" Centro Recreativo y Educativo Especial S.S.",position:[-32.7461683, -60.735128]},
  {category:"salud",type:"centro terapeutico",address:"Eva Peron 5034 , Rosario , Santa Fe",name:"C.E.T.\"Mi Lugar\" (ROSARIO)",position:[-32.9366444, -60.6917322]},
  {category:"salud",type:" hospital",address:"Cochabamba 1456 , San Cristobal , Santa Fe",name:"HOSPITAL S.A.M.C.O.\"JULIO VILLANUEVA\" (SAN CRISTOBAL)",position:[-30.3076358, -61.2287459]},
  {category:"salud",type:"centro educativo",address:"Pasaje Civit 2871 , Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO DRA. SABINA SPIELREIN ",position:[-32.9561181, -60.6658113]},
  {category:"salud",type:"hogar",address:"Alte. Brown 6917 , Santa Fe , Santa Fe",name:"HOGAR JUANA VARGAS DE STRINGHINI de ASOCIACI\u00d3N HOMIMEN",position:[0.0, 0.0]},
  {category:"salud",type:"asociaci\u00f3n",address:"CALLE 17 N\u00ba 945 , Las Parejas , Santa Fe",name:"CD LA CASA perteneciente a la ASOC P  INCL DISCAP A.P.I.D.",position:[-32.6813791, -61.5264646]},
  {category:"salud",type:"asociaci\u00f3n",address:"Laprida 1482, Rosario , Santa Fe",name:"ASOCIACION CIVIL SENDEROS (ROSARIO)",position:[-32.9554809, -60.63651539999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Pasco 1092 , Rosario , Santa Fe",name:"ASOCIACION CIVIL SENDEROS (ROSARIO)",position:[-32.9595065, -60.6411992]},
  {category:"salud",type:"centro de d\u00eda",address:"Kay 290 , Rosario , Santa Fe",name:"CENTRO DE DIA VIRGEN DE LOURDES ",position:[-32.9318419, -60.7309795]},
  {category:"salud",type:"centro de d\u00eda",address:"Suipacha 724 , Ceres , Santa Fe",name:"CENTRO DE DIA de la ASOCIACION CIVIL ALAS ",position:[-29.8863501, -61.95497599999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"J.M. de Rosas 1862 , Rosario , Santa Fe",name:"S.A.I.E. de la ASOCIACION CIVIL COLEGIO ESPA\u00d1OL de ROSARIO",position:[-32.9603487, -60.63465359999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Diego Ortiz de Zarate s n , Cayast\u00e1 , Santa Fe",name:"ASOCIACION CIVIL CREER (CAYASTA) ",position:[-31.1989074, -60.1597187]},
  {category:"salud",type:"centro educativo",address:"Buenos Aires 1950 , Funes , Santa Fe",name:"CET RECREARTE S.R.L. ",position:[-32.911836, -60.81323899999999]},
  {category:"salud",type:"centro de d\u00eda",address:"San Lorenzo 2167 , Rosario , Santa Fe",name:"CENTRO DE D\u00cdA de RENACER de LMP S.R.L ",position:[-32.9417419, -60.6521589]},
  {category:"salud",type:" hospital",address:"Mitre 1551 , Galvez , Santa Fe",name:"HOSPITAL S.A.M.co de Galvez ",position:[-32.0185347, -61.2272794]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mendoza 3243 , Santa Fe , Santa Fe",name:"SPRAI Soc. Pro Rehab. Pers. Discapacidad ",position:[-32.8894587, -68.8458386]},
  {category:"salud",type:"centro de rehabilitacion",address:"La Paz 1833 , Rosario , Santa Fe",name:"CENTRO INTEGRAL DE REHABILITACI\u00d3N Y ESCOLARIDAD S.R.L. \"UN MUNDO POSIBLE\"",position:[-32.9625384, -60.6528039]},
  {category:"salud",type:"fundaci\u00f3n",address:"Hipolito Irigoyen 3311 , Santa Fe , Santa Fe",name:"FUNDACION PRESBITERO MARIO PANTALEO",position:[-31.6407155, -60.714609]},
  {category:"salud",type:"centro de d\u00eda",address:"Puerreydon 401 , Rafaela , Santa Fe",name:"CENTRO DE DIA \u00a8LA HUELLA\u00a8 ",position:[-31.2496138, -61.4876484]},
  {category:"salud",type:"centro de d\u00eda",address:"ARENALES 572, Rafaela , Santa Fe",name:"CENTRO DE DIA \u00a8LA HUELLA\u00a8 ",position:[-31.2469582, -61.4923693]},
  {category:"salud",type:"centro de d\u00eda",address:"Obispo Gelabert 2252 , Santo Tome , Santa Fe",name:"CENTRO DE DIA de la FUNDACION SANTO TOMAS ",position:[-31.6630533, -60.76395589999999]},
  {category:"salud",type:"miscelaneo",address:"Habbeger 331 , Reconquista , Santa Fe",name:"INTEGRAR RECONQUISTA S.R.L.",position:[-29.1471325, -59.640697]},
  {category:"salud",type:"miscelaneo",address:"Calle N\u00b0 5 209, Avellaneda , Santa Fe",name:"INTEGRAR RECONQUISTA S.R.L.",position:[-29.1064387, -59.66336999999999]},
  {category:"salud",type:"miscelaneo",address:"Patricio Diez 957 , Reconquista , Santa Fe",name:"INTEGRAR RECONQUISTA S.R.L.",position:[-29.1441143, -59.64803919999999]},
  {category:"salud",type:"centro de d\u00eda",address:"NICOLAS FIGUEREDO 2458 , San Justo , Santa Fe",name:"CENTRO DE DIA de la ASOCIACION CIVIL DESPERTARES ",position:[-30.7910835, -60.5922731]},
  {category:"salud",type:"instituto",address:"Junin 2666 , Santa Fe , Santa Fe",name:"I.N.N.E.L. INST. de NEURO. y NEUROREHAB. del LITORAL ",position:[-31.6384942, -60.7051198]},
  {category:"salud",type:"asociaci\u00f3n",address:"Dr. de la Colina 1709 , San Javier , Santa Fe",name:"ASOCIACION CIVIL \"TU LUGAR\" (SAN JAVIER) ",position:[-30.5745613, -59.93485869999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. Cordoba 4045 Km 321 , Funes , Santa Fe",name:"CENTRO DE DIA \"UN LUGAR\" (FUNES) ",position:[-32.939384, -60.6783848]},
  {category:"salud",type:"miscelaneo",address:"Rioja N\u00ba 5541 , Rosario , Santa Fe",name:"S.A.I.E. INCLUIRNOS ",position:[-32.9380681, -60.69917460000001]},
  {category:"salud",type:"centro de d\u00eda",address:"EStanislao Lopez 1380, Rold\u00e1n , Santa Fe",name:"CENTRO DE DIA JUNTOS (ROLDAN) ",position:[-32.8957009, -60.91598560000001]},
  {category:"salud",type:"centro de d\u00eda",address:"White 7235, Rosario , Santa Fe",name:"CENTRO DE DIA WHITE S.R.L",position:[-32.9587022, -60.69304159999999]},
  {category:"salud",type:"miscelaneo",address:"Alem 1059, Rosario , Santa Fe",name:"S.A.I.E. y E.T. ANDAMIOS",position:[-32.9514872, -60.62902159999999]},
  {category:"salud",type:"miscelaneo",address:"B. Ferreyra 531, Rufino , Santa Fe",name:"SAN TEODORO SERVICIOS ASISTENCIALES S.R.L.",position:[-34.2642439, -62.7191466]},
  {category:"salud",type:"miscelaneo",address:"San Martin 2582, Granadero Baigorria , Santa Fe",name:"SAN TEODORO SERVICIOS ASISTENCIALES S.R.L.",position:[-32.8441763, -60.7071621]},
  {category:"salud",type:"miscelaneo",address:"sarmiento 938, Firmat , Santa Fe",name:"SAN TEODORO SERVICIOS ASISTENCIALES S.R.L.",position:[-33.4601003, -61.4835661]},
  {category:"salud",type:"centro de d\u00eda",address:"Santa Fe 838, Rosario , Santa Fe",name:"MANDHALA CENTRO de MANDHALA SERVICIOS EN SALUD S.R.L.",position:[-32.9462507, -60.6340714]},
  {category:"salud",type:"centro de d\u00eda",address:"Maestro Massa 617, Rosario , Santa Fe",name:"MANDHALA CENTRO de MANDHALA SERVICIOS EN SALUD S.R.L.",position:[-32.8742861, -60.6960433]},
  {category:"salud",type:"centro educativo",address:"Pellegrini 1823, Rosario , Santa Fe",name:"C.E.T. INSTITUTO DE NEUROPSICOLOG\u00cdA CL\u00cdNICA perteneciente a \u201cCESIJ\u201dS.R.L.",position:[-32.9556738, -60.65106040000001]},
  {category:"salud",type:"centro educativo",address:"Salta 2274, Rosario , Santa Fe",name:"C.E.T. CASA DE FAMILIA de GIMENEZ PUEBLA S.R.L.",position:[-32.9363768, -60.65250659999999]},
  {category:"salud",type:"centro educativo",address:"Bv. Oro\u00f1o 138 bis, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO ENTRELAZOS S.R.L.",position:[-32.93272779999999, -60.6508961]},
  {category:"salud",type:"centro de d\u00eda",address:"Sarmiento 2161, Rosario , Santa Fe",name:"C.D. BINDU DE ATMANDIA S.R.L.",position:[-32.9626156, -60.64203749999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Alsina 1055, Rosario , Santa Fe",name:"CENTRO DE DIA \" SAN FERMIN \"",position:[-32.9427918, -60.6771054]},
  {category:"salud",type:"centro de d\u00eda",address:"PRESB\u00cdTERO HOURIET 1605, Vera , Santa Fe",name:"CENTRO DE DIA JOAQUIN PAZ S.R.L.",position:[-29.4625765, -60.2103128]},
  {category:"salud",type:"centro de d\u00eda",address:"3 DE JUNIO 645, Romang , Santa Fe",name:"CENTRO DE DIA JOAQUIN PAZ S.R.L.",position:[-29.5003652, -59.7458411]},
  {category:"salud",type:"centro de d\u00eda",address:"San Martin S N, Lanteri , Santa Fe",name:"CENTRO DE DIA JOAQUIN PAZ S.R.L.",position:[-30.24415339999999, -60.58206759999999]},
  {category:"salud",type:"miscelaneo",address:"Riobamba 1540, Rosario , Santa Fe",name:"EL LIMONERO S.R.L. ",position:[-32.961759, -60.6484463]},
  {category:"salud",type:"miscelaneo",address:"Del Valle Iberlucea N\u00b0 1044, Rosario , Santa Fe",name:"S.A.I.E. ACOMPA\u00d1ANDO SABERES de Marisa Elizabeth BARBERIS",position:[-32.9184761, -60.6782552]},
  {category:"salud",type:"miscelaneo",address:"Avda. del Rosario N\u00b0 129, Rosario , Santa Fe",name:"S.A.I.E. CENTRO DEL DESARROLLO DE LA SUBJETIVIDAD",position:[-32.9991929, -60.6265587]},
  {category:"salud",type:"asociaci\u00f3n",address:"La Rioja 3455, Santa Fe , Santa Fe",name:"ASOCIACI\u00d3N CIVIL KAIR\u00d3S OCUPACIONALSANTA FE",position:[-31.6432727, -60.71718719999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Charr\u00faa N\u00b0 4859, Distrito Colastin\u00e9 Norte, Santa Fe",name:"CENTRO DE DIA CANDILEJAS S.R.L.",position:[-31.6173202, -60.59933230000001]},
  {category:"salud",type:"centro educativo",address:"Juan Domingo Peron N\u00b0 2374, Villa Gobernador Galvez , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO CENTIR (V.G.GALVEZ)",position:[-33.0267452, -60.626536]},
  {category:"salud",type:"centro de d\u00eda",address:"Velez Sarfield N\u00b0 1015, Rosario , Santa Fe",name:"E.P.DE.S. S.R.L. Un Espacio para el desarrollo",position:[-32.9251504, -60.678255]},
  {category:"salud",type:"centro de formaci\u00f3n",address:"Darragueira N\u00b0 3257, Rosario , Santa Fe",name:"FORMACI\u00d3N LABORAL TIPO APRESTAMIENTO LABORAL EL APRENDIZ.",position:[-32.8847601, -60.6971129]},
  {category:"salud",type:" hospital",address:"Balcarce N\u00b0 1355, Villa Gobernador Galvez , Santa Fe",name:"HOSPITAL ANSELMO GAMEN VILLA GOBERNADOR G\u00c1LVEZ",position:[-33.0235452, -60.63079979999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"Buenos Aires 3232, Casilda , Santa Fe",name:"FUNDACION TODOS LOS CHICOS",position:[-33.0505992, -61.17789570000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Estrada 6949, Santa Fe , Santa Fe",name:"CENTRO DE D\u00cdA \"LOS \u00c1NGELES\"",position:[35.6869752, -105.937799]},
  {category:"salud",type:"miscelaneo",address:"G\u00fcemes 4543, Santa Fe , Santa Fe",name:"JUAN PABLO II SRL",position:[-31.6290492, -60.68641109999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Francia 3452, Santa Fe , Santa Fe",name:"ASOCIACION SANTAFESINA NUEVA CULTURA",position:[-31.6345383, -60.7112467]},
  {category:"salud",type:"miscelaneo",address:"Carlos Pellegrini 760, Venado Tuerto , Santa Fe",name:"NAHUEN de JUAN MANUEL PICCHI",position:[-33.7498846, -61.96312889999999]},
  {category:"salud",type:"centro de d\u00eda",address:"San Martin 390, Maciel , Santa Fe",name:"CENTRO DE D\u00cdA MI DESPERTAR SRL",position:[-32.4599364, -60.89121999999999]},
  {category:"salud",type:"miscelaneo",address:"Godoy Cruz 641, Rosario , Santa Fe",name:"S.A.I.E. ALQUIMIA",position:[-32.8897359, -60.69291209999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Entre Rios 331, Venado Tuerto , Santa Fe",name:"ASOCIACION CIVIL ENLACES",position:[-33.743723, -61.95250619999999]},
  {category:"salud",type:"miscelaneo",address:"ITURRASPE 476, Reconquista , Santa Fe",name:"CD PASO A PASO perteneciente a LAURA CHAPERO",position:[-29.14346189999999, -59.6419194]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Freyre 2954, Santa Fe , Santa Fe",name:"CENTRO DE D\u00cdA FREYRE de HUMANITAS SRL",position:[-31.6394191, -60.7164429]},
  {category:"salud",type:"miscelaneo",address:"Vicente Marmol 308, San Lorenzo , Santa Fe",name:"ESIN UN ESPACIO INTEGRAL de SILVIA FERN\u00c1NDEZ",position:[-32.7597532, -60.73350429999999]},
  {category:"salud",type:"centro educativo",address:"Virasoro 1933, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO GIROS S.R.L ",position:[-32.9679737, -60.6557505]},
  {category:"salud",type:"miscelaneo",address:"CORDOBA 2569, Rosario , Santa Fe",name:"S.A.I.E. del FIDEICOMISO CEFIR",position:[-32.9440665, -60.65874479999999]},
  {category:"salud",type:"miscelaneo",address:"Rioja 2579, Rosario , Santa Fe",name:"S.A.I.E. del FIDEICOMISO CEFIR",position:[-32.9440299, -60.6587208]},
  {category:"salud",type:"miscelaneo",address:"AVENIDA ROSARIO 263, Rosario , Santa Fe",name:"LA CASA DEL SOL NACIENTE S.A. ",position:[-32.999639, -60.6354389]},
  {category:"salud",type:"miscelaneo",address:"Sanchez de Bustamante N\u00ba 151 Bis, Rosario , Santa Fe",name:"LA CASA DEL SOL NACIENTE S.A. ",position:[-33.0012703, -60.62597029999999]},
  {category:"salud",type:"miscelaneo",address:"HILARION DE LA QUINTANA 63 BIS, Rosario , Santa Fe",name:"LA CASA DEL SOL NACIENTE S.A. ",position:[-33.0005868, -60.6310393]},
  {category:"salud",type:"asociaci\u00f3n",address:"SAN CARLOS 959, San Lorenzo , Santa Fe",name:"S.A.I.E. SAN FRANCISCO de la ASOC CIVIL SANTA MONICA",position:[-32.751955, -60.7324456]},
  {category:"salud",type:"centro de d\u00eda",address:"ESPA\u00d1A 2045, Rosario , Santa Fe",name:"CTRO DE SALUD MENTAL TERCER FILTRO",position:[-32.9598799, -60.6501715]},
  {category:"salud",type:"centro de d\u00eda",address:"Pte. Roca 1184, Rosario , Santa Fe",name:"CTRO DE SALUD MENTAL TERCER FILTRO",position:[-33.0061907, -60.6560335]},
  {category:"salud",type:"centro educativo",address:"MENDOZA 3219, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO ALAS DE NI\u00d1O de LUCIANA MENDIETA ",position:[-32.9464677, -60.6684391]},
  {category:"salud",type:"centro de rehabilitacion",address:"AV. ORO\u00d1O 1488, Rosario , Santa Fe",name:"SERVICIO DE REHABILITACI\u00d3N SIROMA S.A ",position:[-32.9520883, -60.6557463]},
  {category:"salud",type:"miscelaneo",address:"LA PLATA 938, Carcara\u00f1a , Santa Fe",name:"PASADIZO de VELAZCO MARIELA P. E, PEREZ SILVIA D. S.S.",position:[-32.8585339, -61.1588486]},
  {category:"salud",type:"centro de d\u00eda",address:"J. J. PASO 3478, Santa Fe , Santa Fe",name:"CENTRO DE ACCI\u00d3N DE MOVIMIENTOS COMUNITARIOS",position:[-31.661409, -60.72295630000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Entre Rios 2670, Santa Fe , Santa Fe",name:"CENTRO DE ACCI\u00d3N DE MOVIMIENTOS COMUNITARIOS",position:[-31.6600153, -60.71143960000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"ALEM 1416, Rosario , Santa Fe",name:"FUNDACI\u00d3N ROSARINA DE NEURO REHABILITACI\u00d3N ",position:[-32.9559044, -60.63035989999999]},
  {category:"salud",type:"instituto",address:"General Lopez 1843, Villa Constituci\u00f3n , Santa Fe",name:"INSTITUTO ARCO IRIS S.A.S.",position:[-33.2303603, -60.32271919999999]},
  {category:"salud",type:"instituto",address:"Borzone 888, P\u00e9rez , Santa Fe",name:"INSTITUTO ARCO IRIS S.A.S.",position:[-32.9966597, -60.7724786]},
  {category:"salud",type:"instituto",address:"Juan B. Justo 242 , Arroyo Seco , Santa Fe",name:"INSTITUTO ARCO IRIS S.A.S.",position:[-33.152764, -60.51634239999999]},
  {category:"salud",type:"miscelaneo",address:"SAN MARTIN 3884, Rosario , Santa Fe",name:"DEL CAMPUS SRL",position:[-32.9827183, -60.645072]},
  {category:"salud",type:"hogar",address:"DIMMER 475, Venado Tuerto , Santa Fe",name:"HOGAR FAMILIA ASOCIACI\u00d3N CIVIL ",position:[-33.7300266, -61.9745143]},
  {category:"salud",type:"miscelaneo",address:"Estanislao Lopez 1077, Rold\u00e1n , Santa Fe",name:"ALAS S.H. de ROLDAN",position:[-32.896893, -60.911911]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Pellegrini 541, Rosario , Santa Fe",name:"SERVICIOS DE REHABILITACION ROSARIO S.R.L.",position:[-32.9589085, -60.6331783]},
  {category:"salud",type:"centro de rehabilitacion",address:"CASTELLI 253, Venado Tuerto , Santa Fe",name:"SERVICIO DE REHABILITACION IIR VENADO TUERTO",position:[-33.7492679, -61.9611736]},
  {category:"salud",type:"centro de d\u00eda",address:"AV. MITRE 249, Rafaela , Santa Fe",name:"KAIROS ESPACIO TERAPEUTICO SRL RAFAELA",position:[-31.2527983, -61.4827586]},
  {category:"salud",type:"miscelaneo",address:"CULLEN YUGARTE 4449, Rosario , Santa Fe",name:"MARCA NATURALEZA S.R.L.",position:[-32.8784379, -60.741851]},
  {category:"salud",type:"miscelaneo",address:"Agust\u00edn \u00c1lvarez 367, Rafaela , Santa Fe",name:"A.L.P.I. RAFAELA",position:[-31.2474692, -61.4862097]},
  {category:"salud",type:"asociaci\u00f3n",address:"GUINUANES 4994, Santa Fe , Santa Fe",name:"ASOCIACION CIVIL INTEGRAR",position:[-31.6393823, -60.62138119999999]},
  {category:"salud",type:"miscelaneo",address:"SAN MARTIN 932, Arroyo Seco , Santa Fe",name:"S.A.I.E. ARROYO ALAS S.R.L. ",position:[-33.1534644, -60.5141329]},
  {category:"salud",type:"asociaci\u00f3n",address:"Bv PELLEGRINI 2660, Santa Fe , Santa Fe",name:"ASOCIACION MUTUAL DEL PERSONAL JERARQUICO DE BANCOS OFICIALES",position:[-31.6351989, -60.7040452]},
  {category:"salud",type:"instituto",address:"LAPRIDA N\u00ba 1798, Rosario , Santa Fe",name:"S.I.E.C. deL IAN INSTITUTO DE APRENDIZAJE NEUROEDUCATIVO ",position:[-32.9589669, -60.63736910000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Las Acacias y los Cedros Km. N\u00ba 4,81 Ruta 11, Recreo , Santa Fe",name:"ASOCIACION CIVIL MI LUGAR (SANTA FE)",position:[-31.6657454, -60.7411733]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Blas Parera 9501, Santa Fe , Santa Fe",name:"CENTRO DE DIA BURBURINHO",position:[-31.5717482, -60.7295503]},
  {category:"salud",type:"centro de rehabilitacion",address:"San Mart\u00edn 1863, Santa Fe , Santa Fe",name:"CENTRO DE REHABILITACI\u00d3N Y AGUA TERATPIA de JUAN CRUZ ZWIENER ",position:[-31.6539845, -60.7088754]},
  {category:"salud",type:"centro de d\u00eda",address:"4 de Enero 3174, Santa Fe , Santa Fe",name:"CENTRO DE DIA SABONA de MILAGROS FERRANDO",position:[-31.6381802, -60.70938589999999]},
  {category:"salud",type:"centro educativo",address:"Pasaje Escobedo 840, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO EL CRUCE SRL",position:[-32.9412082, -60.6533327]},
  {category:"salud",type:"centro educativo",address:"BV. RONDEUA 1281, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO SENTIRES SRL",position:[-32.9016296, -60.6906732]},
  {category:"salud",type:"miscelaneo",address:"Urquiza 2724, Rosario , Santa Fe",name:"CIMIENTOS FORMANDO REDES S.R.L.",position:[-32.9388423, -60.6596692]},
  {category:"salud",type:"miscelaneo",address:"Pueyrredon 1356, Venado Tuerto , Santa Fe",name:"TRAZANDO de TRIADA S.R.L.",position:[-33.746486, -61.97468989999999]},
  {category:"salud",type:"miscelaneo",address:"Lisandro de la Torre 1081, Venado Tuerto , Santa Fe",name:"TRAZANDO de TRIADA S.R.L.",position:[-33.7467279, -61.974974]},
  {category:"salud",type:"centro de d\u00eda",address:"Colombres Bis 1046, Rosario , Santa Fe",name:"CENTRO DE DIA FISHERTON SRL",position:[-32.9170464, -60.7301738]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda. Pte. Peron N\u00ba 3180, Rosario , Santa Fe",name:"CENTRO de dia RECREATIVO DE EDUCACION FISICA ESPECIAL",position:[-32.9558659, -60.67036039999999]},
  {category:"salud",type:"miscelaneo",address:"Garay 636, San Jorge , Santa Fe",name:"PORTAL DE SUE\u00d1OS (SAN JORGE)",position:[-31.8923307, -61.84962650000001]},
  {category:"salud",type:"centro educativo",address:"Obispo Gelabert 2884, Santa Fe , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO TRISQUEL",position:[-31.6359211, -60.7072101]},
  {category:"salud",type:"centro de d\u00eda",address:"Libertad 1168, Santo Tome , Santa Fe",name:"CENTRO DE DIA EL MIRADOR",position:[-31.6582893, -60.75836529999999]},
  {category:"salud",type:"centro educativo",address:"Ruta Nac. 9 Km. 95, Rold\u00e1n , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO HUELLAS DE MARIPOSAS",position:[-32.9080303, -60.8949191]},
  {category:"salud",type:"asociaci\u00f3n",address:"CHACABUCO 296, Maximo Paz , Santa Fe",name:"ASOCIACION CIVIL ENTRAMADOS",position:[-33.4827826, -60.9528814]},
  {category:"salud",type:"instituto",address:"Pasco 390, Rosario , Santa Fe",name:"S.A.I.E. de INTITUTO DE INTEGRACIONES ESCOLARES",position:[-32.9612951, -60.63125809999999]},
  {category:"salud",type:"miscelaneo",address:"GUEMES 1175, Soldini , Santa Fe",name:"CD TENDIENDO PUENTES PROPIEDAD COMUNA DE SOLDINI",position:[-33.0259789, -60.75285390000001]},
  {category:"salud",type:"centro de d\u00eda",address:"Silvestre Begnis 1795, Sunchales , Santa Fe",name:"CENTRO DE DIA de la ASOCIACI\u00d3N CIVIL \u201cEL FARO\u201d ",position:[-30.9385833, -61.54631810000001]},
  {category:"salud",type:"miscelaneo",address:"CALLAO 299, Rosario , Santa Fe",name:"SAIE TINKU",position:[-32.936607, -60.65849530000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"27 de febrero 1435, Rosario , Santa Fe",name:"FUNDACION SEGUNDA ETAPA",position:[-32.9668833, -60.64832209999999]},
  {category:"salud",type:"miscelaneo",address:"PEDRO GOYENA 814, Rosario , Santa Fe",name:"CD AVRIL perteneciente a AVRIL S.R.L.",position:[-32.876389, -60.69834279999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Cerdan 351, Susana , Santa Fe",name:"CD SENDEROS SH SUSANA",position:[-31.3584827, -61.5142531]},
  {category:"salud",type:"fundaci\u00f3n",address:"LAPRIDA 574, Ca\u00f1ada de G\u00f3mez , Santa Fe",name:"FL SAN ROQUE perteneciente a MUTUAL DE AYUDA DE LA ASOCIACI\u00d3N DEPORTIVA EVERTO",position:[-32.8135159, -61.4023564]},
  {category:"salud",type:"fundaci\u00f3n",address:"Quintana 506, Ca\u00f1ada de G\u00f3mez , Santa Fe",name:"FL SAN ROQUE perteneciente a MUTUAL DE AYUDA DE LA ASOCIACI\u00d3N DEPORTIVA EVERTO",position:[-32.8143898, -61.39980010000001]},
  {category:"salud",type:"miscelaneo",address:"PASAJE ESCOBEDO 840, Rosario , Santa Fe",name:"S.A.I.E. DINAMO perteneciente a MARIA EUGENIA PERRAZO Y MARIA EUGENIA ACURSO S.H",position:[-32.9412082, -60.6533327]},
  {category:"salud",type:"centro educativo",address:"GUTENBERG 866, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO FORT-DA S.R.L.",position:[-32.9383203, -60.6876027]},
  {category:"salud",type:"asociaci\u00f3n",address:"Mariano Moreno N\u00ba 537, San Jer\u00f3nimo Norte , Santa Fe",name:"ASOCIACION CIVIL \"ENREDES\"ASOC EDUC TERAP P  ABORDAJE INTEG P CON DISCA",position:[-31.5527338, -61.077921]},
  {category:"salud",type:"asociaci\u00f3n",address:"1ro de Mayo N\u00ba 40, San Guillermo , Santa Fe",name:"ASOCIACION CENTRO ESPERANZA (San Guilermo)",position:[-30.35908, -61.91688539999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"Ruta 40s Arturo Paoli s n, Fort\u00edn Olmos , Santa Fe",name:"ASOCIACION NUEVA ESPERANZA (FORTIN OLMOS)",position:[-29.0545989, -60.4112427]},
  {category:"salud",type:"miscelaneo",address:"Eva Per\u00f3n 8242 bis, Rosario , Santa Fe",name:"APRENDER A VOLAR de INCLUIR SRL",position:[-32.9277793, -60.74011119999999]},
  {category:"salud",type:"miscelaneo",address:"Calchaqui y Virgen del Rosario S n, Rold\u00e1n , Santa Fe",name:"APRENDER A VOLAR de INCLUIR SRL",position:[-32.9096581, -60.86989819999999]},
  {category:"salud",type:"miscelaneo",address:"Av. Pellegrini 1665 , Rosario , Santa Fe",name:"APRENDER A VOLAR de INCLUIR SRL",position:[-32.9560869, -60.6487752]},
  {category:"salud",type:"miscelaneo",address:"GABOTO 390, Rosario , Santa Fe",name:"S.A.I.E. ENCUENTROS perteneciente a NATALIA RODRIGUEZ",position:[-32.9750728, -60.6348611]},
  {category:"salud",type:"hogar",address:"MENDOZA 667, El Tr\u00e9bol , Santa Fe",name:"HOGAR ARCO IRIS de MUNICIPALIDAD DEL TREBOL",position:[-32.2102225, -61.70630809999999]},
  {category:"salud",type:"miscelaneo",address:"Psje. Amsterdan 1068, Rosario , Santa Fe",name:"ABRIENDO CAMINOS S.R.L",position:[-32.9932912, -60.648612]},
  {category:"salud",type:"miscelaneo",address:"3 de Febrero 1843, Rosario , Santa Fe",name:"CRIANZAS ESPACIO TERAP\u00c9UTICO de CENTRO DE NEUROLOGIA DE ROSARIO S.R.L.",position:[-32.9510488, -60.6500746]},
  {category:"salud",type:"miscelaneo",address:"Bv. Oro\u00f1o 1008, Rosario , Santa Fe",name:"CRIANZAS ESPACIO TERAP\u00c9UTICO de CENTRO DE NEUROLOGIA DE ROSARIO S.R.L.",position:[-32.9464402, -60.6542121]},
  {category:"salud",type:"miscelaneo",address:"Pasaje Colibr\u00ed 174, Rosario , Santa Fe",name:"S.A.I.E. DESPERTARES (ROSARIO)",position:[-32.9274657, -60.69226680000001]},
  {category:"salud",type:"miscelaneo",address:"Las Heras N\u00ba 713, Totoras , Santa Fe",name:"S.A.I.E. ABRIENDO PUERTAS",position:[-32.5878854, -61.16440960000001]},
  {category:"salud",type:"asociaci\u00f3n",address:"Calle 16 N\u00ba 949, Godeken , Santa Fe",name:"SOL DE GODEKEN ASOCIACION CIVIL",position:[-33.400758, -61.84533219999999]},
  {category:"salud",type:"miscelaneo",address:"Necochea 2149, Rosario , Santa Fe",name:"EL ANDEN S.R.L.",position:[-32.9649943, -60.62803289999999]},
  {category:"salud",type:"clinica",address:"AGUSTIN ALVAREZ 95, Rafaela , Santa Fe",name:"LA CLINICA ASOCIACION SIMPLE",position:[-31.2525979, -61.49164219999999]},
  {category:"salud",type:"miscelaneo",address:"COLOMBRES 316 BIS, Rosario , Santa Fe",name:"CD CAMINOS COMPARTIDOS perteneciente a \u201cAL-PI\u201d SRL",position:[-32.93220730000001, -60.72980920000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"ESPA\u00d1A 815, Venado Tuerto , Santa Fe",name:"SERVICIO DE REHABILITACION perteneciente a \u201cCRENI\u201d SRL (VENADO TUERTO)",position:[-33.7434584, -61.9629916]},
  {category:"salud",type:"miscelaneo",address:"LAVALLE 1145, Venado Tuerto , Santa Fe",name:"SAPASAEM S.R.L. VENADO TUERTO",position:[-33.7442938, -61.9723954]},
  {category:"salud",type:"miscelaneo",address:"PELLEGRINI 1050, Rold\u00e1n , Santa Fe",name:"S.A.I.E SOMOS UNO SRL FUNES",position:[-32.8978918, -60.9122463]},
  {category:"salud",type:"centro de d\u00eda",address:"Dr. Riva 1080, Rosario , Santa Fe",name:"CD de la COOPERATIVA DE TRABAJO COMMUNITAS LTDa",position:[-32.9836834, -60.6468709]},
  {category:"salud",type:"centro de d\u00eda",address:"juan de garay s n, Cayast\u00e1 , Santa Fe",name:"CENTRO DE DIA de MANANTIALES S.R.L.",position:[-31.1989074, -60.1597187]},
  {category:"salud",type:"miscelaneo",address:"9 de julio 3672, Rosario , Santa Fe",name:"S.A.I.E. INSTITUTO DE PEDAGOGIA CLINICA DE ROSARIO SRL",position:[-32.9474608, -60.6751253]},
  {category:"salud",type:"centro de d\u00eda",address:"Avanthay 508, Rafaela , Santa Fe",name:"CENTRO DE DIA TIEMPOS RAFAELA",position:[-31.2465455, -61.4991383]},
  {category:"salud",type:"instituto",address:"oro\u00f1o 1431, Rosario , Santa Fe",name:"INSTITUTO DE NEUROCIENCIAS ORO\u00d1O S.A.",position:[-32.9514776, -60.6549241]},
  {category:"salud",type:"centro de d\u00eda",address:"CHACABUCO 496- SOLMAR, Rufino , Santa Fe",name:"CIAD RUFINO Y SOLMAR DE CIAD RUFINO S.R.L.",position:[-34.2688008, -62.7077731]},
  {category:"salud",type:"centro de d\u00eda",address:"san juan 632, Rufino , Santa Fe",name:"CIAD RUFINO Y SOLMAR DE CIAD RUFINO S.R.L.",position:[-34.2670886, -62.7045852]},
  {category:"salud",type:"miscelaneo",address:"maipu 2286, Rosario , Santa Fe",name:"S.A.I.E. CIREI SRL",position:[-32.9644428, -60.639963]},
  {category:"salud",type:"fundaci\u00f3n",address:"Avda. Felipe Hughes y Ameghino, Hughes , Santa Fe",name:"FUNDACION SENDEROS QUE UNEN",position:[-33.7986257, -61.3325731]},
  {category:"salud",type:"miscelaneo",address:"RIVADAVIA 1144, P\u00e9rez , Santa Fe",name:"ABC ESPACIO TERAPEUTICO",position:[-32.9980155, -60.7684891]},
  {category:"salud",type:"centro de d\u00eda",address:"BELGRANO 141, Santo Domingo , Santa Fe",name:"CENTRO DE DIA de la ASOCIACION CIVIL EL JACARANDA",position:[-31.1197752, -60.8886577]},
  {category:"salud",type:"miscelaneo",address:"urquiza 1821, Rosario , Santa Fe",name:"S.A.I.E. AQUARELA",position:[-32.9412596, -60.6472743]},
  {category:"salud",type:"fundaci\u00f3n",address:"laprida 1268, Rosario , Santa Fe",name:"FUNDACION DEL GRAN ROSARIO",position:[-32.9530576, -60.6356346]},
  {category:"salud",type:"centro de d\u00eda",address:"GENERAL PAZ 1986, Funes , Santa Fe",name:"CENTRO ANDALHUE S.R.L.",position:[-32.9138909, -60.81399529999999]},
  {category:"salud",type:"miscelaneo",address:"MENDOZA 2272, Rosario , Santa Fe",name:"S.A.I.E. de BRAINING CENTER S.R.L.",position:[-32.948549, -60.65551800000001]},
  {category:"salud",type:"centro de d\u00eda",address:"CALLE 12 N\u00ba 315, Avellaneda , Santa Fe",name:"CENTRO DE DIA MUTUAL DE LA COSTA",position:[-29.1155167, -59.65805100000001]},
  {category:"salud",type:"miscelaneo",address:"ITALIA 1157, Rosario , Santa Fe",name:"INTI RAYMI SRL",position:[-32.9492699, -60.64891479999999]},
  {category:"salud",type:"miscelaneo",address:"2 DE ABRIL 282, Venado Tuerto , Santa Fe",name:"S.A.I.E. NEXO VENADO TUERTO",position:[-33.7540559, -61.95414239999999]},
  {category:"salud",type:"miscelaneo",address:"SARMIENTO 4105, Rosario , Santa Fe",name:"S.A.I.E. FLORECER",position:[-32.98495620000001, -60.6477199]},
  {category:"salud",type:"centro educativo",address:"SAN MARTIN 693, Elortondo , Santa Fe",name:"CENTRO EDUCATIVO RECREATIVO ELORTONDO",position:[-33.698536, -61.6106148]},
  {category:"salud",type:"centro educativo",address:"FRANCIA 619, Elortondo , Santa Fe",name:"CENTRO EDUCATIVO RECREATIVO ELORTONDO",position:[-33.7003348, -61.6117115]},
  {category:"salud",type:"centro de d\u00eda",address:"LAS MORAS 7312, Santa Fe , Santa Fe",name:"CENTRO DE DIA \u201cMIRAR AL SOL\u201d perteneciente a RP S.R.L.",position:[-31.6119375, -60.5973263]},
  {category:"salud",type:"miscelaneo",address:".IRIONDO 640, Rosario , Santa Fe",name:"CASA AZUL perteneciente a GRUPO AZUL S.R.L.",position:[-32.938919, -60.66935050000001]},
  {category:"salud",type:"centro educativo",address:"SARMIENTO 2009, Funes , Santa Fe",name:"CENTRO EDUCATIVO TERAP\u00c9UTICO \u201cAPRENDIENDO A APRENDER\u201d S.R.L.",position:[-32.9186329, -60.8156364]},
  {category:"salud",type:"miscelaneo",address:"Tucum\u00e1n 3356 , Rosario , Santa Fe",name:"EQUIPONEURO S.R.L.",position:[-32.9361863, -60.6678533]},
  {category:"salud",type:"centro de d\u00eda",address:"Chacabuco 271 , Rufino , Santa Fe",name:"CENTRO DE DIA SANTA FRANCISCA S.Asoc.",position:[-34.2642025, -62.70644449999999]},
  {category:"salud",type:"centro de d\u00eda",address:"SANTA FE 340, Rufino , Santa Fe",name:"CENTRO DE DIA SANTA FRANCISCA S.Asoc.",position:[-34.262525, -62.71623080000001]},
  {category:"salud",type:"centro de rehabilitacion",address:"Catamarca 2120, Rosario , Santa Fe",name:"CENTRO DE NEUROREHABILITACI\u00d3N SANTE de RED PSICOTERAP\u00c9UTICA S.R.L. ",position:[-32.9379584, -60.65076]},
  {category:"salud",type:"centro de rehabilitacion",address:"SAN MARTIN 2054, Rosario , Santa Fe",name:"CENTRO DE NEUROREHABILITACI\u00d3N SANTE de RED PSICOTERAP\u00c9UTICA S.R.L. ",position:[-32.9616043, -60.64054710000001]},
  {category:"salud",type:"miscelaneo",address:"Mitre 2344, Rosario , Santa Fe",name:"LIBRA - TRESMAVI S.R.L.",position:[-32.9644432, -60.64430669999999]},
  {category:"salud",type:"miscelaneo",address:"Libertad 1245, Villa Constituci\u00f3n , Santa Fe",name:"AYECAN VILLA CONSTITUCION",position:[-33.2255367, -60.3445933]},
  {category:"salud",type:"miscelaneo",address:"BOLIVAR 55, Rafaela , Santa Fe",name:"SAIE ESPEJOS",position:[-31.2500178, -61.49164630000001]},
  {category:"salud",type:"miscelaneo",address:"CATAMARCA 2870, Rosario , Santa Fe",name:"S.A.I.E. LA RONDA AITE S.R.L.",position:[-32.936183, -60.6609241]},
  {category:"salud",type:"miscelaneo",address:"ALEM 760, San Lorenzo , Santa Fe",name:"PERSONARE S.R.L. de MARCELA ALEJANDRA SPEDALETTI",position:[-32.7389476, -60.73566629999999]},
  {category:"salud",type:"hogar",address:"CARRASCO 1221, Rosario , Santa Fe",name:"HOGAR Y CENTRO DE DIA SANTA ROSA de JORGE L. MASTRANTUONI",position:[-32.9202005, -60.680482]},
  {category:"salud",type:"fundaci\u00f3n",address:"RIVADAVIA 1358, Reconquista , Santa Fe",name:"FUNDACION S.I.R.E.T.",position:[-29.1487811, -59.6536681]},
  {category:"salud",type:"centro de d\u00eda",address:"CORDOBA 4045, Funes , Santa Fe",name:"S.A.I.E. de la ASOCIACION CIVIL DEVENIRES",position:[-32.917055, -60.83920800000001]},
  {category:"salud",type:"miscelaneo",address:"OVIDIO LAGOS 1233, Rosario , Santa Fe",name:"EL REINO DEL REVES ",position:[-32.9478032, -60.6626659]},
  {category:"salud",type:"hogar",address:"AV ITALIA 2090, Rafaela , Santa Fe",name:"HOGAR FUNDACION EL CEIBO",position:[-31.2361593, -61.47376339999999]},
  {category:"salud",type:"miscelaneo",address:"Avda. de las Libertades 1570, Rold\u00e1n , Santa Fe",name:"BETA SALUD de BETA SALUD S.R.L.",position:[-32.9321961, -60.8926489]},
  {category:"salud",type:"miscelaneo",address:"SANTIAGO 757, Rosario , Santa Fe",name:"S.A.I.E. BAIGORRI MARIA DE LA PAZ",position:[-32.9427064, -60.6557634]},
  {category:"salud",type:"miscelaneo",address:"25 DE MAYO 748, Villa Mugueta , Santa Fe",name:"JARDINES GRANJA SOLES S.A.",position:[-33.3098409, -61.0557554]},
  {category:"salud",type:"centro de d\u00eda",address:"TARRAGONA 182, Rosario , Santa Fe",name:"CENTRO DE DIA APREHENDER SRL",position:[-32.9308298, -60.73453139999999]},
  {category:"salud",type:"hogar",address:"BROWN 2019, Esperanza , Santa Fe",name:"HOGAR perteneciente a la ASOCIACION CIVIL COMPROMISO",position:[-31.4489859, -60.93036629999999]},
  {category:"salud",type:"asociaci\u00f3n",address:"JUAN BERIZZO 2074, Franck , Santa Fe",name:"ASOC CIVIL DE AYUDA A DISCAPACITADO DE FRANCK",position:[-31.5873037, -60.9452205]},
  {category:"salud",type:"centro de d\u00eda",address:"9 DE JULIO 577, Reconquista , Santa Fe",name:"CENTRO DE DIA ALAS PARA TU VIDA de FORMANDO ALAS S.R.L.",position:[-29.1428889, -59.6469685]},
  {category:"salud",type:"centro de d\u00eda",address:"Avda DE LOS CONQUISTADORES 31, Esperanza , Santa Fe",name:"CENTRO DE DIA de FAROLES S.A.S.",position:[-31.4489859, -60.93036629999999]},
  {category:"salud",type:"miscelaneo",address:"CASTELLANOS 557, Rosario , Santa Fe",name:"S.A.I.E. AYUN GRUPO INCLUIR S.R.L",position:[-32.937104, -60.6742167]},
  {category:"salud",type:"miscelaneo",address:"ROSARIO 437, San Lorenzo , Santa Fe",name:"HUELLAS de HUELLAS S.A.S.",position:[-32.9464376, -60.6326123]},
  {category:"salud",type:"centro educativo",address:"COCHABAMBA 859, Rosario , Santa Fe",name:"C.E.T. INSTITUTO MULTIDISCIPLINARIO IAM de IAMCET S.R.L.",position:[-32.9590936, -60.6379919]},
  {category:"salud",type:"miscelaneo",address:"JUAN JOSE PASO N\u00ba 1496, Pueblo Esther , Santa Fe",name:"GRANJA SOLES S.R.L.",position:[-33.0728252, -60.5786768]},
  {category:"salud",type:"miscelaneo",address:"EL LIBERTADOR 1960, Pueblo Esther , Santa Fe",name:"GRANJA SOLES S.R.L.",position:[-33.0802371, -60.56957939999999]},
  {category:"salud",type:"miscelaneo",address:"MENDOZA 2561, Rosario , Santa Fe",name:"CENTRO IMAGO S.R.L.",position:[-32.9480976, -60.6594764]},
  {category:"salud",type:"hogar",address:"Ruta 8 Km.371, Venado Tuerto , Santa Fe",name:"HOGAR CON CENTRO DE DIA LOS PINOS de HEILEN S.A.",position:[-33.7177052, -62.003848]},
  {category:"salud",type:"miscelaneo",address:"BV. COLON 4580, Casilda , Santa Fe",name:"CINTRE S.A. ",position:[-33.062863, -61.1750532]},
  {category:"salud",type:"miscelaneo",address:"Balcarce 1044, Venado Tuerto , Santa Fe",name:"CINTRE S.A. ",position:[-33.7398268, -61.9618443]},
  {category:"salud",type:"centro educativo",address:"Catamarca 2081, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO de FUNDACI\u00d3N CASA DE FAMILIA",position:[-32.9383199, -60.65007689999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"AVDA PELLEGRINI 156, Rosario , Santa Fe",name:"CENTRO DE REHABILITACION INFANTIL\"CERIN SRL\"",position:[-32.959473, -60.62754330000001]},
  {category:"salud",type:"miscelaneo",address:"Darragueira 1714, Rosario , Santa Fe",name:"S.A.I.E AMANECE",position:[-32.8976351, -60.6938863]},
  {category:"salud",type:"miscelaneo",address:"Cerrito 768, Rosario , Santa Fe",name:"TODO MI MUNDO SAS",position:[-32.9625116, -60.6376041]},
  {category:"salud",type:"fundaci\u00f3n",address:"Salta 359, Venado Tuerto , Santa Fe",name:"FUNDACION TENDIENDO PUENTES",position:[-33.7394712, -61.9550627]},
  {category:"salud",type:"centro educativo",address:"PUEYRREDON N\u00ba 5635, Rosario , Santa Fe",name:"CENTRO EDUCATIVO TERAPEUTICO EL CAMINO S.R.L.",position:[-33.0029542, -60.6689987]},
  {category:"salud",type:"miscelaneo",address:"BOLIVAR 1791, Villa Constituci\u00f3n , Santa Fe",name:"S.A.I.E, MIRADAS CONSULTORIOS TERAPEUTICOS S.R.L.",position:[-33.2348208, -60.32969]},
  {category:"salud",type:"centro de rehabilitacion",address:"Avda. Pellegrini 1353, Rosario , Santa Fe",name:"SERVICIO DE REHABILITACION \u201cPHYSICAL\u201d propiedad de ASOCIACION MUTUAL 2 DE OCTUBR",position:[-32.9568831, -60.6444793]},
  {category:"salud",type:"centro de d\u00eda",address:"San Mart\u00edn 233, Mar\u00eda Juana , Santa Fe",name:"CENTRO DE D\u00cdA UN ESPACIO PARA TODOS de ASOCIACI\u00d3N CIVIL UN ESPACIO PARA TODOS",position:[-31.6743971, -61.7537835]},
  {category:"salud",type:"miscelaneo",address:"Olesio 1487, Reconquista , Santa Fe",name:"S.I.R.E.T. ",position:[-29.1523561, -59.6507805]},
  {category:"salud",type:"miscelaneo",address:"Maip\u00fa 1007 - M\u00e1ximo Paz, Maximo Paz , Santa Fe",name:"S.A.I.E. ESPACIO TERAP\u00c9UTICO INTERDISCIPLINARIO EL \u00c1RBOL S.R.L. ",position:[-33.4854799, -60.9545868]},
  {category:"salud",type:"hogar",address:"Mosconi 2024 - Ruta 9 Km. 321, Funes , Santa Fe",name:"HOGAR VIVIENCIAS",position:[-32.920302, -60.81615969999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Gral. L\u00f3pez 3579, San Lorenzo , Santa Fe",name:"CENTRO DE DIA MERAKI",position:[-32.7261783, -60.73381180000001]},
  {category:"salud",type:"miscelaneo",address:"Ing. Mosconi 1358, Villa Gobernador Galvez , Santa Fe",name:"S.A.I.E. EDUTECA",position:[-33.0253882, -60.63112829999999]},
  {category:"salud",type:"miscelaneo",address:"Espa\u00f1a 2571, Rosario , Santa Fe",name:"S.A.I.E. PAGO DE LOS ARROYOS",position:[-32.96559209999999, -60.6516069]},
  {category:"salud",type:"centro de rehabilitacion",address:"Newbery 2398, Funes , Santa Fe",name:"SERVICIO DE REHABILITACION PLUSVITA",position:[-32.9280112, -60.78168580000001]},
  {category:"salud",type:"centro educativo",address:"Reconquista 752 , Alcorta , Santa Fe",name:"LOS GIRASOLES de CENTRO EDUCATIVO TERAP\u00c9UTICO LOS GIRASOLES S.R.L.",position:[-33.5371808, -61.1263549]},
  {category:"salud",type:"asociaci\u00f3n",address:"Bv. Las Colonias 1120 , San Jos\u00e9 De La Esquina , Santa Fe",name:"ASOCIACI\u00d3N CIVIL CRECIENDO JUNTO A VOS",position:[-33.1192681, -61.7080412]},
  {category:"salud",type:"miscelaneo",address:"Moreno 1514, Rosario , Santa Fe",name:"YUMBREL S.R.L.",position:[-32.9587022, -60.69304159999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Santiago 15 bis , Rosario , Santa Fe",name:"CENTRO DE DIA ATELIER",position:[-32.93461300000001, -60.6535599]},
  {category:"salud",type:"miscelaneo",address:"Irigoyen 230, Ca\u00f1ada de G\u00f3mez , Santa Fe",name:"S.A.E.I. de la ASOCIACION CIVIL LABERINTOS",position:[-32.818643, -61.39481490000001]},
  {category:"salud",type:"centro de d\u00eda",address:"SAN JUAN 2291, Santo Tome , Santa Fe",name:"CENTRO DE DIA SAN JUAN propiedad de LUCAS GIRARDINI",position:[-31.6696669, -60.75220960000001]},
  {category:"salud",type:"miscelaneo",address:"PINTOR MUSTO 347, Rosario , Santa Fe",name:"S.A.I.E. ANAMBE",position:[-32.8729223, -60.6921609]},
  {category:"salud",type:"miscelaneo",address:"J.D. PERON 2369, Villa Gobernador Galvez , Santa Fe",name:"S.A.I.E. EQUIPO INTEGRA S.R.L.",position:[-33.0282925, -60.6245065]},
  {category:"salud",type:"centro de d\u00eda",address:"ALBERDI 1140, Villa Ocampo , Santa Fe",name:"CENTRO DE DIA AVUS VITAN",position:[-28.4910092, -59.3516928]},
  {category:"salud",type:"centro de d\u00eda",address:"JUAN DE GARAY 2829, Santa Fe , Santa Fe",name:"CENTRO DE DIA CUENTAME ",position:[-31.6521644, -60.7114219]},
  {category:"salud",type:"miscelaneo",address:"Iguaz\u00fa 130 ex 71, Rosario , Santa Fe",name:"AMANECER A LA VIDA S.R.L.",position:[-32.9587022, -60.69304159999999]},
  {category:"salud",type:"centro de d\u00eda",address:"BV OCAMPO 951, Helvecia , Santa Fe",name:"CENTRO DE DIA \u201cENCUENTROS\u201d",position:[-31.10353529999999, -60.09418819999999]},
  {category:"salud",type:"miscelaneo",address:"Pasteur 2174, Villa Gobernador Galvez , Santa Fe",name:"S.A.I.E. de MANDAGARAN, PEREZ Y SECONDO",position:[-33.0269616, -60.62527369999999]},
  {category:"salud",type:"miscelaneo",address:"MARCOS CIANI 2096 , Venado Tuerto , Santa Fe",name:"RIZOMA- OCUPARTE S.R.L. ",position:[-33.741071, -61.98494759999999]},
  {category:"salud",type:"miscelaneo",address:"SANTA FE 4073, Rosario , Santa Fe",name:"S.A.I.E ATELIER SRL",position:[-32.938027, -60.67829740000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"Sanchez de Bustamante 65 Bis, Rosario , Santa Fe",name:"FORMACION LABORAL LA MADEJA de la FUNDACION LA CASA DEL SOL NACIENTE",position:[-33.0014234, -60.6276479]},
  {category:"salud",type:"fundaci\u00f3n",address:"PASAJE COFFIN N\u00ba 3050, Rosario , Santa Fe",name:"FUNDACION ABACO",position:[-32.9598934, -60.6696793]},
  {category:"salud",type:"miscelaneo",address:"PARAGUAY 2502, Rosario , Santa Fe",name:"DEJANDO HUELLAS S.R.L.",position:[-32.9655384, -60.6488772]},
  {category:"salud",type:"miscelaneo",address:"3 DE FEBRERO 3702 72, Santa Fe , Santa Fe",name:"RED INTEGRAL DE ASISTENCIA MENTAL - RIAM S.A.",position:[-31.6563262, -60.7210944]},
  {category:"salud",type:"miscelaneo",address:"SAN MARTIN 1863, Santa Fe , Santa Fe",name:"CE.N.A.T. S.R.L.",position:[-31.6106578, -60.697294]},
  {category:"salud",type:"centro de d\u00eda",address:"RIVADAVIA 2225, Laguna Paiva , Santa Fe",name:"CENTRO DE DIA INTEGRAR - LAGUNA PAIVA S.R.L.",position:[-31.3074988, -60.6621257]},
  {category:"salud",type:"centro de d\u00eda",address:"4 DE ENERO 3174, Santa Fe , Santa Fe",name:"CENTRO DE D\u00cdA SABONA PROPIEDAD DE FUNDACION SABONA",position:[-31.6381802, -60.70938589999999]},
  {category:"salud",type:"fundaci\u00f3n",address:"BUENOS AIRES 1069, Rosario , Santa Fe",name:"FUNDACI\u00d3N ALBORADA",position:[-32.9509503, -60.6334648]},
  {category:"salud",type:"miscelaneo",address:"PASTEUR 2035, Villa Gobernador Galvez , Santa Fe",name:"DIVERSITAS S.A.S",position:[-33.025779, -60.62618389999999]},
  {category:"salud",type:"centro de d\u00eda",address:"JUAN B JUSTO 144, Rosario , Santa Fe",name:"INKA CENTRO DE REHABILITACI\u00d3N NEUROLOGICA S.A",position:[-32.9152696, -60.7255851]},
  {category:"salud",type:"miscelaneo",address:"URQUIZA 2041, Villa Gobernador Galvez , Santa Fe",name:"MARSILUC S.R.L.",position:[-33.0330409, -60.63472540000001]},
  {category:"salud",type:"miscelaneo",address:"25 DE MAYO 2726, Santa Fe , Santa Fe",name:"I.N.E.A PROPIEDAD DE SANTIAGO-WAGNER SRL",position:[-31.6106578, -60.697294]},
  {category:"salud",type:"instituto",address:"SAN LUIS 2021, Rosario , Santa Fe",name:"INSTITUTOCZERNY CENTRO DE DIA S.R.L.",position:[-32.94683060000001, -60.6516212]},
  {category:"salud",type:"miscelaneo",address:"SUIPACHA 2342, Santa Fe , Santa Fe",name:"SANATORIO GARAY S.A.",position:[-31.6406275, -60.7018324]},
  {category:"salud",type:"miscelaneo",address:"SAN LORENZO 3640, Rosario , Santa Fe",name:"INTEGRARTE S.R.L",position:[-32.9378574, -60.6723081]},
  {category:"salud",type:"miscelaneo",address:"SAN MARTIN 841, Fighiera , Santa Fe",name:"CORAZON DE PUEBLO S.R.L",position:[-33.1923016, -60.47044029999999]},
  {category:"salud",type:"miscelaneo",address:"AV. BUENOS AIRES 1377, Granadero Baigorria , Santa Fe",name:"ESCARANUJO S.R.L",position:[-32.8586951, -60.71038819999999]},
  {category:"salud",type:"miscelaneo",address:"CORRIENTES 1926, Arequito , Santa Fe",name:"GILLI GEORGINA PAMELA",position:[-33.1520321, -61.4744778]},
  {category:"salud",type:"miscelaneo",address:"Italia S N, A\u00f1atuya , Santiago del Estero",name:" OBISPADO DE A\u00d1ATUYA",position:[-28.4673402, -62.8300989]},
  {category:"salud",type:"miscelaneo",address:"Avenida Espa\u00f1a 150, A\u00f1atuya , Santiago del Estero",name:" OBISPADO DE A\u00d1ATUYA",position:[-28.4638122, -62.8336135]},
  {category:"salud",type:"miscelaneo",address:"Italia y Arist\u00f3bulo del Valle , A\u00f1atuya , Santiago del Estero",name:" OBISPADO DE A\u00d1ATUYA",position:[-28.4690847, -62.8365179]},
  {category:"salud",type:"miscelaneo",address:"Ruta 1 Km 4 El Polear Dpto Banda, La Banda, Santiago del Estero",name:"IRIS SRL (INSTITUTO DE REEDUCACION INFANTIL SANTIAGO DEL ESTERO)",position:[-27.7344433, -64.2416267]},
  {category:"salud",type:"instituto",address:"Av. Solis N\u00b0 1130, Santiago del Estero , Santiago del Estero",name:"INSTITUTO LOLA MORA S.A.",position:[-27.8155698, -64.2359644]},
  {category:"salud",type:" cooperativa",address:"Av. Belgrano 969 , Santiago del Estero , Santiago del Estero",name:"IPAL- COOPERATIVA DE PROVISION DE SERVICIOS PARA P",position:[-27.775413, -64.2730677]},
  {category:"salud",type:"asociaci\u00f3n",address:"NECOCHEA 85 - LA BANDA - SANTIAGO DEL ES, La Banda, Santiago del Estero",name:"ASOCIACION VAY (VENZAMOS EL AUTISMO YA) ",position:[-27.7382404, -64.2462316]},
  {category:"salud",type:"miscelaneo",address:"SOR ANGELA DE LA CRUZ 158 MTE QDO DPTO COPO, Santiago del Estero , Santiago del Estero",name:"HERMANAS DE LA COMPA\u00d1IA DE LA CRUZ ",position:[-25.8008492, -62.8429059]},
  {category:"salud",type:"miscelaneo",address:"25 DE MAYO S N\u00b0 - QUIMILI - SGO DEL ESTER, Santiago del Estero , Santiago del Estero",name:"HERMANAS DE LA COMPA\u00d1IA DE LA CRUZ ",position:[-27.7833574, -64.264167]},
  {category:"salud",type:"miscelaneo",address:"San Mart\u00edn 1274, Santiago del Estero , Santiago del Estero",name:"SENDEROS - SENDAS S.R.L.",position:[-27.7989164, -64.272584]},
  {category:"salud",type:"fundaci\u00f3n",address:"Avda.Belgrano (N) 1354, Santiago del Estero , Santiago del Estero",name:"FUNDACION AN.CA.DI.(AYUDA PARA NI\u00d1OS CON CAP.DIF.)",position:[-27.7709289, -64.2762551]},
  {category:"salud",type:"miscelaneo",address:"Ruta Nac. N\u00b09 - KM 1127 - VILLA ZANJON, Villa Zanj\u00f3n, Santiago del Estero",name:"EL SOLAR - LOS HORNILLOS S.R.L.",position:[-27.8781607, -64.2435837]},
  {category:"salud",type:"miscelaneo",address:"MORENO (N) 409, Santiago del Estero , Santiago del Estero",name:"SUYAY SH",position:[-27.78229, -64.271918]},
  {category:"salud",type:"centro educativo",address:"LIBERTAD 1089, Santiago del Estero , Santiago del Estero",name:"CETSE",position:[-27.7833574, -64.264167]},
  {category:"salud",type:"centro de rehabilitacion",address:"RUTA NAC. N\u00b0 9 KM 1127 ATAHONA, Villa Zanj\u00f3n, Santiago del Estero",name:"REHABILITAR SANTIAGO SRL",position:[-27.8588447, -64.2520464]},
  {category:"salud",type:"centro educativo",address:"AV. BELGRANO 1776 , La Banda, Santiago del Estero",name:"CECDIN - MONTOTO TERESITA BEATRIZ Y FERREYRA MARIA ESTEFANIA",position:[-27.7438605, -64.2571117]},
  {category:"salud",type:"instituto",address:"CALLE SENADOR SALIM Y COLECTORA - FRIAS, Ciudad , Santiago del Estero",name:"INSTITUTO TERAPEUTICO INTEGRAL CRECER",position:[-27.7731078, -64.2807269]},
  {category:"salud",type:"centro educativo",address:"AV CORDOBA N\u00b0 11, Pinto , Santiago del Estero",name:"APROSECSS - Centro Educativo de Atenci\u00f3n M\u00faltiple",position:[-29.1499267, -62.6604372]},
  {category:"salud",type:"centro educativo",address:"Patrocinia Diaz 634 - B\u00b0 Tradici\u00f3n, Santiago del Estero , Santiago del Estero",name:"CETISAN SH",position:[-27.8195013, -64.2537832]},
  {category:"salud",type:"miscelaneo",address:"ALVARADO 261, Santiago del Estero , Santiago del Estero",name:"IMER-SERVIGEN SA",position:[-27.7833574, -64.264167]},
  {category:"salud",type:"miscelaneo",address:"Calle Avi\u00f3n Pucar\u00e1 75 barrio Aeropuerto, Santiago del Estero , Santiago del Estero",name:"EL JARDIN SRL - HORIZONTE",position:[-27.7466394, -64.3033074]},
  {category:"salud",type:"miscelaneo",address:"AVDA COLON 505, Santiago del Estero , Santiago del Estero",name:"BROCA",position:[-27.7937662, -64.2673287]},
  {category:"salud",type:"miscelaneo",address:"SARMIENTO 430, La Banda, Santiago del Estero",name:"SOLES de GASANI S.A.S.",position:[-27.729348, -64.241579]},
  {category:"salud",type:"clinica",address:"Ruta Prov. N\u00ba 306 Km 2, B\u00ba 140 Viviendas, Banda del R\u00edo Sal\u00ed , Tucuman",name:"CLINICA CASA GRANDE S.R.L. ",position:[-26.8434248, -65.2103207]},
  {category:"salud",type:"clinica",address:"Av. Mate de Luna 3041, San Miguel de Tucuman , Tucuman",name:"CLINICA CASA GRANDE S.R.L. ",position:[-26.8222187, -65.24333109999999]},
  {category:"salud",type:"escuela",address:"Catamarca 751 , San Miguel de Tucuman , Tucuman",name:"COLEGIO SAN TARCISIO S.R.L. ",position:[-26.8191705, -65.2095806]},
  {category:"salud",type:"hogar",address:"Av. Rep\u00fablica del Libano 2148 , San Miguel de Tucuman , Tucuman",name:"HOGAR PADRE TIBURCIO de PEQUE\u00d1A OBRA DE LA DIVINA PROVIDENCIA \"DON ORIONE\"",position:[-26.7994737, -65.21176009999999]},
  {category:"salud",type:"escuela",address:"Pje. Comandante Espora 1540 60 , San Miguel de Tucuman , Tucuman",name:"ESCUELA ESPECIAL EL TALLER ",position:[-26.8077265, -65.2071507]},
  {category:"salud",type:"escuela",address:"Av. Avellaneda 240 , San Miguel de Tucuman , Tucuman",name:"ESCUELA ESPECIAL EL TALLER ",position:[-26.8289675, -65.1964897]},
  {category:"salud",type:"centro educativo",address:"LAPRIDA N\u00ba 365, San Miguel de Tucuman , Tucuman",name:"CEPRIR ",position:[-26.8264582, -65.2019834]},
  {category:"salud",type:"centro educativo",address:"Avenida Aconquija 749, Yerba Buena , Tucuman",name:"CEPRIR ",position:[-26.8160418, -65.2765932]},
  {category:"salud",type:"miscelaneo",address:"Padre Roque Correa 57 , San Miguel de Tucuman , Tucuman",name:"SPERANTIA TUCUMAN ASOCIACION CIVIL",position:[-26.8215608, -65.243506]},
  {category:"salud",type:"instituto",address:"Crisostomo Alvarez 1241 51 , San Miguel de Tucuman , Tucuman",name:"Instituto ORAL TUCUM\u00c1N S.R.L. ",position:[-26.8267317, -65.2311604]},
  {category:"salud",type:"miscelaneo",address:"Avda. Roca 655 659 , San Miguel de Tucuman , Tucuman",name:"SAN GENNARO S.R.L. ",position:[-26.8342983, -65.2677462]},
  {category:"salud",type:"centro de d\u00eda",address:"Jujuy 374 , San Miguel de Tucuman , Tucuman",name:"Ctro. Rayo de Sol de Mario Fernando Jesus LADETTO ",position:[-26.8347009, -65.2119214]},
  {category:"salud",type:"instituto",address:"Cariola 1049 , Yerba Buena , Tucuman",name:"Instituto LEO KANNER S.R.L. ",position:[-26.8176071, -65.2835494]},
  {category:"salud",type:"miscelaneo",address:"Av. Salta 566 , San Miguel de Tucuman , Tucuman",name:"Acompa\u00f1ar S.R.L. ",position:[-26.8218907, -65.20910529999999]},
  {category:"salud",type:"miscelaneo",address:"san juan 714, San Miguel de Tucuman , Tucuman",name:"Acompa\u00f1ar S.R.L. ",position:[-26.8649367, -65.182111]},
  {category:"salud",type:"miscelaneo",address:"San Juan 714 , San Miguel de Tucuman , Tucuman",name:"Acompa\u00f1ar S.R.L. ",position:[-26.8649367, -65.182111]},
  {category:"salud",type:"miscelaneo",address:"Avenida Salta 585 , San Miguel de Tucuman , Tucuman",name:"Acompa\u00f1ar S.R.L. ",position:[-26.821925, -65.2087111]},
  {category:"salud",type:"escuela",address:"Balcarce 315 , San Miguel de Tucuman , Tucuman",name:"Colegio Jos\u00e9 Engling ",position:[-26.8276793, -65.1976404]},
  {category:"salud",type:"miscelaneo",address:"BALCARCE 434, San Miguel de Tucuman , Tucuman",name:"MARCKAY ",position:[-26.8260954, -65.19769800000002]},
  {category:"salud",type:"miscelaneo",address:"Pje. Sorol 469 , San Miguel de Tucuman , Tucuman",name:"MARCKAY ",position:[-26.8261542, -65.1946028]},
  {category:"salud",type:"miscelaneo",address:"AV ACONQUIJA 337, Yerba Buena , Tucuman",name:"MARCKAY ",position:[-26.817177, -65.2701632]},
  {category:"salud",type:"fundaci\u00f3n",address:"San Martin S N - Villa Clodomiro Hileret, Villa Clodomiro Hileret, Tucuman",name:"FU.SER.SOL.",position:[-27.4743701, -65.65913379999999]},
  {category:"salud",type:"miscelaneo",address:"Catamarca 735 , San Miguel de Tucuman , Tucuman",name:"Hatun Wasi SRL ",position:[-26.8192997, -65.2095806]},
  {category:"salud",type:"miscelaneo",address:"SANTIAGO DEL ESTERO 2125, San Miguel de Tucuman , Tucuman",name:"Hatun Wasi SRL ",position:[-26.8186859, -65.2278879]},
  {category:"salud",type:"miscelaneo",address:"Bernab\u00e9 Ar\u00e1oz N\u00ba456, San Miguel de Tucuman , Tucuman",name:"Hatun Wasi SRL ",position:[-26.8544316, -65.2220193]},
  {category:"salud",type:"miscelaneo",address:"Hipolito Irigoyen 80 , Concepci\u00f3n , Tucuman",name:"ACONQUIJA CLINICA PRIVADA S.R.L. ",position:[-27.3453363, -65.58834639999999]},
  {category:"salud",type:"centro educativo",address:"Houssay 3177 , Concepci\u00f3n , Tucuman",name:"C.E.T.C.O. Centro Educativo Terapeutico Concepcion",position:[-27.3469064, -65.6127825]},
  {category:"salud",type:"miscelaneo",address:"Jujuy 374, San Miguel de Tucuman , Tucuman",name:"Rayo de Sol S.R.L. ",position:[-26.8347009, -65.2119214]},
  {category:"salud",type:"miscelaneo",address:"Bolivar 147 , San Miguel de Tucuman , Tucuman",name:"Rayo de Sol S.R.L. ",position:[-26.8414542, -65.2015954]},
  {category:"salud",type:"centro de rehabilitacion",address:"9 de Julio 775 , San Miguel de Tucuman , Tucuman",name:"CENTRO MEDICO INTEGRAL DE REHABILITACION Y SALUD MEJORT",position:[-26.8413291, -65.20736389999999]},
  {category:"salud",type:"centro educativo",address:"Espa\u00f1a y San Juan , Yerba Buena , Tucuman",name:"Centro Educativo Terapeutico San Martin de Porres ",position:[-26.8230791, -65.313172]},
  {category:"salud",type:"miscelaneo",address:"Cordoba 53 , San Miguel de Tucuman , Tucuman",name:"Puerto Crecer, Servicio de Apoyo a la Integracio ",position:[-26.8279114, -65.19704879999999]},
  {category:"salud",type:"miscelaneo",address:"Av.AVELLANEDA N\u00ba 590, San Miguel de Tucuman , Tucuman",name:"Puerto Crecer, Servicio de Apoyo a la Integracio ",position:[-26.8244111, -65.19549839999999]},
  {category:"salud",type:"miscelaneo",address:"Cariola 462 , Yerba Buena , Tucuman",name:"Despertar ",position:[-26.8199872, -65.27221840000001]},
  {category:"salud",type:"miscelaneo",address:"AV. ACONQUIJA 495, Yerba Buena , Tucuman",name:"Despertar ",position:[-26.8169746, -65.2725486]},
  {category:"salud",type:"centro de rehabilitacion",address:"Santiago 771 , San Miguel de Tucuman , Tucuman",name:"CIRENE. Centro Integral de Rehabilitaci\u00f3n Neurol\u00f3g",position:[-26.8142877, -65.1341791]},
  {category:"salud",type:"fundaci\u00f3n",address:"Mendoza 2497 , San Miguel de Tucuman , Tucuman",name:"Fundaci\u00f3n \"Espiritu Santo\" ",position:[-26.8211117, -65.2343884]},
  {category:"salud",type:"fundaci\u00f3n",address:"Av. Mate de Luna 3431 , San Miguel de Tucuman , Tucuman",name:"Fundaci\u00f3n Fines Especiales \"CEFAL\" ",position:[-26.8214236, -65.24782789999999]},
  {category:"salud",type:" hospital",address:"Catamarca 2000, San Miguel de Tucuman , Tucuman",name:"HOSPITAL DE CLINICAS PRESIDENTE DR. NICOLAS AVELLANEDA",position:[-26.802301, -65.2053142]},
  {category:"salud",type:" hospital",address:"Av. San Mart\u00edn S N, Taf\u00ed del Valle , Tucuman",name:"HOSPITAL DE CLINICAS PRESIDENTE DR. NICOLAS AVELLANEDA",position:[-26.8470222, -65.7099735]},
  {category:"salud",type:" hospital",address:"SARMIENTO 453, Monteros , Tucuman",name:"HOSPITAL DE CLINICAS PRESIDENTE DR. NICOLAS AVELLANEDA",position:[-27.1626828, -65.4917403]},
  {category:"salud",type:"miscelaneo",address:"Lamadrid 955, San Miguel de Tucuman , Tucuman",name:"EDAPI S.R.L.",position:[-26.8361304, -65.21329039999999]},
  {category:"salud",type:"miscelaneo",address:"Bernab\u00e9 Ar\u00e1oz 746, San Miguel de Tucuman , Tucuman",name:"EDAPI S.R.L.",position:[-26.8389285, -65.2177377]},
  {category:"salud",type:"centro de rehabilitacion",address:"Pje. Velez Sarsfield 378, Aguilares, Tucuman",name:"CTRO. DE REHABILITACION INTEGRAL AGUILARES",position:[-27.4303525, -65.61173389999999]},
  {category:"salud",type:"centro de rehabilitacion",address:"Lucas Cordoba 36, Juan Bautista Alberdi , Tucuman",name:"CTRO. DE REHABILITACION INTEGRAL AGUILARES",position:[-27.5825353, -65.6185943]},
  {category:"salud",type:"instituto",address:"Lamadrid 1945 69, San Miguel de Tucuman , Tucuman",name:"INSTITUTO PRIVADO DE EDUCACION Y ADAPTACION-INPEA",position:[-26.8331498, -65.2282356]},
  {category:"salud",type:"instituto",address:"San Lorenzo 883, San Miguel de Tucuman , Tucuman",name:"INSTITUTO PRIVADO DE EDUCACION Y ADAPTACION-INPEA",position:[-26.83242, -65.21122]},
  {category:"salud",type:"centro de d\u00eda",address:"Larrea S N (\u00daltima cuadra), Famaill\u00e1 , Tucuman",name:"CENTRO DE D\u00cdA \"AMANECER\"",position:[-27.0560551, -65.40216830000001]},
  {category:"salud",type:"centro educativo",address:"Bascary 490, Yerba Buena , Tucuman",name:"C.E.T.I.N.N.E. S.R.L.",position:[-26.8086291, -65.2867995]},
  {category:"salud",type:"miscelaneo",address:"Domingo Garcia 108, San Miguel de Tucuman , Tucuman",name:"NUEVOS AIRES S.R.L.",position:[-26.8370165, -65.19651010000001]},
  {category:"salud",type:"miscelaneo",address:"Federico Rossi 187, Yerba Buena , Tucuman",name:"CUSCA RISUN SRL",position:[-26.8186709, -65.2763418]},
  {category:"salud",type:"centro de rehabilitacion",address:"San Martin 639, Monteros , Tucuman",name:"SERV.INTEGRAL DE MEDICINA Y REHAB. SIMER S.R.L.",position:[-27.172193, -65.50363229999999]},
  {category:"salud",type:"instituto",address:"Corrientes 912, San Miguel de Tucuman , Tucuman",name:"I.M.I. INSTITUTO MODELO INTERDISCIPLINARIO",position:[-26.8217615, -65.2090473]},
  {category:"salud",type:"instituto",address:"CORRIENTES 912, San Miguel de Tucuman , Tucuman",name:"I.M.I. INSTITUTO MODELO INTERDISCIPLINARIO",position:[-26.8217615, -65.2090473]},
  {category:"salud",type:"miscelaneo",address:"SANTA FE 884, San Miguel de Tucuman , Tucuman",name:"ETIAM SRL",position:[-26.8191204, -65.2076835]},
  {category:"salud",type:"miscelaneo",address:"GENERAL PAZ 880, San Miguel de Tucuman , Tucuman",name:"JASPE S.R.L.",position:[-26.8352143, -65.211771]},
  {category:"salud",type:"centro educativo",address:"MENDOZA 1062, San Miguel de Tucuman , Tucuman",name:"CENTRO EDUCAIVO TERAP\u00c9UTICO D\u00cdA A D\u00cdA S.H.",position:[-26.8266394, -65.2126744]},
  {category:"salud",type:"miscelaneo",address:"Alberdi 320, San Miguel de Tucuman , Tucuman",name:"MOSAICOS DE VIDA SRL",position:[-26.8336009, -65.2151222]},
  {category:"salud",type:"miscelaneo",address:"LAS HERAS N\u00ba 280, San Miguel de Tucuman , Tucuman",name:"MOSAICOS DE VIDA SRL",position:[-26.8352391, -65.20248579999999]},
  {category:"salud",type:"miscelaneo",address:"Cris\u00f3stomo \u00c1lvarez N1415, San Miguel de Tucuman , Tucuman",name:"M.E.F.Y.R.",position:[-26.8267317, -65.2311604]},
  {category:"salud",type:"miscelaneo",address:"Rivadavia N\u00ba 1649, San Miguel de Tucuman , Tucuman",name:"EL SENDERO DE FRANCISCO",position:[-26.809421, -65.1961762]},
  {category:"salud",type:"miscelaneo",address:"Las Heras N\u00ba 743, San Miguel de Tucuman , Tucuman",name:"ANIMAR SRL",position:[-26.8415824, -65.204349]},
  {category:"salud",type:"miscelaneo",address:"BUENOS AIRES N\u00ba 889, San Miguel de Tucuman , Tucuman",name:"APRILLA",position:[-26.8425299, -65.2093945]},
  {category:"salud",type:"miscelaneo",address:"Laprida N\u00ba 806, San Miguel de Tucuman , Tucuman",name:"SION de Exequiel SH de Guillermo A. Bensasson y Ma. Teresa Rodr\u00edguez",position:[-26.8201486, -65.2007237]},
  {category:"salud",type:"miscelaneo",address:"Ruto Nacional N\u00ba 9, Km. 1343, San Miguel de Tucuman , Tucuman",name:"EL SOLAR DE LA COLONIA de La Cecilia S.R.L.",position:[-26.7818398, -65.1907124]},
  {category:"salud",type:"miscelaneo",address:"RUTA 9 KM 1343, Trancas , Tucuman",name:"EL SOLAR DE LA COLONIA de La Cecilia S.R.L.",position:[-28.8809457, -63.9785037]},
  {category:"salud",type:"asociaci\u00f3n",address:"Italia N\u00b0 2.868, San Miguel de Tucuman , Tucuman",name:"A.P.P.A.C.E.T. Asociaci\u00f3n Protectora al Paral\u00edtico Cerebral de Tucum\u00e1n",position:[-26.8082848, -65.2175903]},
  {category:"salud",type:"miscelaneo",address:"Las Heras N\u00ba 727, San Miguel de Tucuman , Tucuman",name:"NUEVO ACOMPA\u00d1AR",position:[-26.8414446, -65.2043027]},
  {category:"salud",type:"instituto",address:"San Lorenzo 883, San Miguel de Tucuman , Tucuman",name:"INSTITUTO MODELO DE EDUCACI\u00d3N Y ADAPTACI\u00d3N SRL",position:[-26.83242, -65.21122]},
  {category:"salud",type:"instituto",address:"Lamadrid n\u00ba 1945, San Miguel de Tucuman , Tucuman",name:"INSTITUTO MODELO DE EDUCACI\u00d3N Y ADAPTACI\u00d3N SRL",position:[-26.8329367, -65.2289041]},
  {category:"salud",type:"miscelaneo",address:"Av. PRESIDENTE PERON N\u00ba 1736, Yerba Buena , Tucuman",name:"SENSORY",position:[-26.8025092, -65.29217849999999]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"LARREA N\u00b0 752, San Miguel de Tucuman , Tucuman",name:"CENTRO INTEGRAL DE REAHABILITACION (CIR)",position:[-26.8082848, -65.2175903]},
  {category:"salud",type:"miscelaneo",address:"ESPA\u00d1A N\u00b0 1370, San Miguel de Tucuman , Tucuman",name:"VITALIA SRL",position:[-26.8082848, -65.2175903]},
  {category:"salud",type:"centro de d\u00eda",address:"Perito Moreno N\u00ba 409, Villa Quinteros , Tucuman",name:"CENTRO VILLA HORTENSIA DE HERRERA CAROLINA DEL VALLE",position:[-27.2575487, -65.54839]},
  {category:"salud",type:"centro de d\u00eda",address:"Av. JUAN B. JUSTO N\u00ba 2668, San Miguel de Tucuman , Tucuman",name:"CENTRO JUAN B. JUSTO NORTE SRL",position:[-26.7968413, -65.18880659999999]},
  {category:"salud",type:"taller",address:"Paraguay 1096, Yerba Buena , Tucuman",name:"EL TALLER DE YERBA BUENA SRL",position:[-26.8102409, -65.2821459]},
  {category:"salud",type:"taller",address:"Paraguay N\u00ba 1.096, Yerba Buena , Tucuman",name:"EL TALLER DE YERBA BUENA SRL",position:[-26.8102051, -65.2810637]},
  {category:"salud",type:"centro educativo",address:"AV. Mate de Luna 3490, San Miguel de Tucuman , Tucuman",name:"C.E.D.I. De Maria Ines Elli y Mirtha Delina Palomo",position:[-26.8215183, -65.2497873]},
  {category:"salud",type:"centro educativo",address:"LAPRIDA 857, San Miguel de Tucuman , Tucuman",name:"C.E.D.I. De Maria Ines Elli y Mirtha Delina Palomo",position:[-26.8196295, -65.20013589999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Las Rosas N\u00ba 248, Yerba Buena , Tucuman",name:"CENTRO LAS ROSAS SRL",position:[-26.8127902, -65.2774755]},
  {category:"salud",type:"centro de d\u00eda",address:"Las Rosas 250, Yerba Buena , Tucuman",name:"CENTRO LAS ROSAS SRL",position:[-26.8127478, -65.27745949999999]},
  {category:"salud",type:"centro de d\u00eda",address:"Valenzuela 143., Yerba Buena , Tucuman",name:"CENTRO LAS ROSAS SRL",position:[-26.8178485, -65.277866]},
  {category:"salud",type:"miscelaneo",address:"Pringles 456, Yerba Buena , Tucuman",name:"POIESIS de AGALMA SRL",position:[-26.8189165, -65.2722662]},
  {category:"salud",type:"miscelaneo",address:"LOS CEIBOS 863, Yerba Buena , Tucuman",name:"MAITEA S.R.L.",position:[-26.8119545, -65.2785423]},
  {category:"salud",type:"centro de rehabilitacion",address:"9 DE JULIO 721, Simoca , Tucuman",name:"CENTRO DE REHABILITACI\u00d3N VIRGEN DE LA MERCED S.R.L.",position:[-27.2619614, -65.3611051]},
  {category:"salud",type:"consultorio",address:"9 DE JULIO 775, San Miguel de Tucuman , Tucuman",name:"CENTRO M\u00c9DICO INTEGRAL DE REHABILITACI\u00d3N F\u00cdSICA",position:[-26.8413291, -65.20736389999999]},
  {category:"salud",type:"miscelaneo",address:"LAS HERAS 581, San Miguel de Tucuman , Tucuman",name:"AVANZA DE AMARO DANIELA VANINA",position:[-26.83939, -65.20373]},
  {category:"salud",type:"fundaci\u00f3n",address:"CALLE 15 N\u00ba 125 VILLA MARIANO MORENO, Las talitas , Tucuman",name:"FUNDACI\u00d3N DRA. SANDRA AGUILAR PARA GRUPOS VULNERABLES - INTI PACHA",position:[-26.7734361, -65.2036229]},
  {category:"salud",type:"centro de d\u00eda",address:"RUTA N\u00ba 9 KM 1288 LOS VALLISTOS, Banda del R\u00edo Sal\u00ed , Tucuman",name:"CENTRO DE D\u00cdA EL SEMBRADOR DE SICADI S.R.L.",position:[-26.8666382, -65.17578040000001]},
  {category:"salud",type:"miscelaneo",address:"Las Heras 73, Tucum\u00e1n , Tucuman",name:"ANIMAR S.R.L.",position:[-26.8326234, -65.20231330000001]},
  {category:"salud",type:"miscelaneo",address:"CORRIENTES 918, San Miguel de Tucuman , Tucuman",name:"IMI INTEGRACI\u00d3N S.R.L.",position:[-26.8216411, -65.2092096]},
  {category:"salud",type:"centro de d\u00eda",address:"AV. MATE DE LUNA 3026, San Miguel de Tucuman , Tucuman",name:"CENTRO INDISCIPLINARIO NEUROL\u00d3GICO PARA ASISTENCIA Y REHABILITACI\u00d3N S.R.L. CINAR",position:[-26.8228625, -65.24334030000001]},
  {category:"salud",type:"miscelaneo",address:"RUTA PROV. N\u00ba 306 LOS VALLISTOS, KM 2, Banda del R\u00edo Sal\u00ed , Tucuman",name:"CASAGRANDE DEL ESTE S.R.L.",position:[-26.8666382, -65.17578040000001]},
  {category:"salud",type:"fundaci\u00f3n",address:"SUIPACHA 1245, San Miguel de Tucuman , Tucuman",name:"FUNDACI\u00d3N AYUDA AL NI\u00d1O NECESITADO F.A.N.N.",position:[-26.7958188, -65.261044]},
  {category:"salud",type:"miscelaneo",address:"SALUSTIANO ZAVALIA 171, Yerba Buena , Tucuman",name:"VILLA LITA",position:[-26.8194581, -65.27148629999999]},
  {category:"salud",type:"miscelaneo",address:"PJE IGNACIO BAZ 4315, San Miguel de Tucuman , Tucuman",name:"REVITAL",position:[-26.8096951, -65.25625699999999]},
  {category:"salud",type:"miscelaneo",address:"SAN LORENZO N\u00ba 1976, San Miguel de Tucuman , Tucuman",name:"EUTERPE",position:[-26.8290418, -65.228028]},
  {category:"salud",type:"miscelaneo",address:"RIVADAVIA 1063, San Miguel de Tucuman , Tucuman",name:"LA ALBORADA S.R.L.",position:[-26.8170045, -65.1981361]},
  {category:"salud",type:"miscelaneo",address:"JOSE COLOMBRES 140, San Miguel de Tucuman , Tucuman",name:"LA ALBORADA S.R.L.",position:[-26.8273523, -65.21361399999999]},
  {category:"salud",type:"miscelaneo",address:"Av Ejercito del Norte 1980, San Miguel de Tucuman , Tucuman",name:"Sociedad de Beneficencia de Tucum\u00e1n S.C.",position:[-26.798783, -65.2253575]},
  {category:"salud",type:"centro de integraci\u00f3n",address:"Laprida 326 P.B, Famaill\u00e1 , Tucuman",name:"CENTRO GARDNER CET e INTEGRACION ESCOLAR",position:[-27.0558078, -65.4041628]},
  {category:"salud",type:"miscelaneo",address:"Belgrano 138, Yerba Buena , Tucuman",name:"AMARANTHUS SRL",position:[-26.8145574, -65.294915]},
  {category:"salud",type:"miscelaneo",address:"RUTA 9 KM 1343, Trancas , Tucuman",name:"AMARANTHUS SRL",position:[-28.8809457, -63.9785037]},
  {category:"salud",type:"fundaci\u00f3n",address:"Fray Mamerto Esqui\u00fa 189, San Miguel de Tucuman , Tucuman",name:"FUNDACION LAZOS DE LUZ",position:[-26.8182606, -65.2520746]},
  {category:"salud",type:"miscelaneo",address:"AYACUCHO 120, San Miguel de Tucuman , Tucuman",name:"BARRILETE S.R.L",position:[-26.8316183, -65.209627]},
  {category:"salud",type:"miscelaneo",address:"JUAN XXIII 79, Yerba Buena , Tucuman",name:"MARCKAY S.R.L",position:[-26.8098717, -65.3157544]},
  {category:"salud",type:"miscelaneo",address:"AVDA. REPUBLICA DEL LIBANO 1250, Tucum\u00e1n , Tucuman",name:"CEPRIR S.R.L.",position:[-26.810959, -65.21449609999999]},
  {category:"salud",type:"centro educativo",address:"MENDOZA 1062, San Miguel de Tucuman , Tucuman",name:"CET DIA A DIA S.R.L.",position:[-26.8266394, -65.2126744]},
  {category:"salud",type:"miscelaneo",address:"AVDA. AVELLANEDA 317, Tucum\u00e1n , Tucuman",name:"PUERTO SO\u00d1AR S.A.S.",position:[-26.827989, -65.1961954]},
  {category:"salud",type:"miscelaneo",address:"9 DE JULIO 535, Taf\u00ed Viejo , Tucuman",name:"CENTRO DIVINO NI\u00d1O S.R.L.",position:[-26.7366345, -65.2581469]},
  {category:"salud",type:"miscelaneo",address:"TOBLLI SUSANA CLAUDIA , Taf\u00ed Viejo , Tucuman",name:"CENTRO DIVINO NI\u00d1O S.R.L.",position:[-26.7324791, -65.26702879999999]},
  {category:"salud",type:"centro de d\u00eda",address:"BERNARDO HOUSSAY 3177, Concepci\u00f3n , Tucuman",name:"CENTRO DIVINO NI\u00d1O S.R.L.",position:[-27.3469064, -65.6127825]},
  {category:"salud",type:"centro educativo",address:"LAPRIDA 326 P.B, Famaill\u00e1 , Tucuman",name:"CENTRO GARDNER CET e INTEGRACION ESCOLAR",position:[-27.0558078, -65.4041628]},
  {category:"salud",type:"miscelaneo",address:"SOLER 3945, CABA",name:"ALPI Asociaci\u00f3n Civil",position:[-34.59158716076613, -58.4174411758059]},
  {category:"gastronomia",type:"burger",address:"Av. Cabildo 2301, C1428 CABA, Argentina",name:"Belgrano",position:[-34.5598546, -58.4581242]},
  {category:"gastronomia",type:"burger",address:"Buenos Aires, Argentina",name:"Per\u00fa",position:[-34.6036844, -58.3815591]},
  {category:"gastronomia",type:"burger",address:"BQM, Av. Hip\u00f3lito Yrigoyen 8121, B1832 Lomas de Zamora, Provincia de Buenos Aires, Argentina",name:"Lomas",position:[-34.75005, -58.4006082]},
  {category:"gastronomia",type:"burger",address:"Av. Corrientes 1770, C1042 San Nicolas, Buenos Aires, Argentina",name:"Cine",position:[-34.6047802, -58.3917273]},
  {category:"gastronomia",type:"burger",address:"Carlos Pellegrini 451, C1009ABI CABA, Argentina",name:"Obelisco",position:[-34.603035, -58.38071530000001]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia 6225, C1406GLF CABA, Argentina",name:"Flores",position:[-34.625639, -58.454459]},
  {category:"gastronomia",type:"burger",address:"Paran\u00e1 3745 Local 3330, B1640FRC Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"Uni Cines",position:[-34.5086233, -58.52320999999999]},
  {category:"gastronomia",type:"burger",address:"Paran\u00e1 3745 Local 3082, B1640FRC Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"Uni TN",position:[-34.5086233, -58.52320999999999]},
  {category:"gastronomia",type:"burger",address:"Av. Sta. Fe 1997, C1123AAB CABA, Argentina",name:"Ayacucho",position:[-34.5956145, -58.3959799]},
  {category:"gastronomia",type:"burger",address:"Av. Sta. Fe 3311, C1425 CABA, Argentina",name:"Bulnes",position:[-34.5877539, -58.4116514]},
  {category:"gastronomia",type:"burger",address:"Av. Pueyrred\u00f3n 320, C1032ABQ CABA, Argentina",name:"Once",position:[-34.6062299, -58.4061678]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia 4609, C1184 CABA, Argentina",name:"Caballito",position:[-34.6153341, -58.4302899]},
  {category:"gastronomia",type:"burger",address:"Av. C\u00f3rdoba 2332, C1120 CABA, Argentina",name:"Facultad",position:[-34.5998136, -58.4004394]},
  {category:"gastronomia",type:"burger",address:"La Plata, Buenos Aires Province, Argentina",name:"La Plata",position:[-34.9204948, -57.95356570000001]},
  {category:"gastronomia",type:"burger",address:"Av Triunvirato 4649, C1432 CABA, Argentina",name:"Villa Urquiza",position:[-34.5744953, -58.4858608]},
  {category:"gastronomia",type:"burger",address:"Belgrano 119, B1708IFD Mor\u00f3n, Provincia de Buenos Aires, Argentina",name:"Mor\u00f3n ",position:[-34.649374, -58.62137799999999]},
  {category:"gastronomia",type:"burger",address:"Av. Corrientes 3247 local 3010 y 3011, C1193 CABA, Argentina",name:"Abasto",position:[-34.6038802, -58.41098969999999]},
  {category:"gastronomia",type:"burger",address:"Av. Eva Per\u00f3n 5856, S2008QJO Rosario, Santa Fe, Argentina",name:"Village Rosario",position:[-32.9347337, -60.7010777]},
  {category:"gastronomia",type:"burger",address:"Buenos Aires Province, Argentina",name:"Alto Avellaneda",position:[-37.2017285, -59.84106969999999]},
  {category:"gastronomia",type:"burger",address:"Nansen 323, Rosario, Santa Fe, Argentina",name:"El Portal",position:[-32.9105441, -60.68331449999999]},
  {category:"gastronomia",type:"burger",address:"Buenos Aires, Argentina",name:"Acoyte",position:[-34.6036844, -58.3815591]},
  {category:"gastronomia",type:"burger",address:"Cariola 42, T4107 San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"Portal Tucum\u00e1n",position:[-26.8212762, -65.26760039999999]},
  {category:"gastronomia",type:"burger",address:"Av. Sta. Fe 4190, C1425 CABA, Argentina",name:"Plaza Italia",position:[-34.5814577, -58.4217193]},
  {category:"gastronomia",type:"burger",address:"Mor\u00f3n, Buenos Aires Province, Argentina",name:"Plaza Oeste",position:[-34.6558611, -58.6167212]},
  {category:"gastronomia",type:"burger",address:"Av. Gral. Francisco Fern\u00e1ndez de la Cruz 4602, C1439 CABA, Argentina",name:"Parque Brown",position:[-34.6736189, -58.4602567]},
  {category:"gastronomia",type:"burger",address:"Gral. Bartolom\u00e9 Mitre 432, Tigre, Provincia de Buenos Aires, Argentina",name:"Tigre",position:[-34.42249049999999, -58.57997409999999]},
  {category:"gastronomia",type:"burger",address:"C\u00f3rdoba, Cordoba, Argentina",name:"Rivera Indarte",position:[-31.42008329999999, -64.1887761]},
  {category:"gastronomia",type:"burger",address:"Dr. Luis G\u00fcemes 369 Local 7b, B1707BNG Haedo, Provincia de Buenos Aires, Argentina",name:"Haedo",position:[-34.6389067, -58.5801858]},
  {category:"gastronomia",type:"burger",address:"Av. Cabildo 2957, C1429AAA CABA, Argentina",name:"Quesada",position:[-34.5546637, -58.46268749999999]},
  {category:"gastronomia",type:"burger",address:"Duarte Quir\u00f3s 1400, X5000 C\u00f3rdoba, Argentina",name:"Nuevo Centro",position:[-31.4125384, -64.2044727]},
  {category:"gastronomia",type:"burger",address:"Av. Corrientes 4675, C1195AAF CABA, Argentina",name:"Angel Gallardo",position:[-34.602007, -58.431502]},
  {category:"gastronomia",type:"burger",address:"Rodr\u00edguez del Busto 4086, C\u00f3rdoba, Argentina",name:"Dino Mall",position:[-31.3668598, -64.2192135]},
  {category:"gastronomia",type:"burger",address:"Jun\u00edn 385, S2000 Rosario, Santa Fe, Argentina",name:"Alto Rosario",position:[-32.92692, -60.668947]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia 6661, C1406GLJ CABA, Argentina",name:"Gavil\u00e1n",position:[-34.6275849, -58.459419]},
  {category:"gastronomia",type:"burger",address:"Rivadavia 3050, B7600GNT Mar del Plata, Provincia de Buenos Aires, Argentina",name:"Los Gallegos",position:[-37.9982793, -57.5524777]},
  {category:"gastronomia",type:"burger",address:"Pilar, Buenos Aires Province, Argentina",name:"Tortugas Mall",position:[-34.4663154, -58.9153722]},
  {category:"gastronomia",type:"burger",address:"25 de Mayo 501, T4000 San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"Tucum\u00e1n Centro",position:[-26.8240619, -65.2029539]},
  {category:"gastronomia",type:"burger",address:"C\u00f3rdoba, Rosario, Santa Fe, Argentina",name:"Rosario Centro",position:[-32.9405524, -60.6709582]},
  {category:"gastronomia",type:"burger",address:"Bernardo de Irigoyen 2647, C1138 CABA, Argentina",name:"Soleil",position:[-34.6285715, -58.3781183]},
  {category:"gastronomia",type:"burger",address:"Av. Cabildo, Buenos Aires, Argentina",name:"Jos\u00e9 Hern\u00e1ndez",position:[-34.5590181, -58.4591916]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia, Ramos Mej\u00eda, Provincia de Buenos Aires, Argentina",name:"Ramos Mej\u00eda",position:[-34.6399022, -58.557783]},
  {category:"gastronomia",type:"burger",address:"Laprida 342, B1832 HOH, Provincia de Buenos Aires, Argentina",name:"Lomas Centro",position:[-34.7607725, -58.4017768]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia, Quilmes, Provincia de Buenos Aires, Argentina",name:"Quilmes",position:[-34.7203878, -58.2540808]},
  {category:"gastronomia",type:"burger",address:"Chubut Province, Argentina",name:"Torres del Sol",position:[-43.6846192, -69.2745537]},
  {category:"gastronomia",type:"burger",address:"RP52, Canning, Provincia de Buenos Aires, Argentina",name:"Plaza Canning",position:[-34.9062683, -58.5121271]},
  {category:"gastronomia",type:"burger",address:"KM29, Gonz\u00e1lez Cat\u00e1n, Provincia de Buenos Aires, Argentina",name:"Gonzalez Cat\u00e1n",position:[-34.75923, -58.61651]},
  {category:"gastronomia",type:"burger",address:"Mar del Plata, Buenos Aires Province, Argentina",name:"Regina",position:[-38.0054771, -57.5426106]},
  {category:"gastronomia",type:"burger",address:"Av. Pte. J. D. Per\u00f3n 1330, B1663 San Miguel, Provincia de Buenos Aires, Argentina",name:"San Miguel",position:[-34.5442696, -58.70889099999999]},
  {category:"gastronomia",type:"burger",address:"Av. de Acceso Este 3280, M5521 Mendoza, Argentina",name:"Mendoza Plaza",position:[-32.9009692, -68.7993948]},
  {category:"gastronomia",type:"burger",address:"Av. del Libertador 713, B1722ERU Merlo, Provincia de Buenos Aires, Argentina",name:"Merlo",position:[-34.66625459999999, -58.7267964]},
  {category:"gastronomia",type:"burger",address:"Cnel. Ram\u00f3n L. Falc\u00f3n 7115, C1408DSI CABA, Argentina",name:"Liniers",position:[-34.6401748, -58.52667690000001]},
  {category:"gastronomia",type:"burger",address:"Av. del Bicentenario de la Batalla de Salta 702, A4400 Salta, Argentina",name:"Alto Noa",position:[-24.7813068, -65.40277739999999]},
  {category:"gastronomia",type:"burger",address:"Pontevedra & Santa Rosa, B1714 Ituzaing\u00f3, Provincia de Buenos Aires, Argentina",name:"Castelar",position:[-34.6409881, -58.65620000000001]},
  {category:"gastronomia",type:"burger",address:"Av. Bartolom\u00e9 Mitre 2729, B1744OHL Moreno, Provincia de Buenos Aires, Argentina",name:"Moreno",position:[-34.6492433, -58.7888684]},
  {category:"gastronomia",type:"burger",address:"Cuenca 3384, C1417ABF CABA, Argentina",name:"Cuenca",position:[-34.598665, -58.49712570000001]},
  {category:"gastronomia",type:"burger",address:"Gral. Manuel Belgrano 267, B1642 San Isidro, Provincia de Buenos Aires, Argentina",name:"San Isidro",position:[-34.4703008, -58.51171590000001]},
  {category:"gastronomia",type:"burger",address:"San Francisco Solano, Buenos Aires Province, Argentina",name:"Solano",position:[-34.7778417, -58.3082434]},
  {category:"gastronomia",type:"burger",address:"Av. Bartolom\u00e9 Mitre, Provincia de Buenos Aires, Argentina",name:"Avellaneda Mitre",position:[-34.6832243, -58.3429974]},
  {category:"gastronomia",type:"burger",address:"Av. Calchaqu\u00ed 3950, B1879 Quilmes, Provincia de Buenos Aires, Argentina",name:"Quilmes Factory",position:[-34.7592099, -58.2772702]},
  {category:"gastronomia",type:"burger",address:"Juan Juli\u00e1n Lastra 2400, Q8300 Neuqu\u00e9n, Argentina",name:"Portal Neuqu\u00e9n ",position:[-38.9613069, -68.0904621]},
  {category:"gastronomia",type:"burger",address:"Dr. Ignacio Arieta 3545, B1754 AQK, Provincia de Buenos Aires, Argentina",name:"San Justo",position:[-34.682211, -58.55603370000001]},
  {category:"gastronomia",type:"burger",address:"Malvinas Argentinas Partido, Buenos Aires Province, Argentina",name:"Terrazas",position:[-34.479665, -58.70303260000001]},
  {category:"gastronomia",type:"burger",address:"Av. Hip\u00f3lito Yrigoyen 10699, B1834GTU Temperley, Provincia de Buenos Aires, Argentina",name:"Temperley",position:[-34.7799324, -58.4065691]},
  {category:"gastronomia",type:"burger",address:"9 de Julio, Jos\u00e9 C. Paz, Provincia de Buenos Aires, Argentina",name:"Lan\u00fas Este",position:[-34.5041387, -58.8089771]},
  {category:"gastronomia",type:"burger",address:"Av. Dr. Ram\u00f3n, Neuqu\u00e9n, Argentina",name:"Alto Comahue",position:[-38.9411805, -68.0698256]},
  {category:"gastronomia",type:"burger",address:"Mendoza Province, Argentina",name:"Mendoza San Mart\u00edn",position:[-34.5869034, -68.14314139999999]},
  {category:"gastronomia",type:"burger",address:"RP27 4711 Local 1118, B1648 Rinc\u00f3n de Milberg, Provincia de Buenos Aires, Argentina",name:"Remeros Plaza",position:[-34.4049063, -58.6211527]},
  {category:"gastronomia",type:"burger",address:"Av. Fondo de la Legua 668, B1642AAT Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"Fondo de la Legua",position:[-34.497952, -58.54391499999999]},
  {category:"gastronomia",type:"burger",address:"Av. Pres. Bernardino Rivadavia 2602, B1823 Lan\u00fas, Provincia de Buenos Aires, Argentina",name:"Lan\u00fas Oeste",position:[-34.6909626, -58.4198282]},
  {category:"gastronomia",type:"burger",address:"Diag. 681 147 14 N\u00ba 4675, B1914GWG Villa Elvira, Provincia de Buenos Aires, Argentina",name:"Berazategui",position:[-34.9279952, -57.9088374]},
  {category:"gastronomia",type:"burger",address:"9 de Julio Nte., G3760 A\u00f1atuya, Santiago del Estero, Argentina",name:"Mor\u00f3n II",position:[-28.456903, -62.8369985]},
  {category:"gastronomia",type:"burger",address:"RP11, Provincia de Buenos Aires, Argentina",name:"Mar del Tuy\u00fa",position:[-36.5032113, -57.3037104]},
  {category:"gastronomia",type:"burger",address:"Cordoba, Argentina",name:"C\u00f3rdoba Centro",position:[-32.2968402, -63.580611]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia 4355, C1205AAD CABA, Argentina",name:"Almagro",position:[-34.6138296, -58.42724219999999]},
  {category:"gastronomia",type:"burger",address:"Av. Rivadavia 7408, C1406 CABA, Argentina",name:"Nazca",position:[-34.631007, -58.4699356]},
  {category:"gastronomia",type:"burger",address:"Leandro N. Alem 138, B1842 Monte Grande, Provincia de Buenos Aires, Argentina",name:"Monte Grande",position:[-34.8155712, -58.4687248]},
  {category:"gastronomia",type:"burger",address:"Av. Julio A Roca 260, D5700 San Luis, Argentina",name:"San Luis",position:[-33.3067484, -66.32366499999999]},
  {category:"gastronomia",type:"burger",address:"N-635, 51, 7 y, 39792 Heras, La Plata, Argentina",name:"La Plata",position:[-57.949818770629584, -34.9159356532493]},
  {category:"gastronomia",type:"burger",address:"Av. Gral. Paz, Argentina",name:"Jockey",position:[-34.6210304, -58.530562]},
  {category:"gastronomia",type:"burger",address:"Padua, Ituzaingo, Argentina",name:"Padua",position:[-34.66608707477844, -58.703125872244975]},
  {category:"gastronomia",type:"burger",address:"Gral. Las Heras 161, B1714MCC Ituzaing\u00f3, Provincia de Buenos Aires, Argentina",name:"Ituzaingo",position:[-34.6578072, -58.66648379999999]},
  {category:"gastronomia",type:"burger",address:"Av Rafael Nu\u00f1ez, C\u00f3rdoba, Argentina",name:"Rafael Nu\u00f1ez",position:[-31.3611923, -64.23808369999999]},
  {category:"gastronomia",type:"burger",address:"Av. Pres. Figueroa Alcorta, Buenos Aires, Argentina",name:"Francisco Alvarez",position:[-34.5649648, -58.4211452]},
  {category:"gastronomia",type:"burger",address:"RN1, Sarand\u00ed, Provincia de Buenos Aires, Argentina",name:"Village Avellaneda",position:[-34.6736501, -58.3339002]},
  {category:"gastronomia",type:"burger",address:"San Lorenzo 77, C\u00f3rdoba, Argentina",name:"Buen Pastor",position:[-31.4234004, -64.1862854]},
  {category:"gastronomia",type:"burger",address:"RN9, Argentina",name:"Campana",position:[-28.8805516, -63.978645]},
  {category:"gastronomia",type:"burger",address:"Av. Dr. Ricardo Balb\u00edn, San Miguel, Provincia de Buenos Aires, Argentina",name:"San Miguel II",position:[-34.5550341, -58.7255399]},
  {category:"gastronomia",type:"burger",address:"Sobremonte 80, X5800 BUO, C\u00f3rdoba, Argentina",name:"Rio IV",position:[-33.1179708, -64.3463245]},
  {category:"gastronomia",type:"burger",address:"Av. Dr. Jos\u00e9 Mar\u00eda Ramos Mej\u00eda 1302, C1104 CABA, Argentina",name:"Retiro",position:[-34.5925853, -58.3752739]},
  {category:"gastronomia",type:"burger",address:"RN16, Resistencia, Chaco, Argentina",name:"Resistencia",position:[-27.4159141, -58.9669746]},
  {category:"gastronomia",type:"burger",address:"Scalabrini Ortiz Nte., San Juan, Argentina",name:"San Juan",position:[-31.5224784, -68.5425942]},
  {category:"gastronomia",type:"burger",address:"Manuel Carde\u00f1osa, C\u00f3rdoba, Argentina",name:"Paseo Lugones",position:[-31.3599021, -64.2264238]},
  {category:"gastronomia",type:"burger",address:"Av. Adolfo Alsina 706, B1832 Banfield, Provincia de Buenos Aires, Argentina",name:"Banfield",position:[-34.7446062, -58.3932688]},
  {category:"gastronomia",type:"burger",address:"Av. Corrientes 1217, C1043AAM CABA, Argentina",name:"Talcahuano",position:[-34.6037073, -58.38397430000001]},
  {category:"gastronomia",type:"burger",address:"Mart\u00edn Fierro 2921, Villa Udaondo, Provincia de Buenos Aires, Argentina",name:"Parque Leloir",position:[-34.6281188, -58.6909638]},
  {category:"gastronomia",type:"burger",address:"Las Magnolias 754, La Lonja, Provincia de Buenos Aires, Argentina",name:"Palmas del Pilar",position:[-34.4462858, -58.87045819999999]},
  {category:"gastronomia",type:"burger",address:"Av. Adolfo Alsina 1980, B1832 Lomas de Zamora, Provincia de Buenos Aires, Argentina",name:"Lomas Alsina",position:[-34.759783, -58.3958315]},
  {category:"gastronomia",type:"burger",address:"Av. Col\u00f3n 842, X5000EPV C\u00f3rdoba, Argentina",name:"Col\u00f3n",position:[-31.4101043, -64.194358]},
  {category:"gastronomia",type:"burger",address:"Av. Eugenia Tapia de Cruz 825, B1625 Bel\u00e9n de Escobar, Provincia de Buenos Aires, Argentina",name:"Escobar",position:[-34.3484053, -58.7961357]},
  {category:"gastronomia",type:"burger",address:"Av. del Libertador 2200, Victoria, Provincia de Buenos Aires, Argentina",name:"San Fernando",position:[-34.4467704, -58.54418519999999]},
  {category:"gastronomia",type:"burger",address:"Mariano Moreno, Luj\u00e1n, Provincia de Buenos Aires, Argentina",name:"Wilde",position:[-34.5682867, -59.1148994]},
  {category:"gastronomia",type:"burger",address:"Ant\u00e1rtida Argentina 1111, Q8300 Neuqu\u00e9n, Argentina",name:"Paseo de la Patagonia",position:[-38.9460623, -68.07439289999999]},
  {category:"gastronomia",type:"burger",address:"Ant\u00e1rtida Argentina 799, Llavallol, Provincia de Buenos Aires, Argentina",name:"Portal Lomas",position:[-34.7918033, -58.4168823]},
  {category:"gastronomia",type:"burger",address:"9 de Julio 2, Quilmes, Provincia de Buenos Aires, Argentina",name:"Bernal",position:[-34.7217958, -58.2625882]},
  {category:"gastronomia",type:"burger",address:"Urquiza 4836, S3002ELA Santa Fe de la Vera Cruz, Santa Fe, Argentina",name:"Caseros",position:[-31.6219608, -60.70607249999999]},
  {category:"gastronomia",type:"burger",address:"Av. Corrientes 5217, C1414 CABA, Argentina",name:"Canning",position:[-34.5995198, -58.4388326]},
  {category:"gastronomia",type:"burger",address:"B1802 Ezeiza, Buenos Aires Province, Argentina",name:"Ezeiza Duty Free",position:[-34.8133542, -58.5388491]},
  {category:"gastronomia",type:"burger",address:"Roberto Payr\u00f3, Provincia de Buenos Aires, Argentina",name:"Luj\u00e1n",position:[-35.1816659, -57.65253019999999]},
  {category:"gastronomia",type:"burger",address:"Av. Costanera Rafael Obligado, Buenos Aires, Argentina",name:"Aeroparque",position:[-34.560199, -58.4079923]},
  {category:"gastronomia",type:"burger",address:"Pilar, Buenos Aires Province, Argentina",name:"Village Pilar",position:[-34.4663154, -58.9153722]},
  {category:"banco",type:"galicia",address:"Avenida Rivadavia 2330, C1028 CABA, Argentina",name:"1 - BALVANERA",position:[-34.60967, -58.39949]},
  {category:"banco",type:"galicia",address:"Av. San Juan 3101, C1233 CABA, Argentina",name:"2 - SAN CRISTOBAL",position:[-34.6243732, -58.409021]},
  {category:"banco",type:"galicia",address:"Av. Corrientes 3148, C1193AAQ CABA, Argentina",name:"3 - ABASTO",position:[-34.604206, -58.4095658]},
  {category:"banco",type:"galicia",address:"Monserrat, Buenos Aires, Argentina",name:"4 - MONSERRAT",position:[-34.6130871, -58.38135990000001]},
  {category:"banco",type:"galicia",address:"Paran\u00e1 450, La Lucila, Provincia de Buenos Aires, Argentina",name:"5 - CENTRO",position:[-34.4932965, -58.48736359999999]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 7121, C1406GMB CABA, Argentina",name:"6 - FLORES",position:[-34.6295881, -58.4659096]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 3702, C1204 AAP, Buenos Aires, Argentina",name:"7 - BOEDO",position:[-34.6111539, -58.4185362]},
  {category:"banco",type:"galicia",address:"Av. Cnel. D\u00edaz 1854, C1425DQR CABA, Argentina",name:"8 - CORONEL DIAZ",position:[-34.5896, -58.4108935]},
  {category:"banco",type:"galicia",address:"Av. Dr. Ricardo Balb\u00edn 3899, C1430AAJ CABA, Argentina",name:"9 - SAAVEDRA",position:[-34.5546452, -58.4849864]},
  {category:"banco",type:"galicia",address:"Marcelo Torcuato de Alvear 670, C1005 CABA, Argentina",name:"10 - PLAZA SAN MARTIN",position:[-34.5963918, -58.37658009999999]},
  {category:"banco",type:"galicia",address:"Barrio Norte, Buenos Aires, Buenos Aires, Argentina",name:"11 - QUINTANA",position:[-34.5895459, -58.3973636]},
  {category:"banco",type:"galicia",address:"Av. S. Mart\u00edn 5702, C1419IBN CABA, Argentina",name:"12 - VILLA DEL PARQUE",position:[-34.5968969, -58.4976746]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 11102, C1408 AAY, Buenos Aires, Argentina",name:"14 - LINIERS",position:[-34.6390603, -58.5213584]},
  {category:"banco",type:"galicia",address:"Av. de los Constituyentes 5668, C1431 CABA, Argentina",name:"15 - CONSTITUYENTES",position:[-34.5744685, -58.5021532]},
  {category:"banco",type:"galicia",address:"AAT, Av. Montes de Oca 650, C1270 CABA, Argentina",name:"16 - PARQUE LEZAMA",position:[-34.6358199, -58.374923]},
  {category:"banco",type:"galicia",address:"Juramento 2095, C1428 CABA, Argentina",name:"17 - JURAMENTO",position:[-34.5601586, -58.4538565]},
  {category:"banco",type:"galicia",address:"Carlos Pellegrini 1391, C1011AAA CABA, Argentina",name:"18 - ARROYO",position:[-34.591835, -58.38131599999999]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 6876, Buenos Aires, Argentina",name:"19 - NU\u00d1EZ",position:[-34.5490791, -58.45484880000001]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 2699, C1425AAM CABA, Argentina",name:"20 - PLAZA ALEMANIA",position:[-34.5778265, -58.40746069999999]},
  {category:"banco",type:"galicia",address:"Federico Lacroze 3101, C1426CQE CABA, Argentina",name:"21 - COLEGIALES",position:[-34.5770481, -58.4492406]},
  {category:"banco",type:"galicia",address:"Comuna 15, Av. de los Incas 4763, C1427DNM CABA, Argentina",name:"22 - PARQUE CHAS",position:[-34.5834818, -58.4776533]},
  {category:"banco",type:"galicia",address:"Av. Juan Bautista Justo, Buenos Aires, Argentina",name:"23 - GALICIA",position:[-34.6250653, -58.4859962]},
  {category:"banco",type:"galicia",address:"Gral. Levalle 1102, Dock Sud, Provincia de Buenos Aires, Argentina",name:"24 - PLAZA DE LA REPUBLICA",position:[-34.6551767, -58.3591573]},
  {category:"banco",type:"galicia",address:"Av. Ra\u00fal Scalabrini Ort\u00edz 945, C1414DNJ CABA, Argentina",name:"25 - VILLA CRESPO",position:[-34.5955562, -58.4316261]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 4598, C1426BWT CABA, Argentina",name:"26 - SAN BENITO DE PALERMO",position:[-34.5664789, -58.43231050000001]},
  {category:"banco",type:"galicia",address:"Av. Pedro Goyena 1642, C1406 CABA, Argentina",name:"27 - PEDRO GOYENA",position:[-34.6283313, -58.45131229999999]},
  {category:"banco",type:"galicia",address:"Av. Juan Bautista Alberdi 5859, C1440 CABA, Argentina",name:"28 - MATADEROS NORTE",position:[-34.651596, -58.5030375]},
  {category:"banco",type:"galicia",address:"Av. Angel Gallardo 1072, C1405DJX CABA, Argentina",name:"29 - PARQUE CENTENARIO",position:[-34.6072899, -58.4448806]},
  {category:"banco",type:"galicia",address:"Av. Gral. Mosconi 2340, C1419EQP CABA, Argentina",name:"30 - VILLA PUEYRREDON",position:[-34.582383, -58.495624]},
  {category:"banco",type:"galicia",address:"Quaglia 307, San Carlos de Bariloche, R\u00edo Negro, Argentina",name:"31 - BARILOCHE",position:[-41.1348353, -71.3086929]},
  {category:"banco",type:"galicia",address:"Av. C\u00f3rdoba, Buenos Aires, Argentina",name:"32 - PALERMO SUD",position:[-34.5941939, -58.4302521]},
  {category:"banco",type:"galicia",address:"Comuna 11, Av. Juan Bautista Justo 4802, C1416DKP CABA, Argentina",name:"33 - VILLA GRAL. MITRE",position:[-34.6109657, -58.4665902]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn 601, S2600 Venado Tuerto, Santa Fe, Argentina",name:"34 - VENADO TUERTO",position:[-33.7468336, -61.9645578]},
  {category:"banco",type:"galicia",address:"Av. Francisco Beir\u00f3 4229, C1419 CABA, Argentina",name:"35 - VILLA DEVOTO",position:[-34.605076, -58.511025]},
  {category:"banco",type:"galicia",address:"Crist\u00f3bal Col\u00f3n 147, Caleta Olivia, Santa Cruz, Argentina",name:"36 - CALETA OLIVIA",position:[-46.4432595, -67.5141694]},
  {category:"banco",type:"galicia",address:"Av. Paseo Col\u00f3n 793, C1063ACH CABA, Argentina",name:"37 - PASEO COLON",position:[-34.6170257, -58.36960449999999]},
  {category:"banco",type:"galicia",address:"Av. de Mayo 1225, C1085ABC CABA, Argentina",name:"38 - AV. DE MAYO",position:[-34.6089539, -58.3837454]},
  {category:"banco",type:"galicia",address:"Av. Sta. Fe, Buenos Aires, Argentina",name:"39 - BARRIO NORTE",position:[-34.5951504, -58.39968]},
  {category:"banco",type:"galicia",address:"Av. Bartolom\u00e9 Mitre, Avellaneda, Provincia de Buenos Aires, Argentina",name:"40 - AVELLANEDA",position:[-34.6621365, -58.3653842]},
  {category:"banco",type:"galicia",address:"Av. Sta Fe 2477, B1640IFL Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"41 - MARTINEZ",position:[-34.4938876, -58.49867769999999]},
  {category:"banco",type:"galicia",address:"Ntra Sra del Buen Viaje 739, Mor\u00f3n, Provincia de Buenos Aires, Argentina",name:"42 - MORON",position:[-34.6516039, -58.6190748]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn 435, B6700API Luj\u00e1n, Provincia de Buenos Aires, Argentina",name:"43 - LUJAN",position:[-34.5655114, -59.1161724]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 10557, B1834 Temperley, Provincia de Buenos Aires, Argentina",name:"44 - TEMPERLEY",position:[-34.7812006, -58.4070595]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 2550, B1824AAR Gerli, Provincia de Buenos Aires, Argentina",name:"45 - GERLI",position:[-34.686715, -58.3868683]},
  {category:"banco",type:"galicia",address:"Av. Bartolom\u00e9 Mitre 2407, B1872GFP Sarand\u00ed, Provincia de Buenos Aires, Argentina",name:"46 - SARANDI",position:[-34.6739065, -58.3510314]},
  {category:"banco",type:"galicia",address:"Buenos Aires Metropolitan Area, Argentina",name:"47 - FLORIDA",position:[-34.5733392, -58.64583689999999]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 14806, B1641 Acassuso, Provincia de Buenos Aires, Argentina",name:"48 - ACASSUSO",position:[-34.47735, -58.49747439999999]},
  {category:"banco",type:"galicia",address:"Av. Am\u00e9rica 661, B1674 S\u00e1enz Pe\u00f1a, Provincia de Buenos Aires, Argentina",name:"50 - SAENZ PE\u00d1A",position:[-34.6019893, -58.52834549999999]},
  {category:"banco",type:"galicia",address:"Av. Fleming 2068, B1714ELP Ituzaing\u00f3, Provincia de Buenos Aires, Argentina",name:"51 - LOMAS DE MARTINEZ",position:[-34.6361491, -58.6634994]},
  {category:"banco",type:"galicia",address:"Mercedes, Buenos Aires Province, Argentina",name:"52 - MERCEDES",position:[-34.6510201, -59.43059960000001]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 2299, B1636 Olivos, Provincia de Buenos Aires, Argentina",name:"53 - OLIVOS",position:[-34.5097612, -58.47841090000001]},
  {category:"banco",type:"galicia",address:"Cnel. D Elia 1502, B1824 Lan\u00fas, Provincia de Buenos Aires, Argentina",name:"54 - LANUS OESTE",position:[-34.6918029, -58.40698380000001]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 215, B1638 Vicente L\u00f3pez, Provincia de Buenos Aires, Argentina",name:"55 - VICENTE LOPEZ",position:[-34.53213119999999, -58.46809469999999]},
  {category:"banco",type:"galicia",address:"Gral. Jos\u00e9 de San Mart\u00edn 3499, B1604DED Vicente L\u00f3pez, Provincia de Buenos Aires, Argentina",name:"56 - FLORIDA OESTE",position:[-34.535823, -58.50838599999999]},
  {category:"banco",type:"galicia",address:"Av. Gaona 4104, B1702 Ciudadela, Provincia de Buenos Aires, Argentina",name:"57 - CIUDADELA",position:[-34.63499, -58.54416200000001]},
  {category:"banco",type:"galicia",address:"Av. S. Mart\u00edn 1101, C1650 San Mart\u00edn, Buenos Aires, Argentina",name:"58 - SAN MARTIN",position:[-34.6073973, -58.4464061]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 436, B1722ERR Merlo, Provincia de Buenos Aires, Argentina",name:"59 - MERLO",position:[-34.6696336, -58.7257175]},
  {category:"banco",type:"galicia",address:"Av. Maip\u00fa 301, B1638AAD Vicente L\u00f3pez, Provincia de Buenos Aires, Argentina",name:"60 - ARIST\u00d3BULO DEL VALLE",position:[-34.5351593, -58.4773849]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 7839, B1832BQJ Banfield, Provincia de Buenos Aires, Argentina",name:"61 - BANFIELD",position:[-34.7466734, -58.3998434]},
  {category:"banco",type:"galicia",address:"Av. Gaona 2698, B1707CFA Ramos Mej\u00eda, Provincia de Buenos Aires, Argentina",name:"62 - RAMOS MEJ\u00cdA",position:[-34.6383123, -58.566267]},
  {category:"banco",type:"galicia",address:"Gdor. Inocencio Arias 2355, B1712CDC Castelar, Provincia de Buenos Aires, Argentina",name:"63 - CASTELAR",position:[-34.6511468, -58.64175729999999]},
  {category:"banco",type:"galicia",address:"Av. C\u00f3rdoba 699, C1054AAF CABA, Argentina",name:"64 - AV. CORDOBA",position:[-34.5986196, -58.37670869999999]},
  {category:"banco",type:"galicia",address:"Carlos Pellegrini 115, B6620KSC Chivilcoy, Provincia de Buenos Aires, Argentina",name:"65 - CHIVILCOY",position:[-34.8946804, -60.01797490000001]},
  {category:"banco",type:"galicia",address:"Av. Sta. Fe, Buenos Aires, Argentina",name:"66 - JARDIN BOTANICO",position:[-34.5857909, -58.4149959]},
  {category:"banco",type:"galicia",address:"Nazca 2330, C1416ASZ CABA, Argentina",name:"67 - NAZCA",position:[-34.6084929, -58.483966]},
  {category:"banco",type:"galicia",address:"Av. Independencia, Buenos Aires, Argentina",name:"69 - ALMAGRO SUD",position:[-34.6212596, -58.4205018]},
  {category:"banco",type:"galicia",address:"LQG, Felix de Azara 181, N3300 Posadas, Misiones, Argentina",name:"70 - POSADAS",position:[-27.3645867, -55.8931374]},
  {category:"banco",type:"galicia",address:"BLD, Mitre 153, H3500 Resistencia, Chaco, Argentina",name:"71 - RESISTENCIA",position:[-27.4489575, -58.9861786]},
  {category:"banco",type:"galicia",address:"Av. 25 de Mayo 160, P3600AXO Formosa, Argentina",name:"72 - FORMOSA",position:[-26.1829627, -58.1656679]},
  {category:"banco",type:"galicia",address:"Av. La Voz del Interior 6655, C\u00f3rdoba, Argentina",name:"73 - C\u00d3RDOBA AEROPUERTO",position:[-31.3384264, -64.2079335]},
  {category:"banco",type:"galicia",address:"Belgrano 800, W3450EIP Goya, Corrientes, Argentina",name:"74 - GOYA",position:[-29.1421978, -59.2655663]},
  {category:"banco",type:"galicia",address:"Sarmiento 705, S2000CMK Rosario, Santa Fe, Argentina",name:"75 - ROSARIO",position:[-32.9452767, -60.6377233]},
  {category:"banco",type:"galicia",address:"Av. Col\u00f3n 401, X5022 C\u00f3rdoba, Argentina",name:"76 - CORDOBA",position:[-31.4122942, -64.1888788]},
  {category:"banco",type:"galicia",address:"C\u00f3rdoba 870, W3400CDL Corrientes, Argentina",name:"77 - CORRIENTES",position:[-27.4675448, -58.8344955]},
  {category:"banco",type:"galicia",address:"Av. Col\u00f3n 2415, B7600FYE Mar del Plata, Provincia de Buenos Aires, Argentina",name:"78 - MAR DEL PLATA",position:[-38.0042, -57.5481784]},
  {category:"banco",type:"galicia",address:"Santa Fe, Santa Fe Province, Argentina",name:"79 - SANTA FE",position:[-31.6106578, -60.697294]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn, Paran\u00e1, Entre R\u00edos, Argentina",name:"80 - PARAN\u00c1",position:[-31.7258109, -60.55667619999999]},
  {category:"banco",type:"galicia",address:"Necochea 202, M5500 Mendoza, Argentina",name:"81 - MENDOZA",position:[-32.8871133, -68.8414148]},
  {category:"banco",type:"galicia",address:"Galicia, Chiclana 302, B8000 Bah\u00eda Blanca, Provincia de Buenos Aires, Argentina",name:"82 - BAHIA BLANCA",position:[-38.720809, -62.262972]},
  {category:"banco",type:"galicia",address:"Av. Independencia 3156, B7602BOQ Mar del Plata, Provincia de Buenos Aires, Argentina",name:"83 - INDEPENDENCIA",position:[-38.0080499, -57.5619864]},
  {category:"banco",type:"galicia",address:"Av. Juan B. Justo 2539, B7602 Mar del Plata, Provincia de Buenos Aires, Argentina",name:"84 - JUAN B. JUSTO",position:[-38.0232888, -57.5649785]},
  {category:"banco",type:"galicia",address:"Rivadavia 1524, B6640 Bragado, Provincia de Buenos Aires, Argentina",name:"85 - BRAGADO",position:[-35.117971, -60.48705700000001]},
  {category:"banco",type:"galicia",address:"Av. Pueyrred\u00f3n 1875, C1119ACB CABA, Argentina",name:"86 - RECOLETA",position:[-34.5897659, -58.39944939999999]},
  {category:"banco",type:"galicia",address:"Av. Gaona 2293, B1707BKC El Palomar, Provincia de Buenos Aires, Argentina",name:"87 - HAEDO",position:[-34.6313053, -58.58653349999999]},
  {category:"banco",type:"galicia",address:"Gral. Jos\u00e9 de San Mart\u00edn 867, T4000CVQ San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"89 - TUCUMAN",position:[-26.828499, -65.2100136]},
  {category:"banco",type:"galicia",address:"Av. S. Mart\u00edn 2155, Buenos Aires, Argentina",name:"91 - LOMAS DEL MIRADOR",position:[-34.6046684, -58.45778199999999]},
  {category:"banco",type:"galicia",address:"Av. 1 de Mayo 6074, B1650 Villa Lynch, Provincia de Buenos Aires, Argentina",name:"92 - TROPEZON",position:[-34.591371, -58.5593902]},
  {category:"banco",type:"galicia",address:"Ayacucho 55, B1718CGA Gran Buenos Aires, Provincia de Buenos Aires, Argentina",name:"93 - SAN ANTONIO DE PADUA",position:[-34.6667851, -58.700193]},
  {category:"banco",type:"galicia",address:"Lan\u00fas, Buenos Aires Province, Argentina",name:"94 - LANUS ESTE",position:[-34.7067455, -58.3917289]},
  {category:"banco",type:"galicia",address:"V\u00e9lez S\u00e1rsfield N\u00ba 4298, B1605 Munro, Provincia de Buenos Aires, Argentina",name:"95 - MUNRO",position:[-34.528471, -58.51999799999999]},
  {category:"banco",type:"galicia",address:"Gral. Miguel Soler 122, B1714 Ituzaing\u00f3, Provincia de Buenos Aires, Argentina",name:"96 - ITUZAINGO",position:[-34.6587952, -58.6680192]},
  {category:"banco",type:"galicia",address:"Pres. Quintana 119, B6000FLC Jun\u00edn, Provincia de Buenos Aires, Argentina",name:"97 - JUNIN",position:[-34.5900603, -60.9442385]},
  {category:"banco",type:"galicia",address:"Ricardo Guti\u00e9rrez 401, Arrecifes, Provincia de Buenos Aires, Argentina",name:"98 - ARRECIFES",position:[-34.0640137, -60.10604309999999]},
  {category:"banco",type:"galicia",address:"Moreno 14, C6740 Chacabuco, Provincia de Buenos Aires, Argentina",name:"100 - CHACABUCO",position:[-34.6430961, -60.4690421]},
  {category:"banco",type:"galicia",address:"Buenos Aires, Argentina",name:"101 - PERGAMINO",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"galicia",address:"Av. Pte. J. D. Per\u00f3n 1044, B1662ASX Mu\u00f1iz, Provincia de Buenos Aires, Argentina",name:"102 - SAN MIGUEL",position:[-34.546615, -58.7056941]},
  {category:"banco",type:"galicia",address:"BTL, Av. Pellegrini 1101, S2000 Rosario, Santa Fe, Argentina",name:"103 - AV. PELLEGRINI",position:[-32.957465, -60.6410595]},
  {category:"banco",type:"galicia",address:"Av. San Mart\u00edn 2842, B1678GQR Caseros, Provincia de Buenos Aires, Argentina",name:"104 - CASEROS",position:[-34.60852089999999, -58.5620293]},
  {category:"banco",type:"galicia",address:"Gral. Rodr\u00edguez 698, B7000 Tandil, Provincia de Buenos Aires, Argentina",name:"105 - TANDIL",position:[-37.325326, -59.13744870000001]},
  {category:"banco",type:"galicia",address:"Av. 9 de Julio, Buenos Aires, Argentina",name:"106 - TRES ARROYOS",position:[-34.6073319, -58.3816252]},
  {category:"banco",type:"galicia",address:"Belgrano, Bernal, Provincia de Buenos Aires, Argentina",name:"107 - BERNAL",position:[-34.7103977, -58.2845753]},
  {category:"banco",type:"galicia",address:"Buenos Aires, Argentina",name:"108 - CASILDA",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"galicia",address:"25 de Mayo 1527, X2400 San Francisco, C\u00f3rdoba, Argentina",name:"109 - SAN FRANCISCO",position:[-31.4300052, -62.08309449999999]},
  {category:"banco",type:"galicia",address:"Necochea 3054, B7400 LDB, Provincia de Buenos Aires, Argentina",name:"110 - OLAVARR\u00cdA",position:[-36.8936577, -60.317876]},
  {category:"banco",type:"galicia",address:"Rafaela, Santa Fe Province, Argentina",name:"111 - RAFAELA",position:[-31.2525979, -61.49164219999999]},
  {category:"banco",type:"galicia",address:"Av. Jos\u00e9 Ignacio de la Roza Este 125, J5402 DBB, San Juan, Argentina",name:"112 - SAN JUAN",position:[-31.5372828, -68.5240625]},
  {category:"banco",type:"galicia",address:"1 de Mayo 91, E3200EDA Concordia, Entre R\u00edos, Argentina",name:"113 - CONCORDIA",position:[-31.3981287, -58.01718739999999]},
  {category:"banco",type:"galicia",address:"25 de Mayo 836, E2820 Gualeguaych\u00fa, Entre R\u00edos, Argentina",name:"114 - GUALEGUAYCHU",position:[-33.0094369, -58.5157801]},
  {category:"banco",type:"galicia",address:"Villa Mar\u00eda, Cordoba, Argentina",name:"115 - VILLA MAR\u00cdA",position:[-32.4104614, -63.24364499999999]},
  {category:"banco",type:"galicia",address:"Gral. Belgrano 877, Y4600 San Salvador de Jujuy, Jujuy, Argentina",name:"116 - JUJUY",position:[-24.1852741, -65.3051658]},
  {category:"banco",type:"galicia",address:"Bartolom\u00e9 Mitre 296, C1036AAD CABA, Argentina",name:"117 - SAN NICOLAS",position:[-34.6066515, -58.3707171]},
  {category:"banco",type:"galicia",address:"Enrique Rocca, Campana, Provincia de Buenos Aires, Argentina",name:"118 - CAMPANA",position:[-34.164877, -58.9753619]},
  {category:"banco",type:"galicia",address:"Lorenzo L\u00f3pez 564, B1629HWL Pilar Centro, Provincia de Buenos Aires, Argentina",name:"119 - PILAR",position:[-34.4594667, -58.91262949999999]},
  {category:"banco",type:"galicia",address:"Burgos 611, B7300 Azul, Provincia de Buenos Aires, Argentina",name:"120 - AZUL",position:[-36.7776781, -59.86269919999999]},
  {category:"banco",type:"galicia",address:"Avellaneda, Santiago del Estero, Argentina",name:"121 - SANTIAGO DEL ESTERO",position:[-27.7870695, -64.2576651]},
  {category:"banco",type:"galicia",address:"Alsina 128, Quilmes, Provincia de Buenos Aires, Argentina",name:"122 - QUILMES",position:[-34.72191100000001, -58.2595044]},
  {category:"banco",type:"galicia",address:"C. 20 643, L6360 Gral. Pico, La Pampa, Argentina",name:"123 - GENERAL PICO",position:[-35.6591868, -63.7539541]},
  {category:"banco",type:"galicia",address:"San Justo, Buenos Aires Province, Argentina",name:"124 - SAN JUSTO",position:[-34.6874084, -58.5632629]},
  {category:"banco",type:"galicia",address:"Catamarca 723, W3400 Corrientes, Argentina",name:"125 - BARRIO LA ROSADA",position:[-27.4659533, -58.83269720000001]},
  {category:"banco",type:"galicia",address:"Av. Gral. Las Heras 3799, C1425 ATB, Buenos Aires, Argentina",name:"127 - UGARTECHE",position:[-34.5816673, -58.4125052]},
  {category:"banco",type:"galicia",address:"Av. Cabildo 935, C1426AAJ CABA, Argentina",name:"128 - BELGRANO SUD",position:[-34.5691999, -58.4458178]},
  {category:"banco",type:"galicia",address:"Juan B. Justo 124, Q8300 Neuqu\u00e9n, Argentina",name:"129 - NEUQUEN",position:[-38.9541693, -68.061002]},
  {category:"banco",type:"galicia",address:"Av. Cabildo 2762, Buenos Aires, Argentina",name:"130 - BELGRANO NORTE",position:[-34.5565478, -58.46173599999999]},
  {category:"banco",type:"galicia",address:"Gral. Arenales & Av. Juan Domingo Per\u00f3n, Salta, Argentina",name:"131 - GRAND BOURG - SALTA",position:[-24.7664285, -65.4475781]},
  {category:"banco",type:"galicia",address:"C. 150 3371, B1880DWM Berazategui, Provincia de Buenos Aires, Argentina",name:"132 - FLORENCIO VARELA",position:[-34.7664927, -58.20274029999999]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 2196, C1425 CABA, Argentina",name:"133 - PLAZA GRAND BOURG",position:[-34.5815252, -58.4036132]},
  {category:"banco",type:"galicia",address:"Av. de Las Palmeras 1452, S2121 Rosario, Santa Fe, Argentina",name:"134 - PARQUE INDUSTRIAL METROPOLITANO",position:[-32.9881478, -60.7413835]},
  {category:"banco",type:"galicia",address:"Av. de los Constituyentes 2985, C1314 Gral. Pacheco, Buenos Aires, Argentina",name:"135 - PARQUE INDUSTRIAL ESTRELLA",position:[-34.5921856, -58.4757115]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 5306, C1424CEV CABA, Argentina",name:"136 - CABALLITO",position:[-34.6200769, -58.44012000000001]},
  {category:"banco",type:"galicia",address:"Loria 187, B1832IXD Lomas de Zamora, Provincia de Buenos Aires, Argentina",name:"137 - LOMAS DE ZAMORA",position:[-34.7631264, -58.3996738]},
  {category:"banco",type:"galicia",address:"Buenos Aires, Argentina",name:"138 - CATEDRAL",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"galicia",address:"Merlo 2819, B1744 Moreno, Provincia de Buenos Aires, Argentina",name:"139 - MORENO",position:[-34.6477577, -58.7896764]},
  {category:"banco",type:"galicia",address:"Alvear 2566, Villa Ballester, Provincia de Buenos Aires, Argentina",name:"140 - VILLA BALLESTER",position:[-34.5486794, -58.5528439]},
  {category:"banco",type:"galicia",address:"AFD, Almte. Brown 169, H3500 Resistencia, Chaco, Argentina",name:"141 - ALMIRANTE BROWN - RESISTENCIA",position:[-27.4508201, -58.9832166]},
  {category:"banco",type:"galicia",address:"Gral. Alvear 357, B1640 Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"142 - ESTACI\u00d3N MART\u00cdNEZ",position:[-34.487561, -58.49634599999999]},
  {category:"banco",type:"galicia",address:"Rivadavia 554, San Fernando del Valle de Catamarca, Catamarca, Argentina",name:"143 - CATAMARCA",position:[-28.4676461, -65.7782677]},
  {category:"banco",type:"galicia",address:"Pringles 817, D5700 San Luis, Argentina",name:"144 - SAN LUIS",position:[-33.30241290000001, -66.3347442]},
  {category:"banco",type:"galicia",address:"Av. Leandro N. Alem 651, C1001AAB CABA, Argentina",name:"145 - LEANDRO N. ALEM",position:[-34.6000876, -58.3704159]},
  {category:"banco",type:"galicia",address:"Av. San Mart\u00edn 5189, S2011JQA Rosario, Santa Fe, Argentina",name:"146 - TIRO SUIZO",position:[-32.9976765, -60.6479852]},
  {category:"banco",type:"galicia",address:"Gdor. Emilio Belenguer 2235, Q8300 Neuqu\u00e9n, Argentina",name:"147 - PARQUE INDUSTRIAL NEUQU\u00c9N",position:[-38.9097946, -68.0800055]},
  {category:"banco",type:"galicia",address:"Av. Juan B Justo 1850, C\u00f3rdoba, Argentina",name:"148 - ALTA C\u00d3RDOBA",position:[-31.4050982, -64.1756649]},
  {category:"banco",type:"galicia",address:"San Carlos de Bol\u00edvar, Buenos Aires Province, Argentina",name:"149 - BOL\u00cdVAR",position:[-36.2391263, -61.1255879]},
  {category:"banco",type:"galicia",address:"8 de Junio 800, E3260 Concepci\u00f3n del Uruguay, Entre R\u00edos, Argentina",name:"150 - C. DEL URUGUAY",position:[-32.4825907, -58.2341071]},
  {category:"banco",type:"galicia",address:"Av. V\u00e9lez Sarsfield 1847, C1285 ABF, Buenos Aires, Argentina",name:"151 - AV. VELEZ SARSFIELD",position:[-34.65740040000001, -58.3887605]},
  {category:"banco",type:"galicia",address:"Blvd. Buenos Aires 1806, B1838AHV Luis Guillon, Provincia de Buenos Aires, Argentina",name:"152 - LUIS GUILL\u00d3N",position:[-34.8073128, -58.44345119999999]},
  {category:"banco",type:"galicia",address:"Av. Entre R\u00edos 1145, C1080ABF CABA, Argentina",name:"153 - AV. ENTRE RIOS",position:[-34.6220834, -58.3918585]},
  {category:"banco",type:"galicia",address:"Av. Francisco Beir\u00f3 5289, C1419IAB CABA, Argentina",name:"154 - VILLA DEVOTO SUD",position:[-34.6135982, -58.5249917]},
  {category:"banco",type:"galicia",address:"Av. San Martin 529, B7200CVF Las Flores, Provincia de Buenos Aires, Argentina",name:"155 - LAS FLORES",position:[-36.0149138, -59.1004531]},
  {category:"banco",type:"galicia",address:"Av. 9 de Julio 1088, C1097 CABA, Argentina",name:"156 - 9 DE JULIO",position:[-34.6153584, -58.3812328]},
  {category:"banco",type:"galicia",address:"Av. Eva Per\u00f3n 3760, C1407HUO CABA, Argentina",name:"157 - PARQUE AVELLANEDA",position:[-34.6468159, -58.4674875]},
  {category:"banco",type:"galicia",address:"Av. S. Mart\u00edn 2800, C1416 CABA, Argentina",name:"158 - PATERNAL",position:[-34.6029486, -58.465792]},
  {category:"banco",type:"galicia",address:"Av. Forest 600, C1427CEP CABA, Argentina",name:"159 - CHACARITA",position:[-34.5855072, -58.4539457]},
  {category:"banco",type:"galicia",address:"Av. La Plata 2402, C1437DHP CABA, Argentina",name:"160 - AV. CHICLANA",position:[-34.6428787, -58.4226192]},
  {category:"banco",type:"galicia",address:"Av. 12 de Octubre 2954, B1879 Quilmes, Provincia de Buenos Aires, Argentina",name:"161 - QUILMES OESTE",position:[-34.7419197, -58.28843739999999]},
  {category:"banco",type:"galicia",address:"Av. Maip\u00fa 2353, B1636AAD Olivos, Provincia de Buenos Aires, Argentina",name:"162 - OLIVOS ALTO",position:[-34.5135151, -58.48896549999999]},
  {category:"banco",type:"galicia",address:"Rua Caama\u00f1o & Jos\u00e9 Verdi, La Lonja, Provincia de Buenos Aires, Argentina",name:"163 - CAAMA\u00d1O",position:[-34.4287694, -58.8353936]},
  {category:"banco",type:"galicia",address:"Jujuy 45, Ober\u00e1, Misiones, Argentina",name:"164 - OBER\u00c1",position:[-27.4849077, -55.1219562]},
  {category:"banco",type:"galicia",address:"San Isidro, Buenos Aires Province, Argentina",name:"165 - SAN ISIDRO",position:[-34.470829, -58.5286102]},
  {category:"banco",type:"galicia",address:"Av. Pedro Luro 3043, B7600 Mar del Plata, Provincia de Buenos Aires, Argentina",name:"166 - LURO",position:[-37.9961889, -57.55059969999999]},
  {category:"banco",type:"galicia",address:"9 de Julio 198, T4146 Concepci\u00f3n, Tucum\u00e1n, Argentina",name:"167 - CONCEPCI\u00d3N TUCUM\u00c1N",position:[-27.3473725, -65.5925455]},
  {category:"banco",type:"galicia",address:"Eldorado, Misiones Province, Argentina",name:"168 - ELDORADO",position:[-26.4068823, -54.6369172]},
  {category:"banco",type:"galicia",address:"Av. Amadeo Sabattini 1895, X5802 R\u00edo Cuarto, C\u00f3rdoba, Argentina",name:"170 - AVENIDA ITALIA - RIO CUARTO",position:[-33.1350751, -64.3527531]},
  {category:"banco",type:"galicia",address:"C. 60 3164, B7630GUR Necochea, Provincia de Buenos Aires, Argentina",name:"171 - NECOCHEA",position:[-38.5556985, -58.7431085]},
  {category:"banco",type:"galicia",address:"Av. 7 875, B1900 La Plata, Provincia de Buenos Aires, Argentina",name:"172 - LA PLATA",position:[-34.9147766, -57.9503923]},
  {category:"banco",type:"galicia",address:"Balcarce 101, A4400 Salta, Argentina",name:"173 - SALTA",position:[-24.7883157, -65.412375]},
  {category:"banco",type:"galicia",address:"Av. \u00c1lvarez Jonte, Buenos Aires, Argentina",name:"174 - MONTE CASTRO",position:[-34.6192172, -58.5047293]},
  {category:"banco",type:"galicia",address:"Av. Pres. Roque S\u00e1enz Pe\u00f1a 865, C1035 San Nicolas, Buenos Aires, Argentina",name:"175 - DIAGONAL NORTE",position:[-34.6051369, -58.378561]},
  {category:"banco",type:"galicia",address:"Sobremonte 801, X5800AAQ R\u00edo Cuarto, C\u00f3rdoba, Argentina",name:"176 - R\u00cdO CUARTO",position:[-33.1245706, -64.3486046]},
  {category:"banco",type:"galicia",address:"Av. San Nicol\u00e1s de Bari 785, F5300 La Rioja, Argentina",name:"177 - LA RIOJA",position:[-29.4111982, -66.8358496]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 255, U9000 Comodoro Rivadavia, Chubut, Argentina",name:"178 - COMODORO RIVADAVIA",position:[-45.8619276, -67.4770601]},
  {category:"banco",type:"galicia",address:"AEA, Dr. \u00c1ngel C. Rotta 41, B1842 Monte Grande, Provincia de Buenos Aires, Argentina",name:"179 - MONTE GRANDE",position:[-34.8184911, -58.4673485]},
  {category:"banco",type:"galicia",address:"Esteban Adrogu\u00e9 1060, B1846 Adrogu\u00e9, Provincia de Buenos Aires, Argentina",name:"180 - ADROGUE",position:[-34.7985779, -58.3921734]},
  {category:"banco",type:"galicia",address:"San Fernando, Buenos Aires Province, Argentina",name:"181 - SAN FERNANDO",position:[-34.4430618, -58.55796429999999]},
  {category:"banco",type:"galicia",address:"Av. Pres. Manuel Quintana 111, C1014ACB CABA, Argentina",name:"182 - PARERA",position:[-34.5913055, -58.3860983]},
  {category:"banco",type:"galicia",address:"Blvd. Juan Manuel de Rosas 501, B1708 Castelar, Provincia de Buenos Aires, Argentina",name:"183 - MORON PLAZA",position:[-34.6363106, -58.6272096]},
  {category:"banco",type:"galicia",address:"Parque Leloir, Villa Udaondo, Provincia de Buenos Aires, Argentina",name:"186 - PARQUE LELOIR",position:[-34.616667, -58.683333]},
  {category:"banco",type:"galicia",address:"Av. Pedro Luro 6040, B7604BXX Mar del Plata, Provincia de Buenos Aires, Argentina",name:"187 - MONOLITO - MDP",position:[-37.9812762, -57.5810362]},
  {category:"banco",type:"galicia",address:"Av. Gral. Francisco Fern\u00e1ndez de la Cruz 4602, C1439 CABA, Argentina",name:"188 - JUMBO PARQUE BROWN",position:[-34.6736189, -58.4602567]},
  {category:"banco",type:"galicia",address:"Sarmiento 222, R8500ACF Viedma, R\u00edo Negro, Argentina",name:"189 - VIEDMA",position:[-40.8072542, -62.99123530000001]},
  {category:"banco",type:"galicia",address:"Las Flores 241, B1875 Wilde, Provincia de Buenos Aires, Argentina",name:"190 - WILDE",position:[-34.70191459999999, -58.3147703]},
  {category:"banco",type:"galicia",address:"San Lorenzo 2373, B1650 San Mart\u00edn, Provincia de Buenos Aires, Argentina",name:"191 - SAN MARTIN CENTRO",position:[-34.57467250000001, -58.54138849999999]},
  {category:"banco",type:"galicia",address:"Av. Pedro Goyena 280, C1424 CABA, Argentina",name:"192 - DOBLAS Y PEDRO GOYENA",position:[-34.6257387, -58.4310947]},
  {category:"banco",type:"galicia",address:"Av. del Libertador 5740, C1428ARN CABA, Argentina",name:"194 - BARRANCAS DE BELGRANO",position:[-34.5593263, -58.4459349]},
  {category:"banco",type:"galicia",address:"Jos\u00e9 Manuel Estrada 272, X5000BQE C\u00f3rdoba, Argentina",name:"196 - NUEVA CORDOBA",position:[-31.4283107, -64.18562430000001]},
  {category:"banco",type:"galicia",address:"Av. Amadeo Sabattini 4295, X5006 KQG, C\u00f3rdoba, Argentina",name:"197 - AV.SABBATINI",position:[-31.4344479, -64.1314029]},
  {category:"banco",type:"galicia",address:"Av. Pres. Dr. Nestor C. Kirchner 739, Z9400 R\u00edo Gallegos, Santa Cruz, Argentina",name:"198 - RIO GALLEGOS",position:[-51.6225832, -69.2127181]},
  {category:"banco",type:"galicia",address:"Av. Rafael N\u00fa\u00f1ez 3989, C\u00f3rdoba, Argentina",name:"199 - AMERICANOS - CERRO LAS ROSAS",position:[-31.372524, -64.2300456]},
  {category:"banco",type:"galicia",address:"Dardo Rocha 2036, Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"209 - DARDO ROCHA",position:[-34.49182, -58.52848999999999]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn 130, M5515 Maip\u00fa, Mendoza, Argentina",name:"210 - MAIP\u00da MENDOZA",position:[-32.983781, -68.788245]},
  {category:"banco",type:"galicia",address:"Cno. Gral. Manuel Belgrano & C. 514, B1897 Gonnet, Provincia de Buenos Aires, Argentina",name:"211 - CARREFOUR LA PLATA",position:[-34.8943479, -57.9994356]},
  {category:"banco",type:"galicia",address:"Av. Int. Bullrich 345, C1425 CABA, Argentina",name:"212 - JUMBO PALERMO",position:[-34.5742714, -58.4267667]},
  {category:"banco",type:"galicia",address:"Av. Libertador Gral. San Mart\u00edn 3196, Rivadavia, San Juan, Argentina",name:"213 - EL LIBERTADOR",position:[-31.5284353, -68.5699589]},
  {category:"banco",type:"galicia",address:"C. 94, Provincia de Buenos Aires, Argentina",name:"217 - PARQUE INDUSTRIAL PILAR",position:[-34.5740816, -58.567966]},
  {category:"banco",type:"galicia",address:"Av. Eva Per\u00f3n 7974, Rosario, Santa Fe, Argentina",name:"220 - ROSARIO - FISHERTON",position:[-32.9290879, -60.7338929]},
  {category:"banco",type:"galicia",address:"Av. 7 59, La Plata, Provincia de Buenos Aires, Argentina",name:"221 - LA PLATA NORTE",position:[-34.9017534, -57.9676603]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 28, M5602 San Rafael, Mendoza, Argentina",name:"222 - SAN RAFAEL",position:[-34.6158064, -68.3302873]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn, Mendoza Province, Argentina",name:"224 - SAN MARTIN",position:[-33.0806497, -68.47057]},
  {category:"banco",type:"galicia",address:"Carril Rodr\u00edguez Pe\u00f1a 2500, M5501 Godoy Cruz, Mendoza, Argentina",name:"225 - PARQUE INDUSTRIAL MENDOZA",position:[-32.9283817, -68.8123521]},
  {category:"banco",type:"galicia",address:"Humberto Primo, C\u00f3rdoba, Argentina",name:"227 - HUMBERTO PRIMO",position:[-31.4067268, -64.1933096]},
  {category:"banco",type:"galicia",address:"Av. Leandro N. Alem 176, Buenos Aires, Argentina",name:"228 - QUILMES ALEM",position:[-34.6056187, -58.3704238]},
  {category:"banco",type:"galicia",address:"Santiago Pampiglione 4891, X2401 San Francisco, C\u00f3rdoba, Argentina",name:"229 - PARQUE INDUSTRIAL SAN FRANCISCO",position:[-31.4391944, -62.121137]},
  {category:"banco",type:"galicia",address:"Chacabuco 522, H3700 S\u00e1enz Pe\u00f1a, Chaco, Argentina",name:"232 - PRESIDENCIA ROQUE S\u00c1ENZ PE\u00d1A",position:[-26.791313, -60.442327]},
  {category:"banco",type:"galicia",address:"C\u00f3rdoba 1851, S2000AXC Rosario, Santa Fe, Argentina",name:"233 - PASEO DEL SIGLO",position:[-32.944694, -60.64853900000001]},
  {category:"banco",type:"galicia",address:"Av. C\u00f3rdoba 2090, C1120AAP CABA, Argentina",name:"234 - FACULTAD DE CIENCIAS ECONOMICAS",position:[-34.59988510000001, -58.396941]},
  {category:"banco",type:"galicia",address:"Av. Eugenia Tapia de Cruz 602, B1625AAT Bel\u00e9n de Escobar, Provincia de Buenos Aires, Argentina",name:"235 - ESCOBAR",position:[-34.34684, -58.794183]},
  {category:"banco",type:"galicia",address:"Av. de Mayo 600, C1084AAN CABA, Argentina",name:"236 - PLAZA DE MAYO",position:[-34.6088313, -58.37472950000001]},
  {category:"banco",type:"galicia",address:"Puente del Inca 2450, B1813 Ezeiza, Provincia de Buenos Aires, Argentina",name:"240 - POLO INDUSTRIAL EZEIZA",position:[-34.8713802, -58.6124042]},
  {category:"banco",type:"galicia",address:"Paran\u00e1 3745, B1640FRC Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"241 - JUMBO UNICENTER",position:[-34.5086233, -58.52320999999999]},
  {category:"banco",type:"galicia",address:"Av. Belgrano 992, Buenos Aires, Argentina",name:"243 - AV. BELGRANO",position:[-34.613287, -58.38009129999999]},
  {category:"banco",type:"galicia",address:"Super\u00ed 1801, C1430FDA CABA, Argentina",name:"245 - BELGRANO R",position:[-34.5703879, -58.464185]},
  {category:"banco",type:"galicia",address:"Av. Ar\u00edstides Villanueva 380, M5500EOX Mendoza, Argentina",name:"246 - ARISTEDES VILLANUEVA",position:[-32.8922742, -68.8561565]},
  {category:"banco",type:"galicia",address:"Libertad 376, M5521KPQ Mendoza, Argentina",name:"247 - MENDOZA - GUAYMALLEN",position:[-32.9027619, -68.7891576]},
  {category:"banco",type:"galicia",address:"Av. Elcano 2988, C1426EJN CABA, Argentina",name:"248 - AVENIDA ELCANO",position:[-34.57070780000001, -58.4578454]},
  {category:"banco",type:"galicia",address:"Arturo Jauretche 1106, Hurlingham, Provincia de Buenos Aires, Argentina",name:"250 - HURLINGHAM",position:[-34.5895827, -58.6325709]},
  {category:"banco",type:"galicia",address:"Hip\u00f3lito Yrigoyen 169, L6302 Santa Rosa, La Pampa, Argentina",name:"251 - SANTA ROSA",position:[-36.6197558, -64.28903559999999]},
  {category:"banco",type:"galicia",address:"Manuel Belgrano, M5507 Luj\u00e1n de Cuyo, Mendoza, Argentina",name:"253 - MENDOZA - LUJAN DE CUYO",position:[-33.0358789, -68.8774827]},
  {category:"banco",type:"galicia",address:"Domingo Faustino Sarmiento 1534, Gral. Roca, R\u00edo Negro, Argentina",name:"254 - GENERAL ROCA",position:[-39.0282551, -67.5746894]},
  {category:"banco",type:"galicia",address:"Puerto Madryn, Chubut Province, Argentina",name:"256 - PUERTO MADRYN",position:[-42.76362169999999, -65.03483109999999]},
  {category:"banco",type:"galicia",address:"Av. Rivadavia 6187, C1406 CABA, Argentina",name:"257 - DONATO ALVAREZ",position:[-34.62529620000001, -58.4538342]},
  {category:"banco",type:"galicia",address:"RP6, Ca\u00f1uelas, Provincia de Buenos Aires, Argentina",name:"259 - MERCADO AGROGANADERO",position:[-35.0398842, -58.7142771]},
  {category:"banco",type:"galicia",address:"EAS, Sarmiento 2200, S3080 Esperanza, Santa Fe, Argentina",name:"260 - ESPERANZA",position:[-31.4477471, -60.93095770000001]},
  {category:"banco",type:"galicia",address:"Av. Col\u00f3n 503, B8000 Bah\u00eda Blanca, Provincia de Buenos Aires, Argentina",name:"261 - BAHIA BLANCA - AV. COL\u00d3N",position:[-38.7234409, -62.2731432]},
  {category:"banco",type:"galicia",address:"Belgrano 198, Ap\u00f3stoles, Misiones, Argentina",name:"262 - APOSTOLES",position:[-27.9191958, -55.7588421]},
  {category:"banco",type:"galicia",address:"Entre Rios, Argentina",name:"263 - LIBERTADOR GRAL. SAN MARTIN",position:[-32.5175643, -59.1041758]},
  {category:"banco",type:"galicia",address:"Sarmiento 499, S2252IKI G\u00e1lvez, Santa Fe, Argentina",name:"264 - GALVEZ",position:[-32.030511, -61.22022519999999]},
  {category:"banco",type:"galicia",address:"9 de Julio 500, M5500 Mendoza, Argentina",name:"265 - MUNICIPALIDAD CIUDAD DE MENDOZA",position:[-32.8977768, -68.84299829999999]},
  {category:"banco",type:"galicia",address:"Av. Coronel Roca 3450, C1437 CABA, Argentina",name:"266 - CTC - VILLA SOLDATI",position:[-34.6677993, -58.44063800000001]},
  {category:"banco",type:"galicia",address:"Blvd. Oro\u00f1o 1042, S2000DSX Rosario, Santa Fe, Argentina",name:"267 - BOULEVARD ORO\u00d1O",position:[-32.9467504, -60.65448019999999]},
  {category:"banco",type:"galicia",address:"Garibaldi 107, X5850 R\u00edo Tercero, C\u00f3rdoba, Argentina",name:"274 - RIO TERCERO",position:[-32.1753193, -64.1095058]},
  {category:"banco",type:"galicia",address:"Bell Ville, Cordoba, Argentina",name:"278 - BELL VILLE",position:[-32.6305078, -62.68885689999999]},
  {category:"banco",type:"galicia",address:"Avenida Leandro N. Alem 499, X5001HYC C\u00f3rdoba, Argentina",name:"281 - MARCOS JUAREZ",position:[-31.3961056, -64.1685797]},
  {category:"banco",type:"galicia",address:"Italia 71, Rufino, Santa Fe, Argentina",name:"294 - RUFINO",position:[-34.26269550000001, -62.71081640000001]},
  {category:"banco",type:"galicia",address:"Mariano Moreno 647, C6230 Gral. Villegas, Provincia de Buenos Aires, Argentina",name:"296 - GENERAL VILLEGAS",position:[-35.03304, -63.01680700000001]},
  {category:"banco",type:"galicia",address:"De la Esq. 501, Godoy Cruz, Mendoza, Argentina",name:"300 - MENDOZA - GODOY CRUZ",position:[-32.9464181, -68.8581792]},
  {category:"banco",type:"galicia",address:"Av. Corrientes 4152, C1195AAN CABA, Argentina",name:"303 - MEDRANO",position:[-34.6032843, -58.4234638]},
  {category:"banco",type:"galicia",address:"Av. C\u00f3rdoba 1428, C1055AAR CABA, Argentina",name:"306 - PLAZA LAVALLE",position:[-34.5994387, -58.3872873]},
  {category:"banco",type:"galicia",address:"Leandro N. Alem, Lincoln, Provincia de Buenos Aires, Argentina",name:"307 - LINCOLN",position:[-34.8687001, -61.5287532]},
  {category:"banco",type:"galicia",address:"Av. Caz\u00f3n 1554, B1648 Tigre, Provincia de Buenos Aires, Argentina",name:"308 - TIGRE",position:[-34.42329670000001, -58.5802406]},
  {category:"banco",type:"galicia",address:"Viamonte, Aguinaga 5055 Y, M5528 Chacras de Coria, Mendoza, Argentina",name:"310 - MENDOZA - CHACRAS DE CORIA",position:[-32.9862433, -68.8826844]},
  {category:"banco",type:"galicia",address:"Av. del Libertador, Argentina",name:"313 - GENERAL PACHECO",position:[-34.5150385, -58.4764927]},
  {category:"banco",type:"galicia",address:"Av. Sta. Fe 2835, C1425 CABA, Argentina",name:"314 - ESTACION AGUERO",position:[-34.5920188, -58.4061678]},
  {category:"banco",type:"galicia",address:"Av. 13 665, B1900 La Plata, Provincia de Buenos Aires, Argentina",name:"316 - LA PLATA - TRIBUNALES",position:[-34.9176023, -57.960059]},
  {category:"banco",type:"galicia",address:"Av. Luis Mar\u00eda Campos 1349, C1426 CABA, Argentina",name:"322 - BELGRANO",position:[-34.564016, -58.4418377]},
  {category:"banco",type:"galicia",address:"Av. Callao 1171, C1023AAE CABA, Argentina",name:"325 - CALLAO Y ARENALES",position:[-34.5951902, -58.39309739999999]},
  {category:"banco",type:"galicia",address:"Av. Leandro N. Alem 910, C1001AAR CABA, Argentina",name:"327 - RETIRO",position:[-34.596987, -58.372285]},
  {category:"banco",type:"galicia",address:"Av. de Mayo 173, B1704BUO Ramos Mej\u00eda, Provincia de Buenos Aires, Argentina",name:"332 - RAMOS MEJIA CENTRO",position:[-34.6424787, -58.5656019]},
  {category:"banco",type:"galicia",address:"Av. Juan Segundo Fern\u00e1ndez 159, San Isidro, Provincia de Buenos Aires, Argentina",name:"335 - GOLF SAN ISIDRO",position:[-34.4901341, -58.5476853]},
  {category:"banco",type:"galicia",address:"Mariscal Antonio Jos\u00e9 de Sucre 676, C1428 CABA, Argentina",name:"338 - PLAZA JURAMENTO",position:[-34.5532108, -58.43686169999999]},
  {category:"banco",type:"galicia",address:"Maip\u00fa 241, Buenos Aires, Argentina",name:"339 - FLORIDA II",position:[-34.6053263, -58.3764396]},
  {category:"banco",type:"galicia",address:"Mart\u00edn Miguel de G\u00fcemes 3536, B7600 Mar del Plata, Provincia de Buenos Aires, Argentina",name:"340 - GUEMES",position:[-38.0207756, -57.5456095]},
  {category:"banco",type:"galicia",address:"Buenos Aires, Argentina",name:"341 - PEHUAJO",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"galicia",address:"Villegas 434, B6400 Trenque Lauquen, Provincia de Buenos Aires, Argentina",name:"342 - TRENQUE LAUQUEN",position:[-35.972357, -62.7336973]},
  {category:"banco",type:"galicia",address:"Ituzaing\u00f3, Z\u00e1rate, Provincia de Buenos Aires, Argentina",name:"343 - ZARATE",position:[-34.1056341, -59.0396402]},
  {category:"banco",type:"galicia",address:"Calle Tucum\u00e1n, Jesus Mar\u00eda, C\u00f3rdoba, Argentina",name:"344 - JESUS MARIA",position:[-30.9808865, -64.0973504]},
  {category:"banco",type:"galicia",address:"Habegger 560, S3560 Reconquista, Santa Fe, Argentina",name:"345 - RECONQUISTA",position:[-29.1465092, -59.64343299999999]},
  {category:"banco",type:"galicia",address:"Gdor. Pedro Godoy 162, V9410DID Ushuaia, Tierra del Fuego, Argentina",name:"346 - USHUAIA",position:[-54.8053653, -68.3031294]},
  {category:"banco",type:"galicia",address:"Falconier 158, Tierra del Fuego, Argentina",name:"347 - RIO GRANDE",position:[-53.8134538, -67.675274]},
  {category:"banco",type:"galicia",address:"Av. Fontana 304, U9100 Trelew, Chubut, Argentina",name:"348 - TRELEW",position:[-43.2515854, -65.3060371]},
  {category:"banco",type:"galicia",address:"Espa\u00f1a 240, R8324 Cipolletti, R\u00edo Negro, Argentina",name:"349 - CIPOLLETTI",position:[-38.9387766, -67.9963537]},
  {category:"banco",type:"galicia",address:"Mariano Castex 1277, B1804 Canning, Provincia de Buenos Aires, Argentina",name:"350 - CANNING",position:[-34.8602473, -58.5032181]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 2615, U9000 Comodoro Rivadavia, Chubut, Argentina",name:"351 - PARQUE INDUSTRIAL COMODORO RIVADAVIA",position:[-45.8825691, -67.5158282]},
  {category:"banco",type:"galicia",address:"Lavalle, Villa Mercedes, San Luis, Argentina",name:"352 - VILLA MERCEDES",position:[-33.6910467, -65.4667053]},
  {category:"banco",type:"galicia",address:"Av. del Bicentenario de la Batalla de Salta 805, A4400DWI Salta, Argentina",name:"353 - BICENTENARIO",position:[-24.7802246, -65.4037022]},
  {category:"banco",type:"galicia",address:"Av. Recta Martinolli 6377, X5021 C\u00f3rdoba, Argentina",name:"355 - RECTA MARTINOLLI",position:[-31.3488512, -64.2545847]},
  {category:"banco",type:"galicia",address:"Tte. Gral. Juan Domingo Per\u00f3n 456, C1038AAJ CABA, Argentina",name:"356 - DISTRITO GALICIA",position:[-34.6057532, -58.3722027]},
  {category:"banco",type:"galicia",address:"Maip\u00fa 761, T4000 San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"357 - TUCUMAN BARRIO NORTE",position:[-26.8201847, -65.2048507]},
  {category:"banco",type:"galicia",address:"Juana Manso 1150, C1107CBX CABA, Argentina",name:"358 - PUERTO MADERO",position:[-34.6103371, -58.3625447]},
  {category:"banco",type:"galicia",address:"Av. San Mart\u00edn 1262, S2200 San Lorenzo, Santa Fe, Argentina",name:"359 - SAN LORENZO",position:[-32.7491246, -60.7340315]},
  {category:"banco",type:"galicia",address:"Av. Alvear 1331, C1014AAA CABA, Argentina",name:"360 - AV. ALVEAR",position:[-34.5911123, -58.38344189999999]},
  {category:"banco",type:"galicia",address:"Ramal Pilar km 36, B1638 Tortuguitas, Provincia de Buenos Aires, Argentina",name:"361 - TORTUGAS MALL",position:[-34.4533486, -58.7262757]},
  {category:"banco",type:"galicia",address:"Blvd. San Juan 234, X5000 C\u00f3rdoba, Argentina",name:"362 - BOULEVARD SAN JUAN",position:[-31.4197984, -64.18925089999999]},
  {category:"banco",type:"galicia",address:"Av Triunvirato 4458, C1431 Villa Urquiza, Buenos Aires, Argentina",name:"363 - VILLA URQUIZA",position:[-34.5761674, -58.48380869999999]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn 3201, B7260ERA Saladillo, Provincia de Buenos Aires, Argentina",name:"364 - SALADILLO",position:[-35.6399438, -59.7794482]},
  {category:"banco",type:"galicia",address:"Av. Corrientes 1910, C1045 AAO, Buenos Aires, Argentina",name:"366 - ESTACION PASTEUR",position:[-34.6046297, -58.39403309999999]},
  {category:"banco",type:"galicia",address:"Las Magnolias 754, La Lonja, Provincia de Buenos Aires, Argentina",name:"367 - PALMAS DEL PILAR",position:[-34.4462858, -58.87045819999999]},
  {category:"banco",type:"galicia",address:"Av. Constituci\u00f3n 1042, Pinamar, Provincia de Buenos Aires, Argentina",name:"368 - PINAMAR",position:[-37.1108099, -56.8678129]},
  {category:"banco",type:"galicia",address:"Buenos Aires, Argentina",name:"369 - BALCARCE",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"galicia",address:"Av. 14 Pres. Juan Domingo Per\u00f3n 5169, B1880BFF Berazategui, Provincia de Buenos Aires, Argentina",name:"371 - BERAZATEGUI",position:[-34.759898, -58.2052334]},
  {category:"banco",type:"galicia",address:"Paran\u00e1 6534, B1607 Villa Adelina, Provincia de Buenos Aires, Argentina",name:"372 - VILLA ADELINA",position:[-34.5268516, -58.5494943]},
  {category:"banco",type:"galicia",address:"Av. 44 1724, La Plata, Provincia de Buenos Aires, Argentina",name:"373 - LA PLATA AV. 44",position:[-34.9319641, -57.97803460000001]},
  {category:"banco",type:"galicia",address:"Av. Aconquija 1391, T4107 Yerba Buena, Tucum\u00e1n, Argentina",name:"374 - YERBA BUENA",position:[-26.8145866, -65.2845449]},
  {category:"banco",type:"galicia",address:"25 de Mayo 531, U9200 Esquel, Chubut, Argentina",name:"375 - ESQUEL",position:[-42.9146402, -71.319668]},
  {category:"banco",type:"galicia",address:"City Bell, Buenos Aires Province, Argentina",name:"376 - CITY BELL",position:[-34.8807236, -58.0572698]},
  {category:"banco",type:"galicia",address:"Cnel. Su\u00e1rez 110, B1766CYD La Tablada, Provincia de Buenos Aires, Argentina",name:"377 - CORONEL SUAREZ",position:[-34.6907225, -58.51509979999999]},
  {category:"banco",type:"galicia",address:"Av. 11 de Septiembre 2335, B2930 San Pedro, Provincia de Buenos Aires, Argentina",name:"379 - SAN PEDRO",position:[-33.6853167, -59.67837480000001]},
  {category:"banco",type:"galicia",address:"Blvd. G\u00e1lvez, Santa Fe, Argentina",name:"380 - BOULEVARD GALVEZ",position:[-31.6378489, -60.6922477]},
  {category:"banco",type:"galicia",address:"Av. Corrientes 409, C1043AAR CABA, Argentina",name:"381 - MICROCENTRO RECONQUISTA",position:[-34.6029927, -58.3726368]},
  {category:"banco",type:"galicia",address:"Av. de los Lagos 6895 LOCAL 1, B1670 Buenos Aires, Provincia de Buenos Aires, Argentina",name:"383 - NORDELTA",position:[-34.3997787, -58.6507941]},
  {category:"banco",type:"galicia",address:"Nogoy\u00e1 3151, C1417 CABA, Argentina",name:"385 - ESTACI\u00d3N VILLA DEL PARQUE",position:[-34.603263, -58.49279699999999]},
  {category:"banco",type:"galicia",address:"Av. Olascoaga 890, Q8322 Cutral Co, Neuqu\u00e9n, Argentina",name:"386 - CUTRAL-CO",position:[-38.9323644, -69.2174017]},
  {category:"banco",type:"galicia",address:"A\u00f1elo, Neuquen, Argentina",name:"390 - A\u00d1ELO",position:[-38.3516044, -68.7874869]},
  {category:"banco",type:"galicia",address:"San Mart\u00edn 643, B1812 Ca\u00f1uelas, Provincia de Buenos Aires, Argentina",name:"391 - CA\u00d1UELAS",position:[-34.9423383, -58.62159550000001]},
  {category:"banco",type:"galicia",address:"Av. Monteverde 3793, B1852 Burzaco, Provincia de Buenos Aires, Argentina",name:"392 - BURZACO",position:[-34.8342462, -58.40955599999999]},
  {category:"banco",type:"galicia",address:"Av. Caseros 2860, C1244 CABA, Argentina",name:"393 - PARQUE PATRICIOS",position:[-34.6368357, -58.40312040000001]},
  {category:"banco",type:"galicia",address:"Av. Cnel. Escalada 1200, B1645 Gral. Pacheco, Provincia de Buenos Aires, Argentina",name:"394 - LAGUNA DEL SOL",position:[-34.4686235, -58.6096433]},
  {category:"banco",type:"galicia",address:"Don Bosco 322, Ensenada, Provincia de Buenos Aires, Argentina",name:"395 - ENSENADA",position:[-34.8578163, -57.90716099999999]},
  {category:"banco",type:"galicia",address:"San Justo, Buenos Aires Province, Argentina",name:"396 - SAN JUSTO UNIVERSIDAD",position:[-34.6874084, -58.5632629]},
  {category:"banco",type:"galicia",address:"9 de Julio 417, T4000IHI San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"397 - TUCUMAN TRIBUNALES",position:[-26.8366836, -65.2062571]},
  {category:"banco",type:"galicia",address:"Av. Hip\u00f3lito Yrigoyen 2420 LOCAL A, B1618AWW El Talar, Provincia de Buenos Aires, Argentina",name:"398 - EL TALAR",position:[-34.4745855, -58.6576312]},
  {category:"banco",type:"galicia",address:"Av. Corrientes 6287, C1427BPA CABA, Argentina",name:"400 - PLAZA GALICIA",position:[-34.5905728, -58.448573]},
  {category:"banco",type:"galicia",address:"Mar del Plata, Buenos Aires Province, Argentina",name:"432 - AV. CONSTITUCI\u00d3N - MDP",position:[-38.0054771, -57.5426106]},
  {category:"banco",type:"galicia",address:"Av. Juan Bautista Justo 1423, C1414CWD CABA, Argentina",name:"701 - HONDURAS",position:[-34.5847056, -58.43432379999999]},
  {category:"banco",type:"galicia",address:"Av. Cabildo 4476, Buenos Aires, Provincia de Buenos Aires, Argentina",name:"702 - ESTACI\u00d3N RAMALLO",position:[-34.5433761, -58.4727891]},
  {category:"banco",type:"galicia",address:"Av. Alberdi 822, S2000 Rosario, Santa Fe, Argentina",name:"704 - BOULEVARD ALBERDI",position:[-32.9178308, -60.68331579999999]},
  {category:"banco",type:"galicia",address:"Tte. Gral. Juan Domingo Per\u00f3n 407, C1038 CABA, Argentina",name:"999 - CASA MATRIZ",position:[-34.6053082, -58.3725241]},
  {category:"banco",type:"santander",address:"Bartolom\u00e9 Mitre 480, B1603 CABA, Argentina",name:"Casa Central",position:[-34.606841, -58.37350960000001]},
  {category:"banco",type:"santander",address:"Av. Belgrano 980, C1095 CABA, Argentina",name:"Monserrat",position:[-34.6134383, -58.38002499999999]},
  {category:"banco",type:"santander",address:"Florencio Varela, Buenos Aires Province, Argentina",name:"Florencio Varela",position:[-34.7965806, -58.27601199999999]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 474, B1876 Bernal, Provincia de Buenos Aires, Argentina",name:"Bernal ",position:[-34.7096803, -58.2810107]},
  {category:"banco",type:"santander",address:"Av. Rigolleau 4399, B1884CUF Berazategui, Provincia de Buenos Aires, Argentina",name:"Berazategui",position:[-34.7641247, -58.2133646]},
  {category:"banco",type:"santander",address:"C. 26, Provincia de Buenos Aires, Argentina",name:"San Cayetano",position:[-34.8870442, -58.0467719]},
  {category:"banco",type:"santander",address:"Av. Cabildo 2543, C1428AAH CABA, Argentina",name:"Belgrano",position:[-34.5576353, -58.46027160000001]},
  {category:"banco",type:"santander",address:"Av. Rivadavia 11120, C1408AAY CABA, Argentina",name:"Liniers",position:[-34.6391684, -58.5215778]},
  {category:"banco",type:"santander",address:"Av. \u00c1lvarez Jonte 4502, C1407GOZ CABA, Argentina",name:"Floresta",position:[-34.6181951, -58.5024762]},
  {category:"banco",type:"santander",address:"Comuna 4, Av. La Plata 2401, C1437 CABA, Argentina",name:"Pompeya",position:[-34.6427608, -58.42308790000001]},
  {category:"banco",type:"santander",address:"Av. Juan Bautista Alberdi 6367, C1440ABB CABA, Argentina",name:"Mataderos",position:[-34.6550436, -58.50722030000001]},
  {category:"banco",type:"santander",address:"Av. Sta. Fe 1450, C1060 ABN, Buenos Aires, Argentina",name:"Av. Santa Fe ",position:[-34.5958868, -58.38776420000001]},
  {category:"banco",type:"santander",address:"Av. de los Constituyentes 5857, C1431EZJ CABA, Argentina",name:"Av. de Los Constituyentes",position:[-34.573056, -58.5034667]},
  {category:"banco",type:"santander",address:"Av. Corrientes 1427, C1048 CABA, Argentina",name:"Tribunales",position:[-34.6038891, -58.38695550000001]},
  {category:"banco",type:"santander",address:"Av. Jujuy 938, C1229ABR CABA, Argentina",name:"San Cristobal",position:[-34.6202606, -58.4029249]},
  {category:"banco",type:"santander",address:"Av. Forest 1187, Buenos Aires, Argentina",name:"Chacarita",position:[-34.5785828, -58.45977610000001]},
  {category:"banco",type:"santander",address:"Av. Brig. Gral. Juan Manuel de Rosas 3801, B1754FUJ San Justo, Provincia de Buenos Aires, Argentina",name:"San Justo",position:[-34.6838729, -58.5569564]},
  {category:"banco",type:"santander",address:"Av. Hip\u00f3lito Yrigoyen 3703, B1824 Lan\u00fas, Provincia de Buenos Aires, Argentina",name:"Lanus",position:[-34.6982908, -58.3923832]},
  {category:"banco",type:"santander",address:"CGN, Av. Hip\u00f3lito Yrigoyen 8820, B1828 Lomas de Zamora, Provincia de Buenos Aires, Argentina",name:"Lomas de Zamora ",position:[-34.7583356, -58.40195989999999]},
  {category:"banco",type:"santander",address:"Av. Hip\u00f3lito Yrigoyen, Quilmes, Provincia de Buenos Aires, Argentina",name:"Quilmes",position:[-34.7342872, -58.2516964]},
  {category:"banco",type:"santander",address:"Av. Entre R\u00edos 1692, B1133 CABA, Argentina",name:"Constitucion",position:[-34.6279298, -58.39092309999999]},
  {category:"banco",type:"santander",address:"Ntra Sra del Buen Viaje 780, B1708 Mor\u00f3n, Provincia de Buenos Aires, Argentina",name:"Moron",position:[-34.6517693, -58.61956119999999]},
  {category:"banco",type:"santander",address:"Av Triunvirato 4648, C1431 CABA, Argentina",name:"Villa Urquiza",position:[-34.5747978, -58.4861372]},
  {category:"banco",type:"santander",address:"Av. S. Mart\u00edn 2219, C1425 CABA, Argentina",name:"Paternal",position:[-34.6041039, -58.45903010000001]},
  {category:"banco",type:"santander",address:"Espora, Ramos Mej\u00eda, Provincia de Buenos Aires, Argentina",name:"Ramos Mejia",position:[-34.6454991, -58.5716392]},
  {category:"banco",type:"santander",address:"Av. Bartolom\u00e9 Mitre 6222, B1875ABF Wilde, Provincia de Buenos Aires, Argentina",name:"Wilde",position:[-34.703074, -58.31811119999999]},
  {category:"banco",type:"santander",address:"Av. 9 de Julio 1472, B1824KKJ Lan\u00fas, Provincia de Buenos Aires, Argentina",name:"Lanus Este",position:[-34.7100808, -58.3868662]},
  {category:"banco",type:"santander",address:"Av. Rivadavia 4364, C1205AAQ CABA, Argentina",name:"San Carlos",position:[-34.6142299, -58.4269579]},
  {category:"banco",type:"santander",address:"Gdor. Inocencio Arias 2437, B1712CDE Castelar, Provincia de Buenos Aires, Argentina",name:"Castelar",position:[-34.6508693, -58.6430977]},
  {category:"banco",type:"santander",address:"Av. Gaona 3935, Buenos Aires, Argentina",name:"Gaona",position:[-34.6232541, -58.4801061]},
  {category:"banco",type:"santander",address:"Av. Bartolom\u00e9 Mitre 553, B1874 Avellaneda, Provincia de Buenos Aires, Argentina",name:"Avellaneda",position:[-34.66084, -58.36750179999999]},
  {category:"banco",type:"santander",address:"Bartolom\u00e9 Mitre 363, Rojas, Provincia de Buenos Aires, Argentina",name:"Rojas",position:[-34.1980773, -60.73264229999999]},
  {category:"banco",type:"santander",address:"Av. Emilio Caraffa, C\u00f3rdoba, Argentina",name:"Villa Cabrera ",position:[-31.3886114, -64.2105785]},
  {category:"banco",type:"santander",address:"Rosario de Sta. Fe 177, X5000ACC C\u00f3rdoba, Argentina",name:"Cordoba",position:[-31.4166233, -64.18169809999999]},
  {category:"banco",type:"santander",address:"Av. Libertad 6950, B7604 Mar del Plata, Provincia de Buenos Aires, Argentina",name:"Mar Del Plata",position:[-37.9717758, -57.5860856]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 898, M5500 Mendoza, Argentina",name:"Mendoza",position:[-32.8928864, -68.8400752]},
  {category:"banco",type:"santander",address:"Gral. Jos\u00e9 de San Mart\u00edn 586, T4000CVL San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"Tucuman",position:[-26.829543, -65.2057038]},
  {category:"banco",type:"santander",address:"Av. Hip\u00f3lito Yrigoyen 797, Gral. Pacheco, Provincia de Buenos Aires, Argentina",name:"Gral. Pacheco",position:[-34.4595357, -58.63328199999999]},
  {category:"banco",type:"santander",address:"A. T. de Alvear, Carapachay, Provincia de Buenos Aires, Argentina",name:"Don Torcuato",position:[-34.532045, -58.538363]},
  {category:"banco",type:"santander",address:"Av. Ing. Agust\u00edn Rocca 67, B2804 Campana, Provincia de Buenos Aires, Argentina",name:"Campana",position:[-34.1579546, -58.95883499999999]},
  {category:"banco",type:"santander",address:"Gdor. Marcelino Ugarte, Munro, Provincia de Buenos Aires, Argentina",name:"Munro",position:[-34.5245567, -58.5125578]},
  {category:"banco",type:"santander",address:"Av. Bartolom\u00e9 Mitre 1695, B1602 Florida, Provincia de Buenos Aires, Argentina",name:"Florida",position:[-34.5341676, -58.5092619]},
  {category:"banco",type:"santander",address:"Acassuso 252, B1642 CGF, Provincia de Buenos Aires, Argentina",name:"San Isidro",position:[-34.4704105, -58.5111791]},
  {category:"banco",type:"santander",address:"C. 85 1933, San Mart\u00edn, Provincia de Buenos Aires, Argentina",name:"San Martin",position:[-34.5769532, -58.535474]},
  {category:"banco",type:"santander",address:"Henry Ford & Fournier, Ricardo Rojas, Provincia de Buenos Aires, Argentina",name:"Alto Del Talar",position:[-34.4475174, -58.6972124]},
  {category:"banco",type:"santander",address:"Av. Pte. J. D. Per\u00f3n 906, B1662ASW Mu\u00f1iz, Provincia de Buenos Aires, Argentina",name:"San Miguel ",position:[-34.5477645, -58.7044691]},
  {category:"banco",type:"santander",address:"Alvear 2495, B1653 Villa Ballester, Provincia de Buenos Aires, Argentina",name:"Villa Ballester",position:[-34.5479211, -58.5523957]},
  {category:"banco",type:"santander",address:"Constituci\u00f3n 882, B1646 San Fernando, Provincia de Buenos Aires, Argentina",name:"San Fernando",position:[-34.4397711, -58.5581552]},
  {category:"banco",type:"santander",address:"Arturo Jauretche 1576, B1686 Hurlingham, Provincia de Buenos Aires, Argentina",name:"Hurlingham",position:[-34.5926017, -58.63589270000001]},
  {category:"banco",type:"santander",address:"Av. Sta Fe, Mart\u00ednez, Provincia de Buenos Aires, Argentina",name:"Martinez",position:[-34.4848211, -58.503368]},
  {category:"banco",type:"santander",address:"Av. Caz\u00f3n, Tigre, Provincia de Buenos Aires, Argentina",name:"Tigre",position:[-34.4284311, -58.5734082]},
  {category:"banco",type:"santander",address:"Banco Santander Rio, Av. Corrientes 5100, C1414 CABA, Argentina",name:"Villa Crespo",position:[-34.6004653, -58.4376013]},
  {category:"banco",type:"santander",address:"C. 71 1723, B1904BKQ La Plata, Provincia de Buenos Aires, Argentina",name:"Casa Matriz",position:[-34.9511577, -57.95227559999999]},
  {category:"banco",type:"santander",address:"Ocampo 954, S2500FRT Ca\u00f1ada de Gomez, Santa Fe, Argentina",name:"Ca\u00f1ada de Gomez",position:[-32.81900110000001, -61.3961874]},
  {category:"banco",type:"santander",address:"DRG, Pellegrini 376, L6300 Santa Rosa, La Pampa, Argentina",name:"Santa Rosa",position:[-36.62063680000001, -64.2926131]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 699, Gral. Belgrano, Provincia de Buenos Aires, Argentina",name:"Gral. Belgrano",position:[-35.7683302, -58.4958142]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 501, S2600 Venado Tuerto, Santa Fe, Argentina",name:"Venado Tuerto",position:[-33.7476788, -61.96384339999999]},
  {category:"banco",type:"santander",address:"Manuel Belgrano 1302, B7540BFY Coronel Suarez, Provincia de Buenos Aires, Argentina",name:"Coronel Suarez",position:[-37.4592756, -61.934855]},
  {category:"banco",type:"santander",address:"Juncal 735, C1062ABC CABA, Argentina",name:"Juncal",position:[-34.592703, -58.3776101]},
  {category:"banco",type:"santander",address:"C. 47 1098, B2720 Col\u00f3n, Provincia de Buenos Aires, Argentina",name:"Colon",position:[-33.890279, -61.0968289]},
  {category:"banco",type:"santander",address:"Moreno & Azcu\u00e9naga, Pergamino, Provincia de Buenos Aires, Argentina",name:"Pergamino",position:[-33.9144945, -60.5760395]},
  {category:"banco",type:"santander",address:"9 de Julio 469, B7000AQI Tandil, Provincia de Buenos Aires, Argentina",name:"Tandil",position:[-37.3274649, -59.134096]},
  {category:"banco",type:"santander",address:"Sta. Mar\u00eda de Oro 525, B2942 Baradero, Provincia de Buenos Aires, Argentina",name:"Baradero",position:[-33.8073053, -59.5026549]},
  {category:"banco",type:"santander",address:"Buenos Aires, Argentina",name:"Firmat",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"santander",address:"Asunci\u00f3n, Buenos Aires, Argentina",name:"Devoto ",position:[-34.5986215, -58.5075188]},
  {category:"banco",type:"santander",address:"Av. 59 2760, B7630 Necochea, Provincia de Buenos Aires, Argentina",name:"Necochea",position:[-38.5529413, -58.74073970000001]},
  {category:"banco",type:"santander",address:"Juan B. Justo, Neuqu\u00e9n, Argentina",name:"Neuquen",position:[-38.9539294, -68.0746164]},
  {category:"banco",type:"santander",address:"Esteban Adrogu\u00e9 1212, B1846FHG Adrogu\u00e9, Provincia de Buenos Aires, Argentina",name:"Adrogue",position:[-34.7984879, -58.3903429]},
  {category:"banco",type:"santander",address:"Espa\u00f1a 266, R8324 Cipolletti, R\u00edo Negro, Argentina",name:"Cipolletti",position:[-38.938361, -67.996233]},
  {category:"banco",type:"santander",address:"O Higgins 81, B8000 Bah\u00eda Blanca, Provincia de Buenos Aires, Argentina",name:"Bahia Blanca",position:[-38.7202302, -62.266847]},
  {category:"banco",type:"santander",address:"Inmigrantes Gallegos, Comodoro Rivadavia, Chubut, Argentina",name:"Comodoro Rivadavia",position:[-45.8628635, -67.4760127]},
  {category:"banco",type:"santander",address:"Dr. M. Lebensohn 19, Jun\u00edn, Provincia de Buenos Aires, Argentina",name:"Junin",position:[-34.594092, -60.94519930000001]},
  {category:"banco",type:"santander",address:"Av. Bartolom\u00e9 Mitre 3137, B1744 Moreno, Provincia de Buenos Aires, Argentina",name:"Moreno",position:[-34.6476186, -58.79444169999999]},
  {category:"banco",type:"santander",address:"Av. Pueyrred\u00f3n 1914, C1119ACP CABA, Argentina",name:"Recoleta",position:[-34.588831, -58.399945]},
  {category:"banco",type:"santander",address:"Av. del Libertador 557, B1722ERF Merlo, Provincia de Buenos Aires, Argentina",name:"Merlo",position:[-34.6680595, -58.72594100000001]},
  {category:"banco",type:"santander",address:"Carlos Pellegrini 1101, W3400BAV Corrientes, Argentina",name:"Corrientes",position:[-27.4652491, -58.83663789999999]},
  {category:"banco",type:"santander",address:"C. 27 499, Mercedes, Provincia de Buenos Aires, Argentina",name:"Mercedes",position:[-34.6519044, -59.43138809999999]},
  {category:"banco",type:"santander",address:"Gral. Alvear 802, Y4600 San Salvador de Jujuy, Jujuy, Argentina",name:"San S. de Jujuy",position:[-24.1843123, -65.3044932]},
  {category:"banco",type:"santander",address:"24 de Septiembre 233, G4200 CMH, Santiago del Estero, Argentina",name:"Sgo. del Estero",position:[-27.789834, -64.25891469999999]},
  {category:"banco",type:"santander",address:"C. 16 550, B7620 Balcarce, Provincia de Buenos Aires, Argentina",name:"Balcarce",position:[-37.8445264, -58.2555641]},
  {category:"banco",type:"santander",address:"General Pico, La Pampa, Argentina",name:"Gral. Pico",position:[-35.6593239, -63.75778869999999]},
  {category:"banco",type:"santander",address:"Carlos Pellegrini 321, San Ramon de la Nueva Oran, Salta, Argentina",name:"Oran",position:[-23.1325822, -64.3230129]},
  {category:"banco",type:"santander",address:"Av. Moreno 399, B7500 Tres Arroyos, Provincia de Buenos Aires, Argentina",name:"Tres Arroyos",position:[-38.3798719, -60.2720018]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 2501, S3000 Santa Fe de la Vera Cruz, Santa Fe, Argentina",name:"Santa Fe",position:[-31.6466568, -60.70677629999999]},
  {category:"banco",type:"santander",address:"Av. 25 de Mayo 599, Azul, Provincia de Buenos Aires, Argentina",name:"Azul",position:[-36.7795362, -59.86006870000001]},
  {category:"banco",type:"santander",address:"Constituci\u00f3n 602, X5800 R\u00edo Cuarto, C\u00f3rdoba, Argentina",name:"Rio Cuarto",position:[-33.1222922, -64.3491987]},
  {category:"banco",type:"santander",address:"Av. Sta. Fe 2201, C1113 CABA, Argentina",name:"Barrio Norte",position:[-34.5950704, -58.3985672]},
  {category:"banco",type:"santander",address:"Arturo Frondizi, Resistencia, Chaco, Argentina",name:"Resistencia",position:[-27.46216, -58.977628]},
  {category:"banco",type:"santander",address:"Espa\u00f1a 635, A4400 Salta, Argentina",name:"Salta",position:[-24.7886121, -65.4115863]},
  {category:"banco",type:"santander",address:"Av. Rivadavia 9711, C1407 CABA, Argentina",name:"Villa Luro",position:[-34.6374682, -58.50123050000001]},
  {category:"banco",type:"santander",address:"Av. Rivadavia 6902, C1406GLZ CABA, Argentina",name:"Flores",position:[-34.629154, -58.462799]},
  {category:"banco",type:"santander",address:"Av. Callao 349, C1042 CABA, Argentina",name:"Congreso",position:[-34.6049528, -58.39211940000001]},
  {category:"banco",type:"santander",address:"Rivadavia 3019, B7400 Olavarr\u00eda, Provincia de Buenos Aires, Argentina",name:"Olavarria",position:[-36.8923032, -60.3206371]},
  {category:"banco",type:"santander",address:"Rafaela, Santa Fe Province, Argentina",name:"Rafaela",position:[-31.2525979, -61.49164219999999]},
  {category:"banco",type:"santander",address:"Av. Caseros 2795, C1264AAH CABA, Argentina",name:"Pque. Patricios",position:[-34.636229, -58.4020665]},
  {category:"banco",type:"santander",address:"Adolfo Alsina 1338, C1088AAJ CABA, Argentina",name:"Alsina",position:[-34.6114045, -58.38539960000001]},
  {category:"banco",type:"santander",address:"Rivadavia 954, B6700CKL Luj\u00e1n, Provincia de Buenos Aires, Argentina",name:"Lujan",position:[-34.5642439, -59.11748419999999]},
  {category:"banco",type:"santander",address:"San Juan, San Juan Province, Argentina",name:"San Juan",position:[-31.5351074, -68.5385941]},
  {category:"banco",type:"santander",address:"Av. Sofia Terrero de Santamarina 420, B1842 Monte Grande, Provincia de Buenos Aires, Argentina",name:"Monte Grande",position:[-34.8186335, -58.46596470000001]},
  {category:"banco",type:"santander",address:"Felix de Azara 1853, N3301KLF Posadas, Misiones, Argentina",name:"Posadas",position:[-27.3675053, -55.8934514]},
  {category:"banco",type:"santander",address:"Urquiza, Ramos Mej\u00eda, Provincia de Buenos Aires, Argentina",name:"Caseros",position:[-34.6482684, -58.571736]},
  {category:"banco",type:"santander",address:"Urquiza 652, E3200 Concordia, Entre R\u00edos, Argentina",name:"Concordia",position:[-31.3968055, -58.017855]},
  {category:"banco",type:"santander",address:"Pedernera 328, Villa Mercedes, San Luis, Argentina",name:"Villa Mercedes",position:[-33.6867824, -65.4666783]},
  {category:"banco",type:"santander",address:"Bartolom\u00e9 Mitre, Buenos Aires, Argentina",name:"San Nicolas de Los Arroyos",position:[-34.6073388, -58.382034]},
  {category:"banco",type:"santander",address:"Int. Pedro Whelan 142, B1748 Gral. Rodr\u00edguez, Provincia de Buenos Aires, Argentina",name:"Gral. Rodriguez",position:[-34.60746, -58.9526718]},
  {category:"banco",type:"santander",address:"Jer\u00f3nimo Salguero 3212, C1425 CABA, Argentina",name:"Paseo Alcorta",position:[-34.5747293, -58.40347019999999]},
  {category:"banco",type:"santander",address:"Buenos Aires 219, Paran\u00e1, Entre R\u00edos, Argentina",name:"Parana",position:[-31.7293839, -60.53072259999999]},
  {category:"banco",type:"santander",address:"Av. Pueyrred\u00f3n 1055, C1118AAA CABA, Argentina",name:"Av. Pueyrredon",position:[-34.5979109, -58.4034136]},
  {category:"banco",type:"santander",address:"Av. Sta. Fe 3655, C1425BGY CABA, Argentina",name:"Botanico",position:[-34.5853233, -58.41540389999999]},
  {category:"banco",type:"santander",address:"Av. Corrientes 4626, C1414 CABA, Argentina",name:"Angel Gallardo",position:[-34.6024029, -58.4306388]},
  {category:"banco",type:"santander",address:"Av. Rafael N\u00fa\u00f1ez 4174, X4174 C\u00f3rdoba, Argentina",name:"Cerro de Las Rosas",position:[-31.3698699, -64.2316228]},
  {category:"banco",type:"santander",address:"Buenos Aires, Argentina",name:"Berlgrano R  ",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"santander",address:"Av. Ambrosio Olmos 494 Torre A, X5000JGN C\u00f3rdoba, Argentina",name:"Nueva Cordoba ",position:[-31.42866039999999, -64.1854378]},
  {category:"banco",type:"santander",address:"Av. Avelino Rol\u00f3n 117, B1609 Boulogne, Provincia de Buenos Aires, Argentina",name:"Marquez ",position:[-34.4946122, -58.55199940000001]},
  {category:"banco",type:"santander",address:"Acassuso, Buenos Aires Province, Argentina",name:"Libertador ",position:[-34.4752712, -58.496585]},
  {category:"banco",type:"santander",address:"Jer\u00f3nimo Salguero, Buenos Aires, Argentina",name:"Plaza Alemania",position:[-34.587203, -58.4133926]},
  {category:"banco",type:"santander",address:"Av. Luis Mar\u00eda Campos 1175, C1426BOJ CABA, Argentina",name:"Luis Maria Campos ",position:[-34.56558589999999, -58.43992300000001]},
  {category:"banco",type:"santander",address:"Gral. Roca 1449, B7602GUC Mar del Plata, Provincia de Buenos Aires, Argentina",name:"Guemes",position:[-38.0179204, -57.5456125]},
  {category:"banco",type:"santander",address:"Alberdi, C\u00f3rdoba, Argentina",name:"Villa Belgrano",position:[-31.4084595, -64.20073719999999]},
  {category:"banco",type:"santander",address:"13, Av. Callao 1300, C1021 CABA, Argentina",name:"Cervi\u00f1o",position:[-34.5938928, -58.3933992]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn Sur 69, M5501 Godoy Cruz, Mendoza, Argentina",name:"Godoy Cruz",position:[-32.9250868, -68.84871629999999]},
  {category:"banco",type:"santander",address:"RP8 & Brig. Gral. Juan Manuel de Rosas, Pilar, Provincia de Buenos Aires, Argentina",name:"Pilar",position:[-34.4543495, -58.9090155]},
  {category:"banco",type:"santander",address:"Paso de los Andes, Capital, Mendoza, Argentina",name:"5Ta. Seccion",position:[-32.8838538, -68.8586825]},
  {category:"banco",type:"santander",address:"Maip\u00fa 684, T4000 San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"Tucuman Norte ",position:[-26.8210975, -65.2055927]},
  {category:"banco",type:"santander",address:"Av. Pellegrini 1863, S2000BUF Rosario, Santa Fe, Argentina",name:"Rosario - Av. Pellegrini",position:[-32.9555624, -60.6515417]},
  {category:"banco",type:"santander",address:"Mariano Fragueiro 1871, C\u00f3rdoba, Argentina",name:"Cordoba-Alta Cordoba ",position:[-31.3938558, -64.1852802]},
  {category:"banco",type:"santander",address:"Av. del Bicentenario de la Batalla de Salta 749, Salta, Argentina",name:"Salta - Tres Cerritos ",position:[-24.7809784, -65.4033884]},
  {category:"banco",type:"santander",address:"Castulo Pe\u00f1a 170, Jesus Mar\u00eda, C\u00f3rdoba, Argentina",name:"Jesus Maria",position:[-30.9749664, -64.0961185]},
  {category:"banco",type:"santander",address:"Humberto Primo 650, X5000EPU C\u00f3rdoba, Argentina",name:"Capitalinas - Cordoba",position:[-31.4072468, -64.19050080000001]},
  {category:"banco",type:"santander",address:"Tucum\u00e1n 750, Gral. Roca, R\u00edo Negro, Argentina",name:"Gral. Roca",position:[-39.0287434, -67.5750678]},
  {category:"banco",type:"santander",address:"25 de Mayo 1563, X2400 San Francisco, C\u00f3rdoba, Argentina",name:"San Francisco - Cordoba",position:[-31.4299417, -62.0835808]},
  {category:"banco",type:"santander",address:"Dr. Carlos Pellegrini 270, M5602CHN San Rafael, Mendoza, Argentina",name:"San Rafael - Mendoza",position:[-34.61301450000001, -68.3295258]},
  {category:"banco",type:"santander",address:"Esqui\u00fa 426, San Fernando del Valle de Catamarca, Catamarca, Argentina",name:"Catamarca",position:[-28.4669875, -65.7809546]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 256, R\u00edo Tercero, C\u00f3rdoba, Argentina",name:"Rio Tercero - Cordoba",position:[-32.1749972, -64.11148109999999]},
  {category:"banco",type:"santander",address:"Sta. Fe 1285, Villa Mar\u00eda, C\u00f3rdoba, Argentina",name:"Villa Maria - Cordoba",position:[-32.4087638, -63.24259739999999]},
  {category:"banco",type:"santander",address:"C. Libertad 673, B1814BDG Ca\u00f1uelas, Provincia de Buenos Aires, Argentina",name:"Ca\u00f1uelas",position:[-35.0545502, -58.760197]},
  {category:"banco",type:"santander",address:"Padre Grenon 207, Alta Gracia, C\u00f3rdoba, Argentina",name:"Alta Gracia - Cordoba",position:[-31.6553452, -64.43670879999999]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 25, X5152 Villa Carlos Paz, C\u00f3rdoba, Argentina",name:"Villa Carlos Paz - Cordoba",position:[-31.4206625, -64.4986735]},
  {category:"banco",type:"santander",address:"Proyectada, La Rioja, Argentina",name:"La Rioja",position:[-29.4234525, -66.9025674]},
  {category:"banco",type:"santander",address:"Pasaje Jos\u00e9 Luis Bustamante 2225, S3000FOA Santa Fe de la Vera Cruz, Santa Fe, Argentina",name:"Santa Fe - A. Del Valle ",position:[-31.649428, -60.70958]},
  {category:"banco",type:"santander",address:"C\u00f3rdoba, Rosario, Santa Fe, Argentina",name:"Rosario - Paseo Del Siglo",position:[-32.9405524, -60.6709582]},
  {category:"banco",type:"santander",address:"Int. Tomkinson 2925, B1642 San Isidro, Provincia de Buenos Aires, Argentina",name:"San Isidro - Tomkinson ",position:[-34.4802904, -58.5540545]},
  {category:"banco",type:"santander",address:"Sta. Rosa 1738, B1714 Castelar, Provincia de Buenos Aires, Argentina",name:"Ituzaingo  ",position:[-34.6405439, -58.6563579]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 936, San Luis, Argentina",name:"San Luis",position:[-33.2999463, -66.3380856]},
  {category:"banco",type:"santander",address:"Mitre 520, San Carlos de Bariloche, R\u00edo Negro, Argentina",name:"Bariloche",position:[-41.1341057, -71.30224559999999]},
  {category:"banco",type:"santander",address:"Rivadavia 171, Trelew, Chubut, Argentina",name:"Trelew",position:[-43.2512496, -65.3100244]},
  {category:"banco",type:"santander",address:"Gualeguaych\u00fa 907, Paran\u00e1, Entre R\u00edos, Argentina",name:"Parana - 5 Esquinas ",position:[-31.74178849999999, -60.5192703]},
  {category:"banco",type:"santander",address:"Villa Gesell, Buenos Aires Province, Argentina",name:"Villa Gesell",position:[-37.2598939, -56.97141939999999]},
  {category:"banco",type:"santander",address:"Cam. Parque Centenario 2837, B1897 Gonnet, Provincia de Buenos Aires, Argentina",name:"Camino Centenario",position:[-34.882048, -58.0071453]},
  {category:"banco",type:"santander",address:"San Pedro, Buenos Aires Province, Argentina",name:"San Pedro",position:[-33.6757835, -59.66286640000001]},
  {category:"banco",type:"santander",address:"Gualeguaych\u00fa, Entre Rios, Argentina",name:"Gualeguaychu",position:[-33.0077778, -58.5111667]},
  {category:"banco",type:"santander",address:"Puerto Madryn, Chubut Province, Argentina",name:"Puerto Madryn",position:[-42.76362169999999, -65.03483109999999]},
  {category:"banco",type:"santander",address:"AFA, San Mart\u00edn 53, R8500 Viedma, R\u00edo Negro, Argentina",name:"Viedma",position:[-40.8098827, -62.9924769]},
  {category:"banco",type:"santander",address:"Av. Gral. Francisco Fern\u00e1ndez de la Cruz 6212, C1439CPO CABA, Argentina",name:"Villa Lugano",position:[-34.6857522, -58.4730357]},
  {category:"banco",type:"santander",address:"Cornejo 458, A4560 Tartagal, Salta, Argentina",name:"Tartagal",position:[-22.5165879, -63.80435559999999]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 1066, S2200FMW San Lorenzo, Santa Fe, Argentina",name:"San Lorenzo",position:[-32.7508478, -60.73409160000001]},
  {category:"banco",type:"santander",address:"Av. Belgrano 926, X2580 Marcos Ju\u00e1rez, C\u00f3rdoba, Argentina",name:"Marcos Juarez",position:[-32.6954913, -62.1042526]},
  {category:"banco",type:"santander",address:"Boulogne Sur Mer 150, M5570EDL San Mart\u00edn, Mendoza, Argentina",name:"Gral.San Martin -Mza",position:[-33.0831767, -68.47560969999999]},
  {category:"banco",type:"santander",address:"12 de Octubre 3468, B7600 Mar del Plata, Provincia de Buenos Aires, Argentina",name:"Puerto",position:[-38.0419025, -57.5479367]},
  {category:"banco",type:"santander",address:"Carril Rodr\u00edguez Pe\u00f1a 2600, M5501 Godoy Cruz, Mendoza, Argentina",name:"Mendoza -Rodriguez Pe\u00f1a",position:[-32.9276975, -68.8109781]},
  {category:"banco",type:"santander",address:"Mendoza 3940, S2002PCP Rosario, Santa Fe, Argentina",name:"Rosario-Barrio Echesortu",position:[-32.9444683, -60.6784835]},
  {category:"banco",type:"santander",address:"Av. Belgrano Sur 1987, G4200 Santiago del Estero, Argentina",name:"Sgo. Del Estero - Av. Belgrano ",position:[-27.8032568, -64.25128579999999]},
  {category:"banco",type:"santander",address:"Av. Aconquija 1395, San Miguel de Tucum\u00e1n, Tucum\u00e1n, Argentina",name:"Tucuman - Yerba Buena",position:[-26.813597, -65.2902112]},
  {category:"banco",type:"santander",address:"Av. Rivadavia, Haedo, Provincia de Buenos Aires, Argentina",name:"Haedo ",position:[-34.6468295, -58.5998311]},
  {category:"banco",type:"santander",address:"Rivadavia 1733, B6640 Bragado, Provincia de Buenos Aires, Argentina",name:"Bragado",position:[-35.1188846, -60.48430080000001]},
  {category:"banco",type:"santander",address:"C. del Canal, Pilar, Provincia de Buenos Aires, Argentina",name:"Parque Industrial Pilar",position:[-34.4198755, -58.9789992]},
  {category:"banco",type:"santander",address:"Av. Eva Per\u00f3n 7672, S2008 EET, Santa Fe, Argentina",name:"Rosario Fisherton",position:[-32.9303475, -60.7267263]},
  {category:"banco",type:"santander",address:"Bv Elias Yofre 843, C\u00f3rdoba, Argentina",name:"Cordoba-B\u00b0 Jardin",position:[-31.4504393, -64.1840093]},
  {category:"banco",type:"santander",address:"Buenos Aires 383, Salto, Provincia de Buenos Aires, Argentina",name:"Salto",position:[-34.2922116, -60.2545801]},
  {category:"banco",type:"santander",address:"Juan Domingo Per\u00f3n 34, Concepci\u00f3n del Uruguay, Entre R\u00edos, Argentina",name:"Concepcion Del Uruguay",position:[-32.4849727, -58.2309912]},
  {category:"banco",type:"santander",address:"Rivadavia 2192, S3080 Esperanza, Santa Fe, Argentina",name:"Esperanza",position:[-31.4485153, -60.9279274]},
  {category:"banco",type:"santander",address:"Rivadavia 57, Lobos, Provincia de Buenos Aires, Argentina",name:"Lobos",position:[-35.18541099999999, -59.0961373]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 1645, N3380 Eldorado, Misiones, Argentina",name:"Eldorado",position:[-26.4065523, -54.6579553]},
  {category:"banco",type:"santander",address:"Av. Gral. Belgrano 1054, B1619 Garin, Provincia de Buenos Aires, Argentina",name:"Garin",position:[-34.4170639, -58.72458289999999]},
  {category:"banco",type:"santander",address:"Av. Crovara, La Tablada, Provincia de Buenos Aires, Argentina",name:"Tablada",position:[-34.6915514, -58.5279731]},
  {category:"banco",type:"santander",address:"Av. 9 de Julio 128, B6070 Lincoln, Provincia de Buenos Aires, Argentina",name:"Lincoln",position:[-34.8688743, -61.52806569999999]},
  {category:"banco",type:"santander",address:"Ezpeleta 76, Victoria, Entre R\u00edos, Argentina",name:"Victoria",position:[-32.6212333, -60.15917679999999]},
  {category:"banco",type:"santander",address:"Av. 25 de Mayo 1138, B1625DYL Bel\u00e9n de Escobar, Provincia de Buenos Aires, Argentina",name:"Belen de Escobar",position:[-34.3536121, -58.7951734]},
  {category:"banco",type:"santander",address:"Plaza 25 de Mayo, C\u00f3rdoba 700, S2000 AWF, Santa Fe, Argentina",name:"Puerto Norte Rosario",position:[-32.94711, -60.6331846]},
  {category:"banco",type:"santander",address:"Sarmiento 1250, S2452ETC Santa Fe, Argentina",name:"San Jorge",position:[-31.8957683, -61.85838510000001]},
  {category:"banco",type:"santander",address:"Av. Leandro N. Alem 456, C1003 CABA, Argentina",name:"Alem",position:[-34.6022503, -58.37075429999999]},
  {category:"banco",type:"santander",address:"Maip\u00fa 440, C1006 San Nicolas, Buenos Aires, Argentina",name:"Centro Porte\u00f1o",position:[-34.6028471, -58.3772732]},
  {category:"banco",type:"santander",address:"Av. Juan Bautista Justo 1271, C1414CWB CABA, Argentina",name:"Juan B Justo",position:[-34.5835305, -58.4327408]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 3275, Saladillo, Provincia de Buenos Aires, Argentina",name:"Saladillo",position:[-35.6405066, -59.78004559999999]},
  {category:"banco",type:"santander",address:"Av. del Libertador, Vicente L\u00f3pez, Provincia de Buenos Aires, Argentina",name:"Al Rio",position:[-34.5249718, -58.4722744]},
  {category:"banco",type:"santander",address:"Vedia 3626, C1430 CABA, Argentina",name:"Dot Baires Shopping",position:[-34.5469088, -58.4873339]},
  {category:"banco",type:"santander",address:"Belgrano 91, B7130 Chascom\u00fas, Provincia de Buenos Aires, Argentina",name:"Chascomus",position:[-35.5760559, -58.01507729999999]},
  {category:"banco",type:"santander",address:"Av San Mart\u00edn 267, E3280 Col\u00f3n, Entre R\u00edos, Argentina",name:"Colon",position:[-32.220514, -58.13914250000001]},
  {category:"banco",type:"santander",address:"Zarate, Buenos Aires Province, Argentina",name:"Puente Zarate",position:[-34.0957811, -59.0242302]},
  {category:"banco",type:"santander",address:"Italia 150, X5896 Oncativo, C\u00f3rdoba, Argentina",name:"Oncativo",position:[-31.9135435, -63.681554]},
  {category:"banco",type:"santander",address:"Av. C\u00f3rdoba 2282, C1120AAR CABA, Argentina",name:"Plaza Houssay",position:[-34.5997766, -58.39973370000001]},
  {category:"banco",type:"santander",address:"Hip\u00f3lito Yrigoyen 456, B6450BDJ Pehuaj\u00f3, Provincia de Buenos Aires, Argentina",name:"Pehuajo",position:[-35.810537, -61.89745730000001]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn Sur 2500, Godoy Cruz, Mendoza, Argentina",name:"Palmares",position:[-32.9543644, -68.8567791]},
  {category:"banco",type:"santander",address:"Libertad 662, M5521 Mendoza, Argentina",name:"Guaymallen",position:[-32.9004874, -68.7881795]},
  {category:"banco",type:"santander",address:"C\u00f3rdoba, Cordoba, Argentina",name:"Vicu\u00f1a Mackenna",position:[-31.42008329999999, -64.1887761]},
  {category:"banco",type:"santander",address:"Bartolom\u00e9 Mitre 565, E3240 Villaguay, Entre R\u00edos, Argentina",name:"Villaguay",position:[-31.8673529, -59.0259464]},
  {category:"banco",type:"santander",address:"1ero de Mayo 2060, S2170AEH Casilda, Santa Fe, Argentina",name:"Casilda",position:[-33.0450222, -61.16678820000001]},
  {category:"banco",type:"santander",address:"Av. Sta. Fe 1594, S2132AUB Funes, Santa Fe, Argentina",name:"Funes",position:[-32.9182553, -60.810068]},
  {category:"banco",type:"santander",address:"Alsina 184, B2760 San Antonio de Areco, Provincia de Buenos Aires, Argentina",name:"San Antonio de Areco",position:[-34.2449638, -59.4741369]},
  {category:"banco",type:"santander",address:"9 de Julio 62, T4146 Concepci\u00f3n, Tucum\u00e1n, Argentina",name:"Concepcion Tucuman",position:[-27.3462058, -65.5927217]},
  {category:"banco",type:"santander",address:"Mar de Aj\u00f3, Buenos Aires Province, Argentina",name:"Mar de Ajo",position:[-36.7232497, -56.6750185]},
  {category:"banco",type:"santander",address:"ARA, Av. Rep\u00fablica Argentina 45, N3370 Puerto Iguaz\u00fa, Misiones, Argentina",name:"Puerto Iguazu",position:[-25.6024, -54.5729519]},
  {category:"banco",type:"santander",address:"9 de Julio 601, X5809 Gral. Cabrera, C\u00f3rdoba, Argentina",name:"Gral Cabrera",position:[-32.8134229, -63.87657059999999]},
  {category:"banco",type:"santander",address:"Libertad 521, X5000FGG Santa Rosa de Calamuchita, C\u00f3rdoba, Argentina",name:"Santa Rosa de Calamuchita",position:[-32.0660383, -64.5390244]},
  {category:"banco",type:"santander",address:"Ramal Pilar & Au Panamericana, Tortuguitas, Provincia de Buenos Aires, Argentina",name:"Tortuguitas",position:[-34.4546481, -58.72931819999999]},
  {category:"banco",type:"santander",address:"Hip\u00f3lito Yrigoyen 127, La Carlota, C\u00f3rdoba, Argentina",name:"La Carlota",position:[-33.4171067, -63.29708309999999]},
  {category:"banco",type:"santander",address:"Av. Eden 655, La Falda, C\u00f3rdoba, Argentina",name:"La Falda",position:[-31.0925348, -64.4795881]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 941, Mercedes, Corrientes, Argentina",name:"Mercedes Corrientes",position:[-29.1837937, -58.0758222]},
  {category:"banco",type:"santander",address:"Av. Carmen 540, B7200 Las Flores, Provincia de Buenos Aires, Argentina",name:"Las Flores",position:[-36.0138663, -59.0988281]},
  {category:"banco",type:"santander",address:"Av. Pedro Luro 6592, Mar del Plata, Provincia de Buenos Aires, Argentina",name:"Av Luro Mar del Plata",position:[-37.9784677, -57.5864579]},
  {category:"banco",type:"santander",address:"Padre Vazquez 270, M5515DLF Mendoza, Argentina",name:"Maipu Mza",position:[-32.9829436, -68.7888315]},
  {category:"banco",type:"santander",address:"Belgrano 1198, S2919BQL Villa Constituci\u00f3n, Santa Fe, Argentina",name:"Villa Constitucion",position:[-33.2285003, -60.3309122]},
  {category:"banco",type:"santander",address:"Gral. Mitre 277, S2128DRE Arroyo Seco, Santa Fe, Argentina",name:"Arroyo Seco",position:[-33.156663, -60.51135679999999]},
  {category:"banco",type:"santander",address:"R\u00edo de Janeiro 325, X5105 Villa Allende, C\u00f3rdoba, Argentina",name:"Villa Allende",position:[-31.291183, -64.2919198]},
  {category:"banco",type:"santander",address:"Av. del Libertador 2715, B1636DSH Olivos, Provincia de Buenos Aires, Argentina",name:"Puerto Olivos",position:[-34.5053886, -58.4802985]},
  {category:"banco",type:"santander",address:"25 de Mayo 340, Caleta Olivia, Santa Cruz, Argentina",name:"Caleta Olivia",position:[-46.439338, -67.5173263]},
  {category:"banco",type:"santander",address:"Salta, S2000 Rosario, Santa Fe, Argentina",name:"B\u00b0 Pichincha",position:[-32.9362502, -60.6542367]},
  {category:"banco",type:"santander",address:"Rosario 804, C1424 CABA, Argentina",name:"Primera Junta",position:[-34.6207567, -58.4397864]},
  {category:"banco",type:"santander",address:"Av. Brig. Gral. Juan Manuel de Rosas, Isidro Casanova, Provincia de Buenos Aires, Argentina",name:"Isidro Casanova",position:[-34.7156334, -58.5931778]},
  {category:"banco",type:"santander",address:"San Mart\u00edn, Buenos Aires Province, Argentina",name:"Bolivar",position:[-34.5757521, -58.53709649999999]},
  {category:"banco",type:"santander",address:"Libertad 793, R\u00edo Grande, Tierra del Fuego, Argentina",name:"Rio Grande",position:[-53.7904597, -67.6956433]},
  {category:"banco",type:"santander",address:"Pres. Bernardino Rivadavia 34, V9410 Ushuaia, Tierra del Fuego, Argentina",name:"Ushuaia",position:[-54.80601739999999, -68.3007509]},
  {category:"banco",type:"santander",address:"El Callao 955, B1615DCT Grand Bourg, Provincia de Buenos Aires, Argentina",name:"Grand Bourg",position:[-34.4920039, -58.7223456]},
  {category:"banco",type:"santander",address:"Brandsen 167, C1161AAC CABA, Argentina",name:"Brandsen",position:[-34.6330409, -58.35624019999999]},
  {category:"banco",type:"santander",address:"Av. de Mayo 255, Justiniano Posse, C\u00f3rdoba, Argentina",name:"Justiniano Posse",position:[-32.8825235, -62.6787199]},
  {category:"banco",type:"santander",address:"Independencia 455, B1727DRD Marcos Paz, Provincia de Buenos Aires, Argentina",name:"Marcos Paz",position:[-34.7801449, -58.8418195]},
  {category:"banco",type:"santander",address:"Jujuy 1309, Monte Maiz, C\u00f3rdoba, Argentina",name:"Monte Maiz",position:[-33.2062035, -62.6023195]},
  {category:"banco",type:"santander",address:"N. Uriburu 656, Malarg\u00fce, Mendoza, Argentina",name:"Malargue",position:[-35.4727966, -69.5843539]},
  {category:"banco",type:"santander",address:"Las Heras 864, B1741BIR Gral. Las Heras, Provincia de Buenos Aires, Argentina",name:"Las Heras Bs As",position:[-34.92687600000001, -58.9458156]},
  {category:"banco",type:"santander",address:"Av. Mart\u00edn Garc\u00eda 673, Buenos Aires, Argentina",name:"Parque Lezama",position:[-34.6319364, -58.37336140000001]},
  {category:"banco",type:"santander",address:"JRV, Av. Calchaqu\u00ed 5389, B1889 Ezpeleta Oeste, Provincia de Buenos Aires, Argentina",name:"Cruce Varela",position:[-34.7708547, -58.26999379999999]},
  {category:"banco",type:"santander",address:"Luis Mar\u00eda Drago 1951, Burzaco, Provincia de Buenos Aires, Argentina",name:"Parque Industrial Burzaco",position:[-34.8400395, -58.41514129999999]},
  {category:"banco",type:"santander",address:"Av. Perito Moreno 237, Las Heras, Santa Cruz, Argentina",name:"Las Heras Santa Cruz",position:[-46.5395826, -68.9300138]},
  {category:"banco",type:"santander",address:"Charata, Chaco Province, Argentina",name:"Charata",position:[-27.2179902, -61.18736169999999]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 1825, S2000 Rosario, Santa Fe, Argentina",name:"P\u00e9rez",position:[-32.9590624, -60.6395369]},
  {category:"banco",type:"santander",address:"Buenos Aires, Argentina",name:"Roque P\u00e9rez",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"santander",address:"General Villegas, Buenos Aires Province, Argentina",name:"Gral. Villegas",position:[-35.0326402, -63.01484170000001]},
  {category:"banco",type:"santander",address:"Reconquista, Santa Fe Province, Argentina",name:"Reconquista Santa Fe",position:[-29.1448193, -59.64352419999999]},
  {category:"banco",type:"santander",address:"Av. Pres. Juan Domingo Peron 1399, Pres. Derqui, Provincia de Buenos Aires, Argentina",name:"Palmas Del Pilar",position:[-34.4570929, -58.8659776]},
  {category:"banco",type:"santander",address:"Morteros, Cordoba, Argentina",name:"Morteros",position:[-30.7113387, -62.00657030000001]},
  {category:"banco",type:"santander",address:"De la Tropilla, Villa Udaondo, Provincia de Buenos Aires, Argentina",name:"Parque Leloir",position:[-34.6266826, -58.6902072]},
  {category:"banco",type:"santander",address:"Buenos Aires, Argentina",name:"Bajo Belgrano",position:[-34.6036844, -58.3815591]},
  {category:"banco",type:"santander",address:"Av. San Mart\u00edn 144, S2202DDD Puerto Gral. San Martin, Santa Fe, Argentina",name:"Puerto Gral. San Martin - Santa Fe",position:[-32.7180641, -60.7310362]},
  {category:"banco",type:"santander",address:"C. 22, Miramar, Provincia de Buenos Aires, Argentina",name:"Miramar",position:[-38.2752328, -57.8421737]},
  {category:"banco",type:"santander",address:"Acceso a Av. Almte Brown 407, Rada Tilly, Chubut, Argentina",name:"Rada Tilly",position:[-45.9231108, -67.55667129999999]},
  {category:"banco",type:"santander",address:"Presidencia Roque S\u00e1enz Pe\u00f1a, Chaco Province, Argentina",name:"Roque S\u00e1enz Pe\u00f1a - Chaco",position:[-26.8004428, -60.4312354]},
  {category:"banco",type:"santander",address:"Navarro, Buenos Aires Province, Argentina",name:"Navarro",position:[-35.0058318, -59.2783596]},
  {category:"banco",type:"santander",address:"Rivadavia 377, X5280 Cruz del Eje, C\u00f3rdoba, Argentina",name:"Cruz Del Eje",position:[-30.72743609999999, -64.8048642]},
  {category:"banco",type:"santander",address:"Av. Hip\u00f3lito Yrigoyen, Banfield, Provincia de Buenos Aires, Argentina",name:"Banfield Oeste",position:[-34.7431367, -58.3990633]},
  {category:"banco",type:"santander",address:"San Salvador de Jujuy, Jujuy, Argentina",name:"Libertador Gral. San Mart\u00edn - Jujuy",position:[-24.1857864, -65.2994767]},
  {category:"banco",type:"santander",address:"Julio Argentino Roca 1257, El Calafate, Santa Cruz, Argentina",name:"El Calafate",position:[-50.3398911, -72.2673963]},
  {category:"banco",type:"santander",address:"Dolores, Buenos Aires Province, Argentina",name:"Dolores",position:[-36.3152317, -57.6753107]},
  {category:"banco",type:"santander",address:"San Mart\u00edn 1475, Tunuy\u00e1n, Mendoza, Argentina",name:"Tunuyan",position:[-33.5742002, -69.0160156]},
  {category:"banco",type:"santander",address:"Av. 25 de Mayo 281, B1865 San Vicente, Provincia de Buenos Aires, Argentina",name:"San Vicente",position:[-35.0217065, -58.4205013]},
  {category:"banco",type:"santander",address:"Beron de Astrada 646, Curuz\u00fa Cuati\u00e1, Corrientes, Argentina",name:"Curuzu Cuatia",position:[-29.7908537, -58.0527334]},
  {category:"banco",type:"santander",address:"Padre Jacinto St\u00e1bile 357, Centenario, Neuqu\u00e9n, Argentina",name:"Centenario",position:[-38.8302635, -68.1304704]},
  {category:"banco",type:"santander",address:"Av. 25 de Mayo 1335, H3506KJS Resistencia, Chaco, Argentina",name:"Resistencia 25 de Mayo",position:[-27.4406051, -58.99857849999999]},
  {category:"banco",type:"santander",address:"BAR, Av. Pres. Dr. Nestor C. Kirchner 568, Z9400 R\u00edo Gallegos, Santa Cruz, Argentina",name:"Rio Gallegos",position:[-51.62400530000001, -69.2103667]},
  {category:"banco",type:"santander",address:"La Voz del Interior 6731, C\u00f3rdoba, Argentina",name:"Barrio Los Boulevares",position:[-31.3424404, -64.2079296]},
  {category:"banco",type:"santander",address:"Daireaux, Buenos Aires Province, Argentina",name:"Daireaux",position:[-36.5994803, -61.7475931]},
  {category:"banco",type:"santander",address:"Av. Independencia 1155, C1099AAL CABA, Argentina",name:"Uade",position:[-34.6175899, -58.3823743]},
  {category:"banco",type:"santander",address:"Aut. Ricchieri y, Av. Boulogne Sur Mer, B1771 Tapiales, Provincia de Buenos Aires, Argentina",name:"Mercado Central",position:[-34.7113872, -58.4974715]},
  {category:"banco",type:"santander",address:"Colectora Sur Acceso Oeste 11024, B1744 La Reja, Provincia de Buenos Aires, Argentina",name:"La Reja",position:[-34.6198801, -58.8384246]},
  {category:"banco",type:"santander",address:"Av. Libertador Gral. San Mart\u00edn 3267, J5400 Rivadavia, San Juan, Argentina",name:"San Juan - Rivadavia",position:[-31.527878, -68.570551]},
  {category:"banco",type:"santander",address:"Hip\u00f3lito Yrigoyen 1602, W3400ATI Corrientes, Argentina",name:"Plaza Cabral Corrientes Capital",position:[-27.4694255, -58.83052729999999]},
  {category:"banco",type:"santander",address:"Av. Jos\u00e9 Ignacio de la Roza Este 211, J5402DBQ San Juan, Argentina",name:"Facultad  San Juan",position:[-31.5367502, -68.52020639999999]},
  {category:"banco",type:"santander",address:"Av. Sarmiento 1098, M5500EOR Mendoza, Argentina",name:"Peatonal Mendoza",position:[-32.8889913, -68.84953740000002]},
  {category:"banco",type:"santander",address:"Blvd. Oro\u00f1o 759, S2000 Rosario, Santa Fe, Argentina",name:"Rosario Centro",position:[-32.9430627, -60.65289199999999]},
  {category:"banco",type:"santander",address:"Bv. Marcelo T. de Alvear 97, Neuqu\u00e9n, Argentina",name:"Bv M.T Alvear Neuquen",position:[-38.9529432, -68.060858]},
  {category:"banco",type:"santander",address:"Av. de los Lagos 6855, B1670 Rinc\u00f3n de Milberg, Provincia de Buenos Aires, Argentina",name:"Shopping Nordelta",position:[-34.3997845, -58.65066399999999]},
  {category:"banco",type:"santander",address:"Av. Maip\u00fa 2453, B1636AAE Olivos, Provincia de Buenos Aires, Argentina",name:"Estacion Maipu",position:[-34.512565, -58.48936279999999]},
  {category:"banco",type:"santander",address:"Mariano Castex 3489, B1804 Canning, Provincia de Buenos Aires, Argentina",name:"Canning",position:[-34.8849588, -58.5057815]},
  {category:"banco",type:"santander",address:"Av. S. Mart\u00edn, Buenos Aires, Argentina",name:"Agronomia",position:[-34.598067, -58.4826633]},
  {category:"banco",type:"santander",address:"Av. Callao 1902, C1024AAS CABA, Argentina",name:"Plaza Francia",position:[-34.5880997, -58.38817139999999]},
  {category:"banco",type:"santander",address:"Av. Sta. Fe 1146, C1059ABS CABA, Argentina",name:"Torre 1",position:[-34.5958079, -58.38350819999999]},
  {category:"banco",type:"santander",address:"Viamonte 1548, C1055ABD CABA, Argentina",name:"Macrocentro",position:[-34.6007471, -58.3887848]},
  {category:"banco",type:"santander",address:"Pierina Dealessi 360, Buenos Aires, Argentina",name:"Madero Yachting",position:[-34.6016051, -58.36480899999999]},
  {category:"banco",type:"santander",address:"Av. Cabildo 1717, C1426ABE CABA, Argentina",name:"Jose Hernandez",position:[-34.565337, -58.453211]},
  {category:"banco",type:"santander",address:"Av. Gral. Las Heras 2837, C1425ASF CABA, Argentina",name:"Salguero",position:[-34.5836354, -58.40137999999999]},
  {category:"banco",type:"santander",address:"Azopardo 1389, C1107 San Telmo, Buenos Aires, Argentina",name:"Garay",position:[-34.6238055, -58.3667773]},
  {category:"banco",type:"santander",address:"Juan Jos\u00e9 Olleros 221, C\u00f3rdoba, Argentina",name:"Sagrada Familia - Cordoba",position:[-31.398496, -64.231745]},
  {category:"comercios",type:"espumeria",address:"CABA",name:"Local Avenida Santa Fe",position:[-34.5950373, -58.4019222]},
  {category:"comercios",type:"espumeria",address:"CABA",name:"Local Honduras",position:[-34.5891061, -58.4319002]},
  {category:"comercios",type:"espumeria",address:"Provincia de Buenos Aires",name:"Local Unicenter",position:[-34.5086189, -58.525784]},
  {category:"comercios",type:"espumeria",address:"CABA",name:"Local Cabildo",position:[-34.5677046, -58.4523044]},
  {category:"comercios",type:"espumeria",address:"CABA",name:"Local Portal Palermo",position:[-34.574267, -58.4293416]},
  {category:"comercios",type:"espumeria",address:"Provincia de Buenos Aires",name:"Local Plaza Oeste",position:[-34.6349818, -58.6319306]},
  {category:"comercios",type:"espumeria",address:"Provincia de Buenos Aires",name:"Local Palmas De Pilar",position:[-34.4475241, -58.8703567]},
  {category:"organizaciones",type:"amia",address:"CABA",name:"AMIA | Comunidad Jud\u00eda",position:[-34.6018357, -58.3996566]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"01 - MERCEDES",position:[-28.933, -58.1047]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"02 - SAN ROQUE",position:[-28.3294, -58.7415]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"03 - LA CRUZ",position:[-28.9177, -56.6981]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"04 - EMPEDRADO",position:[-27.8922, -58.8086]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"05 - GOYA",position:[-28.9651, -59.2764]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"06 - PASO DE LOS LIBRES",position:[-29.0223227, -59.1943731]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"\u00bb Anexo Yapey\u00fa",position:[-29.588, -56.8485]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"07 - CURUZ\u00da CUATI\u00c1",position:[-27.5512, -57.5281]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"08 - MBURUCUY\u00c1",position:[-28.0445, -58.2313]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"09 - ESQUINA",position:[-30.0192, -59.5334]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"10 - GDOR. VIRASORO",position:[-27.4755, -58.8468]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"11 - BELLA VISTA",position:[-28.2804, -59.0347]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"\u00e0 Anexo 9 de Julio",position:[-28.6185, -58.837]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"12 - MONTE CASEROS",position:[-29.9885, -57.6395]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"\u00bb Anexo Juan Pujol",position:[-30.1976, -57.8043]},
  {category:"banco",type:"BANCCOR",address:"Santa F\u00e9",name:"13 - SANTO TOM\u00c9",position:[-28.2611, -56.0355]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"14 - ITUZAING\u00d3",position:[-27.5848, -56.6892]},
  {category:"banco",type:"BANCCOR",address:"CABA",name:"15 - BUENOS AIRES",position:[-34.6039756025469, -58.3737604523798]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"16 - COL. LIEBIG'S",position:[-27.9132, -55.8236]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"17 - SAUCE",position:[-29.7789, -58.7711]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"18 - COL. MOCORET\u00c1",position:[-30.3968, -57.8922]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"19 - SALADAS",position:[-28.2316, -58.6255]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"20 - IT\u00c1 IBAT\u00c9",position:[-27.3943, -57.3397]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"21 - SAN MIGUEL",position:[-27.9418, -57.5931]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"22 - PERUGORR\u00cdA",position:[-29.3386, -58.6065]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"23 - GDOR MART\u00cdNEZ",position:[-28.7246, -58.9359]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"24 - ITAT\u00cd",position:[-28.9118, -58.9368]},
  {category:"banco",type:"BANCCOR",address:"La Pampa",name:"25 - SANTA ROSA",position:[-28.2697, -58.1232]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"26 - P. DE LA PATRIA",position:[-27.31979, -58.5762]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"27 \u2013 MARIANO I. LOZA",position:[-29.1476, -58.1778]},
  {category:"banco",type:"BANCCOR",address:"Misiones",name:"28 - POSADAS",position:[-27.3641, -55.8931]},
  {category:"banco",type:"BANCCOR",address:"Entre Rios",name:"31 - CONCEPCI\u00d3N",position:[-28.342, -57.8877]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"32 - SANTA LUC\u00cdA",position:[-28.7535, -59.1007]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"33 - ALVEAR",position:[-28.9459, -56.6727]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"34 - BERON DE ASTRADA",position:[-29.5307, -58.0899]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"35 - CHAVARR\u00cdA",position:[-28.6378, -58.5843]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"36 - S. LUIS DEL PALMAR",position:[-27.5083, -58.5532]},
  {category:"banco",type:"BANCCOR",address:"Misiones",name:"37 - OBER\u00c1",position:[-27.4866, -55.1161]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"38 - CA\u00c1 CAT\u00cd",position:[-27.7528, -57.6188]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"40 - TENIENTE IB\u00c1\u00d1EZ",position:[-27.4842, -58.8291]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"41 - RAM\u00d3N CARRILLO",position:[-27.4751, -58.8469]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"42 - SAN CARLOS",position:[-27.746, -55.8988]},
  {category:"banco",type:"BANCCOR",address:"Iguaz\u00fa",name:"43 - PUERTO IGUAZ\u00da",position:[-25.575, -54.59]},
  {category:"banco",type:"BANCCOR",address:"Entre rios",name:"44 - ENTRE RIOS - CHAJAR\u00cd",position:[-30.7222, -58.7822]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"99 - CASA MATRIZ",position:[-27.4654, -58.8378]},
  {category:"banco",type:"BANCCOR",address:"Corrientes",name:"\u00bb Centro \u00danico de Pagos",position:[-27.4666, -58.8321]},
  {category:"organizaciones",type:"museo_moderno",address:"CABA",name:"Museo Moderno",position:[-34.6219154385846, -58.3705307756519]},
  {category:"organizaciones",type:"carp",address:"CABA",name:"Estadio M\u00e1s Monumental",position:[-34.5451118006241, -58.4497777085069]},
  {category:"gastronomia",type:"Nespresso",address:"Buenos Aires Alto Palermo",name:"Av. Santa Fe 3253",position:[-34.5880019, -58.41044119999999]},
  {category:"gastronomia",type:"Nespresso",address:"Buenos Aires Recoleta",name:"Calle Montevideo 1704 Recoleta",position:[-34.5906338, -58.3869102]},
  {category:"gastronomia",type:"Nespresso",address:"Buenos Aires Unicenter",name:"Paran\u00e1 3745",position:[-34.50860350000001, -58.5231694]},
  {category:"gastronomia",type:"Nespresso",address:"Buenos Aires Paseo Alcorta",name:"Jer\u00f3nimo Salguero 3212-3360",position:[-34.5753432, -58.4040484]},
  {category:"gastronomia",type:"Nespresso",address:"Buenos Aires Solar de la Abad\u00eda",name:"Av. Luis Maria Campos 900",position:[-34.5680404, -58.4373399]},
  {category:"gastronomia",type:"Nespresso",address:"Rosario Alto Rosario",name:"Jun\u00edn 501",position:[-32.9274658, -60.6690017]},
  {category:"gastronomia",type:"Nespresso",address:"C\u00f3rdoba Nuevocentro",name:"Duarte Quir\u00f3s 1400 C\u00f3rdoba",position:[-31.4129589, -64.2045375]},
  {category:"gastronomia",type:"Nespresso",address:"La Plata Baxar La Plata",name:"Calle 50 536 La Plata",position:[-34.9136961, -57.948242]},
  {category:"comercios",type:"colorshop",address:"Marcelo T. de Alvear 1411 Barrio Norte CABA CABA Argentina",name:"Colorshop Barrio Norte I",position:[-34.5969224,-58.3872543]},
{category:"comercios",type:"colorshop",address:"Marcelo T. de Alvear 1905 Barrio Norte CABA CABA Argentina",name:"Colorshop Barrio Norte II",position:[-34.5972146,-58.394963]},
{category:"comercios",type:"colorshop",address:"Fern\u00e1ndez de Enciso 4651 Villa Devoto CABA CABA Argentina",name:"Colorshop Villa Devoto II",position:[-34.5923336,-58.5109017]},
{category:"comercios",type:"colorshop",address:"Mosconi 2326 Villa Pueyrredon CABA CABA Argentina",name:"Colorshop Villa Pueyrredon",position:[-34.5852983,-58.5008697]},
{category:"comercios",type:"colorshop",address:"Av. Gaona 3602 (esq. Argerich) Flores  CABA CABA Argentina",name:"Colorshop Flores ",position:[-34.6033677,-58.4898271]},
{category:"comercios",type:"colorshop",address:"Av. Nazca 2196 (esq. Elpidio Gonz\u00e1lez) Villa del CABA CABA Argentina",name:"Colorshop Villa del Parque",position:[-34.6096475,-58.4830905]},
{category:"comercios",type:"colorshop",address:"Av. Beir\u00f3 4784  Villa Devoto CABA CABA Argentina",name:"Colorshop Villa Devoto I",position:[-34.6097367,-58.5177624]},
{category:"comercios",type:"colorshop",address:"Castex 650 Canning  GBA BUENOS AIRES Argentina",name:"Colorshop Canning ",position:[-34.8519229,-58.5036588]},
{category:"comercios",type:"colorshop",address:"Boulevard Buenos Aires 1499 Luis Guill\u00f3n GBA BUENOS AIRES Argentina",name:"Colorshop Luis Guill\u00f3n",position:[-34.8096654,-58.4473772]},
{category:"comercios",type:"colorshop",address:"Boulevard Buenos Aires esq Pedro Reta Monte Grande GBA BUENOS AIRES Argentina",name:"Colorshop Monte Grande",position:[-34.81603250000001,-58.4596598]},
{category:"comercios",type:"colorshop",address:"Ruta 210  N\u00b018593 Longchamps  GBA BUENOS AIRES Argentina",name:"Colorshop Longchamps",position:[-34.8630738,-58.38970529999999]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn 3196 Caseros  GBA BUENOS AIRES Argentina",name:"Colorshop Caseros",position:[-34.6119342,-58.56215210000001]},
{category:"comercios",type:"colorshop",address:"De los Aromos 6208 - Local 1 Ciudad Jard\u00edn GBA BUENOS AIRES Argentina",name:"Colorshop Ciudad Jard\u00edn",position:[-34.60402440000001,-58.5936397]},
{category:"comercios",type:"colorshop",address:"Lamadrid 1799 MASCHWITZ  GBA BUENOS AIRES Argentina",name:"Colorshop MASCHWITZ",position:[-34.3847412,-58.7473903]},
{category:"comercios",type:"colorshop",address:"Jos\u00e9 Mar\u00eda Paz (Colect. Acc. Oeste) 3092 Ituzaing\u00f3 I GBA BUENOS AIRES Argentina",name:"Colorshop Ituzaing\u00f3 I",position:[-34.6404506,-58.6751659]},
{category:"comercios",type:"colorshop",address:"Av. 9 de Julio 2317 Lan\u00fas Este GBA BUENOS AIRES Argentina",name:"Colorshop Lan\u00fas Este I",position:[-34.7163352,-58.37861229999999]},
{category:"comercios",type:"colorshop",address:"Francia esq Mitre Luj\u00e1n  GBA BUENOS AIRES Argentina",name:"Colorshop Luj\u00e1n",position:[-34.5656235,-59.11980089999999]},
{category:"comercios",type:"colorshop",address:"Av. Am\u00e9rica 467 S\u00e1enz Pe\u00f1a GBA BUENOS AIRES Argentina",name:"Colorshop S\u00e1enz Pe\u00f1a",position:[-34.6010607,-58.52663399999999]},
{category:"comercios",type:"colorshop",address:"Av. 44, N\u00b0 1452 esq.24  La Plata GBA BUENOS AIRES Argentina",name:"Colorshop La Plata I",position:[-34.9266402,-57.9726841]},
{category:"comercios",type:"colorshop",address:"Diagonal 74, N\u00b0 2192, esq. 58 La Plata GBA BUENOS AIRES Argentina",name:"Colorshop La Plata II",position:[-34.9282974,-57.9539391]},
{category:"comercios",type:"colorshop",address:"Av.13, N\u00b0 51 esq. 33 La Plata GBA BUENOS AIRES Argentina",name:"Colorshop La Plata III",position:[-34.907069,-57.97393719999999]},
{category:"comercios",type:"colorshop",address:"Av. Presidente Peron 4201 San Martin GBA BUENOS AIRES Argentina",name:"Colorshop San Martin",position:[-34.6004242,-58.5763802]},
{category:"comercios",type:"colorshop",address:"Av. Hip\u00f3lito Yrigoyen 9927 Temperley  GBA BUENOS AIRES Argentina",name:"Colorshop Temperley",position:[-34.7716698,-58.40502369999999]},
{category:"comercios",type:"colorshop",address:"Av. Crovara 1535 Villa Madero GBA BUENOS AIRES Argentina",name:"Colorshop Villa Madero",position:[-34.6828197,-58.5152221]},
{category:"comercios",type:"colorshop",address:"Lagos 904 (esq. Mitre) 9 de BA NORTE BUENOS AIRES Argentina",name:"Colorshop 9 de Julio",position:[-34.6333,-60.8833]},
{category:"comercios",type:"colorshop",address:"Lamadrid 201 (esq. Belgrano) Bah\u00eda Blanca BA SUR BUENOS AIRES Argentina",name:"Colorshop Bah\u00eda Blanca I",position:[-38.7169754,-62.2604585]},
{category:"comercios",type:"colorshop",address:"Av. Alem 2338 Bah\u00eda Blanca BA SUR BUENOS AIRES Argentina",name:"Colorshop Bah\u00eda Blanca II",position:[-38.6908797,-62.2822611]},
{category:"comercios",type:"colorshop",address:"Darregueyra esq. Fitz Roy Bah\u00eda Blanca BA SUR BUENOS AIRES Argentina",name:"Colorshop Bah\u00eda Blanca III",position:[-38.7183177,-62.2663478]},
{category:"comercios",type:"colorshop",address:"Av. 59 N\u00aa 1516  Necochea I BA SUR BUENOS AIRES Argentina",name:"Colorshop Necochea I",position:[-38.5544968,-58.73960879999999]},
{category:"comercios",type:"colorshop",address:"Av. 58 y calle 67 Necochea II BA SUR BUENOS AIRES Argentina",name:"Colorshop Necochea II",position:[-38.5577447,-58.7430764]},
{category:"comercios",type:"colorshop",address:"Av. Moreno 498 (esq. Roca) Tres Arroyos BA SUR BUENOS AIRES Argentina",name:"Colorshop Tres Arroyos",position:[-38.3803359,-60.2707965]},
{category:"comercios",type:"colorshop",address:"Av. Lavalle 122 Bol\u00edvar  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Bol\u00edvar",position:[-36.2308809,-61.115858]},
{category:"comercios",type:"colorshop",address:"Garibaldi 102 Coronel Su\u00e1rez BA SUR BUENOS AIRES Argentina",name:"Colorshop Coronel Su\u00e1rez",position:[-37.4586139,-61.93280189999999]},
{category:"comercios",type:"colorshop",address:"Rodr\u00edguez 1602 (esq. Machado) Tandil I BA SUR BUENOS AIRES Argentina",name:"Colorshop Tandil I",position:[-37.3287999,-59.1367167]},
{category:"comercios",type:"colorshop",address:"Av. Marconi 1198 (esq. Alsina)  Tandil II BA SUR BUENOS AIRES Argentina",name:"Colorshop Tandil II",position:[-37.3192539,-59.1300953]},
{category:"comercios",type:"colorshop",address:"Pinto esq. Santamarina Tandil III BA SUR BUENOS AIRES Argentina",name:"Colorshop Tandil III",position:[-37.3252353,-59.13005519999999]},
{category:"comercios",type:"colorshop",address:"Av. Tormey 486 Rojas  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Rojas",position:[-34.5772972,-58.49134069999999]},
{category:"comercios",type:"colorshop",address:"Luro 3999 (esquina Funes) Mar del BA SUR BUENOS AIRES Argentina",name:"Colorshop Mar del Plata I",position:[-37.9913445,-57.5600832]},
{category:"comercios",type:"colorshop",address:"Av. Independencia 991 (esq. Libertad) Mar del BA SUR BUENOS AIRES Argentina",name:"Colorshop Mar del Plata II",position:[-38.0055,-57.5575]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn esq. Tucum\u00e1n Ramallo  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Ramallo",position:[-33.48868540000001,-60.0105703]},
{category:"comercios",type:"colorshop",address:"Alberdi 298 San Nicol\u00e1s BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Nicol\u00e1s I",position:[-33.3334669,-60.2110494]},
{category:"comercios",type:"colorshop",address:"Av. Savio 436 San Nicol\u00e1s BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Nicol\u00e1s II",position:[-33.3432314,-60.2121175]},
{category:"comercios",type:"colorshop",address:"Clark esq. Rivadavia Villa Ramallo BA NORTE BUENOS AIRES Argentina",name:"Colorshop Villa Ramallo",position:[-33.5016111,-60.06728690000001]},
{category:"comercios",type:"colorshop",address:"Velez Sarfield esq. Jose Manavella Adelia Maria CENTRO CORDOBA Argentina",name:"Colorshop Adelia Maria",position:[-33.6307493,-64.0211028]},
{category:"comercios",type:"colorshop",address:"Mitre 300 (esq. Tomas Scott) Laboulaye  CENTRO CORDOBA Argentina",name:"Colorshop Laboulaye",position:[-31.4148491,-64.1792179]},
{category:"comercios",type:"colorshop",address:"Luis Agote 1509 (esq Julio Rocca) C\u00f3rdoba  CENTRO CORDOBA Argentina",name:"Colorshop C\u00f3rdoba ",position:[-31.4280359,-64.20892409999999]},
{category:"comercios",type:"colorshop",address:"Av. Moreno 464 Hernando  CENTRO CORDOBA Argentina",name:"Colorshop Hernando",position:[-41.1333953,-71.3089469]},
{category:"comercios",type:"colorshop",address:"Santa Fe 1016 esq. La Florida Jes\u00fas Mar\u00eda CENTRO CORDOBA Argentina",name:"Colorshop Jes\u00fas Mar\u00eda",position:[-30.9843137,-64.0875716]},
{category:"comercios",type:"colorshop",address:"Alem 899 Marcos Ju\u00e1rez CENTRO CORDOBA Argentina",name:"Colorshop Marcos Ju\u00e1rez",position:[-32.6878458,-62.10369859999999]},
{category:"comercios",type:"colorshop",address:"9 de Julio 1697 Monte Ma\u00edz CENTRO CORDOBA Argentina",name:"Colorshop Monte Ma\u00edz",position:[-33.2048662,-62.60070289999999]},
{category:"comercios",type:"colorshop",address:"9 de Julio 1382 San Francisco CENTRO CORDOBA Argentina",name:"Colorshop San Francisco",position:[-31.42499919999999,-62.0841599]},
{category:"comercios",type:"colorshop",address:"Belgrano 700 (esq. Espa\u00f1a) Villa Dolores CENTRO CORDOBA Argentina",name:"Colorshop Villa Dolores",position:[-31.4229938,-64.191777]},
{category:"comercios",type:"colorshop",address:"Fragata Sarmiento 7 (esq. Roca y Villarino) Puerto Madryn PATAGONIA CHUBUT Argentina",name:"Colorshop Puerto Madryn I",position:[-42.7850343,-65.051361]},
{category:"comercios",type:"colorshop",address:"Hip\u00f3lito Yrigoyen 1147 Trelew I PATAGONIA CHUBUT Argentina",name:"Colorshop Trelew I",position:[-43.2591711,-65.2980615]},
{category:"comercios",type:"colorshop",address:"Pellegrini 1398 (esq. Michael Jones) Trelew II PATAGONIA CHUBUT Argentina",name:"Colorshop Trelew II",position:[-43.2781818,-65.3189099]},
{category:"comercios",type:"colorshop",address:"Gregorio Mayo 35 Rawson  PATAGONIA CHUBUT Argentina",name:"Colorshop Rawson",position:[-43.2991694,-65.1032784]},
{category:"comercios",type:"colorshop",address:"Bartolome Mitre 2570 San Pedro BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Pedro",position:[-33.689577,-59.6749528]},
{category:"comercios",type:"colorshop",address:"Av. 12 de Octubre 360 Chamical  CENTRO LA RIOJA Argentina",name:"Colorshop Chamical",position:[-30.3549454,-66.3121793]},
{category:"comercios",type:"colorshop",address:"Av. Moreno 30 San Rafael CUYO MENDOZA Argentina",name:"Colorshop San Rafael",position:[-34.6136694,-68.3356878]},
{category:"comercios",type:"colorshop",address:"Mariano Moreno 126 L. N. NEA MISIONES Argentina",name:"Colorshop L. N. Alem",position:[-26.9377146,-54.4342138]},
{category:"comercios",type:"colorshop",address:"Bol\u00edvar 2294 esq. San Luis Posadas III NEA MISIONES Argentina",name:"Colorshop Posadas III",position:[-27.3673439,-55.8990059]},
{category:"comercios",type:"colorshop",address:"9 de Julio 1031    Ober\u00e1  NEA MISIONES Argentina",name:"Colorshop Ober\u00e1",position:[-27.4872342,-55.1219796]},
{category:"comercios",type:"colorshop",address:"Av L\u00f3pez y Planes esq. Berm\u00fadez Posadas I NEA MISIONES Argentina",name:"Colorshop Posadas I",position:[-27.3737316,-55.90365869999999]},
{category:"comercios",type:"colorshop",address:"Bartolom\u00e9 Mitre 2253 Posadas IV NEA MISIONES Argentina",name:"Colorshop Posadas IV",position:[-27.37439,-55.899278]},
{category:"comercios",type:"colorshop",address:"Planas 739 Neuqu\u00e9n I PATAGONIA NEUQUEN Argentina",name:"Colorshop Neuqu\u00e9n I",position:[-38.9589829,-68.0696653]},
{category:"comercios",type:"colorshop",address:"Combate de San Lorenzo 37 Neuqu\u00e9n II PATAGONIA NEUQUEN Argentina",name:"Colorshop Neuqu\u00e9n II",position:[-38.9544552,-68.0897016]},
{category:"comercios",type:"colorshop",address:"Colectora 690 Las Grutas PATAGONIA RIO NEGRO Argentina",name:"Colorshop Las Grutas",position:[-40.7952991,-65.07872019999999]},
{category:"comercios",type:"colorshop",address:"25 de Mayo esq.G\u00fcemes Or\u00e1n  NOA SALTA Argentina",name:"Colorshop Or\u00e1n",position:[-23.1314073,-64.32410829999999]},
{category:"comercios",type:"colorshop",address:"Belgrano 194 Met\u00e1n I NOA SALTA Argentina",name:"Colorshop Met\u00e1n I",position:[-25.4982957,-64.97829999999999]},
{category:"comercios",type:"colorshop",address:"Tucuman esq. Maip\u00fa Met\u00e1n II NOA SALTA Argentina",name:"Colorshop Met\u00e1n II",position:[-25.4942245,-64.97212979999999]},
{category:"comercios",type:"colorshop",address:"Espa\u00f1a 698 esq. Gorriti Tartagal I NOA SALTA Argentina",name:"Colorshop Tartagal I",position:[-22.5167622,-63.8056084]},
{category:"comercios",type:"colorshop",address:"Primero de Mayo 2160 Casilda  CENTRO SANTA FE Argentina",name:"Colorshop Casilda",position:[-33.0442776,-61.16744629999999]},
{category:"comercios",type:"colorshop",address:"Belgrano 1110 (esq. Col\u00f3n) Firmat  CENTRO SANTA FE Argentina",name:"Colorshop Firmat",position:[-33.4583964,-61.4862572]},
{category:"comercios",type:"colorshop",address:"Lavalle 198 (esq. Iturraspe) Venado Tuerto CENTRO SANTA FE Argentina",name:"Colorshop Venado Tuerto",position:[-33.7525458,-61.9662717]},
{category:"comercios",type:"colorshop",address:"Av. Lujan 2399 Santo Tom\u00e9 CENTRO SANTA FE Argentina",name:"Colorshop Santo Tom\u00e9",position:[-31.669253,-60.76576499999999]},
{category:"comercios",type:"colorshop",address:"Av. 25 de Mayo 220 A\u00f1atuya  NOA SGO DEL ESTERO Argentina",name:"Colorshop A\u00f1atuya",position:[-28.4624078,-62.8323134]},
{category:"comercios",type:"colorshop",address:"Republica del L\u00edbano esq. Avenida Belgrano La Banda NOA SGO DEL ESTERO Argentina",name:"Colorshop La Banda II",position:[-27.7380594,-64.24770610000002]},
{category:"comercios",type:"colorshop",address:"Av. Belgrano (S) No. 2 (esq. Rivadavia) Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero I",position:[-27.7846199,-64.26564119999999]},
{category:"comercios",type:"colorshop",address:"Av. Belgrano 790 esquina Mitre Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero II",position:[-27.7915591,-64.25794379999999]},
{category:"comercios",type:"colorshop",address:"Av. Belgrano 2845 Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero III",position:[-28.186577,-63.580611]},
{category:"comercios",type:"colorshop",address:"25 de mayo esq. Avellaneda Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero IV",position:[-27.7863392,-64.25658709999999]},
{category:"comercios",type:"colorshop",address:"C. Pellegrini 56 Frias  NOA SGO DEL ESTERO Argentina",name:"Colorshop Frias",position:[-28.6289922,-65.13072509999999]},
{category:"comercios",type:"colorshop",address:"Semanario de la verdad 1380 Puerto San PATAGONIA SANTA CRUZ Argentina",name:"Colorshop Puerto San Juli\u00e1n",position:[-49.3068942,-67.72982480000002]},
{category:"comercios",type:"colorshop",address:"Belgrano 1395 (esq. Col\u00f3n) R\u00edo Grande PATAGONIA T. DEL FUEGO Argentina",name:"Colorshop R\u00edo Grande I",position:[-53.7915459,-67.70965989999999]},
{category:"comercios",type:"colorshop",address:"Islas Malvinas esq. Lugones R\u00edo Grande PATAGONIA T. DEL FUEGO Argentina",name:"Colorshop R\u00edo Grande II",position:[-53.7923857,-67.7320781]},
{category:"comercios",type:"colorshop",address:"Kuanip esq. Primer Argentino Ushuaia II PATAGONIA T. DEL FUEGO Argentina",name:"Colorshop Ushuaia II",position:[-54.8175687,-68.3342067]},
{category:"comercios",type:"colorshop",address:"Av. Alem 61 Taf\u00ed Viejo NOA TUCUMAN Argentina",name:"Colorshop Taf\u00ed Viejo",position:[-26.7310714,-65.254594]},
{category:"comercios",type:"colorshop",address:"Poeta Aguero 429 Villa de CUYO SAN LUIS Argentina",name:"Colorshop Villa de Merlo",position:[-32.3455461,-65.0159157]},
{category:"comercios",type:"colorshop",address:"Uruguay 730 Santa Rosa BA SUR LA PAMPA Argentina",name:"Colorshop Santa Rosa",position:[-36.6252027,-64.30080389999999]},
{category:"comercios",type:"colorshop",address:"Mitre 754 Quilmes  GBA BUENOS AIRES Argentina",name:"Colorshop Quilmes",position:[-34.722981,-58.2526603]},
{category:"comercios",type:"colorshop",address:"Av. Gonzalez Lelong 765 Formosa  NEA FORMOSA Argentina",name:"Colorshop Formosa",position:[-26.1761025,-58.17619819999999]},
{category:"comercios",type:"colorshop",address:"Maestro Lang 1247 Calafate  PATAGONIA SANTA CRUZ Argentina",name:"Colorshop Calafate",position:[-50.3503667,-72.2669161]},
{category:"comercios",type:"colorshop",address:"Cecilio Rodriguez 110  Rosario de NOA SALTA Argentina",name:"Colorshop Rosario de Lerma",position:[-25.2529539,-64.7162415]},
{category:"comercios",type:"colorshop",address:"Bolivia 26 Ucacha  CENTRO CORDOBA Argentina",name:"Colorshop Ucacha",position:[-33.0324052,-63.51183580000001]},
{category:"comercios",type:"colorshop",address:"Gregorio Ram\u00edrez 645 Vicu\u00f1a Mackenna CENTRO CORDOBA Argentina",name:"Colorshop Vicu\u00f1a Mackenna",position:[-33.9202718,-64.3904429]},
{category:"comercios",type:"colorshop",address:"Pte. Kirchner 402 (ruta 205) esq. Diego Laure Ezeiza  GBA BUENOS AIRES Argentina",name:"Colorshop Ezeiza",position:[-34.8555745,-58.5238409]},
{category:"comercios",type:"colorshop",address:"Buratovich 226 Plottier  PATAGONIA NEUQUEN Argentina",name:"Colorshop Plottier",position:[-38.944528,-68.22914420000001]},
{category:"comercios",type:"colorshop",address:"Dr. Benito de Miguel 776 esq. Cmte. Acha Jun\u00edn  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Jun\u00edn ",position:[-34.6010574,-60.96088639999999]},
{category:"comercios",type:"colorshop",address:"Av. Quaranta 691 (esq. Santiago D\u00edaz) Etruria  CENTRO CORDOBA Argentina",name:"Colorshop Etruria",position:[-32.9410354,-63.247646]},
{category:"comercios",type:"colorshop",address:"Hip\u00f3lito Yrigoyen esq Matheu Quilmes II GBA BUENOS AIRES Argentina",name:"Colorshop Quilmes II",position:[-34.7153786,-58.266318]},
{category:"comercios",type:"colorshop",address:"Kennedy 151 Cipolletti  PATAGONIA RIO NEGRO Argentina",name:"Colorshop Cipolletti",position:[-38.938771,-67.9954929]},
{category:"comercios",type:"colorshop",address:"Av. Independencia 5001  - nueva mudanza Corrientes  NEA CORRIENTES Argentina",name:"Colorshop Corrientes",position:[-27.4832349,-58.7910701]},
{category:"comercios",type:"colorshop",address:"Av. Rivadavia 8901 Floresta II CABA CABA Argentina",name:"Colorshop Floresta II",position:[-34.6354544,-58.48965279999999]},
{category:"comercios",type:"colorshop",address:"Av. Intendente Carlos Ratti esq Laprida Ituzaing\u00f3 II GBA BUENOS AIRES Argentina",name:"Colorshop Ituzaing\u00f3 II",position:[-34.6447502,-58.6912654]},
{category:"comercios",type:"colorshop",address:"Av San Martin 630 esquina Los Andes Termas de NOA SGO DEL ESTERO Argentina",name:"Colorshop Termas de R\u00edo Hondo",position:[-27.7948929,-64.2666401]},
{category:"comercios",type:"colorshop",address:"Av. Sarmiento 1699 Resistencia II NEA CHACO Argentina",name:"Colorshop Resistencia II",position:[-27.4375042,-58.97166379999999]},
{category:"comercios",type:"colorshop",address:"Italia 1004 Leones  CENTRO CORDOBA Argentina",name:"Colorshop Leones",position:[-32.6596268,-62.2995159]},
{category:"comercios",type:"colorshop",address:"Scalabrini Ortiz 1345 Palermo  CABA CABA Argentina",name:"Colorshop Palermo",position:[-34.5926852,-58.427398]},
{category:"comercios",type:"colorshop",address:"9 de Julio 1500 Concepci\u00f3n del CENTRO ENTRE RIOS Argentina",name:"Colorshop Concepci\u00f3n del Uruguay",position:[-32.4871517,-58.24613480000001]},
{category:"comercios",type:"colorshop",address:"Moreno 521 Charata  NEA CHACO Argentina",name:"Colorshop Charata",position:[-27.2174547,-61.1894205]},
{category:"comercios",type:"colorshop",address:"Av Cipolleti 1065 Villa Regina PATAGONIA RIO NEGRO Argentina",name:"Colorshop Villa Regina",position:[-39.0937594,-67.1002202]},
{category:"comercios",type:"colorshop",address:"Av. Eva Per\u00f3n 781  Derqui  GBA BUENOS AIRES Argentina",name:"Colorshop Derqui",position:[-34.491177,-58.8395414]},
{category:"comercios",type:"colorshop",address:"Av. San Martin esq. Defensa Puerto Rico NEA MISIONES Argentina",name:"Colorshop Puerto Rico",position:[-26.8063572,-55.0240801]},
{category:"comercios",type:"colorshop",address:"O\u00b4Higgins 1395 San Antonio PATAGONIA RIO NEGRO Argentina",name:"Colorshop San Antonio Oeste",position:[-41.804728,-65.2857082]},
{category:"comercios",type:"colorshop",address:"Rivadavia 390 Lobos  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Lobos",position:[-35.1833,-59.0833]},
{category:"comercios",type:"colorshop",address:"Belgrano esq. Pellegrini Daireaux  BA SUR BUENOS AIRES Argentina",name:"Colorshop Daireaux",position:[-36.5997941,-61.74668109999999]},
{category:"comercios",type:"colorshop",address:"Sobremonte 1198 R\u00edo Cuarto CENTRO CORDOBA Argentina",name:"Colorshop R\u00edo Cuarto I",position:[-33.1231585,-64.3493441]},
{category:"comercios",type:"colorshop",address:"Av. De Mayo 319 Justiniano Posse CENTRO CORDOBA Argentina",name:"Colorshop Justiniano Posse",position:[-32.8832319,-62.6789146]},
{category:"comercios",type:"colorshop",address:"Tom\u00e1s M\u00e1rquez 1258 Pilar  GBA BUENOS AIRES Argentina",name:"Colorshop Pilar",position:[-34.4658892,-58.9085018]},
{category:"comercios",type:"colorshop",address:"Choc\u00f3n 920 Neuqu\u00e9n III PATAGONIA NEUQUEN Argentina",name:"Colorshop Neuqu\u00e9n III",position:[-38.9687548,-68.0464465]},
{category:"comercios",type:"colorshop",address:"Av. Ortiz de Ocampo y calle proyectada Sur La Rioja CENTRO LA RIOJA Argentina",name:"Colorshop La Rioja II",position:[-29.4535858,-66.8612985]},
{category:"comercios",type:"colorshop",address:"Av. Directorio 712 Caballito  CABA CABA Argentina",name:"Colorshop Caballito ",position:[-34.6286691,-58.43737839999999]},
{category:"comercios",type:"colorshop",address:"Rivadavia 1609 Villa Constituci\u00f3n BA NORTE BUENOS AIRES Argentina",name:"Colorshop Villa Constituci\u00f3n",position:[-33.2333,-60.3333]},
{category:"comercios",type:"colorshop",address:"Mitre 189 San Pedro NOA JUJUY Argentina",name:"Colorshop San Pedro ",position:[-24.2321891,-64.8688006]},
{category:"comercios",type:"colorshop",address:"Carlos Casares 837 Rafael Castillo GBA BUENOS AIRES Argentina",name:"Colorshop Rafael Castillo",position:[-34.6983834,-58.6244866]},
{category:"comercios",type:"colorshop",address:"Av. Alvear 401 Resistencia III NEA CHACO Argentina",name:"Colorshop Resistencia III",position:[-27.4544772,-58.9973795]},
{category:"comercios",type:"colorshop",address:"Del Valle 325 Pehuaj\u00f3  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Pehuaj\u00f3",position:[-35.8107166,-61.8987832]},
{category:"comercios",type:"colorshop",address:"Av. Serrana s-n La Punta CUYO SAN LUIS Argentina",name:"Colorshop La Punta",position:[-33.1689409,-66.3198061]},
{category:"comercios",type:"colorshop",address:"Perito Moreno 264 R\u00edo Gallegos PATAGONIA SANTA CRUZ Argentina",name:"Colorshop R\u00edo Gallegos",position:[-51.61807150000001,-69.2307241]},
{category:"comercios",type:"colorshop",address:"C\u00e9sar Comolli 61  Alejandro  CENTRO CORDOBA Argentina",name:"Colorshop Alejandro",position:[-33.3554506,-63.7192125]},
{category:"comercios",type:"colorshop",address:"Almafuerte  2779 esqu Illia San Justo GBA BUENOS AIRES Argentina",name:"Colorshop San Justo ",position:[-34.6749938,-58.5639271]},
{category:"comercios",type:"colorshop",address:"General Paz 172 Cinco Saltos PATAGONIA RIO NEGRO Argentina",name:"Colorshop Cinco Saltos",position:[-38.8247083,-68.0707911]},
{category:"comercios",type:"colorshop",address:"San Mart\u00edn 275 Valcheta  PATAGONIA RIO NEGRO Argentina",name:"Colorshop Valcheta",position:[-40.6769386,-66.1642177]},
{category:"comercios",type:"colorshop",address:"Savio 1126 (esq. Alurralde) San Nicol\u00e1s BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Nicol\u00e1s III",position:[-34.596616,-58.393509]},
{category:"comercios",type:"colorshop",address:"Av. Espora 934 Adrogu\u00e9  GBA BUENOS AIRES Argentina",name:"Colorshop Adrogu\u00e9",position:[-34.8011526,-58.3889122]},
{category:"comercios",type:"colorshop",address:"Juan Benigar 307 Alumin\u00e9  PATAGONIA NEUQUEN Argentina",name:"Colorshop Alumin\u00e9",position:[-39.2366092,-70.91959419999999]},
{category:"comercios",type:"colorshop",address:"Av. San Martin esq. Boulevard Buenos Aires General Cabrera CENTRO CORDOBA Argentina",name:"Colorshop General Cabrera",position:[-32.7833,-63.8667]},
{category:"comercios",type:"colorshop",address:"Nicol\u00e1s Repetto 547   Hurlingham  GBA BUENOS AIRES Argentina",name:"Colorshop Hurlingham",position:[-34.6275323,-58.6568382]},
{category:"comercios",type:"colorshop",address:"Hip\u00f3lito Irigoyen 3556 Lan\u00fas Oeste GBA BUENOS AIRES Argentina",name:"Colorshop Lan\u00fas Oeste I",position:[-34.6994795,-58.39207949999999]},
{category:"comercios",type:"colorshop",address:"Av. Pte Arturo Illia 4599 Malvinas Argentinas GBA BUENOS AIRES Argentina",name:"Colorshop Malvinas Argentinas",position:[-34.479665,-58.70303260000001]},
{category:"comercios",type:"colorshop",address:"Rivadavia 110 San Cayetano BA SUR BUENOS AIRES Argentina",name:"Colorshop San Cayetano",position:[-38.3432397,-59.6114468]},
{category:"comercios",type:"colorshop",address:"Av. Del Trabajo 444 Cutral C\u00f3 PATAGONIA NEUQUEN Argentina",name:"Colorshop Cutral C\u00f3",position:[-38.9343162,-69.2296922]},
{category:"comercios",type:"colorshop",address:"Av. Roca 350 Allen  PATAGONIA RIO NEGRO Argentina",name:"Colorshop Allen",position:[-38.9802988,-67.8302726]},
{category:"comercios",type:"colorshop",address:"Entre R\u00edos esq. Ingeniero Garro Catriel  PATAGONIA RIO NEGRO Argentina",name:"Colorshop Catriel",position:[-37.879257,-67.79190729999999]},
{category:"comercios",type:"colorshop",address:"Ocampo 502 Ca\u00f1ada de CENTRO SANTA FE Argentina",name:"Colorshop Ca\u00f1ada de Gomez",position:[-32.9494008,-60.6432865]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn\u00a0 125 Despe\u00f1aderos  CENTRO CORDOBA Argentina",name:"Colorshop Despe\u00f1aderos",position:[-31.4145053,-64.1834832]},
{category:"comercios",type:"colorshop",address:"Calle 10 N\u00b0 900 25 de Mayo BA NORTE BUENOS AIRES Argentina",name:"Colorshop 25 de Mayo ",position:[-35.4333,-60.1667]},
{category:"comercios",type:"colorshop",address:"Chacabuco 1094 (esq. Per\u00f3n) San Fernando GBA BUENOS AIRES Argentina",name:"Colorshop San Fernando",position:[-34.4325947,-58.5782682]},
{category:"comercios",type:"colorshop",address:"Av. de Mayo 1801  Ramos Mejia GBA BUENOS AIRES Argentina",name:"Colorshop Ramos Mejia",position:[-34.6593598,-58.5623247]},
{category:"comercios",type:"colorshop",address:"Santa F\u00e9 823  La Rioja CENTRO LA RIOJA Argentina",name:"Colorshop La Rioja I",position:[-29.41726999999999,-66.85919659999999]},
{category:"comercios",type:"colorshop",address:"Andres Rol\u00f3n 601 San Isidro GBA BUENOS AIRES Argentina",name:"Colorshop San Isidro",position:[-34.4762225,-58.52994760000001]},
{category:"comercios",type:"colorshop",address:"Zelarrayan 2220 Bah\u00eda Blanca BA SUR BUENOS AIRES Argentina",name:"Colorshop Bah\u00eda Blanca V",position:[-38.6986254,-62.2890312]},
{category:"comercios",type:"colorshop",address:"El Carmen esq Mitre Ca\u00f1uelas  GBA BUENOS AIRES Argentina",name:"Colorshop Ca\u00f1uelas",position:[-35.0524487,-58.7598199]},
{category:"comercios",type:"colorshop",address:"12 de Octubre 986 Puerto Deseado PATAGONIA SANTA CRUZ Argentina",name:"Colorshop Puerto Deseado",position:[-47.7491061,-65.8894924]},
{category:"comercios",type:"colorshop",address:"Calle 15  N\u00ba 548 (esq. 16) Balcarce  BA SUR BUENOS AIRES Argentina",name:"Colorshop Balcarce",position:[-34.6090586,-58.37066199999999]},
{category:"comercios",type:"colorshop",address:"Roca 325 Fernandez Oro PATAGONIA RIO NEGRO Argentina",name:"Colorshop Fernandez Oro",position:[-38.9536042,-67.92081999999999]},
{category:"comercios",type:"colorshop",address:"Luis Maria Drago 396 esq Alfredo Palacios Tortuguitas  GBA BUENOS AIRES Argentina",name:"Colorshop Tortuguitas",position:[-34.4885953,-58.740757]},
{category:"comercios",type:"colorshop",address:"Avellaneda 3499 (esq. Jos\u00e9 I. Rucci) Virreyes  GBA BUENOS AIRES Argentina",name:"Colorshop Virreyes ",position:[-34.4594873,-58.57042509999999]},
{category:"comercios",type:"colorshop",address:"Libertad 333 Benito Ju\u00e1rez BA SUR BUENOS AIRES Argentina",name:"Colorshop Benito Ju\u00e1rez",position:[-34.604678,-58.38343999999999]},
{category:"comercios",type:"colorshop",address:"Av Libertador Sur 410 General Alvear CUYO MENDOZA Argentina",name:"Colorshop General Alvear",position:[-34.9779882,-67.6893858]},
{category:"comercios",type:"colorshop",address:"25 de Mayo 352 Rosario de NOA SALTA Argentina",name:"Colorshop Rosario de la Frontera",position:[-25.2529539,-64.7162415]},
{category:"comercios",type:"colorshop",address:"Santa Rosa 1829 Castelar  GBA BUENOS AIRES Argentina",name:"Colorshop Castelar",position:[-34.6397201,-58.65563969999999]},
{category:"comercios",type:"colorshop",address:"Av. del Valle 3768 Olavarria  BA SUR BUENOS AIRES Argentina",name:"Colorshop Olavarria",position:[-36.9045794,-60.31960599999999]},
{category:"comercios",type:"colorshop",address:"Misiones 1433 Rinc\u00f3n de PATAGONIA NEUQUEN Argentina",name:"Colorshop Rinc\u00f3n de los Sauces",position:[-38.9516784,-68.0591888]},
{category:"comercios",type:"colorshop",address:"Av. Santa Rosa 198 25 de PATAGONIA LA PAMPA Argentina",name:"Colorshop 25 de Mayo - LA PAMPA",position:[-37.7712793,-67.7111838]},
{category:"comercios",type:"colorshop",address:"Bv. H. Irigoyen 906 Rafaela  CENTRO SANTA FE Argentina",name:"Colorshop Rafaela",position:[-31.2609648,-61.4940235]},
{category:"comercios",type:"colorshop",address:"Acceso Carlos Sand S-N Buena Esperanza CUYO SAN LUIS Argentina",name:"Colorshop Buena Esperanza",position:[-34.7551584,-65.2572003]},
{category:"comercios",type:"colorshop",address:"Alem 1199 Ushuaia I PATAGONIA T. DEL FUEGO Argentina",name:"Colorshop Ushuaia I",position:[-54.8019121,-68.3029511]},
{category:"comercios",type:"colorshop",address:"Sarmiento 698 Bah\u00eda Blanca BA SUR BUENOS AIRES Argentina",name:"Colorshop Bah\u00eda Blanca IV",position:[-38.7183177,-62.2663478]},
{category:"comercios",type:"colorshop",address:"Avenida Libertad esq. Fray Mamerto Esqui\u00fa Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero V",position:[-33.4488897,-70.6692655]},
{category:"comercios",type:"colorshop",address:"Av. Libertad 499 Jard\u00edn America NEA MISIONES Argentina",name:"Colorshop Jard\u00edn America",position:[-27.043292,-55.2324616]},
{category:"comercios",type:"colorshop",address:"Av. rep\u00fablica Argentina N\u00b0 12 y Av. Victoria Aguirre Puerto Iguaz\u00fa NEA MISIONES Argentina",name:"Colorshop Puerto Iguaz\u00fa",position:[-25.6019124,-54.5727652]},
{category:"comercios",type:"colorshop",address:"Av. Uruguay 4929 Posadas II NEA MISIONES Argentina",name:"Colorshop Posadas II",position:[-27.3621374,-55.90087459999999]},
{category:"comercios",type:"colorshop",address:"Belgrano 770 Ap\u00f3stoles  NEA  MISIONES Argentina",name:"Colorshop Ap\u00f3stoles",position:[-27.9155024,-55.7546938]},
{category:"comercios",type:"colorshop",address:"Av. Uruguay 4360 Posadas V NEA MISIONES Argentina",name:"Colorshop Posadas V",position:[-27.3621374,-55.90087459999999]},
{category:"comercios",type:"colorshop",address:"Av. El \u00c9xodo N\u00ba 668 Jujuy I NOA JUJUY Argentina",name:"Colorshop Jujuy I",position:[-24.1950565,-65.2990186]},
{category:"comercios",type:"colorshop",address:"Avenida Sarmiento N180  Machagai  NEA CHACO Argentina",name:"Colorshop Machagai",position:[-26.9191371,-60.0518741]},
{category:"comercios",type:"colorshop",address:"Av. Presidente Peron N\u00ba 6525  Benavidez  GBA BUENOS AIRES Argentina",name:"Colorshop Benavidez",position:[-34.6301981,-58.6544053]},
{category:"comercios",type:"colorshop",address:"Av. San Lorenzo 21 este  Concordia  CENTRO ENTRE RIOS Argentina",name:"Colorshop Concordia",position:[-31.3856906,-58.0156132]},
{category:"comercios",type:"colorshop",address:"San Lorenzo 790 (esq. Av. Castelli) Resistencia IV NEA CHACO Argentina",name:"Colorshop Resistencia IV",position:[-27.4822155,-58.9657728]},
{category:"comercios",type:"colorshop",address:"Av. Mitre 1100 (esq. Eva Per\u00f3n) Ramallo II BA NORTE BUENOS AIRES Argentina",name:"Colorshop Ramallo II",position:[-33.4845,-60.0113]},
{category:"comercios",type:"colorshop",address:"Av. Hip\u00f3lito Yrigoyen 1970 Talar de GBA BUENOS AIRES Argentina",name:"Colorshop Talar de Pacheco",position:[-34.4694966,-58.65113350000001]},
{category:"comercios",type:"colorshop",address:"Juan Bautista La Salle 4295-4285 Gonzalez Cat\u00e1n GBA BUENOS AIRES Argentina",name:"Colorshop Gonzalez Cat\u00e1n ",position:[-34.7727062,-58.6456822]},
{category:"comercios",type:"colorshop",address:"Av San Martin 1610\u00a0\u00a0(esq. Urquiza)\u00a0 Mina Clavero CENTRO CORDOBA Argentina",name:"Colorshop Mina Clavero",position:[-31.4080027,-64.1806384]},
{category:"comercios",type:"colorshop",address:"Av de Americas 240 Aristobulo del NEA MISIONES Argentina",name:"Colorshop Aristobulo del Valle",position:[-27.0956103,-54.8935263]},
{category:"comercios",type:"colorshop",address:"Pellegrini 696 Esq. Ushuaia R\u00edo Grande PATAGONIA T. DEL FUEGO Argentina",name:"Colorshop R\u00edo Grande III",position:[-53.7711864,-67.7106842]},
{category:"comercios",type:"colorshop",address:"Art\u00e9mides Zatti 756 Viedma  PATAGONIA RIO NEGRO Argentina",name:"Colorshop  Viedma",position:[-40.81973259999999,-62.9898213]},
{category:"comercios",type:"colorshop",address:"Tolhuin 340 R\u00edo Grande PATAGONIA T. DEL FUEGO Argentina",name:"colorshop R\u00edo Grande IV ",position:[-53.8045833,-67.6683469]},
{category:"comercios",type:"colorshop",address:"Av Fuerza Aerea 70 local 7 Alto comedero NOA JUJUY Argentina",name:"Colorshop Alto comedero ",position:[-24.23341,-65.27427279999999]},
{category:"comercios",type:"colorshop",address:"Hipolito Irigoyen 1111 Sunchales  CENTRO SANTA FE Argentina",name:"Colorshop Sunchales ",position:[-30.9451714,-61.5607353]},
{category:"comercios",type:"colorshop",address:"San Martin Sur 2505 Mendoza  CUYO MENDOZA Argentina",name:"Colorshop Mendoza",position:[-32.9508161,-68.8550976]},
{category:"comercios",type:"colorshop",address:"Diagonal Eva Per\u00f3n 501 Barranqueras  NEA CHACO Argentina",name:"Colorshop Barranqueras",position:[-27.4826564,-58.94187809999999]},
{category:"comercios",type:"colorshop",address:"Av. Luro 5476 Laferrere  GBA BUENOS AIRES Argentina",name:"Colorshop Laferrere",position:[-34.7436273,-58.59302880000001]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn 1715 Baradero  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Baradero",position:[-34.6057909,-58.45339329999999]},
{category:"comercios",type:"colorshop",address:"Tucum\u00e1n 879 Catamarca II NOA CATAMARCA Argentina",name:"Colorshop Catamarca II",position:[-28.4716007,-65.77546939999999]},
{category:"comercios",type:"colorshop",address:"San Mart\u00edn 428 Tres Arroyos BA SUR BUENOS AIRES Argentina",name:"Colorshop Tres Arroyos II",position:[-38.3775437,-60.27522949999999]},
{category:"comercios",type:"colorshop",address:"Av. Morteo 599 esquina Benitez San Nicolas BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Nicolas IV",position:[-33.3519117,-60.2145073]},
{category:"comercios",type:"colorshop",address:"Remedios de Escalada de San Martin 1302 Lan\u00fas Oeste GBA BUENOS AIRES Argentina",name:"Colorshop Lan\u00fas Oeste II",position:[-34.6861388,-58.3980592]},
{category:"comercios",type:"colorshop",address:"Urquiza 839   Concordia II CENTRO ENTRE RIOS Argentina",name:"Colorshop Concordia II",position:[-31.3936402,-58.01679499999999]},
{category:"comercios",type:"colorshop",address:"Onelli 447 local 6 y local 7 Bariloche  PATAGONIA RIO NEGRO Argentina",name:"Colorshop Bariloche",position:[-41.1374717,-71.2976051]},
{category:"comercios",type:"colorshop",address:"Av. Avelino Rolon 1165 Boulogne  GBA BUENOS AIRES Argentina",name:"Colorshop Boulogne",position:[-34.5009265,-58.5594458]},
{category:"comercios",type:"colorshop",address:"Hipolito Yrigoyen 8600 Lomas de GBA BUENOS AIRES Argentina",name:"Colorshop Lomas de Zamora",position:[-34.6149961,-58.4285684]},
{category:"comercios",type:"colorshop",address:"Independencia 269 Escobar  GBA BUENOS AIRES Argentina",name:"Colorshop Escobar",position:[-34.3553862,-58.7964255]},
{category:"comercios",type:"colorshop",address:"Av. Juan Manuel de Rosas 559-61 Mor\u00f3n  GBA BUENOS AIRES Argentina",name:"Colorshop Mor\u00f3n",position:[-34.636238,-58.6274345]},
{category:"comercios",type:"colorshop",address:"Av. 14 4031  Berazategui Berazategui  GBA BUENOS AIRES Argentina",name:"Colorshop Berazategui",position:[-34.7662121,-58.2172066]},
{category:"comercios",type:"colorshop",address:"Av. Libertador 199 Merlo  GBA BUENOS AIRES Argentina",name:"Colorshop Merlo",position:[-34.6723311,-58.72403509999999]},
{category:"comercios",type:"colorshop",address:"Rodriguez 474 General Guemes NOA SALTA Argentina",name:"Colorshop General Guemes",position:[-24.676013,-65.0480075]},
{category:"comercios",type:"colorshop",address:"Las Retamas 379 Villa La PATAGONIA NEUQUEN Argentina",name:"Colorshop Villa La Angostura",position:[-40.7599216,-71.64286659999999]},
{category:"comercios",type:"colorshop",address:"San Martin y 9 de Julio 22 Los Toldos BA NORTE BUENOS AIRES Argentina",name:"Colorshop Los Toldos - Viamonte",position:[-35.0040142,-61.0348339]},
{category:"comercios",type:"colorshop",address:"Juan Bautista Alberdi 3202 Florencio Varela GBA BUENOS AIRES Argentina",name:"Colorshop Florencio Varela",position:[-34.8088959,-58.27276089999999]},
{category:"comercios",type:"colorshop",address:"Tom\u00e1s Orell e Italia Allen II PATAGONIA RIO NEGRO Argentina",name:"Colorshop Allen II",position:[-38.978686,-67.82105039999999]},
{category:"comercios",type:"colorshop",address:"Ruta nacional 9 km 1784 Tilcara  NOA JUJUY Argentina",name:"Colorshop Tilcara",position:[-23.5778165,-65.4031317]},
{category:"comercios",type:"colorshop",address:"AV. 9 DE JULIO 462 - BARRIO SAN JOSE Monterrico  NOA JUJUY Argentina",name:"Colorshop Monterrico",position:[-24.1929687,-65.2866469]},
{category:"comercios",type:"colorshop",address:"Jos\u00e9 Camilo Crotto 6806 Mart\u00edn Coronado GBA BUENOS AIRES Argentina",name:"Colorshop Mart\u00edn Coronado",position:[-34.5884661,-58.5906321]},
{category:"comercios",type:"colorshop",address:" Av. Santamarina 398 Monte Grande GBA BUENOS AIRES Argentina",name:"Colorshop Monte Grande II",position:[-34.8220329,-58.4707642]},
{category:"comercios",type:"colorshop",address:"Av Felipe Figueroa esquina Morazan Catamarca III NOA CATAMARCA Argentina",name:"Colorshop Catamarca III",position:[-28.4637691,-65.80141309999999]},
{category:"comercios",type:"colorshop",address:"Av. Colon esq. San Martin Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero VI",position:[-27.7944952,-64.2663069]},
{category:"comercios",type:"colorshop",address:"Av . Almirante Brown 1460 Jujuy II NOA JUJUY Argentina",name:"Colorshop Jujuy II",position:[-24.2055932,-65.28276679999999]},
{category:"comercios",type:"colorshop",address:"Sarmiento 426 Puerto Madryn PATAGONIA CHUBUT Argentina",name:"Colorshop Puerto Madryn II",position:[-42.7707495,-65.0373528]},
{category:"comercios",type:"colorshop",address:"M. MORENO N\u00b098 (calle 14 esquina 3 Centro) Pte. Roque NEA CHACO Argentina",name:"Colorshop Pte. Roque Saenz Pe\u00f1a",position:[-26.5857656,-60.9540073]},
{category:"comercios",type:"colorshop",address:"Rivera Indarte 64  Flores II CABA CABA Argentina",name:"Colorshop Flores II",position:[-34.6296665,-58.4623136]},
{category:"comercios",type:"colorshop",address:"Av. San Martin 3198 Rafael Calzada GBA BUENOS AIRES Argentina",name:"Colorshop Rafael Calzada",position:[-34.7931762,-58.35937500000001]},
{category:"comercios",type:"colorshop",address:"Lavalle 1445 Zarate  GBA BUENOS AIRES Argentina",name:"Colorshop Zarate",position:[-34.1090505,-59.02120720000001]},
{category:"comercios",type:"colorshop",address:"San Martin 1181 Gualeuaychu  CENTRO ENTRE RIOS Argentina",name:"Colorshop Gualeuaychu",position:[-33.0104794,-58.52192669999999]},
{category:"comercios",type:"colorshop",address:"Av Constituci\u00f3n 595 San Vicente NEA MISIONES Argentina",name:"Colorshop San Vicente",position:[-26.9994022,-54.48012019999999]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn 50 Burzaco  GBA BUENOS AIRES Argentina",name:"Colorshop Burzaco",position:[-34.8141173,-58.3967985]},
{category:"comercios",type:"colorshop",address:"Arenales 302 Or\u00e1n ll NOA SALTA Argentina",name:"Colorshop Or\u00e1n ll",position:[-23.1358869,-64.3277994]},
{category:"comercios",type:"colorshop",address:"AV. PTE. PER\u00d3N 345 (esq. GAILLARD) Colon  CENTRO ENTRE RIOS Argentina",name:"Colorshop Colon",position:[-32.2131077,-58.1541469]},
{category:"comercios",type:"colorshop",address:"Boulogne Sur Mer 356 Mendoza 2 CUYO MENDOZA Argentina",name:"Colorshop Mendoza 2",position:[-32.8894587,-68.8458386]},
{category:"comercios",type:"colorshop",address:"Balcarce 1999  Chabas  CENTRO SANTA FE Argentina",name:"Colorshop Chabas",position:[-33.2399639,-61.36002999999999]},
{category:"comercios",type:"colorshop",address:"3 de Febrero 195 esq. Independencia Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero VII",position:[-27.7833574,-64.264167]},
{category:"comercios",type:"colorshop",address:"Entre R\u00edos 1131 Crespo  CENTRO ENTRE RIOS Argentina",name:"Colorshop Crespo",position:[-32.0255285,-60.3058984]},
{category:"comercios",type:"colorshop",address:"Colectora Av Ant\u00e1rtida esq Guiraldes Zarate II GBA BUENOS AIRES Argentina",name:"Colorshop Zarate II",position:[-34.5733392,-58.64583689999999]},
{category:"comercios",type:"colorshop",address:"Av Espa\u00f1a 498 General Rodriguez GBA BUENOS AIRES Argentina",name:"Colorshop General Rodriguez",position:[-34.60392119999999,-58.9529304]},
{category:"comercios",type:"colorshop",address:"Calle 842 esq. Calle 897 San Francisco GBA BUENOS AIRES Argentina",name:"Colorshop San Francisco Solano",position:[-34.77943339999999,-58.3135875]},
{category:"comercios",type:"colorshop",address:"Av. Per\u00f3n 154 (esq. Alvear) Campana  GBA BUENOS AIRES Argentina",name:"Colorshop Campana",position:[-34.1774637,-58.9457893]},
{category:"comercios",type:"colorshop",address:"Av. Crovara 4185 La Tablada GBA BUENOS AIRES Argentina",name:"Colorshop La Tablada",position:[-34.6979933,-58.5386988]},
{category:"comercios",type:"colorshop",address:"Boulogne Sur Mer 810 Tapiales  GBA BUENOS AIRES Argentina",name:"Colorshop Tapiales",position:[-34.693707,-58.512705]},
{category:"comercios",type:"colorshop",address:"Juan E. Mart\u00ednez 284 Goya  NEA CORRIENTES Argentina",name:"Colorshop Goya",position:[-29.1428292,-59.26094310000001]},
{category:"comercios",type:"colorshop",address:"3 de Febrero 285 San Pedro BA NORTE BUENOS AIRES Argentina",name:"Colorshop San Pedro II",position:[-33.6819427,-59.66547420000001]},
{category:"comercios",type:"colorshop",address:"Av. 9 de julio 155 El Carmen NOA JUJUY Argentina",name:"Colorshop El Carmen",position:[-24.3894811,-65.2573482]},
{category:"comercios",type:"colorshop",address:"9 de julio 939 Pilar C\u00f3rdoba CENTRO CORDOBA Argentina",name:"Colorshop Pilar C\u00f3rdoba",position:[-31.6816616,-63.88369900000001]},
{category:"comercios",type:"colorshop",address:"Tucuman y La Pampa General Roca PATAGONIA RIO NEGRO Argentina",name:"Colorshop General Roca",position:[-39.0294391,-67.56757499999999]},
{category:"comercios",type:"colorshop",address:"Jujuy 369 sur  San Juan CUYO SAN JUAN Argentina",name:"Colorshop San Juan",position:[-31.5292606,-68.5205959]},
{category:"comercios",type:"colorshop",address:"Libertad 499 esq 3 de Febrero Arroyo Seco CENTRO SANTA FE Argentina",name:"Colorshop Arroyo Seco",position:[-33.1496939,-60.5149079]},
{category:"comercios",type:"colorshop",address:"Av. de Tomaso 205 Chivilcoy  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Chivilcoy",position:[-34.8882121,-60.02992069999999]},
{category:"comercios",type:"colorshop",address:"Av presidente Per\u00f3n 453 Capit\u00e1n Sarmiento BA NORTE BUENOS AIRES Argentina",name:"Colorshop Capit\u00e1n Sarmiento",position:[-34.177056,-59.79290570000001]},
{category:"comercios",type:"colorshop",address:"Rivadavia 790 Bragado  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Bragado",position:[-35.1154755,-60.48994130000001]},
{category:"comercios",type:"colorshop",address:"Roca 485 (general roca y uruguay) Sarmiento  PATAGONIA CHUBUT Argentina",name:"Colorshop Sarmiento",position:[-45.5868207,-69.068778]},
{category:"comercios",type:"colorshop",address:"Belgrano 902 Tres Arroyos BA SUR BUENOS AIRES Argentina",name:"Colorshop Tres Arroyos III",position:[-34.6130695,-58.37908829999999]},
{category:"comercios",type:"colorshop",address:"Gaspar Campos 6480-82 Jos\u00e9 C. GBA BUENOS AIRES Argentina",name:"Colorshop Jos\u00e9 C. Paz",position:[-34.5373851,-58.7409044]},
{category:"comercios",type:"colorshop",address:"B\u00ba LA LOMA AVENIDA ZAPLA ESQUINA JACARANDA. Ledesma  NOA JUJUY Argentina",name:"Colorshop Ledesma",position:[-23.8143905,-64.79815889999999]},
{category:"comercios",type:"colorshop",address:"Avellaneda 21 Ramirez  CENTRO ENTRE RIOS Argentina",name:"Colorshop Ramirez",position:[-31.7640145,-60.4127638]},
{category:"comercios",type:"colorshop",address:"Boulevard Ovidio Lagos 257 Venado Tuerto CENTRO SANTA FE Argentina",name:"Colorshop Venado Tuerto II",position:[-33.7601292,-61.97047999999999]},
{category:"comercios",type:"colorshop",address:"Humberto  535  Punta Alta BA SUR BUENOS AIRES Argentina",name:"Colorshop Punta Alta",position:[-38.8766081,-62.0707813]},
{category:"comercios",type:"colorshop",address:"Av. Marcos Paz 1126 Marcos Paz GBA BUENOS AIRES Argentina",name:"Colorshop Marcos Paz",position:[-34.7763368,-58.82747699999999]},
{category:"comercios",type:"colorshop",address:"Av. Pueyrred\u00f3n 922 Balvanera  CABA CABA Argentina",name:"Colorshop Balvanera",position:[-34.5995134,-58.4042153]},
{category:"comercios",type:"colorshop",address:"Av Presidente Per\u00f3n 2633 Los Polvorines GBA BUENOS AIRES Argentina",name:"Colorshop Los Polvorines",position:[-34.6352931,-58.59838749999999]},
{category:"comercios",type:"colorshop",address:"9 de Julio 497 (esq. Riobamba) Zapala  PATAGONIA NEUQUEN Argentina",name:"Colorshop Zapala",position:[-38.9031623,-70.0649014]},
{category:"comercios",type:"colorshop",address:"Av Belgrano esq Castelli Santiago del NOA SGO DEL ESTERO Argentina",name:"Colorshop Santiago del Estero VIII",position:[-27.7989749,-64.2537375]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn esq. Jujuy Baradero ll BA NORTE BUENOS AIRES Argentina",name:"Colorshop Baradero ll",position:[-33.8235408,-59.51653589999999]},
{category:"comercios",type:"colorshop",address:"Av. Beir\u00f3 3278 Villa Devoto CABA CABA Argentina",name:"Colorshop Villa Devoto lll",position:[-34.5980505,-58.4989595]},
{category:"comercios",type:"colorshop",address:"NO ABRI\u00d3 Bel\u00e9n  NOA CATAMARCA Argentina",name:"Colorshop Bel\u00e9n",position:[-27.650239,-67.02594839999999]},
{category:"comercios",type:"colorshop",address:"Av. San Martin 697 Plaza Huincul PATAGONIA NEUQUEN Argentina",name:"Colorshop Plaza Huincul",position:[-38.9304588,-69.2038106]},
{category:"comercios",type:"colorshop",address:"DARDO LAFALCE 51 (esq. 25 de Mayo) Salto  BA NORTE BUENOS AIRES Argentina",name:"Colorshop Salto",position:[-34.2955369,-60.2412836]},
{category:"comercios",type:"colorshop",address:"Av. C\u00f3rdoba 872 Retiro  CABA CABA Argentina",name:"Colorshop Retiro",position:[-34.5990879,-58.3792758]},
{category:"comercios",type:"colorshop",address:"Gral Guemes 545 El Carril NOA SALTA Argentina",name:"Colorshop El Carril",position:[-25.080125,-65.4883134]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn 955 Tres Arroyos BA SUR BUENOS AIRES Argentina",name:"Colorshop Tres Arroyos IV",position:[-38.3696873,-60.2847008]},
{category:"comercios",type:"colorshop",address:"Avenida 25 de Mayo 1085 Resistencia V NEA CHACO Argentina",name:"Colorshop Resistencia V",position:[-27.4426296,-58.99632889999999]},
{category:"comercios",type:"colorshop",address:"Bv. Ricardo Balbin 2086 Concepci\u00f3n del NEA ENTRE RIOS Argentina",name:"Colorshop Concepci\u00f3n del Uruguay II",position:[-32.5175643,-59.1041758]},
{category:"comercios",type:"colorshop",address:"AV. ANGEL MARIA BRUZZO 1355 Bella Vista NEA CORRIENTES Argentina",name:"Colorshop Bella Vista",position:[-28.516423,-59.043741]},
{category:"comercios",type:"colorshop",address:"Av. Italia 172 Perico  NOA JUJUY Argentina",name:"Colorshop Perico",position:[-24.3827026,-65.11549330000001]},
{category:"comercios",type:"colorshop",address:"Heriberto Sandoval 255, P3600 Formosa Formosa ll NEA FORMOSA Argentina",name:"Colorshop Formosa ll",position:[-26.192127,-58.21573650000001]},
{category:"comercios",type:"colorshop",address:"Avenida General Roca 260 Gral Levalle CENTRO CORDOBA Argentina",name:"Colorshop Gral Levalle",position:[-34.0064853,-63.9237796]},
{category:"comercios",type:"colorshop",address:"Boulevar Buenos Aires 630 La Playosa CENTRO CORDOBA Argentina",name:"Colorshop La Playosa",position:[-32.1007596,-63.03202769999999]},
{category:"comercios",type:"colorshop",address:"Sarmiento 236 Jovita  CENTRO CORDOBA Argentina",name:"Colorshop Jovita",position:[-31.4148491,-64.1792179]},
{category:"comercios",type:"colorshop",address:"Roca 172 Sierra de BA SUR BUENOS AIRES Argentina",name:"Colorshop Sierra de la Ventana",position:[-38.1384,-61.7950]},
{category:"comercios",type:"colorshop",address:"AV. TISSERA 663 loc.7 Mendiolaza  CENTRO CORDOBA Argentina",name:"Colorshop Mendiolaza",position:[-31.25817899999999,-64.2915308]},
{category:"comercios",type:"colorshop",address:"Ruta 3 KM 1 San Luis CUYO SAN LUIS Argentina",name:"Colorshop San Luis",position:[-33.3169975,-66.3299407]},
{category:"comercios",type:"colorshop",address:"General Paz 259 Chos Malal PATAGONIA NEUQUEN Argentina",name:"Colorshop Chos Malal",position:[-37.3750821,-70.273094]},
{category:"comercios",type:"colorshop",address:"California 320 Armstrong  CENTRO SANTA FE Argentina",name:"Colorshop Armstrong",position:[-32.7828968,-61.6040831]},
{category:"comercios",type:"colorshop",address:"Nicol\u00e1s Avellaneda 557 Choele Choel PATAGONIA RIO NEGRO Argentina",name:"Colorshop Choele Choel",position:[-39.2896389,-65.663844]},
{category:"comercios",type:"colorshop",address:"AV SAN MARTIN 423 Trevelin  PATAGONIA CHUBUT Argentina",name:"Colorshop Trevelin",position:[-43.084898,-71.4640004]},
{category:"comercios",type:"colorshop",address:"Belgrano 699 esq. Arenales  Azul  BA SUR BUENOS AIRES Argentina",name:"Colorshop Azul",position:[-34.6125887,-58.3760506]},
{category:"comercios",type:"colorshop",address:"Av. Juan Jos\u00e9 Paso 2322 Punta Alta BA SUR BUENOS AIRES Argentina",name:"Colorshop Punta Alta II",position:[-38.8608786,-62.0832256]},
{category:"comercios",type:"colorshop",address:"Avenida Tierney 147 Vicu\u00f1a Mackenna CENTRO CORDOBA Argentina",name:"Colorshop Vicu\u00f1a Mackenna ll",position:[-33.9115539,-64.3881145]},
{category:"comercios",type:"colorshop",address:"AV. LIBERTADOR GENERAL SAN MARTIN 1692 Mar de BA SUR BUENOS AIRES Argentina",name:"Colorshop Mar de Aj\u00f3",position:[-34.5975431,-58.56140009999999]},
{category:"comercios",type:"colorshop",address:"Av. 9 de julio y Bonoli (Local 3) Villa Regina PATAGONIA RIO NEGRO Argentina",name:"Colorshop Villa Regina II",position:[-39.0961801,-67.0779861]},
{category:"comercios",type:"colorshop",address:"Michael Jones esq Juan de Garay Trelew III PATAGONIA CHUBUT Argentina",name:"Colorshop Trelew III",position:[-43.2637228,-65.2963781]},
{category:"comercios",type:"colorshop",address:"Av. San Mart\u00edn 1699  Caseros II GBA BUENOS AIRES Argentina",name:"Colorshop Caseros II",position:[-34.597678,-58.5615319]},
{category:"comercios",type:"colorshop",address:"Tucuman 540 QUITILIPI  NEA CHACO Argentina",name:"Colorshop QUITILIPI",position:[-26.8720118,-60.21676889999999]},
{category:"comercios",type:"colorshop",address:"Gobernador Moyano 406 Piedrabuena  PATAGONIA SANTA CRUZ Argentina",name:"Colorshop Piedrabuena",position:[-49.979323,-68.91841099999999]},
{category:"comercios",type:"colorshop",address:"Av. Raya esq Blanco Lomas de NOA TUCUMAN Argentina",name:"Colorshop Lomas de Taf\u00ed",position:[-26.7809776,-65.2357694]},
{category:"comercios",type:"colorshop",address:"Av. Espa\u00f1a 1225  La Falda CENTRO CORDOBA Argentina",name:"Colorshop La Falda",position:[-31.106223,-64.4818729]},
{category:"comercios",type:"colorshop",address:"Santa Rosa 1380 Ituzaing\u00f3 III GBA BUENOS AIRES Argentina",name:"Colorshop Ituzaing\u00f3 III",position:[-34.6441083,-58.65722100000001]},
{category:"comercios",type:"colorshop",address:"Av Mitre 578 Salto ll BA NORTE BUENOS AIRES Argentina",name:"Colorshop Salto ll",position:[-34.2940751,-60.2516218]},
{category:"comercios",type:"colorshop",address:"Av. Gral. G\u00fcemes Nte. 165 Cafayate  NOA SALTA Argentina",name:"Colorshop Cafayate",position:[-26.0704021,-65.97476739999999]}];
